import dispatcher from 'js/core/dispatcher';
import { firebaseDatabase } from 'js/library/utils/firebaseUtils';
import { uploadImage, downloadImageAsBlob } from 'js/library/services/StorageManager';
import {
  updateDatabase,
  removeDatabase,
  b64toBlob,
  updateDatabaseArray,
  verificarAprovacaoPromocao,
} from 'js/library/services/DatabaseManager';
import createStore from 'js/library/utils/API/Store/apiCreateStore';
import { updateStore, updateStoreApprovals } from 'js/library/utils/API/Store/apiUpdateStore';
import { getUserInfo } from 'js/library/utils/helpers';
import { updateHasPromo } from 'js/library/utils/API/Store/apiUpdateHasPromo';

export const updateStoreGroupInformationAction = (
  dispatch,
  idStore,
  active,
  level,
  region,
  description,
  keywordsSetConsumptionList,
  messageBlockedKw,
  showQRCode,
  title,
  autoValidate,
  hasVoucher,
  enableDate,
  disableDate,
  isPublished,
  keywords,
  tags,
  fileStoreThumbnail,
  oldStoreThumbnail,
  storeThumbnail,
  fileStorePhoto,
  oldStorePhoto,
  storePhoto,

  idEstabelecimento,
  idGestor,
  nomeEstabelecimento,
  lat,
  long,
  address,
  neighborhood,

  buttonLabel,
  buttonDescription,
  showButtonDescription,
  goToPlaceLabel,
  showGoToPlace,

  idGroup,
  state,
  created,
  groupName,
  plural,
  singular,
  groupTitle,
  groupDetailsTitle,
  groupDescription,
  groupDetailsDescription,
  groupType,
  counterRoof,
  equalImageThumbnailFile,
  equalImagePhotoFile,
  fileThumbnail,
  oldThumbnail,
  thumbnail,
  fileFoto,
  oldFoto,
  photo,
  oldGroup,
  templatesGroup,

  templates,
  templateCounterMatch,
  oldPhotoList,
  photoListFile,
  messageApproval,
  newStatus,
  expirationType = null,
  onSendExpiration = null,
  disableDateCoupon = null
) => {
  toDataURL(equalImageThumbnailFile).then((equalImageThumbnailFile) => {
    toDataURL(equalImagePhotoFile).then((equalImagePhotoFile) => {
      toDataURL(templateCounterMatch.equalImageThumbnailFile).then(
        (templateCounterMatchEqualImageThumbnailFile) => {
          toDataURL(templateCounterMatch.equalImagePhotoFile).then(
            async (templateCounterMatchEqualImagePhotoFile) => {
              let newPromotion = idStore ? false : true;

              //tratamento de variaveis
              long = long === undefined ? 0 : long;
              lat = lat === undefined ? 0 : lat;

              //dados de Voucher Group
              idGroup =
                idGroup === 'newGroup' || idGroup === null
                  ? firebaseDatabase.ref('Voucher-Group').push().key
                  : idGroup;

              let currentDate = Date.now();

              if (fileThumbnail !== null || equalImageThumbnailFile !== null) {
                thumbnail = 'storage:' + idGroup + ':thumbnail:' + currentDate;
                fileThumbnail =
                  equalImageThumbnailFile === null
                    ? b64toBlob(fileThumbnail)
                    : b64toBlob(equalImageThumbnailFile);
              }
              //CASO A IMAGEM NÃO TENHA SIDO ALTERADA, A LÓGICA TENTARA SUBIR NO STORAGE O LINK BAIXADO
              //CONVERTER LINK EM FILE E TRATAR SE É FILE OU LINK

              if (fileFoto !== null || equalImagePhotoFile !== null) {
                photo = 'storage:' + idGroup + ':foto:' + currentDate;
                fileFoto =
                  equalImagePhotoFile === null
                    ? b64toBlob(fileFoto)
                    : b64toBlob(equalImagePhotoFile);
              }

              if (enableDate !== null && typeof enableDate === 'string') {
                //convertendo a data para milisegundos
                enableDate = enableDate.split('T');

                let date = enableDate[0].split('-');
                let hour = enableDate[1].split(':');

                enableDate = new Date(
                  parseInt(date[0], 10),
                  parseInt(date[1] - 1, 10),
                  parseInt(date[2], 10),
                  parseInt(hour[0], 10),
                  parseInt(hour[1], 10)
                ).getTime();
              }

              if (disableDate !== null && typeof disableDate === 'string') {
                //convertendo a data para milisegundos
                disableDate = disableDate.split('T');

                let date = disableDate[0].split('-');
                let hour = disableDate[1].split(':');

                disableDate = new Date(
                  parseInt(date[0], 10),
                  parseInt(date[1] - 1, 10),
                  parseInt(date[2], 10),
                  parseInt(hour[0], 10),
                  parseInt(hour[1], 10)
                ).getTime();
              }

              if (
                disableDateCoupon !== null &&
                disableDateCoupon !== undefined &&
                typeof disableDateCoupon === 'string'
              ) {
                //convertendo a data para milisegundos
                disableDateCoupon = disableDateCoupon.split('T');

                let date = disableDateCoupon[0].split('-');
                let hour = disableDateCoupon[1].split(':');

                disableDateCoupon = new Date(
                  parseInt(date[0], 10),
                  parseInt(date[1] - 1, 10),
                  parseInt(date[2], 10),
                  parseInt(hour[0], 10),
                  parseInt(hour[1], 10)
                ).getTime();
              }

              const numberToMillis = (number) => {
                return number * 86400000;
              };

              //dados dos Templates
              let templatesId = [];
              let objTemplatesId = {};

              for (let i = 0; i < templates.length; i++) {
                templatesId.push(
                  templates[i].id
                    ? templates[i].id
                    : firebaseDatabase.ref('Voucher-Template-v2').push().key
                );

                objTemplatesId[templates[i].id] = parseInt(templates[i].value, 10);

                templates[i].id = null;

                templates[i].dataGeracao =
                  templates[i].dataGeracao === null ? Date.now() : templates[i].dataGeracao;

                templates[i].qtdSimultaneo =
                  templates[i].hasSimultaneo === false || showQRCode
                    ? 1
                    : parseInt(templates[i].qtdSimultaneo, 10);
                templates[i].qtdDisponivel =
                  templates[i].qtdDisponivel === undefined || templates[i].qtdDisponivel === null
                    ? null
                    : parseInt(templates[i].qtdDisponivel, 10);
                templates[i].hasSimultaneo = null;

                templates[i].idEstabelecimento = idEstabelecimento;
                templates[i].idGestor = idGestor;
                templates[i].nomeEstabelecimento = nomeEstabelecimento;
                templates[i].lat = lat;
                templates[i].long = long;
                templates[i].address = address;
                templates[i].neighborhood = neighborhood === undefined ? '' : neighborhood;
                templates[i].groupId = idGroup;
                templates[i].tags = tags;
                templates[i].expirationType = expirationType === undefined ? null : expirationType;
                templates[i].onSendExpiration =
                  expirationType === 'onSend' ? numberToMillis(onSendExpiration) : null;
                templates[i].disableDate = expirationType === 'fixed' ? disableDateCoupon : null;
                templates[i].disableDateCoupon = null;

                templates[i].cooldownHours =
                  templates[i].cooldownHours === null ? 0 : templates[i].cooldownHours;
                templates[i].cooldownMinutes =
                  templates[i].cooldownMinutes === null ? 0 : templates[i].cooldownMinutes;
                templates[i].cooldown =
                  (templates[i].cooldownHours * 60 * 60 + templates[i].cooldownMinutes * 60) *
                    1000 || 0;

                templates[i].cooldownHours = null;
                templates[i].cooldownMinutes = null;
                templates[i].generator =
                  templates[i].generator === false || templates[i].generator === undefined
                    ? null
                    : templates[i].generator;
              }

              templatesGroup =
                templatesGroup === undefined || templatesGroup === null ? {} : templatesGroup;

              templatesGroup[templatesId[0]] = parseInt(templates[0].value, 10);

              //dados do template Counter Match
              const templateCounterMatchId =
                templateCounterMatch.id === undefined || templateCounterMatch.id === null
                  ? firebaseDatabase.ref('Voucher-Template-v2').push().key
                  : templateCounterMatch.id;

              templateCounterMatch.cooldown =
                (templateCounterMatch.cooldownHours * 60 * 60 +
                  templateCounterMatch.cooldownMinutes * 60) *
                  1000 || 0;

              templateCounterMatch.disableDate = templateCounterMatch.hasDisableDate
                ? (templateCounterMatch.disableDateDays * 24 * 60 * 60 +
                    templateCounterMatch.disableDateHours * 60 * 60 +
                    templateCounterMatch.disableDateMinutes * 60) *
                  1000
                : null;

              let templateCounterMatchFileThumbnail = null;
              let templateCounterMatchFileFoto = null;

              if (
                templateCounterMatch.equalImageThumbnailFile !== null ||
                templateCounterMatch.fileThumbnail !== null
              ) {
                templateCounterMatch.thumbnail =
                  'storage:' + templateCounterMatchId + ':thumbnail:' + currentDate;

                templateCounterMatchFileThumbnail =
                  templateCounterMatch.equalImageThumbnailFile === null
                    ? b64toBlob(templateCounterMatch.fileThumbnail)
                    : b64toBlob(templateCounterMatchEqualImageThumbnailFile);
              }
              //CASO A IMAGEM NÃO TENHA SIDO ALTERADA, A LÓGICA TENTARA SUBIR NO STORAGE O LINK BAIXADO
              //CONVERTER LINK EM FILE E TRATAR SE É FILE OU LINK

              if (
                templateCounterMatch.equalImagePhotoFile !== null ||
                templateCounterMatch.fileFoto !== null
              ) {
                templateCounterMatch.foto =
                  'storage:' + templateCounterMatchId + ':foto:' + currentDate;

                templateCounterMatchFileFoto =
                  templateCounterMatch.equalImagePhotoFile === null
                    ? b64toBlob(templateCounterMatch.fileFoto)
                    : b64toBlob(templateCounterMatchEqualImagePhotoFile);
              }

              //informações do estabelecimento
              templateCounterMatch.idEstabelecimento =
                templateCounterMatch.idEstabelecimento === undefined ||
                templateCounterMatch.idEstabelecimento === null
                  ? idEstabelecimento
                  : templateCounterMatch.idEstabelecimento;
              templateCounterMatch.idGestor =
                templateCounterMatch.idGestor === undefined ||
                templateCounterMatch.idGestor === null
                  ? idGestor
                  : templateCounterMatch.idGestor;
              templateCounterMatch.nomeEstabelecimento = nomeEstabelecimento;
              templateCounterMatch.lat =
                templateCounterMatch.lat === undefined || templateCounterMatch.lat === null
                  ? lat
                  : templateCounterMatch.lat;
              templateCounterMatch.long =
                templateCounterMatch.long === undefined || templateCounterMatch.long === null
                  ? long
                  : templateCounterMatch.long;
              templateCounterMatch.address =
                templateCounterMatch.address === undefined || templateCounterMatch.address === null
                  ? address
                  : templateCounterMatch.address;
              templateCounterMatch.neighborhood =
                templateCounterMatch.neighborhood === undefined ||
                templateCounterMatch.neighborhood === null
                  ? neighborhood === undefined
                    ? ''
                    : neighborhood
                  : templateCounterMatch.neighborhood;

              templateCounterMatch.dataGeracao =
                templateCounterMatch.dataGeracao === null ||
                templateCounterMatch.dataGeracao === undefined
                  ? Date.now()
                  : templateCounterMatch.dataGeracao;
              templateCounterMatch.generator =
                templateCounterMatch.generator === false ||
                templateCounterMatch.generator === undefined
                  ? null
                  : templateCounterMatch.generator;
              templateCounterMatch.tags = tags;
              templateCounterMatch.qtdSimultaneo = templateCounterMatch.hasSimultaneo
                ? parseInt(templateCounterMatch.qtdSimultaneo, 10)
                : 1;

              //anulando informações desnecessárias no banco
              templateCounterMatch.id = null;
              templateCounterMatch.hasDisableDate = null;
              templateCounterMatch.equalImagePhoto = null;
              templateCounterMatch.equalImageThumbnail = null;
              templateCounterMatch.equalImagePhotoFile = null;
              templateCounterMatch.equalImageThumbnailFile = null;
              templateCounterMatch.cooldownHours = null;
              templateCounterMatch.cooldownMinutes = null;
              templateCounterMatch.disableDateDays = null;
              templateCounterMatch.disableDateHours = null;
              templateCounterMatch.disableDateMinutes = null;
              templateCounterMatch.loadedFoto = null;
              templateCounterMatch.loadedThumbnail = null;
              templateCounterMatch.hasSimultaneo = null;
              templateCounterMatch.sameInformation = null;
              templateCounterMatch.fileFoto = null;
              templateCounterMatch.fileThumbnail = null;

              //dados da Store
              idStore = idStore === null ? firebaseDatabase.ref('Store').push().key : idStore;

              autoValidate = autoValidate === 'cliente' ? true : null;

              // buttonLabel = buttonLabel === "" ? null : buttonLabel;
              // buttonDescription =
              //   buttonDescription === "" ? null : buttonDescription;
              showButtonDescription = showButtonDescription === '' ? null : showButtonDescription;
              // goToPlaceLabel = goToPlaceLabel === "" ? null : goToPlaceLabel;
              showGoToPlace = showGoToPlace === '' ? null : showGoToPlace;

              region = region === 'local' ? null : region;

              // if (enableDate !== null) {
              //   //convertendo a data para milisegundos
              //   enableDate = enableDate.split("T");

              //   let date = enableDate[0].split("-");
              //   let hour = enableDate[1].split(":");

              //   enableDate = new Date(
              //     parseInt(date[0], 10),
              //     parseInt(date[1] - 1, 10),
              //     parseInt(date[2], 10),
              //     parseInt(hour[0], 10),
              //     parseInt(hour[1], 10)
              //   ).getTime();
              // }

              // if (disableDate !== null) {
              //   //convertendo a data para milisegundos
              //   disableDate = disableDate.split("T");

              //   let date = disableDate[0].split("-");
              //   let hour = disableDate[1].split(":");

              //   disableDate = new Date(
              //     parseInt(date[0], 10),
              //     parseInt(date[1] - 1, 10),
              //     parseInt(date[2], 10),
              //     parseInt(hour[0], 10),
              //     parseInt(hour[1], 10)
              //   ).getTime();
              // }

              //ativo se estiver publicado e dentro do disable e enable datetive = false;
              if (isPublished === true && Date.now() >= enableDate && Date.now() < disableDate) {
                active = true;
              } else {
                active = false;
              }

              if (fileStoreThumbnail !== null) {
                storeThumbnail = 'storage:' + idStore + ':thumbnail:' + currentDate;
                fileStoreThumbnail = b64toBlob(fileStoreThumbnail);
              }

              if (fileStorePhoto !== null) {
                storePhoto = 'storage:' + idStore + ':foto:' + currentDate;
                if (fileStorePhoto.indexOf('https') === 0) {
                  fileStorePhoto = await downloadImageAsBlob(fileStorePhoto);
                } else {
                  fileStorePhoto = b64toBlob(fileStorePhoto);
                }
              }

              let id = firebaseDatabase.ref('/').push().key;
              let listaFotos = [];
              if (photoListFile !== null) {
                for (const key in photoListFile) {
                  listaFotos[key] = 'gal' + key + '_' + id + ':' + currentDate;

                  if (photoListFile[key].indexOf('https') === 0) {
                    photoListFile[key] = await downloadImageAsBlob(photoListFile[key]);
                  } else {
                    photoListFile[key] = b64toBlob(photoListFile[key]);
                  }
                }
              } else {
                listaFotos = oldPhotoList;
              }

              //cadastro no banco
              //const pathname = '/admin/estabelecimento/' + idEstabelecimento + '/ofertas';

              let pathnameArray = window.location.pathname.split('/');

              let pathname = '/';

              if (pathnameArray[1] === 'admin' && pathnameArray[2] === 'estabelecimento') {
                pathname = '/admin/estabelecimento/' + idEstabelecimento + '/ofertas';
              } else if (pathnameArray[1] === 'admin' && pathnameArray[2] === 'aprovacao') {
                pathname = '/admin/aprovar/promocoes';
              } else {
                pathname = '/estabelecimento/' + idEstabelecimento + '/promocoes';
              }

              //dados da voucher group
              const storageFolderVoucherGroup = 'voucher';

              const dataVoucherGroup = {
                groupName,
                counterMatch: groupType === 'ilimitada' ? null : templateCounterMatchId,
                counterRoof: groupType === 'ilimitada' ? null : parseInt(counterRoof, 10),
                created: created === null ? Date.now() : created,
                description: groupDescription,
                detailsDescription: groupDetailsDescription,
                establishmentId: idEstabelecimento,
                establishmentName: nomeEstabelecimento,
                title: groupTitle,
                detailsTitle: groupDetailsTitle,
                photo,
                plural,
                singular,
                state,
                templates: templatesGroup,
                thumbnail,
                type: 'promo',
              };

              const storageFolderStore = 'promocao';

              const photoList = photoListFile === null ? oldPhotoList : listaFotos;

              let dataStore = {
                id: idStore,
                hasGroup: true,
                keyWordsSet: keywords,
                tags,
                // active,
                level: parseInt(level, 10),
                region: region === null ? '' : region,
                campanhaTriibo: templates[0].campanhaTriibo,
                description,
                dueDate: disableDate,
                showQRCode,
                thumbnail: storeThumbnail,
                title,
                type: 'promotion',
                establishmentId: idEstabelecimento,
                establishmentName: nomeEstabelecimento,
                lat,
                long,
                photo: storePhoto,
                templateId: templatesId[0],
                enableDate,
                disableDate,
                isPublished,
                autoValidate,
                photoList: photoList === null ? '' : photoList,
                // CASO O CAMPO JÁ EXISTA NO FIREBASE, E O USUÁRIO EDITAR ESSE CAMPO E ENVIA-LO VAZIO, SERA REMOVIMO DO BANDO.
                buttonLabel: buttonLabel.trim() === '' ? null : buttonLabel,
                // CASO O CAMPO JÁ EXISTA NO FIREBASE, E O USUÁRIO EDITAR ESSE CAMPO E ENVIA-LO VAZIO, SERA REMOVIMO DO BANDO.
                buttonDescription: buttonDescription.trim() === '' ? null : buttonDescription,
                showButtonDescription,
                // CASO O CAMPO JÁ EXISTA NO FIREBASE, E O USUÁRIO EDITAR ESSE CAMPO E ENVIA-LO VAZIO, SERA REMOVIMO DO BANDO.
                goToPlaceLabel: goToPlaceLabel.trim() === '' ? null : goToPlaceLabel,
                showGoToPlace,

                hasVoucher:
                  templates[0].generator === true
                    ? true
                    : templates[0].qtdDisponivel === null || templates[0].qtdDisponivel < 1
                    ? null
                    : true,
              };

              if (keywordsSetConsumptionList) {
                dataStore.keyWordsSetConsumption = keywordsSetConsumptionList;
              }

              if (messageBlockedKw) {
                dataStore.message = messageBlockedKw;
              }

              oldGroup = oldGroup === idGroup ? null : oldGroup + '/templates/' + templatesId[0];

              // const idPromotion = storeType === 'promotion' ? idStore : null;
              // const idProduct = storeType === 'product' ? idStore : null;
              // Aprovação
              const pathApproval = '/admin/aprovacao/promocaofidelidade';
              const uId = getUserInfo().uId;

              if (window.location.pathname === pathApproval) {
                let aprovacao = {};
                let oldAprovacao = await verificarAprovacaoPromocao(
                  idStore,
                  'promotion',
                  idEstabelecimento,
                  nomeEstabelecimento
                );

                if (newStatus) {
                  if (newStatus === 'aprovado') {
                    aprovacao = {
                      status: 'aprovado',
                      comment:
                        messageApproval && messageApproval !== ''
                          ? messageApproval
                          : oldAprovacao.comment,
                    };
                    dataStore.isPublished = true;
                    dataStore.active = true;
                  } else {
                    aprovacao = {
                      status: 'negado',
                      comment: messageApproval,
                    };
                    dataStore.isPublished = false;
                    dataStore.active = false;
                  }
                }

                if (oldAprovacao.status === 'negado') {
                  aprovacao = {
                    status: 'aberto',
                    comment: messageApproval,
                  };
                }

                aprovacao = JSON.parse(JSON.stringify(aprovacao));

                await updateStoreApprovals(uId, idStore, aprovacao, null).then(() => {
                  updateHasPromo(idEstabelecimento);
                });

                if (newPromotion) {
                  dataStore.isPublished = false;
                  dataStore.active = false;
                }
              }
              //Remove todos os campos undefined
              dataStore = JSON.parse(JSON.stringify(dataStore));

              if (oldStoreThumbnail !== storeThumbnail) {
                //await deleteImage(storageFolderStore, oldStoreThumbnail)
              }
              if (oldStorePhoto !== storePhoto) {
                //await deleteImage(storageFolderStore, oldStorePhoto)
              }
              if (oldThumbnail !== thumbnail) {
                //await deleteImage(storageFolderVoucherGroup, oldThumbnail)
              }
              if (oldFoto !== photo) {
                //await deleteImage(storageFolderVoucherGroup, oldFoto)
              }
              if (templateCounterMatch.oldThumbnail !== templateCounterMatch.thumbnail) {
                //await deleteImage(storageFolderVoucherGroup, templateCounterMatch.oldThumbnail)
              }
              if (templateCounterMatch.oldPhoto !== templateCounterMatch.foto) {
                //await deleteImage(storageFolderVoucherGroup, templateCounterMatch.oldPhoto)
              }

              if (oldPhotoList !== listaFotos) {
                //await deleteImage(storageFolderStore, oldPhotoList)
              }

              return dispatcher(
                dispatch,
                'STORE_PROMOTION',
                newPromotion
                  ? createStore(uId, idStore, dataStore, pathname).then(() => {
                      updateHasPromo(idEstabelecimento);
                    })
                  : updateStore(idStore, dataStore, pathname).then(() => {
                      updateHasPromo(idEstabelecimento);
                    }),
                updateDatabase('Voucher-Group', dataVoucherGroup, idGroup),
                //apagando o template do antigo grupo
                removeDatabase('Voucher-Group', oldGroup),
                updateDatabaseArray('Voucher-Template-v2', templates, templatesId),
                updateDatabase('Voucher-Template-v2', templateCounterMatch, templateCounterMatchId),

                uploadImage(storageFolderStore, photoListFile, listaFotos),
                uploadImage(storageFolderVoucherGroup, fileThumbnail, thumbnail),
                uploadImage(storageFolderVoucherGroup, fileFoto, photo),
                uploadImage(storageFolderStore, fileStoreThumbnail, storeThumbnail),
                uploadImage(storageFolderStore, fileStorePhoto, storePhoto),
                uploadImage(
                  storageFolderVoucherGroup,
                  templateCounterMatchFileThumbnail,
                  templateCounterMatch.thumbnail
                ),
                uploadImage(
                  storageFolderVoucherGroup,
                  templateCounterMatchFileFoto,
                  templateCounterMatch.foto
                )
              );
            }
          );
        }
      );
    });
  });
};

function toDataURL(url) {
  return new Promise((fulfill, reject) => {
    if (url === null) fulfill(null);
    else {
      var xhr = new XMLHttpRequest();
      xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
          fulfill(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    }
  });
}
