import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@mui/styles';
import { Route } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@mui/material';
import { Error, CheckCircle, WatchLater } from '@mui/icons-material';
import EventBusyRoundedIcon from '@mui/icons-material/EventBusyRounded';
import { ImQrcode } from 'react-icons/im';
import { FiSend } from 'react-icons/fi';
import { MdContentCopy } from 'react-icons/md';
import { RiCoupon2Line } from 'react-icons/ri';

//arquivo com as actions
import placeholder from 'styles/assets/placeholder/placeholder_thumbnail.png';
import { getUserInfo, isAdminPage } from 'js/library/utils/helpers';
import Loading from '../Loading/Loading';
import { ToastContainer, toast } from 'react-toastify';
import { getVoucherTemplate } from 'js/library/utils/API/getVoucherTemplate';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }

  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class DataTable extends React.Component {
  constructor(props) {
    super(props);

    const userInfo = getUserInfo();

    this.state = {
      order: 'desc',
      orderBy: 'active',
      rowsPerPage: window?.promoListOrganized ? window.promoListOrganized.rowsPerPage : 10,
      thumbnail: [props.folder, props.src],
      loadedImage: placeholder,
      dataEstabelecimento: props.dataEstabelecimento,
      establishmentId: props.establishmentId,
      uId: userInfo !== null ? userInfo.uId : null,
      disabledButtons: [],
      clients: JSON.parse(localStorage.getItem('ClientsSelect')) || [],
      isClients: false,
      generator: false,
      openDialog: false,
      // data: window?.promoListOrganized?.originalPromotionList ? window?.promoListOrganized?.originalPromotionList :
    };
  }

  handleRequestSort = (property) => (event) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({
      order,
      orderBy,
    });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({
      rowsPerPage: event.target.value,
    });
  };

  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  setPagePosition = (key, index, n) => {
    const { page } = this.props;
    const { rowsPerPage } = this.state;

    switch (true) {
      case n.aprovacao && n.aprovacao.status === 'negado':
        localStorage.setItem('statusPromotion', 'Reprovado');
        break;
      case n.aprovacao && n.aprovacao.status === 'aberto':
        localStorage.setItem('statusPromotion', 'Em Revisão');
        break;
      case n.isPublished === true && n.enableDate <= Date.now() && n.disableDate >= Date.now():
        localStorage.setItem('statusPromotion', 'Ativo');
        break;
      default:
        localStorage.setItem('statusPromotion', 'Inativo');
    }

    // GRAVA A LISTA EM CACHE PARA N PERDER A POSIÇÃO
    window.promoListOrganized = {
      page,
      rowsPerPage,
      establishmentId: this.props.establishmentId,
      promotionListActive: this.props.promotionList,
      originalPromotionList: this.props.copyPromotionList,
      newFilteredPromotionList: this.props.newFilteredPromotionList,
      isDisableCheck: this.props.isDisableCheck,
      isChecked: this.props.isChecked,
      loadingList: this.props.loadingList,
      type: 'promo-edit',
      promotionPositionClicked: key,
      index,
    };
  };

  componentDidMount() {
    let promotionPositionClicked = null;
    const activePromotions = window?.promoListOrganized?.newFilteredPromotionList;
    const promotionIndex = window?.promoListOrganized?.index;
    const isChecked = window?.promoListOrganized?.isChecked;

    if (window?.promoListOrganized) {
      if (activePromotions.length > 0 && !isChecked) {
        activePromotions.forEach((promo) => {
          if (promo.key === window?.promoListOrganized?.promotionPositionClicked) {
            promotionPositionClicked = window?.promoListOrganized?.promotionPositionClicked;
          } else {
            promotionPositionClicked =
              promotionIndex > 1 ? activePromotions[promotionIndex - 1]?.key : '';
          }
        });
      } else {
        promotionPositionClicked = window?.promoListOrganized?.promotionPositionClicked;
      }

      const element = document.getElementById(promotionPositionClicked);

      if (element?.scrollIntoView) {
        element.scrollIntoView({ block: 'start', behavior: 'auto' });
      }
    }
  }

  handleGetVoucherTemplate = (dataPromotion, history) => {
    getVoucherTemplate(dataPromotion.templateId)
      .then((data) => {
        this.setState({
          generator: data.result.generator,
        });

        // Verifica se o usuário tem acesso ou não
        if (this.state.generator) {
          this.setState({ openDialog: true });
        } else {
          history.push({
            pathname: '/admin/store/gerarVoucher',
            state: dataPromotion,
          });
        }
      })
      .catch((error) => {
        console.error('Erro na requisição da getVoucherTemplate', error);
      });
  };

  render() {
    const { classes } = this.props;
    const { order, orderBy, rowsPerPage } = this.state;
    const { page } = this.props;
    const data = this.props.promotionList;
    const downloadImage = this.props.downloadImage;

    return (
      <Route
        render={({ history }) => (
          <div className="scrollbar" style={{ width: '100%', paddingBottom: '10px' }}>
            <ToastContainer autoClose={4000} />
            <div className={classes.root}>
              <div className={classes.tableWrapper}>
                <Table className={classes.table} aria-labelledby="tableTitle">
                  <TableHead>
                    <TableRow>
                      {this.props.rows.map((row, index) => {
                        return (
                          <TableCell
                            key={`${row.id}-${index}`}
                            numeric={row.numeric}
                            padding={row.disablePadding ? 'none' : 'normal'}
                            sortDirection={orderBy === row.id ? order : false}
                            style={{ fontWeight: '550' }}
                          >
                            <Tooltip
                              title={row?.id === 'thumbnail' ? 'Thumbnail' : 'Ordenar'}
                              placement={row?.numeric ? 'bottom-end' : 'bottom-start'}
                              enterDelay={300}
                            >
                              <TableSortLabel
                                active={orderBy === row.id}
                                direction={order}
                                onClick={
                                  row.id === 'thumbnail' ? null : this.handleRequestSort(row.id)
                                }
                                style={{
                                  cursor: row.id === 'thumbnail' ? 'default' : 'pointer',
                                }}
                              >
                                {row?.label}
                              </TableSortLabel>
                            </Tooltip>
                          </TableCell>
                        );
                      }, this)}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {stableSort(data, getSorting(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((n, i) => {
                        let pathname = null;

                        const userAdmin = getUserInfo().admin;

                        if (isAdminPage() && this.props.adminToggle) {
                          if (n.hasGroup === true) {
                            pathname =
                              '/admin/estabelecimento/' +
                              this.state.establishmentId +
                              '/ofertas/promocaoAgrupada/edit/' +
                              n.key;
                          } else {
                            if (n.type === 'businessPartner') {
                              pathname =
                                '/admin/estabelecimento/' +
                                this.state.establishmentId +
                                '/promocaoexterna/' +
                                n.id +
                                '/editar/';
                            } else {
                              pathname =
                                '/admin/estabelecimento/' +
                                this.state.establishmentId +
                                '/ofertas/promocao/edit/' +
                                n.key;
                            }
                          }
                        } else {
                          if (n.hasGroup === true) {
                            pathname =
                              '/estabelecimento/' +
                              this.state.establishmentId +
                              '/promocaofidelidade/editar/';
                          } else {
                            if (n.type === 'businessPartner') {
                              pathname =
                                '/estabelecimento/' +
                                this.state.establishmentId +
                                '/promocaoexterna/' +
                                n.id +
                                '/editar/';
                            } else {
                              pathname =
                                '/estabelecimento/' +
                                this.state.establishmentId +
                                '/promocao/' +
                                n.id +
                                '/editar/';
                              localStorage.setItem('fromWherePromotion', 'estabelecimento-promo');
                            }
                          }
                        }

                        return (
                          <TableRow
                            id={n.key}
                            onClick={() => this.setPagePosition(n.key, i, n)}
                            hover
                            key={`${n.key}-${i}`}
                          >
                            <TableCell
                              scope="row"
                              padding="none"
                              folder="estabelecimento"
                              onClick={() => {
                                if (userAdmin && n.aprovacao?.status === 'aberto') {
                                  toast.error(
                                    'Não é possivel acessar uma promoção com status "Em Revisão", utilize a aba de curadoria!'
                                  );
                                } else {
                                  history.push({
                                    pathname,
                                    state: {
                                      ...n,
                                      dataEstabelecimento: this.state.dataEstabelecimento,
                                    },
                                  });
                                }
                              }}
                              tabIndex={-1}
                            >
                              {n.downloadedThumb ? (
                                <img
                                  src={n.downloadedThumb}
                                  onLoad={() =>
                                    downloadImage(n.thumbnail, n.key, n.downloadedThumb, n.type)
                                  }
                                  alt="Thumb da Oferta"
                                  className="thumbnail"
                                  width="65px"
                                />
                              ) : (
                                <Loading />
                              )}
                            </TableCell>

                            <TableCell
                              scope="row"
                              onClick={() => {
                                if (userAdmin && n.aprovacao?.status === 'aberto') {
                                  toast.error(
                                    'Não é possivel acessar uma promoção com status "Em Revisão", utilize a aba de curadoria!'
                                  );
                                } else {
                                  history.push({
                                    pathname,
                                    state: {
                                      ...n,
                                      dataEstabelecimento: this.state.dataEstabelecimento,
                                    },
                                  });
                                }
                              }}
                            >
                              {n.edicao ? n.edicao.changedFields.title : n.title}
                            </TableCell>

                            <TableCell
                              onClick={() => {
                                if (userAdmin && n.aprovacao?.status === 'aberto') {
                                  toast.error(
                                    'Não é possivel acessar uma promoção com status "Em Revisão", utilize a aba de curadoria!'
                                  );
                                } else {
                                  history.push({
                                    pathname,
                                    state: {
                                      ...n,
                                      dataEstabelecimento: this.state.dataEstabelecimento,
                                    },
                                  });
                                }
                              }}
                            >
                              {n.hasGroup === true
                                ? 'Fidelidade'
                                : n.type === 'promotion'
                                ? 'Simples'
                                : null || n.type === 'product'
                                ? 'Vitriine'
                                : null || n.type === 'businessPartner'
                                ? 'Site externo'
                                : null}
                            </TableCell>

                            <TableCell
                              scope="row"
                              onClick={() => {
                                if (userAdmin && n.aprovacao?.status === 'aberto') {
                                  toast.error(
                                    'Não é possivel acessar uma promoção com status "Em Revisão", utilize a aba de curadoria!'
                                  );
                                } else {
                                  history.push({
                                    pathname,
                                    state: {
                                      ...n,
                                      dataEstabelecimento: this.state.dataEstabelecimento,
                                    },
                                  });
                                }
                              }}
                            >
                              {(() => {
                                switch (true) {
                                  case n.aprovacao && n.aprovacao.status === 'negado':
                                    return 'Reprovado';
                                  case n.aprovacao && n.aprovacao.status === 'aberto':
                                    return 'Em Revisão';
                                  case n.isPublished === true &&
                                    n.enableDate <= Date.now() &&
                                    n.disableDate >= Date.now():
                                    return 'Ativo';
                                  default:
                                    return 'Inativo';
                                }
                              })()}
                            </TableCell>

                            <TableCell
                              scope="row"
                              align="right"
                              style={{ width: '10%', whiteSpace: 'nowrap' }}
                            >
                              <Tooltip title="Gerar cupom">
                                <span>
                                  <Button
                                    style={{
                                      margin: '0 5px',
                                      fontWeight: '550',
                                      color: 'white',
                                      textTransform: 'capitalize',
                                    }}
                                    onClick={() => {
                                      this.handleGetVoucherTemplate(n, history);
                                    }}
                                    disabled={
                                      n?.type !== 'promotion'
                                        ? true
                                        : n?.aprovacao?.status === 'negado'
                                        ? true
                                        : n?.aprovacao?.status === 'aberto'
                                        ? true
                                        : n?.isPublished === true &&
                                          n?.enableDate <= Date.now() &&
                                          n?.disableDate >= Date.now()
                                        ? false
                                        : true
                                    }
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                  >
                                    <RiCoupon2Line fontSize="22px" />
                                  </Button>
                                </span>
                              </Tooltip>

                              {this.state.openDialog && (
                                <Dialog
                                  open={this.state.openDialog}
                                  onClose={() => this.setState({ openDialog: false })}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                  BackdropProps={{
                                    style: { backgroundColor: 'rgba(0, 0, 0, 0.1)' },
                                  }}
                                  PaperProps={{ sx: { boxShadow: 'none' } }}
                                >
                                  <DialogContent>
                                    <DialogContentText
                                      id="alert-dialog-description"
                                      sx={{
                                        height: 'max-content',
                                        paddingLeft: '10px',
                                        paddingRight: '15px',
                                        marginTop: '10px',
                                      }}
                                    >
                                      <b>ATENÇÃO:</b> Não é possível gerar lotes para promoções
                                      ilimitadas!
                                    </DialogContentText>
                                  </DialogContent>
                                  <DialogActions>
                                    <Button onClick={() => this.setState({ openDialog: false })}>
                                      Fechar
                                    </Button>
                                  </DialogActions>
                                </Dialog>
                              )}

                              <Tooltip title="Gerar QRCode">
                                <span>
                                  <Button
                                    onClick={() => {
                                      history.push({
                                        pathname: 'promocoes/qrcode/generate',
                                        state: {
                                          ...n,
                                          dataEstabelecimento: this.state.dataEstabelecimento,
                                        },
                                      });
                                    }}
                                    style={{
                                      margin: '0 5px',
                                      fontWeight: '550',
                                      color: 'white',
                                      textTransform: 'capitalize',
                                    }}
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                  >
                                    <ImQrcode fontSize="22px" />
                                  </Button>
                                </span>
                              </Tooltip>
                              <Tooltip title="Enviar Cupom">
                                <span>
                                  <Button
                                    onClick={() => {
                                      localStorage.setItem('statePromotion', JSON.stringify(n));

                                      this.props.toggleSendVoucherModal(true, n);
                                    }}
                                    disabled={
                                      n.isPublished === true &&
                                      n.enableDate <= Date.now() &&
                                      n.disableDate >= Date.now() &&
                                      (n.type === 'promotion' || n.type === 'product')
                                        ? false
                                        : true
                                    }
                                    style={{
                                      margin: '0 5px',
                                      fontWeight: '550',
                                      color: 'white',
                                      textTransform: 'capitalize',
                                    }}
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                  >
                                    <FiSend fontSize="22px" />
                                  </Button>
                                </span>
                              </Tooltip>
                              <Tooltip title="Duplicar Promoção">
                                <span>
                                  <Button
                                    onClick={() => {
                                      const disabledButtons = [...this.state.disabledButtons];
                                      disabledButtons[i] = true;
                                      this.setState({ disabledButtons });
                                      this.props.duplicatePromotion(n.key);
                                    }}
                                    disabled={this.state.disabledButtons[i] === true}
                                    style={{
                                      margin: '0 5px',
                                      fontWeight: '550',
                                      color: 'white',
                                      textTransform: 'capitalize',
                                    }}
                                    variant="contained"
                                    color="primary"
                                    type="button"
                                  >
                                    <MdContentCopy fontSize="22px" />
                                  </Button>
                                </span>
                              </Tooltip>
                            </TableCell>

                            <TableCell
                              scope="row"
                              // onClick={() =>
                              //   history.push({ pathname, state: n })
                              // }
                            >
                              {(() => {
                                switch (true) {
                                  case n.aprovacao && n.aprovacao.status === 'negado':
                                    return (
                                      <Tooltip title="Reprovado" placement="top">
                                        <Error style={{ color: 'red' }} />
                                      </Tooltip>
                                    );
                                  case n.aprovacao && n.aprovacao.status === 'aberto':
                                    return (
                                      <Tooltip title="Em Revisão" placement="top">
                                        <WatchLater style={{ color: '#08bad3' }} />
                                      </Tooltip>
                                    );
                                  case n.isPublished === true &&
                                    n.enableDate <= Date.now() &&
                                    n.disableDate >= Date.now():
                                    return (
                                      <Tooltip title="Ativo" placement="top">
                                        <CheckCircle style={{ color: 'lime' }} />
                                      </Tooltip>
                                    );
                                  default:
                                    return (
                                      <Tooltip title="Inativo" placement="top">
                                        <EventBusyRoundedIcon style={{ color: '#aaa' }} />
                                      </Tooltip>
                                    );
                                }
                              })()}
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </div>

              <TablePagination
                component="div"
                rowsPerPageOptions={[10, 25, 50, 100]}
                count={data.length}
                rowsPerPage={rowsPerPage}
                page={page}
                backIconButtonProps={{ 'aria-label': 'Previous Page' }}
                nextIconButtonProps={{ 'aria-label': 'Next Page' }}
                onPageChange={this.props.handleChangePage}
                onRowsPerPageChange={this.handleChangeRowsPerPage}
              />
            </div>
          </div>
        )}
      />
    );
  }
}

DataTable.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DataTable);
