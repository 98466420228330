import axios from 'axios';

import authTokenWithScUIDNull from 'js/library/utils/API/authTokenWithScUIDNull';
import cfac22 from '../cfac22';

export function authCode(cellPhone, location) {
  //OBTER INFORMAÇÕES DO USUÁRIO

  return new Promise((resolve, reject) => {
    authTokenWithScUIDNull()
      .then((token) => {
        axios
          .post(
            cfac22('API_HOST_V2') + '/authCode',
            {
              cellphone: cellPhone,
              platform: 'web-app',
              sendType: {
                sms: true,
                email: true,
                whatsapp: false,
              },
            },
            {
              headers: { authorization: `Bearer ${token}` },
            }
          )
          .then((result) => {
            // console.log('RESULTADO AUTH CODE ===>', result);

            let objModel = {};

            if (!result.data.success) {
              objModel = {
                cellPhone,
                transactionId: null,
                error: result.error,
              };
            } else {
              objModel = {
                cellPhone,
                transactionId: result.data.transactionId,
                error: null,
              };

              if (location === null) {
                window.pathname = '/login';
              }
            }

            resolve(objModel);
          })
          .catch((error) => {
            // console.log(error.response.data);
            reject(error.response.data);
          });
      })
      .catch((error) => {
        reject(error);
      });
  });
}
