import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1100px;
  padding: 1rem;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;

  h1 {
    text-align: center;
  }
`;

export const SearchSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  max-width: 1000px;
  width: 100%;
  gap: 1rem;
  align-items: center;

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: stretch;
  }
`;

export const SearchComponent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
`;

export const ErrorMessage = styled.p`
  color: red;
  font-size: 12px;
  margin-top: 0.5rem;
  text-align: left;
  display: ${({ filtered }) => (filtered ? 'block' : 'flex')};
  width: 100%;
  position: absolute;
  bottom: -1.5rem;
`;

export const BoxSearch = styled.form`
  display: flex;
  max-width: 600px;
  width: 100%;
  max-height: 42px;
  height: 100%;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
`;

export const InputBox = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
  padding: 5px;
`;

export const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  padding: 0.4rem 1rem;
  font-size: 16px;
  height: 100%;

  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
`;

export const SearchIconButton = styled.button`
  background: none;
  height: 100%;
  border-left: 1px solid #ccc;
  border-bottom: none;
  border-top: none;
  border-right: none;
  border-radius: 0;
  padding-left: 10px;
  cursor: pointer;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  img {
    width: 24px;
  }
`;

export const IconButton = styled(Link)`
  background: #6e3296;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    filter: opacity(0.9);
  }
`;

export const StyledTooltip = styled(Tooltip)`
  span {
    font-size: 12px;
    white-space: nowrap;
  }
`;

export const AddCampaignButton = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.3rem 1rem;
  background-color: #6e32960c;
  color: #6e3296;
  border: 1px dashed #6e3296;
  border-radius: 10px;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 600;
  font-family: Source Sans Pro;
  cursor: pointer;
  text-decoration: none;
  white-space: nowrap;

  span {
    font-size: 24px;
    margin-right: 8px;
  }

  &:hover {
    background-color: #6e329633;
  }
`;

