import React, { useEffect, useState } from 'react';
import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import { BiRadioCircle, BiRadioCircleMarked } from 'react-icons/bi';
import { Checkbox } from '@mui/material';
import { BsXLg } from 'react-icons/bs';
import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';

export const EditTextInputModal = (props) => {
  const {
    open,
    setOpen,
    input,
    valueInputModal,
    setValueInputModal,
    finalFormState,
    setFinalFormState,
  } = props;
  const [tagCaptureFinal, setTagCapturFinal] = useState('');
  const [valueInputFinal, setValueInputFinal] = useState('');
  const [checked, setChecked] = useState([false, false]);
  const [countryID, setCountryID] = useState(0);
  const [countryValue, setCountryValue] = useState('+55');

  // func para escolher o valor do input antigo e colocar no input da versão final
  const handleChange1 = (event) => {
    setChecked([event.target.checked, checked[1]]);

    //!checked[0] se selecionar o antigo, fica true se nao false
    if (!checked[0]) {
      if (input === 'Data') {
        setFinalFormState((prevState) => {
          const valorEntrada = valueInputModal.antigo;

          const partesDaData = valorEntrada.split('/');

          const data = new Date(partesDaData[2], partesDaData[1] - 1, partesDaData[0]);

          const dataFormatada = data.toISOString().split('T')[0];

          return {
            ...prevState,
            disableDate: dataFormatada,
          };
        });
      } else if (input === 'tags') {
        setFinalFormState((prevState) => {
          let tagHolder = finalFormState.tags;
          tagHolder.push(valueInputModal.antigo);
          tagHolder.filter((tag) => {
            if (typeof tag === 'string') {
              return tag;
            }
          });
          return {
            ...prevState,
            tags: tagHolder.flat(),
          };
        });
      } else if (input === 'Telefone Privado') {
        setFinalFormState((prevState) => {
          let newArr = [];
          let value = valueInputModal.antigo.replace('+55', '');
          // let concat = countryValue.concat(' ', value);
          const result = value.replace(/\D+/g, '');
          newArr.push(result);
          return {
            ...prevState,
            contatos: newArr,
          };
        });
      } else if (input === 'CEP') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            cep: valueInputModal.antigo,
          };
        });
      } else if (input === 'Rua') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            logradouro: valueInputModal.antigo,
          };
        });
      } else if (input === 'Numero') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            numero: valueInputModal.antigo,
          };
        });
      } else if (input === 'Complemento') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            complemento: valueInputModal.antigo,
          };
        });
      } else if (input === 'Bairro') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            bairro: valueInputModal.antigo,
          };
        });
      } else if (input === 'Cidade') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            cidade: valueInputModal.antigo,
          };
        });
      } else if (input === 'UF') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            estado: valueInputModal.antigo,
          };
        });
      } else {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            [input.toLocaleLowerCase()]: valueInputModal.antigo,
          };
        });
      }
    } else {
      if (input === 'Data') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            disableDate: valueInputFinal,
          };
        });
      } else if (input === 'Telefone Privado') {
        setFinalFormState((prevState) => {
          let value = valueInputFinal;
          // let concat = countryValue.concat(' ', value);
          const result = value.replace(/[\(\)\s-]/g, '');
          return {
            ...prevState,
            contatos: result,
          };
        });
      } else if (input === 'CEP') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            cep: valueInputFinal,
          };
        });
      } else if (input === 'Rua') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            logradouro: valueInputFinal,
          };
        });
      } else if (input === 'Numero') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            numero: valueInputFinal,
          };
        });
      } else if (input === 'Complemento') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            complemento: valueInputFinal,
          };
        });
      } else if (input === 'Bairro') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            bairro: valueInputFinal,
          };
        });
      } else if (input === 'Cidade') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            cidade: valueInputFinal,
          };
        });
      } else if (input === 'UF') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            estado: valueInputFinal,
          };
        });
      } else {
        setFinalFormState((prevState) => {
          const updatedTags = [];

          prevState.tags.forEach((tag) => {
            if (!valueInputModal.antigo.includes(tag)) {
              updatedTags.push(tag);
            }
          });

          return {
            ...prevState,
            [input]: valueInputFinal,
            tags: updatedTags,
          };
        });
      }
    }
  };

  // func para escolher o valor do input novo e colocar no input da versão final
  const handleChange2 = (event) => {
    setChecked([checked[0], event.target.checked]);

    if (!checked[1]) {
      if (input === 'Data') {
        setFinalFormState((prevState) => {
          const valorEntrada = valueInputModal.novo;

          const partesDaData = valorEntrada.split('/');

          const data = new Date(partesDaData[2], partesDaData[1] - 1, partesDaData[0]);

          const dataFormatada = data.toISOString().split('T')[0];

          return {
            ...prevState,
            disableDate: dataFormatada,
          };
        });
      } else if (input === 'tags') {
        setFinalFormState((prevState) => {
          let tagHolder = finalFormState.tags;
          tagHolder.push(valueInputModal.novo);
          tagHolder.filter((tag) => {
            if (typeof tag === 'string') {
              return tag;
            }
          });
          return {
            ...prevState,
            tags: tagHolder.flat(),
          };
        });
      } else if (input === 'Telefone Privado') {
        let newArr = [];
        const value = valueInputModal.novo.replace('+55', '');
        // let concat = countryValue.concat(' ', value);
        const result = value.replace(/\D+/g, '');
        newArr.push(result);
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            contatos: newArr,
          };
        });
      } else if (input === 'CEP') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            cep: valueInputModal.novo,
          };
        });
      } else if (input === 'Rua') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            logradouro: valueInputModal.novo,
          };
        });
      } else if (input === 'Numero') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            numero: valueInputModal.novo,
          };
        });
      } else if (input === 'Complemento') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            complemento: valueInputModal.novo,
          };
        });
      } else if (input === 'Bairro') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            bairro: valueInputModal.novo,
          };
        });
      } else if (input === 'Cidade') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            cidade: valueInputModal.novo,
          };
        });
      } else if (input === 'UF') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            estado: valueInputModal.novo,
          };
        });
      } else {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            [input.toLocaleLowerCase()]: valueInputModal.novo,
          };
        });
      }
    } else {
      if (input === 'Data') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            disableDate: valueInputFinal,
          };
        });
      } else if (input === 'Telefone Privado') {
        setFinalFormState((prevState) => {
          let value = valueInputFinal;
          // let concat = countryValue.concat(' ', value);
          const result = value.replace(/[\(\)\s-]/g, '');
          return {
            ...prevState,
            contatos: result,
          };
        });
      } else if (input === 'CEP') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            cep: valueInputFinal,
          };
        });
      } else if (input === 'Rua') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            logradouro: valueInputFinal,
          };
        });
      } else if (input === 'Numero') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            numero: valueInputFinal,
          };
        });
      } else if (input === 'Complemento') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            complemento: valueInputFinal,
          };
        });
      } else if (input === 'Bairro') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            bairro: valueInputFinal,
          };
        });
      } else if (input === 'Cidade') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            cidade: valueInputFinal,
          };
        });
      } else if (input === 'UF') {
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            estado: valueInputFinal,
          };
        });
      } else {
        setFinalFormState((prevState) => {
          const updatedTags = [];

          prevState.tags.forEach((tag) => {
            if (!valueInputModal.novo.includes(tag)) {
              updatedTags.push(tag);
            }
          });

          return {
            ...prevState,
            [input]: valueInputFinal,
            tags: updatedTags,
          };
        });
      }
    }
  };

  // func para pegar valor escrito do input da versão final.
  const handleChange = (e) => {
    setValueInputFinal(e.target.value);

    switch (input) {
      case 'nome':
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            nome: e.target.value,
          };
        });
        break;
      case 'descricao':
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            descricao: e.target.value,
          };
        });
        break;
      case 'Complemento':
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            complemento: e.target.value,
          };
        });
        break;
      case 'CEP':
        setFinalFormState((prevState) => ({
          ...prevState,
          cep: e.target.value,
        }));
        break;
      case 'Rua':
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            logradouro: e.target.value,
          };
        });
        break;
      case 'Numero':
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            numero: e.target.value,
          };
        });
        break;
      case 'Bairro':
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            bairro: e.target.value,
          };
        });
        break;
      case 'Cidade':
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            cidade: e.target.value,
          };
        });
        break;
      case 'UF':
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            estado: e.target.value,
          };
        });
        break;
      case 'Data':
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            disableDate: e.target.value,
          };
        });
        break;
      case 'email':
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            email: e.target.value,
          };
        });
        break;
      case 'site':
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            site: e.target.value,
          };
        });
        break;
      case 'Telefone Privado':
        let newArr = [];
        let value = e.target.value;
        // let concat = countryValue.concat(' ', value);
        const result = value.replace(/[\(\)\s-]/g, '');
        newArr.push(result);
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            contatos: newArr,
          };
        });
        break;
      default:
        break;
    }
  };

  const handleTagCapture = (e) => {
    setTagCapturFinal(e.target.value);
  };

  const handlePushTag = (e) => {
    let tagHolder = finalFormState.tags;
    if (
      tagHolder.indexOf(tagCaptureFinal.toLocaleLowerCase()) === -1 &&
      tagCaptureFinal.length !== 0
    ) {
      tagHolder.push(tagCaptureFinal);
    }
    setFinalFormState((prevState) => {
      return {
        ...prevState,
        tags: [...tagHolder],
      };
    });
    setTagCapturFinal('');
  };

  const handlePressEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handlePushTag();
    }
  };

  // Limpa os inputs no modal
  useEffect(() => {
    if (!open) {
      setValueInputModal({
        antigo: input === 'tags' ? [] : '',
        novo: input === 'tags' ? [] : '',
      });
    }
  }, [open]);

  useEffect(() => {
    const findDDD = phoneMaskArray.map(({ id, ddd }) => (+countryID === id ? ddd : null));

    const countryDDD = findDDD.filter((res) => res !== null);

    setCountryValue(countryDDD[0]);
  }, [countryID]);

  // modelo de dados antigo não compatovel, por favor usar o novo !

  return (
    <>
      <D.ModalContainer show={open}>
        <S.ModalEditText>
          <S.CardContainer modal={true}>
            <>
              <>
                {/* <FormControlLabel value="female" control={<Radio />} label="Female" /> */}
                <S.FormElement>
                  <S.FormLabel>{input} - Antigo</S.FormLabel>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'stretch',
                    }}
                  >
                    {input === 'tags' ? (
                      <S.TagsContainer>
                        <D.TriiboTagsField>
                          {valueInputModal.antigo.map((body, index) => (
                            <>
                              <D.UserTags key={index}>
                                <D.TagText key={body[index]}>{body}</D.TagText>
                              </D.UserTags>
                            </>
                          ))}
                        </D.TriiboTagsField>
                      </S.TagsContainer>
                    ) : (
                      <D.TriiboTextField
                        // style={{ background: 'green' }}
                        rows={5}
                        maxLength="40"
                        value={valueInputModal.antigo.replace(/-/g, '')}
                        disabled
                      />
                    )}
                    <Checkbox
                      checked={checked[0]}
                      disabled={
                        checked[1] || !valueInputModal.antigo || valueInputModal.antigo.length === 0
                      }
                      onChange={handleChange1}
                      icon={<BiRadioCircle size={35} color="#ffff" />}
                      checkedIcon={<BiRadioCircleMarked size={35} color="#ffff" />}
                    />
                  </div>
                </S.FormElement>
                <S.FormElement>
                  <S.FormLabel>{input} - Novo</S.FormLabel>
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      alignItems: 'stretch',
                    }}
                  >
                    {input === 'tags' ? (
                      <S.TagsContainer>
                        <D.TriiboTagsField>
                          {valueInputModal.novo.map((body, index) => (
                            <>
                              <D.UserTags key={index}>
                                <D.TagText key={body[index]}>{body}</D.TagText>
                              </D.UserTags>
                            </>
                          ))}
                        </D.TriiboTagsField>
                      </S.TagsContainer>
                    ) : (
                      <D.TriiboTextField
                        // style={{ background: 'purple' }}
                        rows={5}
                        maxLength="40"
                        // value={valueInputModal.novo.replace(/-/g, '')}
                        value={valueInputModal.novo.replace(/-/g, '')}
                        disabled
                      />
                    )}
                    <Checkbox
                      checked={checked[1]}
                      disabled={
                        checked[0] || !valueInputModal.novo || valueInputModal.novo.length === 0
                      }
                      onChange={handleChange2}
                      icon={<BiRadioCircle size={35} color="#ffffff" />}
                      checkedIcon={<BiRadioCircleMarked size={35} color="#ffffff" />}
                    />
                  </div>
                </S.FormElement>
                {input === 'tags' ? (
                  <S.FormElement>
                    <S.FormLabel>{input} - Versão final</S.FormLabel>
                    <S.TagsAddContainer width="true">
                      <D.TriiboTextField
                        rows={1}
                        placeholder={'Ex.pizza*'}
                        value={tagCaptureFinal}
                        onChange={(e) => {
                          handleTagCapture(e);
                        }}
                        onKeyDown={(e) => {
                          handlePressEnter(e);
                        }}
                      />
                      <S.TagsAddButton
                        blue={true}
                        onClick={(e) => {
                          handlePushTag(e);
                        }}
                      >
                        Adicionar tag
                      </S.TagsAddButton>
                    </S.TagsAddContainer>
                    <S.TagsContainer>
                      <D.TriiboTagsField>
                        {finalFormState.tags.map((body, index) => (
                          <>
                            <D.UserTags key={index}>
                              <D.TagText key={body[index]}>{body}</D.TagText>
                              <D.TagDelete
                                onClick={(e) => {
                                  e.preventDefault();
                                  let newArr = finalFormState.tags;
                                  newArr.splice(index, 1);
                                  setFinalFormState((prevState) => {
                                    return {
                                      ...prevState,
                                      tags: [...newArr],
                                    };
                                  });
                                }}
                              >
                                {<BsXLg style={{ color: 'white' }} />}
                              </D.TagDelete>
                            </D.UserTags>
                          </>
                        ))}
                      </D.TriiboTagsField>
                    </S.TagsContainer>
                  </S.FormElement>
                ) : input === 'Data' ? (
                  <S.FormElement>
                    <S.FormLabel>{input} - Versão final</S.FormLabel>
                    <D.TriiboDateField
                      type="date"
                      value={finalFormState.disableDate}
                      onChange={handleChange}
                    />
                  </S.FormElement>
                ) : input === 'descricao' ? (
                  <S.FormElement>
                    <S.FormLabel>{input} - Versão final</S.FormLabel>
                    <S.TextCountContainer>
                      <D.TriiboTextField
                        rows={10}
                        maxLength="600"
                        onChange={handleChange}
                        value={finalFormState.descricao}
                      />
                      <S.TextCounter>
                        {finalFormState.descricao.length} {'/600'}
                      </S.TextCounter>
                    </S.TextCountContainer>
                  </S.FormElement>
                ) : input === 'Telefone Privado' ? (
                  <S.FormElement>
                    <S.FormLabel>{input} - Versão final</S.FormLabel>
                    <S.TextCountContainer>
                      <S.InputContainer
                      // style={{ background: 'blue' }}
                      >
                        <S.LeftContainer>
                          <S.ContactCountryField
                            isPhone={true}
                            value={countryID}
                            onChange={(e) => {
                              e.preventDefault();
                              setCountryID(e.target.value);
                            }}
                          >
                            {phoneMaskArray.map(({ initials, emoji, ddd, id }, index) => {
                              return (
                                <S.Options key={id} value={id}>
                                  {emoji} {initials} {ddd}
                                </S.Options>
                              );
                            })}
                          </S.ContactCountryField>
                          <S.PlaceholderContainer>
                            {' '}
                            <S.ContactTextField
                              value={finalFormState.contatos[0]}
                              onChange={handleChange}
                              mask={
                                phoneMaskArray.filter((e) => e.id === parseInt(countryID))[0].mask
                              }
                            />
                          </S.PlaceholderContainer>
                        </S.LeftContainer>
                      </S.InputContainer>
                    </S.TextCountContainer>
                  </S.FormElement>
                ) : input === 'CEP' ? (
                  <S.FormElement>
                    <S.FormLabel>{input} - Versão final</S.FormLabel>
                    <S.TextCountContainer>
                      <D.TriiboTelField
                        type="number"
                        rows={1}
                        onChange={handleChange}
                        value={finalFormState.cep}
                      />
                    </S.TextCountContainer>
                  </S.FormElement>
                ) : input === 'Rua' ? (
                  <S.FormElement>
                    <S.FormLabel>{input} - Versão final</S.FormLabel>
                    <S.TextCountContainer>
                      <D.TriiboTextField
                        rows={1}
                        TriiboTextField
                        onChange={handleChange}
                        value={finalFormState.logradouro}
                      />
                    </S.TextCountContainer>
                  </S.FormElement>
                ) : input === 'Numero' ? (
                  <S.FormElement>
                    <S.FormLabel>{input} - Versão final</S.FormLabel>
                    <S.TextCountContainer>
                      <D.TriiboTelField
                        type="number"
                        rows={1}
                        onChange={handleChange}
                        value={finalFormState.numero}
                      />
                    </S.TextCountContainer>
                  </S.FormElement>
                ) : input === 'Complemento' ? (
                  <S.FormElement>
                    <S.FormLabel>{input} - Versão final</S.FormLabel>
                    <S.TextCountContainer>
                      <D.TriiboTextField
                        rows={1}
                        onChange={handleChange}
                        value={finalFormState.complemento}
                      />
                    </S.TextCountContainer>
                  </S.FormElement>
                ) : input === 'Bairro' ? (
                  <S.FormElement>
                    <S.FormLabel>{input} - Versão final</S.FormLabel>
                    <S.TextCountContainer>
                      <D.TriiboTextField
                        rows={1}
                        onChange={handleChange}
                        value={finalFormState.bairro}
                      />
                    </S.TextCountContainer>
                  </S.FormElement>
                ) : input === 'Cidade' ? (
                  <S.FormElement>
                    <S.FormLabel>{input} - Versão final</S.FormLabel>
                    <S.TextCountContainer>
                      <D.TriiboTextField
                        rows={1}
                        onChange={handleChange}
                        value={finalFormState.cidade}
                      />
                    </S.TextCountContainer>
                  </S.FormElement>
                ) : input === 'UF' ? (
                  <S.FormElement>
                    <S.FormLabel>{input} - Versão final</S.FormLabel>
                    <S.TextCountContainer>
                      <D.TriiboTextField
                        rows={1}
                        onChange={handleChange}
                        value={finalFormState.estado}
                      />
                    </S.TextCountContainer>
                  </S.FormElement>
                ) : (
                  <S.FormElement>
                    <S.FormLabel>{input} - Versão final</S.FormLabel>
                    <S.TextCountContainer>
                      <D.TriiboTextField
                        rows={1}
                        maxLength="600"
                        onChange={handleChange}
                        value={finalFormState[input]}
                      />
                    </S.TextCountContainer>
                  </S.FormElement>
                )}
              </>
            </>
          </S.CardContainer>
          <S.ButtonContainer modal={true}>
            <D.TriiboWhiteButton
              blue={true}
              onClick={() => {
                setOpen(false);
                setChecked([false, false]);
              }}
            >
              Cancelar
            </D.TriiboWhiteButton>
            <D.TriiboFilledButton
              blue={true}
              disabled={!checked[0] && !checked[1]}
              onClick={() => {
                setOpen(false);
                setChecked([false, false]);
                if (input === 'CEP') {
                  setFinalFormState((prevState) => {
                    return {
                      ...prevState,
                      cep: finalFormState.cep,
                    };
                  });
                } else if (input === 'Rua') {
                  setFinalFormState((prevState) => {
                    return {
                      ...prevState,
                      logradouro: finalFormState.logradouro,
                    };
                  });
                } else if (input === 'Numero') {
                  setFinalFormState((prevState) => {
                    return {
                      ...prevState,
                      numero: finalFormState.numero,
                    };
                  });
                } else if (input === 'Complemento') {
                  setFinalFormState((prevState) => {
                    return {
                      ...prevState,
                      complemento: finalFormState.complemento,
                    };
                  });
                } else if (input === 'Bairro') {
                  setFinalFormState((prevState) => {
                    return {
                      ...prevState,
                      bairro: finalFormState.bairro,
                    };
                  });
                } else if (input === 'Cidade') {
                  setFinalFormState((prevState) => {
                    return {
                      ...prevState,
                      cidade: finalFormState.cidade,
                    };
                  });
                } else if (input === 'UF') {
                  setFinalFormState((prevState) => {
                    return {
                      ...prevState,
                      estado: finalFormState.estado,
                    };
                  });
                } else if (input === 'Data') {
                  setFinalFormState((prevState) => {
                    return {
                      ...prevState,
                      disableDate:
                        new Date(finalFormState.disableDate).getTime() + 1000 * 60 * 60 * 3,
                    };
                  });
                } else if (input === 'Telefone Privado') {
                  let newArr = [];
                  let value = finalFormState.contatos;
                  let concat = countryValue.concat(' ', value);
                  const result = concat.replace(/[\(\)\s-]/g, '');
                  newArr.push(result);
                  setFinalFormState((prevState) => {
                    return {
                      ...prevState,
                      contatos: newArr,
                    };
                  });
                } else {
                  setFinalFormState((prevState) => {
                    return {
                      ...prevState,
                      [input.toLocaleLowerCase()]: finalFormState[input],
                    };
                  });
                }
              }}
            >
              Salvar
            </D.TriiboFilledButton>
          </S.ButtonContainer>
        </S.ModalEditText>
        <D.ModalBackground
          onClick={() => {
            setOpen(false);
            setChecked([false, false]);
          }}
        />
      </D.ModalContainer>
    </>
  );
};
