import React, { Component } from 'react';
import { withStyles } from '@mui/styles';
import { Redirect } from 'react-router-dom';

import { TextField, Button, FormLabel, FormControl, Snackbar } from '@mui/material';
import { AddPhotoAlternate, Save } from '@mui/icons-material';

import Loading from 'js/containers/Loading/Loading';
import { financialCredit_v1 } from 'js/library/utils/API/apiFinancialCredit';
import { ToastContainer, toast } from 'react-toastify';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

const styles = () => ({
  root: {
    background: '#ffffff',
    borderRadius: 5,
    border: '1px solid #6c6c6c',
    color: 'white',
  },
});

class FinancialCredits extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      value: 0,
      eventType: null,
      eventDescription: null,
      clientList: null,

      openCheck: false,
      loading: false,
    };

    window.pathname = null;
  }

  //altera os states dos campos, caso o usuário insira informações nos input
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  fileChangedHandler = (name) => (event) => {
    if (event.target.files.length !== 0) {
      this.setState({
        [name]: event.target.files[0],
      });
    }
  };

  readTxt(txtFile) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      let dataArray = [];

      reader.onload = function () {
        const data = this.result.split('\n');
        for (let index = 0; index < data.length; index++) {
          if (
            data[index] !== '' &&
            data[index] !== '\n' &&
            data[index] !== '\r' &&
            data[index] !== '\r\n'
          ) {
            dataArray.push(data[index].trim().replace(/[.]/gi, ','));
          }
        }

        resolve(dataArray);
      };
      reader.readAsText(txtFile);
    });
  }

  checkInformation = async (event) => {
    event.preventDefault();

    if (this.state.clientList === null) {
      this.setState({ openCheck: true });
    } else {
      this.setState({ loading: true });

      this.readTxt(this.state.clientList).then(async (clientList) => {
        try {
          const promises = clientList.map((client, index) => {
            return financialCredit_v1(
              client,
              this.state.value,
              this.state.eventType,
              this.state.eventDescription
            );
          });

          await Promise.all(promises);

          toast.success('Pontos creditados com sucesso!');
          setTimeout(() => {
            window.pathname = '/admin/configuracoes';
            this.setState({ loading: false });
          }, 2000);
        } catch (error) {
          toast.error('Erro ao creditar pontos.');
          this.setState({ loading: false });
        }
      });
    }
  };

  handleCloseAlert = () => {
    this.setState({
      openCheck: false,
    });
  };

  render = () => {
    const { classes } = this.props;

    if (this.props.loading && this.state.loading)
      return (
        <div className="loading">
          <Loading />
        </div>
      );
    else {
      if (window.pathname !== null) {
        return <Redirect to={window.pathname} />;
      } else {
        return (
          <div style={{ padding: '4rem 32px' }}>
            <GoBackHeader />
            <HeaderTitle pageInfo="Creditar Pontos" />
            <ToastContainer autoClose={2000} />
            <form onSubmit={this.checkInformation}>
              <FormControl className="input-field">
                <TextField
                  style={{ width: '300px' }}
                  className="input-field"
                  onChange={this.handleChange('value')}
                  value={this.state.value}
                  type="number"
                  id="value"
                  required
                  label="Quantidade de Pontos"
                />

                <TextField
                  required
                  className="input-field"
                  type="text"
                  value={this.state.eventType === null ? '' : this.state.eventType}
                  id="eventType"
                  label="Tipo do Evento "
                  onChange={this.handleChange('eventType')}
                />

                <TextField
                  required
                  className="input-field"
                  multiline
                  type="text"
                  value={this.state.eventDescription === null ? '' : this.state.eventDescription}
                  id="eventDescription"
                  label="Descrição do Evento"
                  onChange={this.handleChange('eventDescription')}
                />

                <FormLabel style={{ marginTop: '20px' }} component="legend">
                  Arquivo para o envio da notificação
                </FormLabel>

                <input
                  id="thubnailStore"
                  accept=".txt"
                  style={{ display: 'none' }}
                  type="file"
                  onChange={this.fileChangedHandler('clientList')}
                />

                <label htmlFor="thubnailStore">
                  <Button
                    style={{
                      textTransform: 'capitalize',
                      marginTop: '20px',
                      fontWeight: '550',
                      color: '#6c6c6c',
                    }}
                    variant="contained"
                    component="span"
                    classes={{ root: classes.root, disabled: classes.disabled }}
                    startIcon={<AddPhotoAlternate color="secondary" />}
                  >
                    Adicionar Arquivo
                  </Button>{' '}
                  {this.state.clientList === null ? '' : this.state.clientList.name}
                </label>
              </FormControl>

              <Button
                style={{
                  marginTop: '20px',
                  color: 'white',
                  textTransform: 'capitalize',
                  fontWeight: '550',
                }}
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<Save />}
              >
                Salvar
              </Button>
            </form>

            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={this.state.openCheck}
              onClose={this.handleCloseAlert}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={
                <span id="message-id">
                  Insira o arquivo com os e-mails dos usuários que serão creditados.
                </span>
              }
            />
          </div>
        );
      }
    }
  };
}

export default withStyles(styles)(FinancialCredits);

