import React, { Component } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { withStyles } from '@mui/styles';

import { Link, Redirect } from 'react-router-dom';

import { Button, Grid } from '@mui/material';
import { Add } from '@mui/icons-material';
import Loading from 'js/containers/Loading/Loading';

import { getDataRedshift } from 'js/library/utils/API/apiRedshift.js';
import { updateBatchActionsAction } from 'js/core/actions/batchActionsActions';

import DataTableQueries from 'js/containers/DataTable/DataTableQueries';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

const styles = (theme) => ({
  root: {
    background: '#ffffff',
    borderRadius: 5,
    border: '1px solid #6c6c6c',
    color: 'white',
  },
  buttonAddAction: {
    float: 'right',
    marginBottom: '1rem',
    [theme.breakpoints.down('xs')]: {
      float: 'left',
    },
  },
});

class BatchActions extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      id: '',
      fotoThumb: '',
      nome: '',
      endereco: '',
      data: [],
      rows: [
        { id: 'name', numeric: false, disablePadding: false, label: 'Nome' },
        { id: 'query', numeric: false, disablePadding: false, label: 'Query' },
      ],
      pathName: '/admin/configuracoes/acoeslote/',
      tableColumns: [],
    };

    window.pathname = null;
  }

  handleCloseAlert = () => {
    this.setState({ openSnackbar: false });
  };

  redshiftQuery = (query) => {
    // loading
    this.setState({
      userList: false,
      lastQuery: '',
      queryResult: [],
    });

    getDataRedshift(query)
      .then((result) => {
        if (result !== false) {
          if (result.length === 0) {
            this.setState({
              openSnackbar: true,
              snackMessage: 'Nenhum resultado encontrado',
              userList: [],
            });
          } else {
            this.setState({
              userList: result,
              lastQuery: query,
              queryResult: result,
            });
          }
        } else {
          this.setState({
            openSnackbar: true,
            snackMessage: 'Busca inválida',
            userList: [],
          });
        }
      })
      .catch((error) => {
        this.setState({
          openSnackbar: true,
          snackMessage: 'Erro ao realizar busca: ' + error.toString(),
          userList: [],
        });
      });
  };

  clearList = () => {
    this.setState({
      userList: [],
      lastQuery: '',
      queryResult: [],
    });
  };

  getExportList = () => {
    if (this.state.queryResult) {
      let finalList = '';

      Object.keys(this.state.queryResult).map((item, index) => {
        return (finalList =
          finalList + (index === 0 ? '' : ' \n ') + this.state.queryResult[item].linked_account);
      });
      finalList = finalList.replace(/[.]/gi, ',');
      return finalList;
    }
  };

  SaveQuery = () => {
    this.setState({
      openSnackbar: true,
      snackMessage:
        this.state.name +
        ' ' +
        this.state.status +
        ' ' +
        this.state.lastQuery +
        ' ' +
        Date.now() +
        ' ' +
        this.state.creatorUId,
    });

    let data = {
      queryId: this.state.queryId,
      name: this.state.name,
      query: this.state.lastQuery,
      createdDate: Date.now(),
      creatorUId: this.state.creatorUId,
      status: this.state.status,
    };

    this.props.updateBatchActionsComponent(data);
  };

  //altera os states dos campos, caso o usuário insira informações nos input
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCheck = (name) => (event) => {
    this.setState({
      [name]: event.target.checked ? 1 : 0,
    });
  };

  render = () => {
    const { classes } = this.props;
    if (this.props.loading)
      return (
        <div className="loading">
          <Loading />
        </div>
      );
    else {
      if (window.pathname !== null) {
        return <Redirect to={window.pathname} />;
      } else {
        return (
          <div style={{ maxHeight: '100%', padding: '4rem 32px' }}>
            <GoBackHeader />
            <HeaderTitle pageInfo="Queries" />
            <br />
            <Grid container spacing={5}>
              <Grid item md={6} xs={12}></Grid>

              <Grid item md={6} xs={12}>
                <div className={classes.buttonAddAction}>
                  <Link
                    to={{
                      pathname: '/admin/configuracoes/acoeslote/add',
                      state: '',
                    }}
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      style={{
                        color: 'white',
                        textTransform: 'capitalize',
                        fontWeight: '550',
                      }}
                      title="Adicionar Query"
                      variant="contained"
                      color="primary"
                      size="small"
                      startIcon={<Add />}
                    >
                      Adicionar Query
                    </Button>
                  </Link>
                </div>
              </Grid>
            </Grid>

            <DataTableQueries
              rows={this.state.rows}
              tableNode={'Queries'}
              pathName={this.state.pathName}
            />
          </div>
        );
      }
    }
  };
}

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    loading: state.batchActionsComponent.loading,
    success: state.batchActionsComponent.success,
    error: state.batchActionsComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  updateBatchActionsComponent: (value, eventType, eventDescription, clientList) =>
    updateBatchActionsAction(dispatch, value, eventType, eventDescription, clientList),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(BatchActions);

