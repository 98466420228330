import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as S from './styles';
import * as C from '../../styles';
import * as D from 'js/components/DesignSystem/styles';

import { Modal } from '@mui/material';
import { AiFillCloseCircle } from 'react-icons/ai';

import InputSearch from 'js/components/TriiboComponents/InputSearch';
import SelectTable from 'js/components/TriiboComponents/Tables/SelectTable';

import { getStorePlaces_v2 } from 'js/library/utils/API/getStorePlaces_v2';
import { toast, ToastContainer } from 'react-toastify';

export default function ModalPromotion({
  handleCloseModal,
  isModalPromotionOpen,
  setPromotion,
  promotion,
}) {
  const [promotions, setPromotions] = useState([]);
  const [checkedById, setCheckedById] = useState(new Set());
  const [inputSearchPromotions, setInputSearchPromotions] = useState('');
  let establishmentList = useSelector(
    (state) => state.authModel.establishmentList
  );
  let currentEst = useSelector((state) => state.currentEstModel.currentEst);

  useEffect(() => {
    const ac = new AbortController();
    async function getPromotions() {
      let establishmentId = '';

      if (establishmentList.length < 1) {
        return;
      } else {
        if (Object.keys(currentEst).length !== 0) {
          establishmentId = currentEst.id;
        } else {
          establishmentId = establishmentList[0].id;
        }
      }

      try {
        const response = await getStorePlaces_v2(
          null,
          ['businessPartner', 'promotion'],
          null,
          null,
          '',
          establishmentId,
          null,
          0
        );

        if (response.list.length > 0) {
          const newPromotionList = (arr) => {
            return arr.reduce((prev, next) => {
              let newCurrent = {};
              if (next.type === 'businessPartner') {
                newCurrent = { ...next.businessPartner };
              } else if (next.type === 'promotion') {
                newCurrent = { ...next.promotion };
              }

              newCurrent.checkedId = next.id; // identificador para o componente de tabela usar

              newCurrent.typePromotion = newCurrent.hasGroup
                ? 'Promoção Fidelidade'
                : newCurrent.type === 'promotion'
                ? 'Promoção'
                : newCurrent.type === 'businessPartner' && 'Site externo';

              prev.push(newCurrent);
              return prev;
            }, []);
          };
          setPromotions(newPromotionList(response.list));
        }
      } catch (error) {
        toast.error('Erro ao carregar promoções.');
      }
    }

    getPromotions();

    return ac.abort();
  }, []);

  const column = [
    { heading: 'Titulo', value: 'title' },
    { heading: 'Tipo da promoção', value: 'typePromotion' },
  ];

  const handleChangeInputSearchPromotion = (event) => {
    setInputSearchPromotions(event.target.value);
  };

  const filteredPromos = inputSearchPromotions
    ? promotions.filter((promotion) => {
        return promotion.title
          .toLowerCase()
          .includes(inputSearchPromotions.toLowerCase());
      })
    : promotions;

  return (
    <Modal
      onClose={() => handleCloseModal()}
      open={isModalPromotionOpen}
      aria-labelledby='modal-title'
      aria-describedby='modal-description'>
      <C.ModalContainer desk_width={'800px'}>
        <ToastContainer autoClose={6000} />
        <C.ModalBox>
          <C.CloseModalIcon onClick={() => handleCloseModal()}>
            <AiFillCloseCircle size={20} />
          </C.CloseModalIcon>

          <D.TriiboH4 style={{ textAlign: 'center' }}>
            <strong>Selecione uma promoção</strong>
          </D.TriiboH4>

          <S.BoxSearch>
            <InputSearch
              value={inputSearchPromotions}
              onChange={handleChangeInputSearchPromotion}
              disabled={false}
              placeholder='Titulo da promoção'
            />
          </S.BoxSearch>

          <SelectTable
            data={filteredPromos}
            column={column}
            checkedById={checkedById}
            setCheckedById={setCheckedById}
            perPage={10}
            resetValue={false}
          />

          <C.ActionButtons>
            <C.CancelButton
              onClick={() => {
                !promotion && setCheckedById(new Set());
                handleCloseModal();
              }}>
              <D.TriiboH5>Cancelar</D.TriiboH5>
            </C.CancelButton>
            <C.ContinueButton
              disabled={Array.from(checkedById).length > 0 ? false : true}
              onClick={() => {
                setPromotion(checkedById);
                handleCloseModal();
              }}>
              <D.TriiboH5>Confirmar</D.TriiboH5>
            </C.ContinueButton>
          </C.ActionButtons>
        </C.ModalBox>
      </C.ModalContainer>
    </Modal>
  );
}
