import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { compose } from 'recompose';

import * as S from '../../../components/Establishment/styles';
import * as D from 'js/components/DesignSystem/styles';

import thumbnailPromocao from 'styles/assets/promocao/thumbnail-promocao.png';
import listaFotos from 'styles/assets/promocao/listaFotos-promocao.png';
import cuponsValidos from 'styles/assets/promocao/cuponsValidos.png';
import nomeCupom from 'styles/assets/promocao/nomeCupom.png';

import placeholder from 'styles/assets/placeholder/placeholder_triibo.png';
import placeholderThumbnail from 'styles/assets/placeholder/placeholder_thumbnail.png';

import {
  Slide,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  TextField,
  Button,
  FormGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  Switch,
  MenuItem,
  Select,
  Grid,
  Modal,
  Radio,
  RadioGroup,
  Snackbar,
  Tooltip,
  Badge,
  Chip,
  Paper,
  colors,
  Box,
} from '@mui/material';
import { ArrowBack, AddPhotoAlternate, Save, Close, Help } from '@mui/icons-material';

import { Cropper } from 'react-image-cropper';
import Downshift from 'downshift';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';

import { withStyles } from '@mui/styles';

import Loading from 'js/containers/Loading/Loading';
import { updateStorePromotionInformationAction } from 'js/core/actions/storePromotionInformationActions';
import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';
import { downloadImage } from 'js/library/services/StorageManager';

import { convertToDate, msToTime } from 'js/library/utils/helpers.js';
import EditShowId from 'js/components/Store/Promotion/EditShowId';
import { BsXLg } from 'react-icons/bs';
import { getVoucherTemplate } from 'js/library/utils/API/getVoucherTemplate';
let suggestions = [];

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

function getModalStyle() {
  return {
    position: 'absolute',
    top: '50%',
    left: '50%',
    backgroundColor: 'white',
    padding: '30px',
    width: '94%',
    height: '50%',
    maxWidth: '600px',
    maxHeight: '450px',
    transform: 'translate(-50%, -50%)',
  };
}

const styles = (theme) => ({
  root: {
    background: '#ffffff',
    borderRadius: 5,
    border: '1px solid #6c6c6c',
    color: 'white',
  },
});

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      variant="outlined"
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion({ suggestion, index, itemProps, highlightedIndex, selectedItem }) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label + index}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}

// Estilo da notificação - Badge
const StyledBadge = withStyles((theme) => ({
  badge: {
    fontSize: '12px',
    color: '#6c6c6c',
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    right: -2,
    top: 3,
    border: '1px solid #6c6c6c',
    backgroundColor: '#fff',
    padding: '0',

    '&:hover': {
      backgroundColor: colors['red'][700],
      color: 'white',
      cursor: 'pointer',
      border: 'none',
    },
  },
}))(Badge);

const StyledBadge2 = withStyles((theme) => ({
  badge: {
    fontSize: '12px',
    color: '#6c6c6c',
    borderRadius: '50%',
    height: '20px',
    width: '20px',
    right: -2,
    top: 3,
    border: '1px solid #6c6c6c',
    backgroundColor: '#fff',
    padding: '0',
  },
}))(Badge);

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

function getSuggestions(value) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter((suggestion) => {
        const keep =
          count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

function convertIsoToDate(date) {
  const dateYear = date.getFullYear();
  const dateMonth = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
  const dateDay = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
  const dateHour = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
  const dateMinute = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();

  return (date = dateYear + '-' + dateMonth + '-' + dateDay + 'T' + dateHour + ':' + dateMinute);
}

class StorePromotionInformation extends Component {
  constructor(props, context) {
    super(props, context);
    const dataEstabelecimento = props.location.state.dataEstabelecimento; //store.getState().establishmentInformationModel;

    //convertendo enable date
    let enableDate = new Date(Date.now());
    if (props.location.state.enableDate !== undefined) {
      enableDate = new Date(props.location.state.enableDate);
    }

    enableDate = convertIsoToDate(enableDate);

    //convertendo disable date
    let disableDate = new Date(Date.now() + 86400000 * 365);
    if (props.location.state.disableDate !== undefined) {
      disableDate = new Date(props.location.state.disableDate);
    }

    disableDate = convertIsoToDate(disableDate);

    const currentDate = new Date(Date.now()).toISOString().split(':');

    const todayDate = currentDate[0] + ':' + currentDate[1];

    const parsedDisableDate = Date.parse(disableDate);
    const parsedTodayDate = Date.parse(todayDate);

    const isActive = parsedDisableDate >= parsedTodayDate;

    //convertendo disable coupon date
    let disableDateCoupon = new Date(Date.now() + 86400000 * 365);

    disableDateCoupon = convertIsoToDate(disableDateCoupon);

    const urlEdit = window.location.href;

    this.state = {
      wheelOccurred: false,
      //dados do estabelecimento
      idEstabelecimento: props.match.params.id,
      idGestor: dataEstabelecimento?.idGestor,
      nomeEstabelecimento: dataEstabelecimento?.nome,
      lat: dataEstabelecimento?.lat,
      long: dataEstabelecimento?.long,
      address: dataEstabelecimento?.endereco ? dataEstabelecimento.endereco : null,
      neighborhood: dataEstabelecimento?.endereco
        ? dataEstabelecimento.endereco.split('-')[1]
          ? dataEstabelecimento.endereco.split('-')[1].split(',')[0]
            ? dataEstabelecimento.endereco.split('-')[1].split(',')[0]
            : null
          : dataEstabelecimento.endereco
        : 'Brasil',
      dataEstabelecimento,

      //dados do template
      id: props.location.state.templateId === undefined ? null : props.location.state.templateId,
      consumoUnico: false,
      cooldown: null,
      cooldownDays: 0,
      cooldownHours: 0,
      cooldownMinutes: 0,
      dataGeracao: null,
      descricao: null,
      detailsDescription: null,
      credit: null,
      detailsTitle: null,
      generator: false,
      nomeTemplate: null,
      plural: null,
      qtdDisponivel: null,
      hasSimultaneo: false,
      qtdSimultaneo: 1,
      region: props.location.state.region === undefined ? 'local' : props.location.state.region,
      singular: null,
      titulo: null,
      campanhaTriibo: false,
      sendSMS: false,

      expirationType: 'fixed',
      disableDateCoupon,
      onSendExpiration: 1,

      equalImageThumnailFile: null,
      equalImagePhotoFile: null,

      type: 'store',
      //se consumo for posterior ==> showQRCode: null
      //se consumo for imediato showQRCode: true; qntSimultanea:1
      consumo:
        props.location.state.showQRCode === undefined || props.location.state.showQRCode === null
          ? 'posterior'
          : 'imediato',

      foto: null,
      thumbnail: null,

      loadedFoto: null,
      loadedThumbnail: null,

      oldFoto: null,
      oldThumbnail: null,

      fileFoto: null,
      fileThumbnail: null,

      //dados da store
      idStore: props.location.state.key === undefined ? null : props.location.state.key,
      active: isActive,
      // props.location.state.active === undefined || props.location.state.active
      //   ? isActive
      //   : true,
      level: props.location.state.level === undefined ? 99 : props.location.state.level,
      description:
        props.location.state.description === undefined ? null : props.location.state.description,
      showQRCode:
        props.location.state.key === undefined
          ? null
          : props.location.state.showQRCode === undefined
          ? null
          : props.location.state.showQRCode,
      title: props.location.state.title === undefined ? null : props.location.state.title,
      storeType: props.location.state.type === undefined ? 'promotion' : props.location.state.type,
      autoValidate:
        props.location.state?.autoValidate === undefined
          ? 'estabelecimento'
          : props.location.state?.autoValidate
          ? 'cliente'
          : 'estabelecimento',
      deliverType:
        props.location.state.deliverType === undefined ? 1 : props.location.state.deliverType,
      cost: props.location.state.cost === undefined ? 0 : props.location.state.cost,
      isPublished:
        props.location.state.isPublished === undefined ? false : props.location.state.isPublished,
      enableDate,
      disableDate,

      buttonLabel:
        props.location.state.buttonLabel === undefined ? '' : props.location.state.buttonLabel,
      buttonDescription:
        props.location.state.buttonDescription === undefined
          ? ''
          : props.location.state.buttonDescription,
      showButtonDescription:
        props.location.state.showButtonDescription === undefined
          ? false
          : props.location.state.showButtonDescription,
      goToPlaceLabel:
        props.location.state.goToPlaceLabel === undefined
          ? ''
          : props.location.state.goToPlaceLabel,
      showGoToPlace:
        props.location.state.showGoToPlace === undefined
          ? false
          : props.location.state.showGoToPlace,

      consumed: props.location.state.consumed === undefined ? false : props.location.state.consumed,

      keywordsSetConsumption: '',
      keywords: '',
      keywordsDownload: null,
      keywordsSetConsumptionList: props.location.state.keyWordsSetConsumption ?? [],
      messageBlockedKw: props.location.state.message ?? '',
      keywordsList:
        props.location.state.keyWordsSet === undefined ? [] : props.location.state.keyWordsSet,
      tags: props.location.state.tags === undefined ? [] : props.location.state.tags,

      storeThumbnail:
        props.location.state.thumbnail === undefined ? null : props.location.state.thumbnail,
      storePhoto: props.location.state.photo === undefined ? null : props.location.state.photo,

      loadedStoreThumbnail:
        props.location.state.downloadedThumb === undefined
          ? null
          : props.location.state.downloadedThumb,
      loadedStorePhoto: props.location.state.photo === undefined ? null : placeholder,

      tagCapture: '',

      oldStoreThumbnail: null,
      oldStorePhoto: null,

      fileStoreThumbnail: null,
      fileStorePhoto: null,

      //states de validação local
      equalImageThumnail: false,
      equalImagePhoto: false,
      open: false,
      openModal: false,
      goBack: false,
      indexCrop: 0,
      openAlert: false,
      messageAlert: '',
      loading: false,

      oldPhotoList:
        props.location.state.photoList === undefined ? null : props.location.state.photoList,
      photoListFile: null,
      loadedPhotoList: null,

      //Botões de adicionar imagem
      disableButtonImage: false,
      disableButtonUpdate: true,

      messageApproval: null,
      newStatus: null,
      couponGetMode: null,
      editPage: urlEdit.includes('/edit'),
      openDialog: false,

      priceInReais: Math.round(
        props.location.state.cost === undefined ? 0 : props.location.state.cost / 10
      )
        .toFixed(2)
        .replace('.', ','),

      unlimitedDisableDate:
        Math.abs(Date.parse(disableDate) - Date.parse(enableDate)) <= 1000 * 60 * 60 * 24 * 365 * 5
          ? false
          : true,
    };

    if (props.location.state.photoList !== undefined) {
      let photoListFile = [];

      Object.keys(props.location.state.photoList).map((item, i) =>
        downloadImage('promocao', props.location.state.photoList[i]).then((downloadedImage) => {
          photoListFile[i] = downloadedImage;
          this.setState({ photoListFile });
        })
      );
    }

    //baixando dados da Voucher-Template-v2, caso seja edição
    if (this.state.idStore !== null) {
      const folderThumb =
        props.location.state.type === 'product' && props.location.state.thumbnail !== undefined
          ? props.location.state.thumbnail.split('/')[0]
          : 'promocao';

      const thumbImg =
        props.location.state.type === 'product' && props.location.state.thumbnail !== undefined
          ? props.location.state.thumbnail.split('/')[1]
          : props.location.state.thumbnail;

      const folderPhoto =
        props.location.state.type === 'product' && props.location.state.photo !== undefined
          ? props.location.state.photo.split('/')[0]
          : 'promocao';

      const photoImg =
        props.location.state.type === 'product' && props.location.state.photo !== undefined
          ? props.location.state.photo.split('/')[1]
          : props.location.state.photo;

      if (
        props.location.state.thumbnail !== undefined &&
        this.state.loadedStoreThumbnail === placeholderThumbnail
      ) {
        downloadImage(folderThumb, thumbImg)
          .then((downloadedImage) => {
            this.setState({
              loadedStoreThumbnail: downloadedImage,
            });
          })
          .catch(() => {
            // console.log("Foto não encontrada:", props.location.state.thumbnail);
          });
      }

      if (props.location.state.photo !== undefined) {
        downloadImage(folderPhoto, photoImg)
          .then((downloadedImage) => {
            this.setState({
              loadedStorePhoto: downloadedImage,
            });
          })
          .catch(() => {
            // console.log("Foto não encontrada:", props.location.state.photo);
          });
      }

      //baixando os dados da Voucher Template com Rest API
      getVoucherTemplate(props.location.state.templateId)
        .then((dataReceived) => {
          let cooldown = msToTime(dataReceived.result.cooldown);

          if (dataReceived.result.thumbnail !== undefined) {
            dataReceived.result.loadedThumbnail = placeholderThumbnail;
            downloadImage('voucher', dataReceived.result.thumbnail)
              .then((downloadedImage) => {
                this.setState({
                  loadedThumbnail: downloadedImage,
                });
              })
              .catch(() => {
                // console.log("Foto não encontrada:", dataReceived.thumbnail);
              });
          }
          if (dataReceived.result.foto !== undefined) {
            dataReceived.result.loadedFoto = placeholder;
            downloadImage('voucher', dataReceived.result.foto)
              .then((downloadedImage) => {
                this.setState({
                  loadedFoto: downloadedImage,
                });
              })
              .catch(() => {
                // console.log("Foto não encontrada:", dataReceived.thumbnail);
              });
          }

          dataReceived.result.region =
            this.props.location.state.region === undefined
              ? 'local'
              : this.props.location.state.region;
          dataReceived.result.hasSimultaneo =
            dataReceived.result.qtdSimultaneo === 1 ? false : true;
          dataReceived.result.nomeEstabelecimento = this.state.nomeEstabelecimento;
          // convert milliseconds to day
          dataReceived.result.onSendExpiration =
            dataReceived.result.onSendExpiration / 86400000 || null;

          dataReceived.result.disableDateCoupon = dataReceived.result.disableDate || null;

          delete dataReceived.result.disableDate;

          this.setState({
            ...dataReceived.result,
            cooldownDays: cooldown.days,
            cooldownHours: cooldown.hours,
            cooldownMinutes: cooldown.minutes,
          });
        })
        .catch((error) => {
          // console.log("Erro na data:", error);
        });
    }
    window.pathname = null;
  }

  handleWheel = (event) => {
    event.preventDefault();
    this.setState({
      wheelOccurred: true,
    });
  };

  //altera os states dos campos, caso o usuário insira informações nos input
  handleChange = (name) => (event) => {
    //tratando casos especiais
    //não permitir que qtdSimultaneo seja menor que 1
    if (name === 'cost' && event.target.value < 0) {
      return;
    }

    if (name === 'cost') {
      this.handleCalculationValueProduct(event.target.value);

      let currencyValue = event.target.value;

      if (currencyValue) {
        currencyValue = parseInt(currencyValue.replace(/[\D]+/g, ''));
      } else {
        currencyValue = 0;
      }

      this.setState({
        priceInReais: currencyValue,
      });

      return;
    }

    if (name === 'qtdSimultaneo') {
      this.setState({
        [name]: event.target.value < 1 ? 1 : event.target.value,
        goBack: true,
        openAlert: event.target.value < 1 ? true : false,
        messageAlert: event.target.value < 1 ? 'Este valor não pode ser menor que 1.' : '',
      });
    } else if (name === 'level') {
      this.setState({
        [name]:
          event.target.value > 99 || event.target.value < 1 ? this.state.level : event.target.value,
        openAlert: event.target.value > 99 || event.target.value < 1 ? true : false,
        messageAlert:
          event.target.value > 99 || event.target.value < 1
            ? 'O nível da hierarquia deve ser entre 1 e 99.'
            : null,
      });
    } else if (name === 'cooldownHours' || name === 'cooldownMinutes' || name === 'cost') {
      if (name === 'cooldownMinutes' && event.target.value > 59) {
        this.setState({
          [name]: 59,
          goBack: true,
        });
      } else if (name === 'cooldownHours' && event.target.value > 23) {
        this.setState({
          [name]: 23,
          goBack: true,
        });
      } else {
        this.setState({
          [name]: event.target.value < 0 ? 0 : event.target.value,
          goBack: true,
          openAlert: event.target.value < 0 ? true : false,
          messageAlert: event.target.value < 0 ? 'Este valor não pode ser negativo.' : '',
        });
      }
    } else if (event.target.value === 'imediato') {
      this.setState({
        [name]: event.target.value,
        showQRCode: true,
        goBack: true,
        onSendExpiration: null,
        disableDateCoupon: null,
      });
    }

    //ADICIONAL
    else if (event.target.value === 'posterior') {
      this.setState({
        [name]: event.target.value,
        goBack: true,
        showQRCode: null,
        disableDateCoupon: convertIsoToDate(new Date(Date.now() + 86400000 * 365)),
      });
    }

    // //casos normais

    const { wheelOccurred } = this.state;

    if (wheelOccurred) {
      this.setState({ wheelOccurred: false });
      return;
    } else {
      this.setState({
        [name]: event.target.value,
        goBack: true,
      });
    }
  };

  //handle downshift

  handleChangeDownshiftConsume = (item) => {
    let { keywordsSetConsumptionList } = this.state;

    if (keywordsSetConsumptionList.indexOf(item) === -1) {
      keywordsSetConsumptionList = [...keywordsSetConsumptionList, item];
    }

    this.setState({
      keywordsSetConsumption: '',
      keywordsSetConsumptionList,
    });
  };

  handleDeleteDownshiftConsume = (item) => () => {
    this.setState((state) => {
      const keywordsSetConsumptionList = [...state.keywordsSetConsumptionList];
      keywordsSetConsumptionList.splice(keywordsSetConsumptionList.indexOf(item), 1);
      return { keywordsSetConsumptionList };
    });
  };

  handleChangeDownshift = (item) => {
    let { keywordsList } = this.state;

    if (keywordsList.indexOf(item) === -1) {
      keywordsList = [...keywordsList, item];
    }

    this.setState({
      keywords: '',
      keywordsList,
    });
  };

  handleDeleteDownshift = (item) => () => {
    this.setState((state) => {
      const keywordsList = [...state.keywordsList];
      keywordsList.splice(keywordsList.indexOf(item), 1);
      return { keywordsList };
    });
  };

  //fim handle downshift

  // HandleChance componente Switch
  handleChangeSwitch = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
      goBack: true,
    });

    if (name === 'unlimitedDisableDate') {
      this.setState({
        disableDate: convertIsoToDate(new Date(Date.now() + 86400000 * 365 * 60)),
      });
    }
  };

  handleChangeSwitchImage = (name) => (event) => {
    let disableButtonImage = this.state.disableButtonImage;

    if (name === 'disableButtonImage') {
      if (disableButtonImage) {
        disableButtonImage = false;
        this.setState({ disableButtonImage });
      } else {
        disableButtonImage = true;
        this.setState({ disableButtonImage });
      }
    }
  };

  handleTagCapture = (e) => {
    this.setState({ tagCapture: e.target.value });
  };

  handlePressEnter = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.handlePushTag(e);
    }
  };

  handlePushTag = (e) => {
    e.preventDefault();
    let tagHolder = this.state.tags;
    if (
      tagHolder.indexOf(this.state.tagCapture.toLocaleLowerCase()) === -1 &&
      this.state.tagCapture.length !== 0
    ) {
      tagHolder.push(this.state.tagCapture.toLowerCase());
    }
    this.setState({
      tags: [...tagHolder],
      tagCapture: '',
      disableButtonUpdate: false,
    });
  };

  //handle Modal
  handleCloseModal = () => {
    this.setState({ openModal: false });
  };

  fileChangedHandler = (name, oldName) => (event) => {
    this.setState({ [oldName]: this.state[name] });

    if (event.target.files[0] !== undefined) {
      const data = [URL.createObjectURL(event.target.files[0])];
      this.setState({ openModal: true, imageToCrop: data, imageCroped: name });
    }
  };

  photoListChangedHandler = (event) => {
    let listImages = [];

    for (let index = 0; index < event.target.files.length; index++) {
      listImages[index] = URL.createObjectURL(event.target.files[index]);
    }

    if (event.target.files !== undefined) {
      this.setState({
        imageToCrop: listImages,
        imageCroped: 'photoListFile',
        openModal: true,
      });
    }
  };

  cropImage(state) {
    //campo onde a image está sendo inserido
    const newCrop = this.state.imageCroped;

    //imagem recebida do crop
    let node = this[state].crop();

    this.setState({
      [newCrop]: node,
      openModal: false,
      imageToCrop: null,
      goBack: true,
    });
  }

  photoListCropImage(state) {
    //campo onde a image está sendo inserido
    const newCrop = this.state.imageCroped;
    //campo utilizado para saber se a imagem é a ultima do cadastro
    const endArray = this.state.imageToCrop[this.state.indexCrop + 1];

    //imagem recebida do crop
    let node = this[state].crop();

    //verificando se é um array de imagens
    let data = [];
    if (this.state[newCrop] !== null) {
      let a = this.state[newCrop];
      data = a.concat(node);
    } else {
      data = [node];
    }

    this.setState(
      {
        [newCrop]: this.state.imageCroped === 'photoListFile' ? data.slice(0, 4) : node,
        indexCrop: endArray === undefined ? 0 : this.state.indexCrop + 1,
        goBack: true,
        imageToCrop: endArray === undefined ? null : this.state.imageToCrop,
      },
      () => {
        if (endArray === undefined) {
          this.handleCloseModal();
        }
      }
    );
  }
  handleEqualImages = (name) => (event) => {
    if (event.target.checked === true) {
      this.setState({
        equalImageThumnailFile:
          this.state.fileStoreThumbnail === null
            ? this.state.loadedStoreThumbnail
            : this.state.fileStoreThumbnail,
        equalImagePhotoFile:
          this.state.fileStorePhoto === null
            ? this.state.loadedStorePhoto
            : this.state.fileStorePhoto,
        equalImageThumnail: true,
        equalImagePhoto: true,
      });
    } else {
      this.setState({
        equalImageThumnailFile: null,
        equalImagePhotoFile: null,
        equalImageThumnail: false,
        equalImagePhoto: false,
      });
    }
  };

  //HANDLE ALERT
  goBack = () => {
    if (this.state.goBack === false) {
      window.history.back();
    } else {
      this.handleClickOpen();
    }
  };

  handleOpenDialog = () => {
    this.setState({ openDialog: true });
  };

  checkInformation = (e) => {
    e.preventDefault();
    if (this.state.fileStoreThumbnail === null && this.state.loadedStoreThumbnail === null) {
      this.setState({
        openAlert: true,
        messageAlert: 'É necessário adicionar uma imagem de thumbnail.',
      });
    } else if (this.state.fileStorePhoto === null && this.state.loadedStorePhoto === null) {
      this.setState({
        openAlert: true,
        messageAlert: 'É necessário adicionar ao menos uma imagem na lista de fotos.',
      });
    } else if (
      this.state.loadedThumbnail === null &&
      this.state.fileThumbnail === null &&
      this.state.equalImageThumnailFile === null
    ) {
      this.setState({
        openAlert: true,
        messageAlert: 'É necessário adicionar uma imagem de thumbnail para o cupom.',
      });
    } else if (
      this.state.loadedFoto === null &&
      this.state.fileFoto === null &&
      this.state.equalImagePhotoFile === null
    ) {
      this.setState({
        openAlert: true,
        messageAlert: 'É necessário adicionar uma imagem de detalhe para o cupom.',
      });
    } else {
      this.setState({ loading: true });

      let finalHours =
        parseInt(this.state.cooldownHours, 10) +
        parseInt(24 * parseInt(this.state.cooldownDays, 10), 10);

      this.props
        .updateStorePromotionInformationComponent(
          this.state.id,
          this.state.consumoUnico,
          finalHours,
          this.state.cooldownMinutes,
          this.state.dataGeracao,
          this.state.descricao,
          this.state.detailsDescription,
          this.state.credit,
          this.state.keywordsSetConsumptionList,
          this.state.messageBlockedKw,
          this.state.detailsTitle,
          this.state.generator,
          this.state.neighborhood,
          this.state.nomeTemplate,
          this.state.plural,
          this.state.qtdDisponivel,
          this.state.qtdSimultaneo,
          this.state.singular,
          this.state.titulo,
          this.state.type,
          this.state.fileThumbnail,
          this.state.oldThumbnail,
          this.state.thumbnail,
          this.state.fileFoto,
          this.state.oldFoto,
          this.state.foto,
          this.state.campanhaTriibo,
          this.state.buttonLabel,
          this.state.buttonDescription,
          this.state.showButtonDescription,
          this.state.goToPlaceLabel,
          this.state.showGoToPlace,
          this.state.consumed,
          this.state.idStore,
          this.state.active,
          this.state.level,
          this.state.region,
          this.state.description,
          this.state.showQRCode,
          this.state.title,
          this.state.storeType,
          this.state.autoValidate,
          this.state.deliverType,
          this.state.cost,
          this.state.enableDate,
          this.state.disableDate,
          this.state.isPublished,
          this.state.keywordsList,
          this.state.tags,
          this.state.fileStoreThumbnail,
          this.state.oldStoreThumbnail,
          this.state.storeThumbnail,
          this.state.fileStorePhoto,
          this.state.oldStorePhoto,
          this.state.storePhoto,

          this.state.idEstabelecimento,
          this.state.idGestor,
          this.state.nomeEstabelecimento,
          this.state.lat,
          this.state.long,
          this.state.address,

          Number(this.state.hasSimultaneo),
          this.state.equalImageThumnailFile,
          this.state.equalImagePhotoFile,
          this.state.oldPhotoList,
          this.state.photoListFile,
          this.state.messageApproval,
          this.state.newStatus,
          this.state.couponGetMode,
          this.state.expirationType,
          this.state.disableDateCoupon,
          this.state.onSendExpiration,
          this.state.sendSMS
        )
        .then((result) => result.payload)
        .then(() => {
          this.setState({ openDialog: false });
          window.history.back();
        });
    }
  };

  removeImage = (idx) => {
    if (this.state.photoListFile && idx < this.state.photoListFile.length) {
      let newPhotoList = this.state.photoListFile;
      newPhotoList.splice(idx, 1);
      this.setState({
        photoListFile: newPhotoList,
        disableButtonUpdate: false,
      });
    } else {
      // console.log("Imagem não existe");
    }
  };

  mainImage = (idx) => {
    let currentFoto = this.state.fileStorePhoto
      ? this.state.fileStorePhoto
      : this.state.loadedStorePhoto;
    let newPhotoListFile = this.state.photoListFile;
    let newFoto = this.state.photoListFile[idx];

    newPhotoListFile.splice(idx, 1);
    newPhotoListFile.unshift(currentFoto);

    this.setState({ photoListFile: newPhotoListFile, fileStorePhoto: newFoto });
  };

  handleStateButton = () => {
    this.setState({ disableButtonUpdate: false });
  };

  //HANDLE DIALOGS
  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  //handle alerts
  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  };

  handleChangeRadio = (event) => {
    this.setState({ expirationType: event.target.value });
  };

  handleCalculationValueProduct = (price) => {
    const maskRemovedPrice = price.replace(',', '.');

    const valueInPercentage = maskRemovedPrice * 10;
    const finalPrice = Math.round(valueInPercentage);

    this.setState({
      cost: finalPrice,
    });
  };

  addComma = () => {
    const { priceInReais } = this.state;

    if (priceInReais > 0) {
      const newValue = priceInReais + ',00';

      this.setState({
        priceInReais: newValue,
      });
    }
  };

  removeComma = () => {
    const { priceInReais } = this.state;

    if (priceInReais) {
      const newValue = priceInReais.replace(',00', '');

      this.setState({
        priceInReais: newValue,
      });
    }
  };

  componentDidMount() {
    getDataListRestApi('Keywords')
      .then((dataReceived) => {
        let keywordsDownload = [];

        Object.values(dataReceived).map(function (item) {
          return keywordsDownload.push({ label: item.value });
        });

        suggestions = keywordsDownload;
      })
      .catch((error) => {
        // console.log("Erro na data:", error);
      });
  }

  render = () => {
    const { cost, storeType, priceInReais } = this.state;

    const { classes } = this.props;
    const keywords = this.state.keywords;
    const keywordsConsume = this.state.keywordsSetConsumption;
    const selectedItemConsume = this.state.keywordsSetConsumptionList;
    const selectedItem = this.state.keywordsList;

    // NOVA LISTA DE FOTOS
    let fotos = [];
    for (let i = 0; i < 4; i++) {
      let exists =
        (this.state.photoListFile && this.state.photoListFile[i]) ||
        (this.state.loadedPhotoList && this.state.loadedPhotoList[i]);
      fotos.push(
        <Grid key={i} item md={10} style={{ marginBottom: '15px' }}>
          <StyledBadge
            badgeContent={
              exists ? (
                <Tooltip title="Excluir">
                  <Close
                    style={{ width: '100%', height: '100%' }}
                    onClick={() => this.removeImage(i)}
                  />
                </Tooltip>
              ) : (
                ''
              )
            }
            invisible={!exists}
          >
            <Tooltip title={exists ? 'Tornar principal' : 'Utilize o botão "Adicionar fotos"'}>
              <img
                style={{ cursor: exists ? 'pointer' : 'default' }}
                onClick={exists ? () => this.mainImage(i) : null}
                src={
                  this.state.photoListFile && this.state.photoListFile[i]
                    ? this.state.photoListFile[i]
                    : this.state.loadedPhotoList && this.state.loadedPhotoList[i]
                    ? this.state.loadedPhotoList[i]
                    : placeholder
                }
                width="100%"
                height="100%"
                alt="Lista de Fotos"
              />
            </Tooltip>
          </StyledBadge>
        </Grid>
      );
    }

    if (this.props.loading && this.state.loading)
      return (
        <div className="loading">
          <Loading />
        </div>
      );
    else {
      if (window.pathname !== null) {
        return (
          <Redirect
            to={{
              pathname: window.pathname,
              state: this.state.dataEstabelecimento,
            }}
          />
        );
      } else {
        return (
          <div
            style={{
              maxHeight: '100%',
              overflowY: 'auto',
              padding: '40px 32px',
            }}
          >
            <form>
              <FormGroup>
                {this.state.idStore === null ? (
                  <Typography
                    variant="h5"
                    gutterBottom
                    color="primary"
                    style={{ fontWeight: '700', fontSize: '25px' }}
                  >
                    Cadastro da oferta
                  </Typography>
                ) : (
                  <EditShowId text="Editar Oferta" id={this.state.idStore} />
                )}
                <TextField
                  variant="outlined"
                  className="input-field"
                  type="text"
                  value={this.state.title === null ? '' : this.state.title}
                  id="title"
                  label="Título da Oferta"
                  onChange={this.handleChange('title')}
                  required
                />
                <TextField
                  variant="outlined"
                  className="input-field"
                  multiline
                  type="text"
                  value={this.state.description === null ? '' : this.state.description}
                  id="description"
                  required
                  label="Descrição da Oferta"
                  onChange={this.handleChange('description')}
                />
                <TextField
                  variant="outlined"
                  style={{ width: '300px' }}
                  className="input-field"
                  onChange={this.handleChange('level')}
                  value={this.state.level}
                  type="number"
                  id="level"
                  required={this.state.isPublished}
                  label="Hierarquia da Oferta"
                />
                <br />
                <Downshift
                  id="downshift-multiple"
                  inputValue={keywords}
                  onChange={this.handleChangeDownshift}
                  selectedItem={selectedItem}
                >
                  {({
                    getInputProps,
                    getItemProps,
                    isOpen,
                    inputValue: inputValue2,
                    selectedItem: selectedItem2,
                    highlightedIndex,
                  }) => (
                    <div className={classes.container}>
                      {renderInput({
                        fullWidth: true,
                        classes,
                        InputProps: getInputProps({
                          startAdornment: selectedItem.map((item, i) => (
                            <Chip
                              key={item + '-' + i}
                              tabIndex={-1}
                              label={item}
                              className={classes.chip}
                              onDelete={this.handleDeleteDownshift(item)}
                            />
                          )),
                          onChange: this.handleChange('keywords'),
                          onKeyDown: this.handleKeyDown,
                          placeholder: 'Selecione a keyword na lista abaixo',
                        }),
                        label: 'Keywords',
                      })}
                      {isOpen ? (
                        <Paper className={classes.paper} square>
                          {getSuggestions(inputValue2).map((suggestion, index) =>
                            renderSuggestion({
                              suggestion,
                              index,
                              itemProps: getItemProps({
                                item: suggestion.label,
                              }),
                              highlightedIndex,
                              selectedItem: selectedItem2,
                            })
                          )}
                        </Paper>
                      ) : null}
                    </div>
                  )}
                </Downshift>
                <div style={{ paddingTop: '30px' }}>
                  <FormLabel name="2f">Tags da Oferta*</FormLabel>
                  <div>
                    <S.TagsContainer>
                      <S.TagsAddContainer>
                        <D.TriiboTextField
                          rows={1}
                          placeholder={'Insira o tipo e aperte enter'}
                          value={this.state.tagCapture}
                          onChange={(e) => {
                            this.handleTagCapture(e);
                          }}
                          onKeyDown={(e) => {
                            this.handlePressEnter(e);
                          }}
                        />
                        <S.TagsAddButton
                          blue={true}
                          onClick={(e) => {
                            this.handlePushTag(e);
                          }}
                        >
                          Adicionar tag
                        </S.TagsAddButton>
                      </S.TagsAddContainer>
                      <D.TriiboTagsField>
                        <D.TriiboTagsPlaceholder
                          visibility={this.state.tags.length === 0 ? false : true}
                        >
                          Digite a tag desejada no campo acima e pressione o botão para adicioná-las
                        </D.TriiboTagsPlaceholder>
                        {this.state.tags.length > 0 &&
                          this.state.tags.map((body, index) => (
                            <>
                              <D.UserTags key={index}>
                                <D.TagText>{body}</D.TagText>
                                <D.TagDelete
                                  onClick={(e) => {
                                    e.preventDefault();
                                    let newArr = this.state.tags;
                                    this.setState({
                                      tags: [...newArr],
                                    });
                                  }}
                                >
                                  {<BsXLg style={{ color: 'white' }} />}
                                </D.TagDelete>
                              </D.UserTags>
                            </>
                          ))}
                      </D.TriiboTagsField>
                    </S.TagsContainer>
                  </div>
                </div>
                <br /> <br />
                {/* FOTOS DA PROMOCAO */}
                <Typography
                  style={{
                    marginTop: '10px',
                    marginBottom: '-15px',
                    fontWeight: '700',
                    fontSize: '20px',
                  }}
                  variant="h5"
                  gutterBottom
                  color="primary"
                >
                  Fotos da promoção
                </Typography>
                <br />
                <br />
                <div className="flex-external">
                  <div
                    className="img-thumb"
                    style={{
                      maxWidth: '400px',
                      position: 'relative',
                      top: '0',
                      left: '0',
                    }}
                  >
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      color="secondary"
                      style={{ fontWeight: '550', textAlign: 'center' }}
                    >
                      Thumbnail (aparecerá em "Promoções Próximas")
                    </Typography>

                    <img
                      src={thumbnailPromocao}
                      alt="Thumbnail"
                      style={{
                        width: '100%',
                        maxWidth: '400px',
                        maxHeight: '447px',
                        position: 'relative',
                        top: '0',
                        left: '0',
                      }}
                    />

                    {/* style={{ borderRadius: "5%", position: 'absolute', top: '154.5px', left: '12px', width: "118px" }} */}
                    <img
                      className="img-position-simples"
                      alt="thumbnail"
                      src={
                        this.state.fileStoreThumbnail
                          ? this.state.fileStoreThumbnail
                          : this.state.loadedStoreThumbnail
                          ? this.state.loadedStoreThumbnail
                          : placeholder
                      }
                    />

                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                      <input
                        value=""
                        style={{ display: 'none' }}
                        type="file"
                        accept="image/*"
                        onChange={this.fileChangedHandler('fileStoreThumbnail', 'storeThumbnail')}
                        id="thumbnail"
                      />

                      <label htmlFor="thumbnail">
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                          style={{
                            marginTop: '15px',
                            fontWeight: '550',
                            marginLeft: '20px',
                            color: '#fff',
                            textTransform: 'capitalize',
                          }}
                          startIcon={<AddPhotoAlternate />}
                        >
                          Adicionar Foto
                        </Button>

                        <Tooltip title="As imagens devem ser 4x3.">
                          <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                        </Tooltip>
                      </label>
                    </div>
                  </div>

                  {/* Lista de fotos */}
                  <div>
                    <Typography
                      variant="subtitle2"
                      gutterBottom
                      color="secondary"
                      className="style-text"
                      style={{ fontWeight: '550' }}
                    >
                      Lista de Fotos (aparecerá em "Detalhe do Estabelecimento")
                    </Typography>

                    <section className="grid-style">
                      <div
                        className="Foto-detalhe"
                        style={{
                          width: '100%',
                          maxWidth: '400px',
                          position: 'relative',
                          top: '0',
                          left: '0',
                        }}
                      >
                        <img
                          alt="Lista de Fotos"
                          src={listaFotos}
                          style={{
                            width: '100%',
                            maxWidth: '392px',
                            position: 'relative',
                            top: '0',
                            left: '0',
                          }}
                        />

                        <StyledBadge2
                          style={{
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            height: '100%',
                            width: '100%',
                          }}
                          invisible={
                            (this.state.loadedStorePhoto && this.state.loadedStorePhoto[0]) ||
                            (this.state.fileStorePhoto && this.state.fileStorePhoto[0])
                              ? false
                              : true
                          }
                          badgeContent={
                            <Tooltip
                              title={
                                (this.state.loadedStorePhoto && this.state.loadedStorePhoto[0]) ||
                                (this.state.fileStorePhoto && this.state.fileStorePhoto[0])
                                  ? 'Para trocar clique em uma imagem ao lado'
                                  : 'Utilize o botão "Adicionar fotos"'
                              }
                              style={{ height: '100%', width: '100%' }}
                            >
                              <Help color="secondary" style={{ fontSize: 18 }} />
                            </Tooltip>
                          }
                          color="primary"
                        >
                          <img
                            src={
                              this.state.fileStorePhoto
                                ? this.state.fileStorePhoto
                                : this.state.loadedStorePhoto
                                ? this.state.loadedStorePhoto
                                : placeholder
                            }
                            alt="lista de fotos"
                            width="100%"
                            style={{
                              maxWidth: '393px',
                              position: 'absolute',
                              top: '0',
                              left: '0',
                            }}
                          />
                        </StyledBadge2>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          {this.state.loadedStorePhoto || this.state.fileStorePhoto ? (
                            <div>
                              <input
                                value=""
                                style={{ display: 'none' }}
                                type="file"
                                accept="image/*"
                                onChange={this.photoListChangedHandler}
                                id="listaFotos"
                              />
                              <label htmlFor="listaFotos">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                  style={{
                                    marginTop: '15px',
                                    fontWeight: '550',
                                    color: '#fff',
                                    textTransform: 'capitalize',
                                  }}
                                  startIcon={<AddPhotoAlternate />}
                                >
                                  Adicionar Foto Na Lista
                                </Button>

                                <Tooltip title="As imagens devem ser 4x3.">
                                  <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                                </Tooltip>
                              </label>
                            </div>
                          ) : (
                            <div>
                              <input
                                value=""
                                id="photoStore"
                                style={{ display: 'none' }}
                                type="file"
                                accept="image/*"
                                onChange={this.fileChangedHandler('fileStorePhoto', 'storePhoto')}
                              />
                              <label htmlFor="photoStore">
                                <Button
                                  variant="contained"
                                  color="primary"
                                  component="span"
                                  style={{
                                    marginTop: '15px',
                                    fontWeight: '550',
                                    color: '#fff',
                                    textTransform: 'capitalize',
                                  }}
                                  startIcon={<AddPhotoAlternate />}
                                >
                                  Adicionar Foto De Detalhe
                                </Button>
                              </label>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="lista-detalhes" id="photoList">
                        {fotos}
                      </div>
                    </section>
                  </div>
                </div>
                {/* FIM FOTOS DA PROMOCAO */}
                <Typography style={{ marginTop: '20px' }} variant="h5" gutterBottom color="primary">
                  Dados da carteira
                </Typography>
                <TextField
                  variant="outlined"
                  className="input-field"
                  value={this.state.nomeTemplate === null ? '' : this.state.nomeTemplate}
                  onChange={this.handleChange('nomeTemplate')}
                  type="text"
                  id="singular"
                  required
                  label="Nome do Template"
                />
                <TextField
                  variant="outlined"
                  className="input-field"
                  value={this.state.singular === null ? '' : this.state.singular}
                  onChange={this.handleChange('singular')}
                  type="text"
                  id="singular"
                  required={this.state.isPublished}
                  label="Objeto da Carteira no singular"
                />
                <TextField
                  variant="outlined"
                  className="input-field"
                  value={this.state.plural === null ? '' : this.state.plural}
                  onChange={this.handleChange('plural')}
                  type="text"
                  id="plural"
                  required={this.state.isPublished}
                  label="Objeto da Carteira no plural"
                />
                <TextField
                  variant="outlined"
                  className="input-field"
                  value={this.state.titulo === null ? '' : this.state.titulo}
                  type="text"
                  id="titulo"
                  required
                  label="Título"
                  onChange={this.handleChange('titulo')}
                />
                <TextField
                  variant="outlined"
                  className="input-field"
                  value={this.state.detailsTitle === null ? '' : this.state.detailsTitle}
                  onChange={this.handleChange('detailsTitle')}
                  type="text"
                  id="detailsTitle"
                  label="Detalhe do Título"
                />
                <TextField
                  variant="outlined"
                  className="input-field"
                  value={this.state.descricao === null ? '' : this.state.descricao}
                  multiline
                  onChange={this.handleChange('descricao')}
                  type="text"
                  id="descricao"
                  required
                  label="Descrição"
                />
                <TextField
                  variant="outlined"
                  className="input-field"
                  value={
                    this.state.detailsDescription === null ? '' : this.state.detailsDescription
                  }
                  multiline
                  onChange={this.handleChange('detailsDescription')}
                  type="text"
                  id="detailsDescription"
                  label="Detalhe da Descrição"
                />
                <TextField
                  variant="outlined"
                  className="input-field"
                  value={this.state.credit === null ? '' : this.state.credit}
                  onChange={this.handleChange('credit')}
                  onWheel={this.handleWheel}
                  type="number"
                  id="credit"
                  label="Crédito"
                />
                <br />
                <Downshift
                  id="downshift-multiple"
                  inputValue={keywordsConsume}
                  onChange={this.handleChangeDownshiftConsume}
                  selectedItem={selectedItemConsume}
                >
                  {({
                    getInputProps,
                    getItemProps,
                    isOpen,
                    inputValue: inputValue3,
                    selectedItem: selectedItem3,
                    highlightedIndex,
                  }) => (
                    <div className={classes.container}>
                      {renderInput({
                        fullWidth: true,
                        classes,
                        InputProps: getInputProps({
                          startAdornment: selectedItemConsume.map((item) => (
                            <Chip
                              key={item}
                              tabIndex={-1}
                              label={item}
                              className={classes.chip}
                              onDelete={this.handleDeleteDownshiftConsume(item)}
                            />
                          )),
                          onChange: this.handleChange('keywordsSetConsumption'),
                          onKeyDown: this.handleKeyDown,
                          placeholder: 'Selecione as keywords de consumo',
                        }),
                        label: 'Keywords de consumo',
                      })}
                      {isOpen ? (
                        <Paper className={classes.paper} square>
                          {getSuggestions(inputValue3).map((suggestion, index) =>
                            renderSuggestion({
                              suggestion,
                              index,
                              itemProps: getItemProps({
                                item: suggestion.label,
                              }),
                              highlightedIndex,
                              selectedItem: selectedItem3,
                            })
                          )}
                        </Paper>
                      ) : null}
                    </div>
                  )}
                </Downshift>
                <TextField
                  style={{ width: '100%' }}
                  placeholder="Você não tem essa permissão !"
                  className="input-field"
                  disabled={this.state.keywordsSetConsumptionList <= 0}
                  value={this.state.messageBlockedKw === null ? '' : this.state.messageBlockedKw}
                  multiline
                  onChange={this.handleChange('messageBlockedKw')}
                  type="text"
                  id="messageBlockedKw"
                  required={this.state.keywordsSetConsumptionList > 0}
                  label="Mensagem de bloqueio por keyword"
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.campanhaTriibo}
                      onChange={this.handleChangeSwitch('campanhaTriibo')}
                      color="primary"
                    />
                  }
                  label={'Campanha Triibo?'}
                />
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.sendSMS}
                      onChange={this.handleChangeSwitch('sendSMS')}
                      color="primary"
                    />
                  }
                  label={'Enviar SMS ao resgatar a promoção?'}
                />
                <br />
                <FormControlLabel
                  control={
                    <Switch
                      checked={this.state.disableButtonImage}
                      onChange={this.handleChangeSwitchImage('disableButtonImage')}
                      color="primary"
                      onClick={this.handleEqualImages('both')}
                    />
                  }
                  label={'Utilizar as mesmas imagens da promoção'}
                />
                {!this.state.disableButtonImage && (
                  <Typography
                    style={{
                      marginTop: '10px',
                      marginBottom: '15px',
                      fontWeight: '700',
                      fontSize: '20px',
                    }}
                    variant="h5"
                    gutterBottom
                    color="primary"
                  >
                    Fotos do cupom na carteira
                  </Typography>
                )}
                {!this.state.disableButtonImage && (
                  <div className="flex-external">
                    <div
                      className="img-thumb"
                      style={{
                        maxWidth: '400px',
                        position: 'relative',
                        top: '0',
                        left: '0',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        color="secondary"
                        style={{ fontWeight: '550', textAlign: 'center' }}
                      >
                        Thumbnail (aparecerá na "Carteira")
                      </Typography>

                      <img
                        alt="Thumbnail"
                        src={cuponsValidos}
                        style={{
                          width: '100%',
                          maxWidth: '400px',
                          position: 'relative',
                          top: '0',
                          left: '0',
                        }}
                      />

                      <img
                        alt="thumbnail"
                        className="img-position-carteira"
                        src={
                          this.state.equalImageThumnailFile !== null
                            ? this.state.equalImageThumnailFile
                            : this.state.fileThumbnail !== null
                            ? this.state.fileThumbnail
                            : this.state.loadedThumbnail !== null
                            ? this.state.loadedThumbnail
                            : placeholder
                        }
                      />

                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <input
                          value=""
                          disabled={this.state.equalImageThumnail === true ? true : false}
                          id="thumbailTemplate"
                          accept="image/*"
                          style={{ display: 'none' }}
                          type="file"
                          onChange={this.fileChangedHandler('fileThumbnail', 'thumbnail')}
                        />

                        <label htmlFor="thumbailTemplate">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            style={{
                              marginTop: '15px',
                              fontWeight: '550',
                              marginLeft: '20px',
                              color: '#fff',
                              textTransform: 'capitalize',
                            }}
                            disabled={this.state.disableButtonImage}
                            startIcon={<AddPhotoAlternate />}
                          >
                            Adicionar Foto
                          </Button>

                          <Tooltip title="As imagens devem ser 4x3.">
                            <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                          </Tooltip>
                        </label>
                      </div>
                    </div>

                    <div
                      className="img-thumb"
                      style={{
                        maxWidth: '400px',
                        position: 'relative',
                        top: '0',
                        left: '0',
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        gutterBottom
                        color="secondary"
                        style={{ fontWeight: '550', textAlign: 'center' }}
                      >
                        Foto detalhada (aparecerá em "Detalhe do Cupom")
                      </Typography>

                      <img
                        alt="Placeholder lista img"
                        src={nomeCupom}
                        style={{
                          maxWidth: '100%',
                          position: 'relative',
                          top: '0',
                          left: '0',
                        }}
                      />

                      <img
                        alt="Foto detalhada"
                        width="100%"
                        style={{ position: 'absolute', left: '0' }}
                        src={
                          this.state.equalImagePhotoFile !== null
                            ? this.state.equalImagePhotoFile
                            : this.state.fileFoto !== null
                            ? this.state.fileFoto
                            : this.state.loadedFoto !== null
                            ? this.state.loadedFoto
                            : placeholder
                        }
                      />

                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <input
                          value=""
                          disabled={this.state.equalImagePhoto === true ? true : false}
                          id="photoTemplate"
                          style={{ display: 'none' }}
                          type="file"
                          onChange={this.fileChangedHandler('fileFoto', 'foto')}
                        />

                        <label htmlFor="photoTemplate">
                          <Button
                            variant="contained"
                            color="primary"
                            component="span"
                            style={{
                              marginTop: '15px',
                              fontWeight: '550',
                              marginLeft: '20px',
                              color: '#fff',
                              textTransform: 'capitalize',
                            }}
                            disabled={this.state.disableButtonImage}
                            startIcon={<AddPhotoAlternate />}
                          >
                            Adicionar Fotos
                          </Button>
                          <Tooltip title="As imagens devem ser 4x3.">
                            <Help color="secondary" style={{ fontSize: 18, bottom: 0 }} />
                          </Tooltip>
                        </label>
                      </div>
                    </div>
                  </div>
                )}
                <Grid
                  style={{
                    maxHeight: '100%',
                    overflowY: 'auto',
                    padding: '40px 32px',
                  }}
                  container
                  spacing={4}
                >
                  <FormLabel style={{ marginTop: '20px' }} component="legend">
                    Selecione a região da promoção (ordenação na lista)
                  </FormLabel>
                  <FormControl className="input-field">
                    <Select
                      variant="outlined"
                      className="input-field"
                      value={this.state.region}
                      onChange={this.handleChange('region')}
                      displayEmpty
                      name="region"
                    >
                      <MenuItem value="regional">Regional</MenuItem>
                      <MenuItem value="national">Nacional</MenuItem>
                      <MenuItem value="local">Local</MenuItem>
                    </Select>
                  </FormControl>

                  <FormLabel style={{ marginTop: '20px' }} component="legend">
                    Tipo de Consumo
                  </FormLabel>
                  <FormControl className="input-field">
                    <RadioGroup
                      aria-label="consumo"
                      name="consumo"
                      value={this.state.consumo}
                      onChange={this.handleChange('consumo')}
                    >
                      <FormControlLabel
                        value="imediato"
                        control={<Radio color="primary" />}
                        label="Imediato"
                        labelPlacement="end"
                      />

                      <FormControlLabel
                        value="posterior"
                        control={<Radio color="primary" />}
                        label="Posterior"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                    <FormLabel
                      style={{ marginTop: '20px', marginBottom: '10px' }}
                      component="legend"
                    >
                      Validade do cupom
                    </FormLabel>
                    <RadioGroup
                      aria-label="validade do cupom"
                      name="validateCuopon"
                      value={this.state.expirationType}
                      onChange={this.handleChangeRadio}
                    >
                      <FormControlLabel
                        value="fixed"
                        control={<Radio color="primary" />}
                        label="Data de desativação"
                        disabled={this.state.consumo === 'imediato'}
                      />
                      <FormControlLabel
                        value="onSend"
                        control={<Radio color="primary" />}
                        label="Validade em dias (após recebimento na carteira)"
                        disabled={this.state.consumo === 'imediato'}
                      />
                    </RadioGroup>
                    <br />
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <TextField
                          variant="outlined"
                          id="validateDate"
                          label="Data de desativação"
                          type="datetime-local"
                          value={
                            this.state.disableDateCoupon === null
                              ? ''
                              : convertToDate(this.state.disableDateCoupon)
                          }
                          InputLabelProps={{ shrink: true }}
                          onChange={this.handleChange('disableDateCoupon')}
                          // required={this.state.isPublished}
                          disabled={
                            this.state.expirationType === 'onSend' ||
                            this.state.consumo === 'imediato'
                          }
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          variant="outlined"
                          id="validateDays"
                          label="Validade em dias"
                          type="number"
                          style={{ width: '75%' }}
                          InputProps={{ inputProps: { min: 1, max: 365 } }}
                          onInput={(e) =>
                            (e.target.value = Math.max(0, parseInt(e.target.value))
                              .toString()
                              .slice(0, 3))
                          }
                          value={this.state.onSendExpiration}
                          InputLabelProps={{ shrink: true }}
                          onChange={this.handleChange('onSendExpiration')}
                          // required={this.state.isPublished}
                          disabled={
                            this.state.expirationType === 'fixed' ||
                            this.state.consumo === 'imediato'
                          }
                        />
                      </Grid>
                    </Grid>
                    <br />
                    <FormControlLabel
                      control={
                        <Switch
                          disabled={this.state.consumo === 'imediato' ? true : false}
                          checked={this.state.hasSimultaneo}
                          onChange={this.handleChangeSwitch('hasSimultaneo')}
                          color="primary"
                        />
                      }
                      label={'Permite resgate simultâneo?'}
                    />

                    <TextField
                      variant="outlined"
                      style={{ width: '100px' }}
                      className="input-field"
                      disabled={
                        this.state.consumo === 'imediato' || this.state.hasSimultaneo === false
                      }
                      onChange={this.handleChange('qtdSimultaneo')}
                      value={this.state.qtdSimultaneo === null ? '' : this.state.qtdSimultaneo}
                      type="number"
                      id="qtdSimultaneo"
                      required={this.state.isPublished}
                      label="Quantidade Simultaneo"
                    />

                    <TextField
                      variant="outlined"
                      className="input-field"
                      value={this.state.buttonLabel === null ? '' : this.state.buttonLabel}
                      multiline
                      onChange={this.handleChange('buttonLabel')}
                      type="text"
                      id="buttonLabel"
                      label="Texto do botão"
                    />

                    <TextField
                      variant="outlined"
                      className="input-field"
                      value={
                        this.state.buttonDescription === null ? '' : this.state.buttonDescription
                      }
                      multiline
                      onChange={this.handleChange('buttonDescription')}
                      type="text"
                      id="buttonDescription"
                      label="Descrição do botão"
                    />

                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.showButtonDescription}
                          onChange={this.handleChangeSwitch('showButtonDescription')}
                          color="primary"
                        />
                      }
                      label={'Mostrar descrição do botão?'}
                    />

                    <TextField
                      variant="outlined"
                      className="input-field"
                      value={this.state.goToPlaceLabel === null ? '' : this.state.goToPlaceLabel}
                      multiline
                      onChange={this.handleChange('goToPlaceLabel')}
                      type="text"
                      id="goToPlaceLabel"
                      label="Texto do botão para o estabelecimento"
                    />

                    <FormControlLabel
                      control={
                        <Switch
                          checked={this.state.showGoToPlace}
                          onChange={this.handleChangeSwitch('showGoToPlace')}
                          color="primary"
                        />
                      }
                      label={'Mostrar botão de visualizar estabelecimento?'}
                    />

                    <FormLabel style={{ marginTop: '20px' }} component="legend">
                      Processo de Validação
                    </FormLabel>

                    <RadioGroup
                      aria-label="autoValidate"
                      name="autoValidate"
                      value={this.state.autoValidate}
                      onChange={this.handleChange('autoValidate')}
                    >
                      <FormControlLabel
                        value="estabelecimento"
                        control={<Radio color="primary" />}
                        label="Celular do Estabelecimento"
                        labelPlacement="end"
                      />

                      <FormControlLabel
                        value="cliente"
                        control={<Radio color="primary" />}
                        label="Celular do Cliente"
                        labelPlacement="end"
                      />
                    </RadioGroup>

                    <FormControlLabel
                      style={{ marginTop: '10px', marginBottom: '-20px' }}
                      control={
                        <Switch
                          checked={this.state.consumoUnico}
                          onChange={this.handleChangeSwitch('consumoUnico')}
                          color="primary"
                        />
                      }
                      label={'Voucher de Consumo único?'}
                    />
                    <FormControlLabel
                      style={{ marginTop: '7px', marginBottom: '-20px' }}
                      control={
                        <Switch
                          checked={this.state.consumed}
                          onChange={this.handleChangeSwitch('consumed')}
                          color="primary"
                        />
                      }
                      label={'Consumir promoção automaticamente?'}
                    />

                    <FormLabel style={{ marginTop: '40px' }} component="legend">
                      Tempo de espera para pegar outro cupom
                    </FormLabel>

                    <Grid container spacing={4}>
                      <Grid item lg={1}>
                        <TextField
                          variant="outlined"
                          style={{ width: '70px' }}
                          className="input-field"
                          value={this.state.cooldownDays === null ? '' : this.state.cooldownDays}
                          onChange={this.handleChange('cooldownDays')}
                          type="number"
                          id="cooldownDays"
                          required={this.state.isPublished}
                          label="Dias"
                        />
                      </Grid>
                      <Grid item lg={1}>
                        <TextField
                          variant="outlined"
                          style={{ width: '70px' }}
                          className="input-field"
                          value={this.state.cooldownHours === null ? '' : this.state.cooldownHours}
                          onChange={this.handleChange('cooldownHours')}
                          type="number"
                          id="cooldownHours"
                          required={this.state.isPublished}
                          label="Horas"
                        />
                      </Grid>
                      <Grid item lg={1}>
                        <TextField
                          variant="outlined"
                          style={{ width: '70px' }}
                          className="input-field"
                          value={
                            this.state.cooldownMinutes === null ? '' : this.state.cooldownMinutes
                          }
                          onChange={this.handleChange('cooldownMinutes')}
                          type="number"
                          id="cooldownMinutes"
                          required={this.state.isPublished}
                          label="Minutos"
                        />
                      </Grid>
                    </Grid>

                    <FormControlLabel
                      style={{ marginTop: '10px' }}
                      control={
                        <Switch
                          checked={this.state.generator}
                          onChange={this.handleChangeSwitch('generator')}
                          color="primary"
                          disabled={
                            this.state.qtdDisponivel !== null && this.state.qtdDisponivel !== 0
                          }
                        />
                      }
                      label={'Estoque ilimitado?'}
                    />

                    {this.state.qtdDisponivel !== null &&
                      this.state.qtdDisponivel !== 0 &&
                      this.state.editPage && (
                        <Typography variant="subtitle2" color="error">
                          Você não pode alterar o controle de estoque dessa promoção.
                          <br /> Quantidade Disponível: {this.state.qtdDisponivel}
                        </Typography>
                      )}
                  </FormControl>

                  <Typography
                    style={{ marginTop: '20px' }}
                    variant="h5"
                    gutterBottom
                    color="primary"
                  >
                    Publicação da Oferta
                  </Typography>

                  <FormControl className="input-field">
                    <FormLabel style={{ marginTop: '20px' }} component="legend">
                      Tipo da Oferta
                    </FormLabel>
                    <RadioGroup
                      aria-label="storeType"
                      name="storeType"
                      value={this.state.storeType}
                      onChange={this.handleChange('storeType')}
                    >
                      <FormControlLabel
                        value="promotion"
                        control={<Radio color="primary" />}
                        label="Promoção"
                        labelPlacement="end"
                      />

                      <FormControlLabel
                        value="product"
                        control={<Radio color="primary" />}
                        label="Produto"
                        labelPlacement="end"
                      />
                    </RadioGroup>

                    <FormLabel style={{ marginTop: '20px' }} component="legend">
                      Selecione o tipo de entrega
                    </FormLabel>
                    <Select
                      variant="outlined"
                      className="input-field"
                      value={this.state.deliverType}
                      onChange={this.handleChange('deliverType')}
                      displayEmpty
                      name="deliverType"
                      disabled={this.state.storeType === 'promotion' ? true : false}
                    >
                      <MenuItem value={1}>Retirar no estabelecimento</MenuItem>
                      <MenuItem value={0}>Receber pelo correio</MenuItem>
                    </Select>

                    <div>
                      <FormLabel style={{ marginTop: '20px' }} component="legend">
                        Por quanto você quer vender esse produto?
                      </FormLabel>
                      <FormLabel style={{ marginTop: '20px', fontSize: '13px' }} component="legend">
                        OBS: A Triibo cobra uma comissão de 15% sobre o valor total.
                      </FormLabel>
                      <div style={{ display: 'flex', alignItems: 'baseline' }}>
                        {storeType === 'product' && <span style={{ marginRight: '4px' }}>R$ </span>}
                        <TextField
                          variant="outlined"
                          style={{
                            width: '100px',
                          }}
                          onWheel={(e) => e.target.blur()}
                          className="input-field"
                          autoComplete="off"
                          type="text"
                          disabled={storeType === 'promotion' ? true : false}
                          onChange={this.handleChange('cost')}
                          value={priceInReais}
                          id="cost"
                          required={this.state.isPublished}
                          onBlur={() => this.addComma()}
                          onFocus={() => this.removeComma()}
                        />
                      </div>

                      {storeType === 'product' && (
                        <div style={{ marginTop: '10px' }}>
                          <span style={{ color: 'rgba(0, 0, 0, 0.54)' }}>Value em pontos: </span>
                          <span>{cost ? cost : 0} pts </span>
                        </div>
                      )}
                    </div>

                    <FormControlLabel
                      style={{ marginTop: '10px' }}
                      control={
                        <Switch
                          checked={this.state.isPublished}
                          onChange={this.handleChangeSwitch('isPublished')}
                          color="primary"
                        />
                      }
                      label={'Publicar Oferta?'}
                    />

                    <div
                      style={{
                        display: this.state.unlimitedDisableDate ? 'block' : 'block',
                        width: '100%',
                      }}
                    >
                      <TextField
                        variant="outlined"
                        id="enableDate"
                        label="Data de Ativação"
                        type="datetime-local"
                        value={this.state.enableDate === null ? '' : this.state.enableDate}
                        InputLabelProps={{ shrink: true }}
                        onChange={this.handleChange('enableDate')}
                        required={this.state.isPublished}
                      />
                    </div>

                    <FormControlLabel
                      style={{ marginTop: '10px' }}
                      control={
                        <Switch
                          checked={this.state.unlimitedDisableDate}
                          onChange={this.handleChangeSwitch('unlimitedDisableDate')}
                          color="primary"
                        />
                      }
                      label={'Desabilitar data de desativação programada'}
                    />
                    <div
                      style={{
                        color: 'red',
                        fontSize: '0.7rem',
                        fontWeight: 400,
                        marginBottom: '1rem',
                        padding: 0,
                      }}
                    >
                      *Se a data de desativação for maior que 5 anos esse botão será habilitado
                      automaticamente.
                    </div>

                    <br />
                    <div
                      style={{
                        display: this.state.unlimitedDisableDate ? 'none' : 'block',
                        width: '100%',
                      }}
                    >
                      <TextField
                        variant="outlined"
                        id="disableDate"
                        label="Data de Desativação"
                        type="datetime-local"
                        value={this.state.disableDate === null ? '' : this.state.disableDate}
                        InputLabelProps={{ shrink: true }}
                        onChange={this.handleChange('disableDate')}
                        required={this.state.isPublished}
                      />
                    </div>
                  </FormControl>
                </Grid>
              </FormGroup>

              <Button
                style={{
                  fontWeight: '550',
                  marginTop: '50px',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                variant="contained"
                color="secondary"
                onClick={() => this.goBack()}
                startIcon={<ArrowBack />}
              >
                Voltar
              </Button>

              {this.state.openDialog && (
                <Dialog
                  open={this.state.openDialog}
                  TransitionComponent={Transition}
                  keepMounted
                  onClose={() => this.setState({ openDialog: false })}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  {/* <DialogTitle id="alert-dialog-slide-title">{'Deseja continuar?'}</DialogTitle> */}

                  <DialogContent sx={{ position: 'relative' }}>
                    <Box
                      sx={{
                        position: 'absolute',
                        right: '20px',
                        top: '20px',
                        cursor: 'pointer',
                      }}
                      onClick={() => this.setState({ openDialog: false })}
                    >
                      <Close />
                    </Box>
                    <DialogContentText
                      id="alert-dialog-slide-description"
                      sx={{
                        height: 'max-content',
                        paddingLeft: '10px',
                        paddingRight: '15px',
                        marginTop: '40px',
                      }}
                    >
                      {this.state.generator
                        ? 'Atenção! Você está gerando uma promoção com estoque ilimitado.'
                        : 'Atenção! Você está gerando uma promoção sem estoque de vouchers, vá até "Gerar Cupons" para gerar estoque.'}
                    </DialogContentText>
                  </DialogContent>

                  <DialogActions sx={{ margin: '0px 15px 10px 10px' }}>
                    <Button
                      style={{
                        fontWeight: '550',
                        color: 'white',
                        textTransform: 'capitalize',
                      }}
                      variant="contained"
                      color="secondary"
                      onClick={() => this.setState({ openDialog: false })}
                    >
                      Cancelar
                    </Button>
                    <Button
                      style={{
                        fontWeight: '550',
                        color: 'white',
                        textTransform: 'capitalize',
                      }}
                      variant="contained"
                      color="primary"
                      onClick={this.checkInformation}
                      startIcon={<Save />}
                    >
                      Salvar
                    </Button>
                  </DialogActions>
                </Dialog>
              )}

              {/* Botão que abre o dialog */}
              <Button
                style={{
                  fontWeight: '550',
                  marginTop: '50px',
                  marginLeft: '10px',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                variant="contained"
                color="primary"
                type="button"
                onClick={this.handleOpenDialog}
                startIcon={<Save />}
              >
                Salvar
              </Button>
            </form>

            {/* ALERTS */}
            <Dialog
              open={this.state.open}
              TransitionComponent={Transition}
              keepMounted
              onClose={this.handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">
                {'Deseja voltar a página anterior?'}
              </DialogTitle>

              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  As alterações realizadas não serão salvas.
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={this.handleClose} color="primary">
                  cancelar
                </Button>
                <Button onClick={() => window.history.back()} color="primary">
                  voltar
                </Button>
              </DialogActions>
            </Dialog>
            {/* FIM ALERTS */}
            {/* MODAL PARA CROPAR IMAGEM */}
            <Modal
              aria-labelledby="simple-modal-title"
              aria-describedby="simple-modal-description"
              open={this.state.openModal}
            >
              <div style={getModalStyle()}>
                <Typography variant="h5" gutterBottom color="primary" style={{ fontWeight: '600' }}>
                  Editar Imagem
                </Typography>

                <div
                  style={{
                    maxWidth: '40%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  {this.state.imageToCrop === undefined ||
                  this.state.imageToCrop === null ? null : (
                    <Cropper
                      src={this.state.imageToCrop[this.state.indexCrop]}
                      ref={(ref) => {
                        this.image = ref;
                      }}
                      ratio={4 / 3}
                    />
                  )}
                </div>

                <div style={{ position: 'fixed', bottom: 10 }}>
                  <Button
                    style={{
                      fontWeight: '550',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="secondary"
                    onClick={() => this.handleCloseModal()}
                    startIcon={<Save />}
                  >
                    Cancelar
                  </Button>

                  <Button
                    style={{
                      fontWeight: '550',
                      marginLeft: '10px',
                      color: 'white',
                      textTransform: 'capitalize',
                    }}
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      if (this.state.imageCroped === 'photoListFile') {
                        this.photoListCropImage('image');
                      } else {
                        this.cropImage('image');
                      }
                    }}
                    startIcon={<Save />}
                  >
                    Cortar Imagem
                  </Button>
                </div>
              </div>
            </Modal>
            {/* FIM MODAL PARA CROPAR IMAGEM */}

            {/* ALERT */}
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={this.state.openAlert}
              onClose={this.handleCloseAlert}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">{this.state.messageAlert}</span>}
            />
          </div>
        );
      }
    }
  };
}

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    loading: state.storePromotionComponent.loading,
    success: state.storePromotionComponent.success,
    error: state.storePromotionComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  updateStorePromotionInformationComponent: (
    id,
    consumoUnico,
    cooldownHours,
    cooldownMinutes,
    dataGeracao,
    descricao,
    detailsDescription,
    credit,
    keywordsSetConsumptionList,
    messageBlockedKw,
    detailsTitle,
    generator,
    neighborhood,
    nomeTemplate,
    plural,
    qtdDisponivel,
    qtdSimultaneo,
    singular,
    titulo,
    type,
    fileThumbnail,
    oldThumbnail,
    thumbnail,
    fileFoto,
    oldFoto,
    foto,
    campanhaTriibo,
    buttonLabel,
    buttonDescription,
    showButtonDescription,
    goToPlaceLabel,
    showGoToPlace,
    consumed,
    idStore,
    active,
    level,
    region,
    description,
    showQRCode,
    title,
    storeType,
    autoValidate,
    deliverType,
    cost,
    enableDate,
    disableDate,
    isPublished,
    keywordsList,
    tags,
    fileStoreThumbnail,
    oldStoreThumbnail,
    storeThumbnail,
    fileStorePhoto,
    oldStorePhoto,
    storePhoto,

    idEstabelecimento,
    idGestor,
    nomeEstabelecimento,
    lat,
    long,
    address,

    hasSimultaneo,
    equalImageThumnailFile,
    equalImagePhotoFile,
    oldPhotoList,
    photoListFile,
    messageApproval,
    newStatus,
    couponGetMode,
    expirationType,
    disableDateCoupon,
    onSendExpiration,
    sendSMS = null
  ) =>
    updateStorePromotionInformationAction(
      dispatch,
      id,
      consumoUnico,
      cooldownHours,
      cooldownMinutes,
      dataGeracao,
      descricao,
      detailsDescription,
      credit,
      keywordsSetConsumptionList,
      messageBlockedKw,
      detailsTitle,
      generator,
      neighborhood,
      nomeTemplate,
      plural,
      qtdDisponivel,
      qtdSimultaneo,
      singular,
      titulo,
      type,
      fileThumbnail,
      oldThumbnail,
      thumbnail,
      fileFoto,
      oldFoto,
      foto,
      campanhaTriibo,
      buttonLabel,
      buttonDescription,
      showButtonDescription,
      goToPlaceLabel,
      showGoToPlace,
      consumed,
      idStore,
      active,
      level,
      region,
      description,
      showQRCode,
      title,
      storeType,
      autoValidate,
      deliverType,
      cost,
      enableDate,
      disableDate,
      isPublished,
      keywordsList,
      tags,
      fileStoreThumbnail,
      oldStoreThumbnail,
      storeThumbnail,
      fileStorePhoto,
      oldStorePhoto,
      storePhoto,

      idEstabelecimento,
      idGestor,
      nomeEstabelecimento,
      lat,
      long,
      address,

      hasSimultaneo,
      equalImageThumnailFile,
      equalImagePhotoFile,
      oldPhotoList,
      photoListFile,
      messageApproval,
      newStatus,
      couponGetMode,
      expirationType,
      disableDateCoupon,
      onSendExpiration,
      (sendSMS = null)
    ),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(StorePromotionInformation);

