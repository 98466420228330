import dispatcher from "js/core/dispatcher";

import { updateDatabase, b64toBlob } from "js/library/services/DatabaseManager";

import {
  downloadImageAsBlob,
  uploadImage,
} from "js/library/services/StorageManager";

export const updateMarketingTemplateAction = async (
  dispatch,
  id,
  templateName,
  listAllImages,
  listImageURL,
  formElements,
  imageFormat,
  colorListChange,
  tutorialTemplate,
) => {
  
  const storageFolder = "templates";

  let pathname = "/admin/configuracoes/templates";

  let backgroundImages = [];

  if (listImageURL !== null) {
    listAllImages = [];

    for (const key in listImageURL) {

      if (listImageURL[key].indexOf("https") === 0) {
        listImageURL[key] = await downloadImageAsBlob(listImageURL[key]);
      } else {
        listImageURL[key] = b64toBlob(listImageURL[key]);
      }
      listAllImages[key] = "gal" + key + "_" + id;
      backgroundImages[key] = { color: colorListChange[key], image: listAllImages[key] };
    }
  }

  const dataTemplate = {
    backgroundImages,
    elements: formElements,
    format: imageFormat,
    name: templateName,
    tutorial: tutorialTemplate.trim(),
  };

  return dispatcher(
    dispatch,
    "MARKETING_TEMPLATE",
    updateDatabase("Marketing-Content-Templates", dataTemplate, id, pathname),
    uploadImage(storageFolder, listImageURL, listAllImages)
  );
};
