import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Typography , Box, Button, CircularProgress } from "@mui/material"

import * as S from './style';

import TemplateModalLogin from './templateModalLogin';
import { codeInputAction } from 'js/core/actions/codeInputActions.js';
import { store } from 'js/core/configureStore';
import { authCode } from 'js/library/utils/API/authCode.js';

function CodeInput(props) {
  const [loading, setLoading] = useState(false);
  const [_, setStartTimer] = useState(true);
  const [messageError, setMessageError] = useState('');
  const [transactionId, setTransactionId] = useState(
    store.getState().phoneInputModel.transactionId
  );
  const [inputCode, setInputCode] = useState('');
  const [codeInput, _setCodeInput] = useState([]);
  const [focusOnNumberCard, setFocusOnNumberCard] = useState(false);
  const cellPhone = store.getState().phoneInputModel.cellPhone;
  const codeError = store.getState().codeInputModel.error;

  const inputRef = useRef('');

  //altera os states conforme escrevemos no formulário
  const handleInput = (event) => {
    const regex = /^[0-9\b]+$/;
    if (event.target.value === '' || regex.test(event.target.value)) {
      setInputCode(event.target.value);
    }
  };

  const handleClick = () => {
    inputRef.current.focus();
  };

  const handleSubmit = (e) => {
    setInputCode('');
    setLoading(true);
    e && e.preventDefault();
    props.codeInputComponent(cellPhone, codeInput.join(''), transactionId);
  };

  const handleErrors = () => {
    setLoading(false);

    if (codeError === 1006) {
      setMessageError('Código incorreto.');
    } else if (codeError === 1007) {
      setMessageError('Código expirado.');
    } else {
      setMessageError('Ocorreu um erro. Por favor tente novamente.');
    }
  };

  const getNewCode = () => {
    setStartTimer(true);

    authCode(cellPhone, 'login').then((resultSendSMS) => {
        setLoading(false);

        if (resultSendSMS.error === null) {
          setMessageError('Reenviado SMS.');
          setTransactionId(resultSendSMS.transactionId);
          return;
        }
        else {
          setMessageError('Ocorreu um erro. Tente novamente mais tarde!');
        }
      }).catch(() => {
        setLoading(false);
        setMessageError('Ocorreu um erro. Tente novamente mais tarde!');
      });
  };

  useEffect(() => {
    window.pathname = null;
  }, []);

  useEffect(() => {
    _setCodeInput(inputCode.split(''));
  }, [inputCode]);

  useEffect(() => {
    if ((props.error !== null || codeError) && loading) {
      handleErrors();
    }
  }, [props.error, codeError]);

  if (window.pathname !== null) {
    return <Redirect to={window.pathname} />;
  }
  return (
    <div style={{ overflow: 'hidden', position: 'relative' }}>
      {/* serve para fazer com que abra um keyboard digital, está fora da tela para usuário não clicar por acidente */}

      <TemplateModalLogin
        handleSubmit={handleSubmit}
        messageError={messageError}
        gap='1.8rem'
        setMessageError={setMessageError}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: '1rem',
            width: '100%',
          }}>
          <input
            ref={inputRef}
            type='tel'
            value={inputCode}
            autoFocus={true}
            onFocus={setFocusOnNumberCard}
            maxLength={6}
            onChange={handleInput}
            style={{
              opacity: '0',
              cursor: 'default',
              position: 'fixed',
              bottom: '-2rem',
            }}
          />
          <S.TextContainer
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}>
            <Typography
              variant='subtitle1'
              fontSize={{ xs: '17px', sm: '24px' }}
              textAlign={'center'}
              color={'#383838'}
              lineHeight={{ xs: '2rem', sm: '4rem' }}>
              Insira o código de verificação enviado via SMS
            </Typography>

            <Typography
              variant='subtitle2'
              fontSize={{ xs: '12px', sm: '16px' }}
              textAlign='center'
              color='#38383880'
              lineHeight='33px'>
              O código de verificação foi enviado para:{' '}
              {cellPhone ? cellPhone : '+5511XXXXXXXXX'}
            </Typography>
          </S.TextContainer>

          <S.NumbersContainer>
            {Array(6)
              .fill('')
              .map((element, index) =>
                codeInput[index - 1] !== undefined &&
                codeInput[index] === undefined &&
                codeInput[index + 1] === undefined ? (
                  <S.CardNumberUnderline
                    onClick={handleClick}
                    focus={focusOnNumberCard}
                    key={index}
                    className='cardNumber'>
                    {codeInput[index]}
                  </S.CardNumberUnderline>
                ) : (
                  <S.CardNumber
                    onClick={handleClick}
                    focus={focusOnNumberCard}
                    key={index}
                    className='cardNumber'>
                    {codeInput[index]}
                  </S.CardNumber>
                )
              )}
          </S.NumbersContainer>
          <Typography
            variant='subtitle2'
            fontSize={{ xs: '12px', sm: '16px' }}
            textAlign='center'
            color='#38383880'
            lineHeight='33px'>
            Não recebeu o código?{' '}
            <span
              onClick={() => getNewCode()}
              style={{
                color: '#0000FF',
                cursor: 'pointer',
                textDecoration: 'underline',
                fontWeight: 'regular',
              }}>
              Clique aqui e reenvie!
            </span>
          </Typography>

          <Button
            sx={{
              padding: '2',
              width: { xs: '160px', sm: '360px' },
              backgroundColor: '#08bad0',
              '&:hover': {
                backgroundColor: '#08bad0',
                filter: 'brightness(0.9)',
              },
            }}
            disabled={codeInput.length < 6 || loading}
            type='submit'
            variant='contained'
            id='submit-button'>
            {loading ? (
              <CircularProgress size='2rem' style={{ color: 'white' }} />
            ) : (
              <>
                <Typography
                  variant='h6'
                  style={{ textTransform: 'capitalize', color: 'white' }}>
                  Continuar
                </Typography>
              </>
            )}
          </Button>
        </Box>
      </TemplateModalLogin>
    </div>
  );
}

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    loading: state.codeInputComponent.loading,
    success: state.codeInputComponent.success,
    error: state.codeInputComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  codeInputComponent: (cellPhone, code, transactionId) =>
    codeInputAction(dispatch, cellPhone, code, transactionId),
});

export default connect(mapStateToProps, mapDispatchToProps)(CodeInput);
