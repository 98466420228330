import styled from 'styled-components';
import { Box as MuiBox, Container as MuiContainer } from '@mui/material';

export const Container = styled(MuiContainer)`
  max-width: 1100px;
  padding: 1rem;
  margin: 5rem auto;
  background-color: #f9f9f9;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    text-align: center;
  }
`;

export const Box = styled(MuiBox)`
  width: 100%;
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;
  padding: 2rem;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const BoxItems = styled(MuiBox)`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
`;

export const InputContainer = styled.div`
  flex: 1;
  padding: 0 1rem;

  & > .MuiTextField-root {
    width: 100%;
  }
`;

export const IconButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem;
`;

export const SaveButtonContainer = styled(MuiBox)`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 2rem;
`;

export const SubTitle = styled.h2`
  font-size: 1.5rem;
  margin: 1rem 0;
`;

export const FileInstructions = styled.div`
  margin-top: 2rem;
`;

export const ErrorMessage = styled.div`
  color: red;
  margin-top: 1rem;
`;

export const Paper = styled.div`
  padding: 1rem;
  margin-top: 2rem;
  background-color: #f5f5f5;
`;

export const ExampleContent = styled.pre`
  white-space: pre-wrap;
  word-wrap: break-word;
`;
