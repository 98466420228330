import React from 'react';
import { Link } from 'react-router-dom';
import * as D from "js/components/DesignSystem/styles";
import * as S from './styles'

// import UserLoginImg from 'styles/assets/estabelecimentos/estabBanner2.png';
// import imgMarketing from 'styles/assets/estabelecimentos/estabBanner.png';
// import SendMessageImg from "styles/assets/estabelecimentos/estabBanner3.png";
import { StyledButton } from 'js/components/Configurations/AdminFunctions/styledButton';

import { HeaderTitle } from 'js/components/Configurations/CreateClub/RelationshipClub/HeaderTitle';
import { GoBackHeader } from 'js/components/Configurations/CreateClub/RelationshipClub/GoBackHeader';

const Promote = (props) => {

  return (
    <div style={{ padding:'4rem 32px'}}>
    <GoBackHeader />
    <S.Container>
      <HeaderTitle pageInfo ='Promover' />
      <S.Grid container spacing={3}> 
        <StyledButton
          url={`/estabelecimento/${props.match.params.id}/marketing`}
          value={'Geração de materiais'}
          index={1}
        />
        <Link
          style={{ textDecoration: "none", width: "100%" }}
          to={{
            pathname: `/estabelecimento/${props.match.params.id}/promover/links-uteis`,
            state: props.location.state,
          }}>
          <D.CardButton newColor={"#6E3296"}>Adicionar links úteis</D.CardButton>
        </Link>
        <StyledButton
          url={`/estabelecimento/relacionamento/lista-comunicacoes`}
          value={'Enviar comunicação'}
          index={1}
        />
        <StyledButton
          url={`/estabelecimento/relacionamento/lista-postagem/${props.match.params.id}`}
          value={'Enviar postagem'}
          index={1}
        />
      </S.Grid>
    </S.Container>
    </div>
  );
};

export default Promote;
