import React from 'react';
import { useHistory } from 'react-router';

import { Modal, Typography } from "@mui/material";
import * as D from "js/components/DesignSystem/styles";
import { Link } from "react-router-dom";
import { ContentModalClube, ModalBoxClube } from "./BasicRegistration/styles";

export function ModalCreateClub({ close, openModal, subdomain }) {
  const history = useHistory();

  return (
    <Modal
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      open={openModal}
      onClose={close}>
      <ModalBoxClube>
        <ContentModalClube>
          <Typography id='modal-modal-title' variant='h6' component='h3'>
            ✅ Clube criado com sucesso !
          </Typography>
          <div>O que deseja fazer ?</div>
          <div>
            <Link to={`/admin/seu-clube/gerir-admin/${subdomain}`}>
              <D.TriiboFilledButton
                purple={true}
                style={{ marginRight: '6px' }}
                onClick={close}>
                Adicionar gestor
              </D.TriiboFilledButton>
            </Link>
            <D.TriiboFilledButton blue={true} onClick={() => history.goBack()}>
              Listar meus clubes
            </D.TriiboFilledButton>
          </div>
          <Link to={`/admin/seu-clube/${subdomain}`}>
            <D.TriiboFilledButton black={true}>
              personalizar clube
            </D.TriiboFilledButton>
          </Link>
        </ContentModalClube>
      </ModalBoxClube>
    </Modal>
  );
}
