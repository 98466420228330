import React, { useRef, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material';
import ImageDisplay from 'js/components/TriiboComponents/UploadImage/ImageDisplay';
import { MdAddCircle } from 'react-icons/md';
import { ToastContainer } from 'react-toastify';
import ImagesGalleryCuration from './ImagesGalleryCuration';
import * as S from './styles';
import CropperModal from 'js/components/DesignSystem/Cropper/CropperModal';

import { ImBin } from 'react-icons/im';
import CropperModalArr from 'js/components/TriiboComponents/Cropper/CropperModalArr';
import { getBase64FromUrl, separateBase64String } from 'js/library/utils/helpers';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';

const StepThreeCuration = ({
  id,
  formState,
  newFormState,
  finalFormState,
  setFinalFormState,
  estThumb,
  setEstThumb,
  galleryArr,
  setGalleryArr,
  galleryArrExcluded,
  setGalleryArrExcluded,
}) => {
  const [addImage, setAddImage] = useState(false);
  const [cropModalOne, setCropModalOne] = useState(false);
  const [cropModalTwo, setCropModalTwo] = useState(false);
  const [imgSelect, seImgSelect] = useState(
    formState.fotoThumb === newFormState.fotoThumb ? 'antiga' : 'nova'
  );

  const [imgPreview, setImgPreview] = useState(null);

  const hiddenFileInput = useRef(null);
  const handleClick = (e) => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (e) => {
    e.preventDefault();

    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    const reader = new FileReader();
    reader.onload = () => {
      if (cropModalOne === true) {
        setEstThumb(reader.result);
      }

      //aqui seta a imagem quando abre o cropped
      if (cropModalTwo === true) {
        setImgPreview(reader.result);
      }
    };
    reader.readAsDataURL(files[0]);
    e.target.value = null;
  };

  const handleSelect = async (e) => {
    seImgSelect(e.target.value);
    switch (e.target.value) {
      case 'antiga':
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            fotoThumb: formState.fotoThumb,
          };
        });

        break;
      case 'nova':
        setFinalFormState((prevState) => {
          return {
            ...prevState,
            fotoThumb: newFormState.fotoThumb,
          };
        });

        break;
      case 'add':
        // Upload da thumbnail
        const newThumbnailFile = separateBase64String(await getBase64FromUrl(estThumb));

        let fotoThumbWithTimestamp;

        await uploadFiles('estabelecimento', {
          mimeType: newThumbnailFile.mimeType,
          fileName: 'thumb_' + id,
          buffer: newThumbnailFile.buffer,
        })
          .then((res) => {
            const response = res.split('/');
            fotoThumbWithTimestamp = response[response.length - 1].split('?')[0];
            setFinalFormState((prevState) => ({
              ...prevState,
              fotoThumb: fotoThumbWithTimestamp,
            }));
          })
          .catch((error) => {
            console.log(error);
            throw new Error('Erro no upload da thumbnail.');
          });
        break;
      default:
        break;
    }
  };

  return (
    <>
      <ToastContainer autoClose={2500} />
      <CropperModal
        open={cropModalOne}
        setOpen={setCropModalOne}
        img={estThumb}
        setImg={setEstThumb}
      />
      <CropperModalArr
        open={cropModalTwo}
        setOpen={setCropModalTwo}
        img={galleryArr}
        imgPreview={imgPreview}
        setImgPreview={setImgPreview}
        setImg={setGalleryArr}
      />
      <Box sx={{ py: '3.6rem', px: { xs: '1.4rem', md: '6rem' } }}>
        <S.SideContainer>
          <S.CardContainer blue={true}>
            <S.SectionLabelContainer white={true} flexStart={true}>
              <S.SectionLabelBig>Foto principal do seu negócio</S.SectionLabelBig>
            </S.SectionLabelContainer>
            <S.MainPhotoContainerDesktop>
              <FormControl sx={{ width: '100%' }}>
                <RadioGroup
                  value={imgSelect}
                  onChange={(e) => {
                    handleSelect(e);
                  }}
                >
                  <Grid container spacing={{ xs: 2, md: 3 }} columns={{ xs: 2, sm: 12, md: 12 }}>
                    <Grid item xs={2} sm={4} md={4}>
                      <FormControlLabel
                        value="antiga"
                        control={
                          <Radio
                            sx={{
                              color: '#ffff',
                              '&.Mui-checked': {
                                color: '#ffff',
                              },
                              '& .MuiSvgIcon-root': {
                                fontSize: 25,
                              },
                            }}
                          />
                        }
                        label={<S.SectionLabelBig white={true}>Foto antiga</S.SectionLabelBig>}
                      />
                      <ImageDisplay srcImg={formState.fotoThumbFile} />
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      <FormControlLabel
                        value="nova"
                        control={
                          <Radio
                            sx={{
                              color: '#ffff',
                              '&.Mui-checked': {
                                color: '#ffff',
                              },
                              '& .MuiSvgIcon-root': {
                                fontSize: 25,
                              },
                            }}
                          />
                        }
                        label={<S.SectionLabelBig white={true}>Foto nova</S.SectionLabelBig>}
                      />
                      <ImageDisplay
                        srcImg={
                          formState.fotoThumbFile !== newFormState.fotoThumbFile &&
                          newFormState.fotoThumbFile
                        }
                      />
                    </Grid>
                    <Grid item xs={2} sm={4} md={4}>
                      {addImage && (
                        <FormControlLabel
                          value="add"
                          control={
                            <Radio
                              sx={{
                                color: '#ffff',
                                '&.Mui-checked': {
                                  color: '#ffff',
                                },
                                '& .MuiSvgIcon-root': {
                                  fontSize: 25,
                                },
                              }}
                            />
                          }
                          label={
                            <S.SectionLabelBig white={true}>Foto adicionada</S.SectionLabelBig>
                          }
                        />
                      )}
                      {!addImage ? (
                        <div
                          style={{
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-around',
                          }}
                        >
                          <Checkbox
                            onChange={(e) => {
                              e.preventDefault();
                              handleClick(e);
                              setAddImage(true);
                              setCropModalOne(true);
                            }}
                            icon={<MdAddCircle size={100} color={'#ffffff'} />}
                          />
                        </div>
                      ) : (
                        <div>
                          <S.DeleteButton
                            style={{ top: '3.3rem' }}
                            onClick={(e) => {
                              e.preventDefault();
                              setAddImage(false);
                            }}
                            visible={true}
                          >
                            <ImBin color={'#6E3296'} />
                          </S.DeleteButton>
                          <ImageDisplay srcImg={estThumb} />
                        </div>
                      )}
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </S.MainPhotoContainerDesktop>
          </S.CardContainer>
          <S.CardContainer white={true}>
            <ImagesGalleryCuration
              formState={formState}
              newFormState={newFormState}
              galleryArr={galleryArr}
              setGalleryArr={setGalleryArr}
              galleryArrExcluded={galleryArrExcluded}
              setGalleryArrExcluded={setGalleryArrExcluded}
              setCropModalTwo={setCropModalTwo}
              handleClick={handleClick}
            />
          </S.CardContainer>
        </S.SideContainer>
      </Box>
      <input
        type="file"
        ref={hiddenFileInput}
        accept="image/*"
        onChange={(e) => {
          if (e.target.files[0].size > 1048576 * 10) {
            alert('Arquivo excedeu 10MB');
            return;
          }
          handleChange(e);
        }}
        style={{ visibility: 'hidden' }}
      />
    </>
  );
};

export default StepThreeCuration;

