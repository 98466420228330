import React, { useRef, useState } from 'react';

import { createFeedContent } from 'js/library/utils/API/apiCreateFeedContent';
import { updateFeedContent } from 'js/library/utils/API/apiUpdateFeedContent';

import { convertDateToTimestamp } from 'js/library/utils/helpers';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import DashedButton from 'js/components/TriiboComponents/Buttons/DashedButton';
import PrimaryButton from 'js/components/TriiboComponents/Buttons/PrimaryButton';
import ListTemplate from '../ListTemplate';
import TagsDisplay from 'js/components/DesignSystem/TagsDisplay';
import TagsAdd from 'js/components/DesignSystem/TagsAdd';
import TagsAddUF from 'js/components/DesignSystem/TagsAddUF';
import { Switch, FormControlLabel, CircularProgress } from '@mui/material';

import calendarIcon from 'styles/assets/communications/calendarPurple.svg';
import { firebaseStorage } from 'js/library/utils/firebaseUtils';
import { toast, ToastContainer } from 'react-toastify';
import { cloneDeep } from 'lodash';
import { UF } from '../uf';
import EditPreview from '../EditPreview';
import { AreaHeader, GoBack } from 'js/components/Configurations/CreateClub/styles';
import { MdArrowBackIosNew } from 'react-icons/md';

function addProtocolIfNeeded(url) {
  if (!url.startsWith('https://')) {
    return 'https://' + url;
  }
  return url;
}

export default function Banner(props) {
  const { preForm } = props;
  const [formData, setFormData] = useState({
    disableDate: preForm ? convertDateToTimestamp(preForm.disableDate) : '',
    enableDate: preForm ? convertDateToTimestamp(preForm.enableDate) : '',
    bannerDesktop: preForm ? preForm.images.desktop : null,
    bannerDesktopBlob: null,
    bannerMobile: preForm ? preForm.images.mobile : null,
    bannerMobileBlob: null,
    url: preForm ? preForm.url : '',
    regionFilter: preForm ? (preForm.regionFilter ? preForm.regionFilter : false) : false,
    filterType: preForm ? (preForm.filterType ? preForm.filterType : 'city') : 'city',
    filterValue: preForm ? (preForm.filterValue ? preForm.filterValue : []) : [],
    status: preForm ? preForm.status : false,
    trackingId: preForm ? preForm.trackingId : '',
  });
  const [data, setData] = useState([]);
  const [indexItem, setIndexItem] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isExternalLink, setIsExternalLink] = useState(
    preForm ? preForm.url.startsWith('https://') : false
  );

  const bannerDesktopRef = useRef(null);
  const bannerMobileRef = useRef(null);

  const handleClickInputImage = (type) => {
    if (type === 'desktop') {
      return bannerDesktopRef.current.click();
    }

    if (type === 'mobile') {
      return bannerMobileRef.current.click();
    }
  };

  const handleChangeTrackingId = (e) => {
    setFormData({ ...formData, trackingId: e.target.value.replace(/\s/g, '') });
  };

  const handleChangeUrl = (e) => {
    setFormData({ ...formData, url: e.target.value });
  };

  const handleChangeListType = (e) => {
    setFormData({ ...formData, filterType: e.target.value, filterValue: [] });
  };

  const handleChangeEnableDate = (e) => {
    const date = new Date(e.target.value).getTime();
    setFormData({ ...formData, enableDate: date });
  };

  const handleChangeDisableDate = (e) => {
    const date = new Date(e.target.value).getTime();
    setFormData({ ...formData, disableDate: date });
  };

  const bannerDesktopUploader = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);
        canvas.toBlob(
          (blob) => {
            const fileConvert = new File([blob], `${file.name.split('.')[0]}.webp`, {
              type: 'image/webp',
            });
            setFormData({
              ...formData,
              bannerDesktopBlob: blob,
              bannerDesktop: fileConvert,
            });
          },
          'image/webp',
          1
        );
      };
    };
  };

  const bannerMobileUploader = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);
        canvas.toBlob(
          (blob) => {
            const fileConvert = new File([blob], `${file.name.split('.')[0]}.webp`, {
              type: 'image/webp',
            });
            setFormData({
              ...formData,
              bannerMobileBlob: blob,
              bannerMobile: fileConvert,
            });
          },
          'image/webp',
          1
        );
      };
    };
  };

  const uploadingImages = async (image, type) => {
    let imagePath = '';

    const newStorageRef = firebaseStorage.ref('homeMapfre').child(type + Date.now());
    await newStorageRef.put(image).then((result) => {
      imagePath = result._delegate.ref._location.path_;
    });

    return imagePath;
  };

  const handleAddBanner = () => {
    if (
      !formData.bannerDesktopBlob ||
      !formData.bannerMobileBlob ||
      !formData.enableDate ||
      !formData.disableDate ||
      !formData.url ||
      !formData.trackingId
    ) {
      return toast.error('Preencha todos os dados obrigatórios.');
    }

    if (formData.enableDate > formData.disableDate) {
      return toast.error('Data de ativação é maior que a de desativação.');
    }

    setData([...data, formData]);
    setFormData({
      disableDate: '',
      enableDate: '',
      bannerDesktop: null,
      bannerDesktopBlob: null,
      bannerMobile: null,
      bannerMobileBlob: null,
      url: '',
      status: false,
      regionFilter: false,
      trackinId: '',
    });
  };

  const handleUpdateBanner = async () => {
    setIsUpdating(true);
    let newObj = cloneDeep(formData);

    for (let key in newObj) {
      if (!newObj[key]) {
        delete newObj[key];
      }
    }

    if (isExternalLink && formData.url) {
      newObj.url = addProtocolIfNeeded(newObj.url);
    }

    const resolved = new Promise(async (resolved, rejected) => {
      const bannerDesktopPath = await uploadingImages(newObj.bannerDesktopBlob, 'bannerDesktop');
      const bannerMobilePath = await uploadingImages(newObj.bannerMobileBlob, 'bannerMobile');
      if (bannerDesktopPath && bannerMobilePath) {
        const { bannerDesktopBlob, bannerMobileBlob, bannerDesktop, bannerMobile, ...rest } =
          newObj;

        return resolved({
          ...rest,
          images: {
            desktop: bannerDesktopBlob ? bannerDesktopPath : bannerDesktop,
            mobile: bannerMobileBlob ? bannerMobilePath : bannerMobile,
          },
          id: preForm.id,
          type: preForm.type,
          status: formData.status,
        });
      }
    });
    const res = await resolved;
    const filtered = (element) => {
      if (!element.regionFilter) {
        delete element.filterType;
        delete element.filterValue;
      }
      if (element.url === '') {
        delete element.url;
      }
      return element;
    };
    updateFeedContent([filtered(res)])
      .then(() => {
        toast.success('Feed atualizado com sucesso!');
        setIsUpdating(false);
      })
      .catch(() => {
        toast.error('Ocorreu um erro');
        setIsUpdating(false);
      });
  };

  const handleEditBanner = () => {
    if (
      !formData.bannerDesktopBlob ||
      !formData.bannerMobileBlob ||
      !formData.enableDate ||
      !formData.disableDate ||
      !formData.url ||
      !formData.trackingId
    ) {
      return toast.error('Preencha todos os dados obrigatórios.');
    }

    if (formData.enableDate > formData.disableDate) {
      return toast.error('Data de ativação é maior que a de desativação.');
    }

    setData((prevData) => {
      const copyData = [...prevData];
      copyData[indexItem] = formData;

      return copyData;
    });

    setFormData({
      disableDate: '',
      enableDate: '',
      bannerDesktop: null,
      bannerDesktopBlob: null,
      bannerMobile: null,
      bannerMobileBlob: null,
      url: '',
      status: false,
      regionFilter: false,
      trackingId: '',
    });

    setIndexItem(null);
  };

  const handleRemoveBanner = (index) => {
    setData((prevData) => {
      const copyData = [...prevData];
      copyData.splice(index, 1);
      return copyData;
    });
  };

  const handleSubmitBanners = async () => {
    setIsUpdating(true);
    let resolved = await Promise.all(
      data.map(async (item) => {
        const bannerDesktopPath = await uploadingImages(item.bannerDesktopBlob, 'bannerDesktop');
        const bannerMobilePath = await uploadingImages(item.bannerMobileBlob, 'bannerMobile');
        if (bannerDesktopPath && bannerMobilePath) {
          const { bannerDesktopBlob, bannerMobileBlob, bannerDesktop, bannerMobile, ...rest } =
            item;

          return {
            ...rest,
            images: {
              desktop: bannerDesktopPath,
              mobile: bannerMobilePath,
            },
          };
        }
      })
    );
    //enviar para api o resolved

    const filtered = resolved.map((element, index) => {
      if (!element.regionFilter) {
        delete element.filterType;
        delete element.filterValue;
      }
      if (element.url === '') {
        delete element.url;
      }
      return element;
    });

    createFeedContent('banner', filtered)
      .then((res) => {
        toast.success('Feed atualizado com sucesso');
        setIsUpdating(false);
      })
      .catch(() => {
        toast.error('Ocorreu um erro');
        setIsUpdating(false);
      });
  };

  return (
    <S.Container>
      {preForm && (
        <AreaHeader
          style={{ color: '#08bad0', justifyContent: 'end', padding: '1rem', marginLeft: '3rem' }}
        >
          <GoBack
            onClick={() => {
              window.history.back();
            }}
          >
            <MdArrowBackIosNew /> Voltar
          </GoBack>
        </AreaHeader>
      )}
      <ToastContainer autoClose={6000} />
      <S.Content>
        <S.AddBannerContainer>
          <D.TriiboH3>
            <strong>Banner</strong>
          </D.TriiboH3>
          <S.FormElement>
            <D.TriiboH4>
              <strong>Banner Desktop*</strong>
            </D.TriiboH4>

            {formData.bannerDesktop ? (
              <S.FileContainer>
                <PrimaryButton
                  width={'200px'}
                  onClick={() =>
                    setFormData({
                      ...formData,
                      bannerDesktop: null,
                      bannerDesktopBlob: null,
                    })
                  }
                >
                  Limpar seleção
                </PrimaryButton>
                <D.TriiboH5>{formData.bannerDesktop.name}</D.TriiboH5>
              </S.FileContainer>
            ) : (
              <>
                <DashedButton width={'100%'} onClick={() => handleClickInputImage('desktop')}>
                  + Adicionar imagem
                </DashedButton>
                <input
                  type="file"
                  className="inputFile"
                  ref={bannerDesktopRef}
                  onChange={(e) => bannerDesktopUploader(e)}
                />
              </>
            )}
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Banner Mobile*</strong>
            </D.TriiboH4>

            {formData.bannerMobile ? (
              <S.FileContainer>
                <PrimaryButton
                  width={'200px'}
                  onClick={() =>
                    setFormData({
                      ...formData,
                      bannerMobile: null,
                      bannerMobileBlob: null,
                    })
                  }
                >
                  Limpar seleção
                </PrimaryButton>
                <D.TriiboH5>{formData.bannerMobile.name}</D.TriiboH5>
              </S.FileContainer>
            ) : (
              <>
                <DashedButton width={'100%'} onClick={() => handleClickInputImage('mobile')}>
                  + Adicionar imagem
                </DashedButton>
                <input
                  type="file"
                  className="inputFile"
                  ref={bannerMobileRef}
                  onChange={(e) => bannerMobileUploader(e)}
                />
              </>
            )}
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Nome identificador*</strong>
            </D.TriiboH4>

            <D.TriiboTextField
              rows={1}
              value={formData.trackingId}
              placeholder={'Nome da campanha'}
              onChange={(e) => handleChangeTrackingId(e)}
            />
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Link de Redirecionamento*</strong>
            </D.TriiboH4>

            <D.TriiboTextField
              rows={1}
              value={formData.url}
              placeholder={'https://www.triibo.com.br'}
              onChange={(e) => handleChangeUrl(e)}
            />

            <FormControlLabel
              control={
                <Switch
                  checked={isExternalLink}
                  onChange={() => {
                    setIsExternalLink(!isExternalLink);
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Redireciona para link externo"
            />
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Data de Ativação*</strong>
            </D.TriiboH4>

            <S.SelectDateContainer>
              <img src={calendarIcon} alt="" />
              <S.InputDate
                type="datetime-local"
                value={
                  formData.enableDate &&
                  new Date(formData.enableDate - 1000 * 60 * 60 * 3).toISOString().substr(0, 16)
                }
                onChange={(e) => handleChangeEnableDate(e)}
              />
            </S.SelectDateContainer>
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Data de Desativação*</strong>
            </D.TriiboH4>
            <S.SelectDateContainer>
              <img src={calendarIcon} alt="" />
              <S.InputDate
                type="datetime-local"
                value={
                  formData.disableDate &&
                  new Date(formData.disableDate - 1000 * 60 * 60 * 3).toISOString().substr(0, 16)
                }
                onChange={(e) => handleChangeDisableDate(e)}
              />
            </S.SelectDateContainer>
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Status da publicação*</strong>
            </D.TriiboH4>

            <FormControlLabel
              control={
                <Switch
                  checked={formData.status}
                  onChange={() => {
                    setFormData({
                      ...formData,
                      status: !formData.status,
                    });
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Publicado"
            />
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Filtro regional</strong>
            </D.TriiboH4>

            <FormControlLabel
              control={
                <Switch
                  checked={formData.regionFilter}
                  onChange={() => {
                    setFormData({
                      ...formData,
                      regionFilter: !formData.regionFilter,
                    });
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Filtrar por região"
            />
          </S.FormElement>
          {formData.regionFilter ? (
            <S.FormElement>
              <D.TriiboH4>
                <strong>Tipo de Listagem</strong>
              </D.TriiboH4>

              <S.RadioSelectContainer>
                <S.Label>
                  <S.InputRadio
                    value={'city'}
                    checked={formData.filterType === 'city'}
                    onChange={(e) => handleChangeListType(e)}
                  />
                  Cidade
                </S.Label>

                <S.Label>
                  <S.InputRadio
                    value={'state'}
                    checked={formData.filterType === 'state'}
                    onChange={(e) => handleChangeListType(e)}
                  />
                  Estado
                </S.Label>
              </S.RadioSelectContainer>
            </S.FormElement>
          ) : (
            <></>
          )}
          {formData.regionFilter ? (
            <S.FormElement>
              <D.TriiboH4>
                <strong>Adicionar Tags da filtragem</strong>
              </D.TriiboH4>

              {formData.filterType === 'city' ? (
                <TagsAdd form={formData} formKey={'filterValue'} setForm={setFormData} />
              ) : (
                <TagsAddUF
                  form={formData}
                  formKey={'filterValue'}
                  setForm={setFormData}
                  listArr={UF}
                  listKeys={['nome', 'sigla']}
                />
              )}
              <TagsDisplay form={formData} formKey={'filterValue'} setForm={setFormData} />
            </S.FormElement>
          ) : (
            <></>
          )}
          {preForm ? (
            <S.ButtonsContainer>
              <PrimaryButton
                width={'100%'}
                onClick={() => {
                  handleUpdateBanner();
                }}
                disabled={isUpdating}
              >
                {isUpdating ? <CircularProgress size={30} color={'inherit'} /> : 'Atualizar Banner'}
              </PrimaryButton>
            </S.ButtonsContainer>
          ) : (
            <S.ButtonsContainer>
              {indexItem !== null ? (
                <>
                  <PrimaryButton
                    bgColor={'#f13a3a'}
                    width={'100%'}
                    onClick={() => {
                      setFormData({
                        disableDate: '',
                        enableDate: '',
                        bannerDesktop: null,
                        bannerDesktopBlob: null,
                        bannerMobile: null,
                        bannerMobileBlob: null,
                        url: '',
                        status: false,
                        trackingId: '',
                      });
                      setIndexItem(null);
                    }}
                  >
                    Cancelar
                  </PrimaryButton>
                  <PrimaryButton
                    width={'100%'}
                    onClick={() => {
                      if (indexItem !== null) {
                        handleEditBanner();
                      } else {
                        handleAddBanner();
                      }
                    }}
                  >
                    + Adicionar Banner
                  </PrimaryButton>
                </>
              ) : (
                <PrimaryButton width={'100%'} onClick={handleAddBanner}>
                  + Adicionar Banner
                </PrimaryButton>
              )}
            </S.ButtonsContainer>
          )}
        </S.AddBannerContainer>

        {preForm ? (
          <>
            <S.ListBannersContainer>
              <EditPreview title={'Imagens atuais'} data={preForm} />
            </S.ListBannersContainer>
          </>
        ) : (
          <S.ListBannersContainer>
            <ListTemplate
              title={'Lista de Banners'}
              data={data}
              type={'banner'}
              setFormData={setFormData}
              setIndexItem={setIndexItem}
              handleRemoveItem={handleRemoveBanner}
            />
          </S.ListBannersContainer>
        )}
      </S.Content>

      {preForm ? (
        <></>
      ) : (
        <PrimaryButton onClick={handleSubmitBanners} disabled={data.length < 1 || isUpdating}>
          {isUpdating ? <CircularProgress size={30} color={'inherit'} /> : 'Cadastrar Banner'}
        </PrimaryButton>
      )}
    </S.Container>
  );
}

