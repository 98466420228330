import React, { useEffect, useState } from 'react';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import DropDown from 'js/components/DesignSystem/DropDown';
import searchIcon from 'styles/assets/SearchUser/search.svg';
import editIcon from 'styles/assets/communications/edit.svg';
import {
  CircularProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { convertToDateDefault } from 'js/library/utils/helpers';
import usePagination from 'js/components/Establishment/RelationshipScreen/usePagination';
import { toast, ToastContainer } from 'react-toastify';
import { GetAllCampaignsMapfre } from 'js/library/utils/API/CampaignMapfre/apiGetAllCampaignsMapfre';
import { AreaHeader, GoBack } from '../../CreateClub/styles';
import { MdArrowBackIosNew } from 'react-icons/md';

export default function ListCampaignsLp() {
  const [campaigns, setCampaigns] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [filterSelected, setFilterSelected] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const filters = ['título', 'campaignsId'];
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    GetAllCampaignsMapfre()
      .then((response) => {
        const arr = Object.entries(response.result).map((item) => {
          const [key, value] = item;
          return { key, value };
        });
        setCampaigns(arr);
        setLoading(false);
      })
      .catch((error) => {
        // console.log(error);
        toast.error('Erro ao carregar lista de campanhas.');
        setLoading(false);
      });
  }, []);

  const filteredCampaigns = inputSearch
    ? campaigns.filter((campaign) => {
        const { key, value } = campaign;
        return filterSelected === 'título'
          ? value.title.toLowerCase().includes(inputSearch.toLowerCase())
          : filterSelected === 'campaignsId' &&
              key.toLowerCase().includes(inputSearch.toLowerCase());
      })
    : campaigns;

  const PER_PAGE = 20;
  const count = Math.ceil(filteredCampaigns.length / PER_PAGE);
  const _DATA = usePagination(filteredCampaigns, PER_PAGE);

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size="50px" />
      </div>
    );
  }

  return (
    <div style={{ padding: '4rem' }}>
      <AreaHeader
        style={{ color: '#08bad0', justifyContent: 'end', padding: '1rem', marginLeft: '3rem' }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <ToastContainer autoClose={6000} />
      <S.Container style={{ marginTop: '2rem' }}>
        <D.TriiboH1>Lista de campanhas</D.TriiboH1>

        <S.BoxSearch>
          <DropDown
            selectedOption={filterSelected}
            setSelectedOption={setFilterSelected}
            isSelectOpen={isFilterOpen}
            setIsSelectOpen={setIsFilterOpen}
            data={filters}
            top={true}
            height={'42px'}
            width={'180px'}
          />
          <S.InputBox>
            <S.Input
              placeholder="Pesquisar uma campanha..."
              disabled={!filterSelected}
              value={inputSearch}
              onChange={(e) => setInputSearch(e.target.value)}
            />
            <S.SearchIconButton type="submit">
              <img src={searchIcon} alt="" />
            </S.SearchIconButton>
          </S.InputBox>
        </S.BoxSearch>

        {_DATA.currentData().length > 0 ? (
          <>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} size="medium" aria-label="">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <strong>Título</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Id da campanha</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Data Início</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Data Fim</strong>
                    </TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_DATA.currentData().map((campaign) => (
                    <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell align="left">{campaign.value.title}</TableCell>
                      <TableCell align="left">{campaign.key}</TableCell>
                      <TableCell align="left">
                        {convertToDateDefault(
                          campaign.value.periodsLp.startAt || campaign.value.periodsLp.startsAt
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {convertToDateDefault(campaign.value.periodsLp.endsAt)}
                      </TableCell>
                      <TableCell align="left">
                        <S.EditButton
                          to={`/admin/configuracoes/campanhas/atualizarCampanhaMapfre/campaignId=${campaign.key}`}
                        >
                          <img src={editIcon} alt="" />
                        </S.EditButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              sx={{ mt: 3 }}
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
            />
          </>
        ) : (
          <div style={{ margin: '0 auto' }}>Nenhuma campanha encontrada.</div>
        )}
      </S.Container>
    </div>
  );
}

