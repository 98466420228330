import React, { useState, useEffect } from 'react';

import { Redirect } from 'react-router';

import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { getFeedContent } from 'js/library/utils/API/apiGetFeedContent';
import { updateFeedContent } from 'js/library/utils/API/apiUpdateFeedContent';
import { AreaHeader, GoBack } from '../../CreateClub/styles';
import { MdArrowBackIosNew } from 'react-icons/md';

import { cloneDeep } from 'lodash';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import PrimaryButton from 'js/components/TriiboComponents/Buttons/PrimaryButton';
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const getItemStyle = (draggableStyle) => ({
  ...draggableStyle,
});

function removeObjById(arr, id) {
  return arr.filter((obj) => obj.id !== id);
}

function getObjectById(arr, id) {
  return arr.find((obj) => obj.id === id);
}

function hasBanner(array) {
  return array.some((obj) => obj.type === 'banner');
}

function reorderArray(arr) {
  const bannerItems = [];
  const otherItems = [];

  for (const item of arr) {
    if (item.type === 'banner') {
      bannerItems.push(item);
    } else {
      otherItems.push(item);
    }
  }

  return [...bannerItems, ...otherItems];
}

function removeKeysFromArray(arr, keysToRemove) {
  return arr.map((obj) => {
    keysToRemove.forEach((key) => delete obj[key]);
    if (obj.type === 'banner') {
      delete obj['position'];
    }
    return obj;
  });
}

const OrganizeModules = () => {
  const [feed, setFeed] = useState([]);
  const [excluded, setExcluded] = useState([]);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    getFeedContent('inactive').then((res) => {
      let newItems = reorderArray(res.response.feed);

      for (let key in newItems) {
        newItems[key].position = +key;
      }

      setFeed(newItems);
      setExcluded(res.response.untrack);
    });
  }, []);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    let newItems = reorderArray(reorder(feed, result.source.index, result.destination.index));

    for (let key in newItems) {
      newItems[key].position = +key;
    }

    setFeed(newItems);
  };

  const handleSubmit = () => {
    if (hasBanner(feed) === false) {
      toast.error('Cadastre pelo menos um banner');
      return;
    }
    const submitData = removeKeysFromArray(feed.concat(excluded), ['creationDate', 'updateDate']);
    updateFeedContent(submitData).then(() => {
      setRedirect(true);
    });
  };

  if (redirect) {
    return <Redirect to={'/admin/configuracoes/configHomeMapfre'} />;
  }
  return (
    <S.Container style={{ padding: '4rem' }}>
      <AreaHeader
        style={{ color: '#08bad0', justifyContent: 'end', padding: '1rem', marginLeft: '6rem' }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <ToastContainer autoClose={6000} />

      <D.TriiboH1 style={{ marginTop: '-3rem' }}>Listagem de módulos</D.TriiboH1>
      <S.TableAndUntrackContainer>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <S.DragSpan
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={'drag-span'}
                isDragging={snapshot.isDraggingOver}
              >
                {feed.map((item, index) => (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={item.type === 'banner' ? true : false}
                  >
                    {(provided, snapshot) => (
                      <S.DragItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        isDragging={snapshot.isDragging}
                        isBanner={item.type === 'banner' ? true : false}
                        style={getItemStyle(provided.draggableProps.style)}
                      >
                        <span>{'Tipo: ' + item.type}</span>
                        {item.trackingId ? (
                          <span>
                            {'Nome identificador: '}
                            {item.trackingId}
                          </span>
                        ) : (
                          <></>
                        )}
                        {item.title ? (
                          <span>
                            {'Título: '}
                            {item.title}
                          </span>
                        ) : (
                          <></>
                        )}
                        <span>{'id: ' + item.id}</span>
                        <S.DragItemButton
                          onClick={() => {
                            let removedObject = getObjectById(cloneDeep(feed), item.id);
                            removedObject.status = false;
                            delete removedObject['position'];
                            let newRemovedArr = cloneDeep(excluded);
                            newRemovedArr.push(removedObject);
                            setExcluded(newRemovedArr);

                            let newFeedArr = removeObjById(cloneDeep(feed), item.id);
                            for (let key in newFeedArr) {
                              newFeedArr[key].position = +key;
                            }
                            setFeed(newFeedArr);
                          }}
                          black={true}
                        >
                          <FaArrowAltCircleRight />
                        </S.DragItemButton>
                      </S.DragItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </S.DragSpan>
            )}
          </Droppable>
        </DragDropContext>
        <S.TableListUntrack>
          {excluded.length > 0 ? (
            excluded.map((item, index) => (
              <S.ListItem key={index}>
                <D.TriiboH5>
                  id: {item.id} <br /> tipo: {item.type} <br /> <br />{' '}
                  {item.title ? 'Título: ' + item.title : ''}
                  <br />
                  {item.trackingId ? (
                    <span>
                      {'Nome identificador: '}
                      {item.trackingId}
                    </span>
                  ) : (
                    <></>
                  )}
                </D.TriiboH5>
                <S.Actions>
                  <S.EditButton
                    onClick={(e) => {
                      e.preventDefault();
                      let newArr = cloneDeep(feed);
                      newArr.push({ ...item, status: true });
                      let newRejected = removeObjById(cloneDeep(excluded), item.id);
                      setExcluded(newRejected);
                      setFeed(reorderArray(newArr));
                    }}
                  >
                    <FaArrowAltCircleLeft />
                  </S.EditButton>
                  {/* <S.DeleteButton
                    onClick={() => {
                      handleRemoveModule(index);
                      deleteFeedContent(item.id).then(() => {
                        toast.success(
                          `Módulo ${
                            item.title ? item.title : item.id
                          } apagado com sucesso!`
                        );
                      });
                    }}
                  >
                    <FaTrashAlt />
                  </S.DeleteButton> */}
                </S.Actions>
              </S.ListItem>
            ))
          ) : (
            <D.TriiboH4>Nenhum módulo disponível</D.TriiboH4>
          )}
        </S.TableListUntrack>
      </S.TableAndUntrackContainer>
      <PrimaryButton onClick={() => handleSubmit()} disabled={feed.length < 1}>
        Salvar lista
      </PrimaryButton>
    </S.Container>
  );
};

export default OrganizeModules;

