import React, { useEffect, useState } from 'react';

import { b64toBlob } from 'js/library/services/DatabaseManager';
import { blobToBase64, separateBase64String } from 'js/library/utils/helpers';
import { downloadImageAsBlob } from 'js/library/services/StorageManager';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import { HiOutlineSwitchHorizontal } from 'react-icons/hi';
import calendarIcon from '../../../../../styles/assets/communications/calendarIcon.svg';
import sendIcon from '../../../../../styles/assets/communications/send.svg';
import eyeIcon from '../../../../../styles/assets/communications/eye.svg';

import TypeCommunication from './TypeCommunication';
import OriginPartners from './OriginPartners';
import TitleAndMessage from './TitleAndMessage';
import ImageCrop from './ImageCrop';
import ActionsButtons from './ActionsButtons';
import TargetAudience from './TargetAudience';
import PostPreview from './PostPreview';
import NotificationPreview from './NotificationPreview';
import ModalShedulePublication from './ModalShedulePublication';
import ModalPreview from './ModalPreview';
import ModalConfirmSubmit from './ModalConfirmSubmit';

import { store } from 'js/core/configureStore';
import { CreateTemplateCommunication } from 'js/library/utils/API/Communication/apiCreateTemplate';
import { SendTemplateCommunication } from 'js/library/utils/API/Communication/apiSendTemplate';
import { toast, ToastContainer } from 'react-toastify';
import { useSelector } from 'react-redux';
import ButtonText from './ButtonText';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';
import { GoBackHeader } from '../../CreateClub/RelationshipClub/GoBackHeader';

export default function SendCommunication({ history, ...props }) {
  const isGestorPage = window.location.href.split('/').includes('admin') ? false : true;

  const isClubPage = window.location.href.split('/').includes('seu-clube') ? true : false;

  const [currentPageMobile, setCurrentPageMobile] = useState(0);
  const [selectedOptionButtonsText, setSelectedOptionButtonsText] = useState('');
  const [formCommunication, setFormCommunication] = useState({
    sender: {
      id: isGestorPage
        ? store.getState().currentEstModel.currentEst.id
        : store.getState().currentEstModel.currentEst.id,
      name: isGestorPage
        ? store.getState().currentEstModel.currentEst.nome
        : store.getState().currentEstModel.currentEst.name,
      type: isClubPage ? 'club' : 'establishment',
      logo: isGestorPage
        ? 'estabelecimento/' + store.getState().currentEstModel.currentEst.fotoThumb
        : '',
    },
    action: {
      label: '',
      type: 'establishment_list',
    },
    actionButton: {
      promotion: '',
      link: '',
      establishment: '',
    },
    typeCommunication: '',
    originPartners: '',
    title: '',
    message: '',
    image: '',
    targetAudience: '',
  });

  // STATES DO COMPONENTE IMAGECROP
  const [image, setImage] = useState('');
  const [togglePreview, setTogglePreview] = useState(false);

  const [selectedOptionActionButton, setSelectedOptionActionButton] = useState('');

  const [selectedOptionPartners, setSelectedOptionPartners] = useState(
    isGestorPage ? 'triibo' : ''
  );

  // STATES DO COMPONENTE TARGETCOMPONENT
  const [selectedTargetAudience, setSelectedTargetAudience] = useState('');

  // STATES DOS MODAIS E SEUS VALORES
  const [isModalShedulePublicationOpen, setIsModalShedulePublicationOpen] = useState(false);
  const [isModalPreviewOpen, setIsModalPreviewOpen] = useState(false);
  const [isModalConfirmSubmitOpen, setIsModalConfirmSubmitOpen] = useState(false);
  const [fileTxt, setFileTxt] = useState('');
  const [queryText, setQueryText] = useState('');
  const [clientsList, setClientsList] = useState([]);
  const [scheduledDate, setScheduledDate] = useState(0);

  const [typeSubmit, setTypeSubmit] = useState('');
  const [fieldsAreEmpty, setFieldsAreEmpty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [establishmentId, setEstablishmentId] = useState('');

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 600);

  let establishmentList = useSelector((state) => state.authModel.establishmentList);

  let currentEst = useSelector((state) => state.currentEstModel.currentEst);

  useEffect(() => {
    if (establishmentList.length < 1) {
      return;
    } else {
      if (Object.keys(currentEst).length !== 0) {
        setEstablishmentId(currentEst.id);
      } else {
        setEstablishmentId(establishmentList[0].id);
      }
    }

    if (props.location.state) {
      let newObj = props.location.state;
      delete newObj.updateDate;
      delete newObj.creationDate;
      delete newObj.id;
      delete newObj.newObj;

      setFormCommunication({ ...newObj });
      setFieldsAreEmpty(false);

      if (newObj.appFilter) {
        if (newObj.appFilter.url) {
          setSelectedOptionActionButton('Enviar para link externo');
        }
        if (newObj.appFilter.query) {
          setSelectedOptionActionButton('Listagem de promoções ou estabelecimentos');
        }
        if (newObj.appFilter.itemId) {
          setSelectedOptionActionButton('Acessar perfil do Negócio');
        }

        if (newObj.action.label) {
          setSelectedOptionButtonsText(newObj.action.label);
        }
      }
    }
  }, [formCommunication]);

  const updateMedia = () => {
    setIsDesktop(window.innerWidth > 600);
  };

  useEffect(() => {
    window.addEventListener('resize', updateMedia);
    return () => {
      window.removeEventListener('resize', updateMedia);
    };
  }, []);

  const handleCloseModal = (modal) => {
    if (modal === 'shedulePublication') {
      setIsModalShedulePublicationOpen(false);
    }

    if (modal === 'preview') {
      setIsModalPreviewOpen(false);
    }

    if (modal === 'confirmSubmit') {
      setIsModalConfirmSubmitOpen(false);
    }
  };

  const handleTogglePreview = () => {
    setTogglePreview(!togglePreview);
  };

  const handleCloseModalConfirmSubmit = () => {
    setIsModalConfirmSubmitOpen(false);
  };

  const uploadingFile = async (file) => {
    let imagePath = '';

    const newFile = separateBase64String(await blobToBase64(file));
    await uploadFiles('notificationFiles', {
      mimeType: newFile.mimeType,
      fileName: newFile.mimeType === 'text/plain' ? 'notificationFileTxt' : 'notificationFileImage',
      buffer: newFile.buffer,
    })
      .then((res) => {
        const response = res.split('/');
        imagePath = 'notificationFiles/' + response[response.length - 1].split('?')[0];
      })
      .catch(() => {
        imagePath = '';
      });

    return imagePath;
  };

  const submitCommunication = async (type) => {
    setLoading(true);
    if (fieldsAreEmpty) {
      toast.warn('Preencha todos os campos!');
      setLoading(false);
      return;
    }

    let imageFormated;

    if (image.indexOf('https') === 0) {
      const imageBlob = await downloadImageAsBlob(image);
      imageFormated = imageBlob;
    } else {
      imageFormated = b64toBlob(image);
    }

    const objTemplateCommunication = {
      title: formCommunication.title,
      body: formCommunication.message,
      image: await uploadingFile(imageFormated),
      url: formCommunication.appFilter ? formCommunication.appFilter.url : '',
      establishmentId: isGestorPage ? establishmentId : formCommunication.sender.id,
      createdByAdmin: true,
      label: formCommunication.action.label,
    };

    CreateTemplateCommunication(objTemplateCommunication)
      .then(async (response) => {
        let objSendTemplateCommunication = {
          templateId: response.id,
          establishmentId: isGestorPage ? establishmentId : formCommunication.sender.id,
          scheduledDate: type === 'publishNow' ? response.createDate : scheduledDate,
          sendNotification: true, //indica o tipo de comunicação, se for true vai enviar msg + mensagem, se for false apenas mensagem
          createdByAdmin: true,
          origin: selectedOptionPartners,
          home: true,

          sender: {
            id: isGestorPage ? establishmentId : formCommunication.sender.id,
            name: isGestorPage
              ? store.getState().currentEstModel.currentEst.nome
              : formCommunication.sender.name,
            type: isClubPage ? 'establishment' : formCommunication.sender.type,
            logo: isGestorPage
              ? 'estabelecimento/' + store.getState().currentEstModel.currentEst.fotoThumb
              : formCommunication.sender.logo,
          },
          label: formCommunication.action.label,
        };

        if (selectedTargetAudience === 'Meus clientes') {
          objSendTemplateCommunication.tokenList = Array.from(clientsList);
        }

        if (selectedTargetAudience === 'Subir arquivo .txt') {
          objSendTemplateCommunication.fileField = await uploadingFile(fileTxt);
        }

        SendTemplateCommunication(objSendTemplateCommunication)
          .then((response) => {
            toast.success('Comunicação enviada com sucesso!');
            setLoading(false);
            handleCloseModalConfirmSubmit();
            setFormCommunication({
              sender: {
                id: isGestorPage ? store.getState().currentEstModel.currentEst.id : '',
                name: isGestorPage ? store.getState().currentEstModel.currentEst.nome : '',
                type: 'establishment',
                logo: isGestorPage
                  ? 'estabelecimento/' + store.getState().currentEstModel.currentEst.fotoThumb
                  : '',
              },
              action: {
                label: '',
                type: '',
              },
              actionButton: {
                promotion: '',
                link: '',
                establishment: '',
              },
              typeCommunication: '',
              originPartners: '',
              title: '',
              message: '',
              image: '',
              targetAudience: '',
            });
            setFileTxt('');
            setImage('');
            setClientsList([]);
            setScheduledDate(0);
            setSelectedTargetAudience('');
            setSelectedOptionPartners(isGestorPage ? 'triibo' : '');
            setSelectedOptionActionButton('');
          })
          .catch((error) => {
            toast.error('Houve um erro ao enviar a comunicação.');
            setLoading(false);
            handleCloseModalConfirmSubmit();
          });
      })
      .catch((err) => {
        setLoading(false);
        toast.error('Houve um erro ao enviar a comunicação.');
      });
  };

  const arrElements = [
    <S.cols key={1}>
      <TypeCommunication
        formCommunication={formCommunication}
        setFormCommunication={setFormCommunication}
      />
      <OriginPartners
        formCommunication={formCommunication}
        setFormCommunication={setFormCommunication}
        selectedOptionPartners={selectedOptionPartners}
        setSelectedOptionPartners={setSelectedOptionPartners}
      />
      <TitleAndMessage
        formCommunication={formCommunication}
        setFormCommunication={setFormCommunication}
      />
    </S.cols>,
    <S.cols key={2}>
      <ImageCrop image={image} setImage={setImage} />
      <ActionsButtons
        formCommunication={formCommunication}
        setFormCommunication={setFormCommunication}
        selectedOptionActionButton={selectedOptionActionButton}
        setSelectedOptionActionButton={setSelectedOptionActionButton}
        senderType={'admin'}
      />
      <TargetAudience
        fileTxt={fileTxt}
        setFileTxt={setFileTxt}
        queryText={queryText}
        setQueryText={setQueryText}
        clientsList={clientsList}
        setClientsList={setClientsList}
        selectedTargetAudience={selectedTargetAudience}
        setSelectedTargetAudience={setSelectedTargetAudience}
      />
    </S.cols>,
  ];

  useEffect(() => {
    setFieldsAreEmpty(false);

    if (
      formCommunication.title === '' ||
      formCommunication.message === '' ||
      !selectedOptionPartners ||
      !image
    ) {
      setFieldsAreEmpty(true);
      setLoading(false);
      return;
    }

    if (selectedTargetAudience === 'Subir arquivo .txt' && !fileTxt) {
      setFieldsAreEmpty(true);
      setLoading(false);
      return;
    }

    if (selectedTargetAudience === 'Meus clientes' && Array.from(clientsList).length < 1) {
      setFieldsAreEmpty(true);
      setLoading(false);
      return;
    }

    if (
      selectedOptionActionButton === 'Abrir uma promoção/produto' &&
      formCommunication.actionButton.promotion === ''
    ) {
      setFieldsAreEmpty(true);
      setLoading(false);
      return;
    }
    if (
      selectedOptionActionButton === 'Acessar perfil do Negócio' &&
      formCommunication.actionButton.establishment === ''
    ) {
      setFieldsAreEmpty(true);
      setLoading(false);
      return;
    }
    if (
      selectedOptionActionButton === 'Enviar para link externo' &&
      formCommunication.actionButton.link === ''
    ) {
      setFieldsAreEmpty(true);
      setLoading(false);
      return;
    }
  }, [
    formCommunication,
    image,
    fileTxt,
    clientsList,
    selectedOptionActionButton,
    selectedTargetAudience,
  ]);

  return (
    <S.Container>
      <GoBackHeader />
      <ToastContainer autoClose={6000} />

      <S.Header>
        <D.TriiboH1 style={{ marginTop: '-2rem', marginBottom: '1rem' }}>
          Envio de comunicação
        </D.TriiboH1>
        <D.TriiboH4>
          A notificação/post é uma forma rápida e prática de se comunicar com seus clientes. Utilize
          para enviar textos e imagens no aplicativo Triibo.
        </D.TriiboH4>
      </S.Header>
      <S.Content>
        {isDesktop ? (
          <div className="desktop">
            <S.cols>
              {isGestorPage ? (
                <></>
              ) : (
                <TypeCommunication
                  formCommunication={formCommunication}
                  setFormCommunication={setFormCommunication}
                />
              )}
              {isGestorPage ? (
                <></>
              ) : (
                <OriginPartners
                  formCommunication={formCommunication}
                  setFormCommunication={setFormCommunication}
                  selectedOptionPartners={selectedOptionPartners}
                  setSelectedOptionPartners={setSelectedOptionPartners}
                />
              )}
              <TitleAndMessage
                formCommunication={formCommunication}
                setFormCommunication={setFormCommunication}
              />
            </S.cols>
            <S.cols>
              <ImageCrop image={image} setImage={setImage} />
              <ActionsButtons
                formCommunication={formCommunication}
                setFormCommunication={setFormCommunication}
                selectedOptionActionButton={selectedOptionActionButton}
                setSelectedOptionActionButton={setSelectedOptionActionButton}
                senderType={formCommunication.sender.name ? formCommunication.sender.type : 'admin'}
              />
              <ButtonText
                formCommunication={formCommunication}
                setFormCommunication={setFormCommunication}
                selectedOptionButtonsText={selectedOptionButtonsText}
                setSelectedOptionButtonsText={setSelectedOptionButtonsText}
              />
              <TargetAudience
                fileTxt={fileTxt}
                setFileTxt={setFileTxt}
                queryText={queryText}
                setQueryText={setQueryText}
                clientsList={clientsList}
                setClientsList={setClientsList}
                selectedTargetAudience={selectedTargetAudience}
                setSelectedTargetAudience={setSelectedTargetAudience}
              />
            </S.cols>
            <S.cols className="previewContainer">
              <div className="preview">
                <S.SwitchView>
                  <D.TriiboH3>Alternar visualização</D.TriiboH3>
                  <S.SwitchViewButton onClick={handleTogglePreview}>
                    <HiOutlineSwitchHorizontal size={24} color="#6E3296" />
                  </S.SwitchViewButton>
                </S.SwitchView>

                <S.PreviewContainer>
                  {togglePreview ? (
                    <PostPreview formCommunication={formCommunication} image={image} />
                  ) : (
                    <NotificationPreview formCommunication={formCommunication} />
                  )}
                </S.PreviewContainer>
              </div>
            </S.cols>
          </div>
        ) : (
          <div className="mobile">{arrElements[currentPageMobile]}</div>
        )}
      </S.Content>

      <S.ButtonsContainer>
        <S.NextPageButton
          onClick={() => setCurrentPageMobile(1)}
          currentPageMobile={currentPageMobile}
        >
          Avançar
        </S.NextPageButton>

        <S.ButtonPreview
          onClick={() => setIsModalPreviewOpen(true)}
          currentPageMobile={currentPageMobile}
        >
          <img src={eyeIcon} alt="" />
          Visualizar prévia
        </S.ButtonPreview>

        <S.ButtonSchedulePublication
          onClick={() => setIsModalShedulePublicationOpen(true)}
          currentPageMobile={currentPageMobile}
        >
          <img src={calendarIcon} alt="" />
          Agendar publicação
        </S.ButtonSchedulePublication>

        <S.ButtonPublish
          onClick={() => {
            setTypeSubmit('publishNow');
            setIsModalConfirmSubmitOpen(true);
          }}
          currentPageMobile={currentPageMobile}
        >
          <img src={sendIcon} alt="" /> Publicar agora
        </S.ButtonPublish>

        <S.ButtonGoBack
          onClick={() => {
            currentPageMobile === 0 ? history.goBack() : setCurrentPageMobile(0);
          }}
        >
          Voltar
        </S.ButtonGoBack>
      </S.ButtonsContainer>

      <ModalShedulePublication
        handleCloseModal={handleCloseModal}
        isModalShedulePublicationOpen={isModalShedulePublicationOpen}
        setScheduledDate={setScheduledDate}
        setTypeSubmit={setTypeSubmit}
        setIsModalConfirmSubmitOpen={setIsModalConfirmSubmitOpen}
        scheduledDate={scheduledDate}
      />

      <ModalPreview
        handleCloseModal={handleCloseModal}
        isModalImageCropOpen={isModalPreviewOpen}
        togglePreview={togglePreview}
        formCommunication={formCommunication}
        image={image}
        handleTogglePreview={handleTogglePreview}
      />

      <ModalConfirmSubmit
        handleCloseModal={handleCloseModal}
        isModalConfirmSubmitOpen={isModalConfirmSubmitOpen}
        typeSubmit={typeSubmit}
        submitCommunication={submitCommunication}
        loading={loading}
      />
    </S.Container>
  );
}

