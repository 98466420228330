import dispatcher from 'js/core/dispatcher';

import { updateDatabase, b64toBlob } from 'js/library/services/DatabaseManager';
import { uploadImage, downloadImageAsBlob } from 'js/library/services/StorageManager';
import { firebaseDatabase } from 'js/library/utils/firebaseUtils';
import { getUserInfo, findValueInArray } from 'js/library/utils/helpers';

import createEstablishment from 'js/library/utils/API/Establishment/apiCreateEstablishment';

import { updateEstablishment } from 'js/library/utils/API/Establishment/apiUpdateEstablishment';
import { getUserInfo_v1 } from 'js/library/utils/API/getUserInfo_v1';

export const updateEstablishmentAction = async (
  dispatch,
  id,
  idGestor,
  nome,
  keywords,
  tags,
  vitrineOrdem,
  dataInclusao,
  dataAlteracao,
  endereco,
  lat,
  long,
  complemento,
  site,
  email,
  contatos,
  descricao,
  fotoThumb,
  listaFotos,
  funcionamento,
  newThumbnailFile,
  newPhotoListFile,
  OldThumbnail,
  OldPhotoList,
  isPublished,
  enableDate,
  disableDate,
  contatoComercial,
  contatoTriibo,
  horarios,
  redesSociais,
  cep,
  logradouro,
  cidade,
  estado,
  bairro,
  numero,
  messageApproval,
  newStatus,
  signatureId,
  useFullLinks
) => {
  let newEstablishment = id ? false : true;
  id = id === null || id === undefined ? firebaseDatabase.ref('Estabelecimento-v2').push().key : id;

  if (newThumbnailFile !== null) {
    fotoThumb = 'thumb_' + id;
    newThumbnailFile = b64toBlob(newThumbnailFile);
  }

  if (newPhotoListFile !== null) {
    listaFotos = [];
    for (const key in newPhotoListFile) {
      listaFotos[key] = 'gal' + key + '_' + id;

      if (newPhotoListFile[key].indexOf('https') === 0) {
        newPhotoListFile[key] = await downloadImageAsBlob(newPhotoListFile[key]);
      } else {
        newPhotoListFile[key] = b64toBlob(newPhotoListFile[key]);
      }
    }
  }

  enableDate = isNaN(enableDate) ? 0 : enableDate;
  disableDate = isNaN(disableDate) ? 0 : disableDate + 24 * 60 * 60 * 1000;

  let status = 0;
  let now = Date.now();

  let dataEstablishment = {};

  if (isPublished === true && now < disableDate) {
    status = 1;
  }

  dataEstablishment = {
    id,
    idGestor,
    nome,
    keyWordsSet: keywords,
    tags,
    vitrineOrdem: vitrineOrdem + id,
    dataInclusao,
    dataAlteracao,
    endereco,
    lat,
    long,
    complemento,
    site,
    email,
    contatos,
    descricao,
    fotoThumb,
    listaFotos,
    funcionamento,
    isPublished,
    enableDate,
    disableDate,
    customHotspot: !isPublished,
    status,
    contatoComercial,
    contatoTriibo,
    horarios,
    redesSociais,
    cep,
    logradouro,
    cidade,
    estado,
    bairro,
    numero,
    signatureId,
    useFullLinks,
  };

  const storageFolder = 'estabelecimento';

  let pathnameArray = window.location.pathname.split('/');

  let pathname = '/';

  if (pathnameArray[1] === 'admin' && pathnameArray[2] === 'estabelecimentos') {
    pathname = '/admin/estabelecimento/' + id;
  } else if (pathnameArray[1] === 'admin' && pathnameArray[2] === 'aprovacao') {
    pathname = '/admin/aprovar/estabelecimentos';
  } else if (pathnameArray[1] === 'admin' && pathnameArray[2] === 'aprovacao') {
    pathname = '/admin/aprovar/estabelecimentos';
  } else {
    pathname = '/estabelecimento/' + id;
  }

  // Aprovação
  if (pathname.indexOf('/admin/estabelecimento') === -1) {
    let aprovacao = {};

    if (newStatus) {
      if (newStatus === 'aprovado') {
        aprovacao = {
          status: 'aprovado',
          comment: messageApproval === '' ? 'Aprovado' : messageApproval,
        };

        dataEstablishment.isPublished = true;
        dataEstablishment.status = 1;
      } else {
        aprovacao = {
          status: 'negado',
          comment: messageApproval,
        };

        dataEstablishment.isPublished = false;
        dataEstablishment.status = 0;
      }
    }

    if (newEstablishment) {
      dataEstablishment.isPublished = false;
      dataEstablishment.status = 0;

      if (newEstablishment) {
        dataEstablishment.admins = [
          {
            cargo: 'gestor',
            nome: getUserInfo().name,
            telefone: findValueInArray(getUserInfo().contactList, 'type', 'cellPhone').value,
            uid: getUserInfo().uId,
          },
        ];
        let establishmentInfo = { idEstabelecimento: id, idGestor };
        const userInfo = {
          uId: getUserInfo()?.uId,
        };

        getUserInfo_v1(['getUserInfo'], userInfo).then(({ data }) => {
          let user = data?.success?.userInfo;

          let keyWordsSetLimit = user.keyWordsSetLimit;
          if (!keyWordsSetLimit) {
            keyWordsSetLimit = [];
          }

          if (!keyWordsSetLimit.some((item) => item === 'parceirostriibo')) {
            keyWordsSetLimit.push('parceirostriibo');
          }

          if (!keyWordsSetLimit.some((item) => item === 'triibo')) {
            keyWordsSetLimit.push('triibo');
          }

          keyWordsSetLimit = keyWordsSetLimit.reduce(
            (final, item, idx) => ({ ...final, [idx]: item }),
            {}
          );

          let keyWordsSetCustom = user.keyWordsSetCustom;
          if (!keyWordsSetCustom) {
            keyWordsSetCustom = [];
          }

          if (!keyWordsSetCustom.some((item) => item === 'parceirostriibo')) {
            keyWordsSetCustom.push('parceirostriibo');
          }

          if (!keyWordsSetCustom.some((item) => item === 'triibo')) {
            keyWordsSetCustom.push('triibo');
          }

          keyWordsSetCustom = keyWordsSetCustom.reduce(
            (final, item, idx) => ({ ...final, [idx]: item }),
            {}
          );

          updateDatabase(
            'Users-v3/',
            { keyWordsSetLimit, keyWordsSetCustom },
            getUserInfo().uId,
            null
          );
          updateDatabase(
            'Estabelecimento-Validador',
            establishmentInfo,
            `${getUserInfo().triiboId}/${id}`,
            null
          );
        });
      }
    }
  }

  //Remove todos os campos undefined
  dataEstablishment = JSON.parse(JSON.stringify(dataEstablishment));

  //tracking do facebook apenas quando utilizado pelo usuário
  if (pathname.indexOf('/estabelecimento/') === 0) {
    if (newEstablishment) {
      window.fbq('trackCustom', 'EstablishmentCreation', {
        establishmentId: id,
        nome,
      });
    } else {
      window.fbq('trackCustom', 'EstablishmentUpdate', {
        establishmentId: id,
        nome,
      });
    }
  }

  if (newEstablishment) {
    return dispatcher(
      dispatch,
      'ESTABLISHMENT_INFORMATION',
      createEstablishment(id, dataEstablishment, pathname),
      uploadImage(storageFolder, newPhotoListFile, listaFotos),
      uploadImage(storageFolder, newThumbnailFile, fotoThumb)
    );
  } else {
    return dispatcher(
      dispatch,
      'ESTABLISHMENT_INFORMATION',
      updateEstablishment(id, dataEstablishment, pathname),
      uploadImage(storageFolder, newPhotoListFile, listaFotos),
      uploadImage(storageFolder, newThumbnailFile, fotoThumb)
    );
  }
};

