import dispatcher from 'js/core/dispatcher';
import { firebaseDatabase } from 'js/library/utils/firebaseUtils';
import { uploadImage, downloadImageAsBlob } from 'js/library/services/StorageManager';
import { b64toBlob } from 'js/library/services/DatabaseManager';
import { toDataURL } from 'js/library/utils/helpers';
import createStore from 'js/library/utils/API/Store/apiCreateStore';
import { createStoreChange, updateStore } from 'js/library/utils/API/Store/apiUpdateStore';
import { getUserInfo } from 'js/library/utils/helpers';
import { updateVoucherTemplate } from 'js/library/utils/API/apiUpdateVoucherTemplate';
import { createVoucherTemplate } from 'js/library/utils/API/apiCreateVoucherTemplate';
import { updateHasPromo } from 'js/library/utils/API/Store/apiUpdateHasPromo';

export const updateStorePromotionInformationAction = async (
  dispatch,
  id,
  consumoUnico,
  cooldownHours,
  cooldownMinutes,
  dataGeracao,
  descricao,
  detailsDescription,
  credit,
  keyWordsSetConsumptionList,
  messageBlockedKw,
  detailsTitle,
  generator,
  neighborhood,
  nomeTemplate,
  plural,
  qtdDisponivel,
  qtdSimultaneo,
  singular,
  titulo,
  type,
  fileThumbnail,
  oldThumbnail,
  thumbnail,
  fileFoto,
  oldFoto,
  foto,
  campanhaTriibo,
  buttonLabel,
  buttonDescription,
  showButtonDescription,
  goToPlaceLabel,
  showGoToPlace,
  consumed,
  idStore,
  active,
  level,
  region,
  description,
  showQRCode,
  title,
  storeType,
  autoValidate,
  deliverType,
  cost,
  enableDate,
  disableDate,
  isPublished,
  keywordsList,
  tags,
  fileStoreThumbnail,
  oldStoreThumbnail,
  storeThumbnail,
  fileStorePhoto,
  oldStorePhoto,
  storePhoto,

  idEstabelecimento,
  idGestor,
  nomeEstabelecimento,
  lat,
  long,
  address,

  hasSimultaneo,
  equalImageThumnailFile,
  equalImagePhotoFile,
  oldPhotoList,
  photoListFile,
  messageApproval,
  newStatus,
  couponGetMode,
  expirationType,
  disableDateCoupon,
  onSendExpiration,
  sendSMS = null
) => {
  equalImagePhotoFile = await toDataURL(equalImagePhotoFile);
  equalImageThumnailFile = await toDataURL(equalImageThumnailFile);

  let newPromotion = idStore ? false : true;
  //dados do Template
  dataGeracao = dataGeracao === null ? Date.now() : dataGeracao;

  qtdSimultaneo = hasSimultaneo || showQRCode ? parseInt(qtdSimultaneo, 10) : 1;

  let currentDate = Date.now();

  idStore = idStore === null ? firebaseDatabase.ref('Store').push().key : idStore;

  if (fileThumbnail !== null || equalImageThumnailFile !== null) {
    thumbnail = 'storage:' + idStore + ':thumbnail:' + currentDate;
    fileThumbnail =
      equalImageThumnailFile === null
        ? b64toBlob(fileThumbnail)
        : b64toBlob(equalImageThumnailFile);
  }

  //CASO A IMAGEM NÃO TENHA SIDO ALTERADA, A LÓGICA TENTARA SUBIR NO STORAGE O LINK BAIXADO
  //CONVERTER LINK EM FILE E TRATAR SE É FILE OU LINK

  if (fileFoto !== null || equalImagePhotoFile !== null) {
    foto = 'storage:' + idStore + ':foto:' + currentDate;
    fileFoto = equalImagePhotoFile === null ? b64toBlob(fileFoto) : b64toBlob(equalImagePhotoFile);
  }

  const storageFolderTemplate = 'voucher';

  cooldownHours = cooldownHours === null ? 0 : parseInt(cooldownHours, 10);
  cooldownMinutes = cooldownMinutes === null ? 0 : parseInt(cooldownMinutes, 10);
  const cooldown = (cooldownHours * 60 * 60 + cooldownMinutes * 60) * 1000;

  cost = storeType === 'product' ? parseInt(cost, 10) : null;
  deliverType =
    storeType === 'product' && deliverType !== undefined && deliverType !== null
      ? parseInt(deliverType, 10)
      : null;

  if (enableDate !== null && typeof enableDate === 'string') {
    //convertendo a data para milisegundos
    enableDate = enableDate.split('T');

    let date = enableDate[0].split('-');
    let hour = enableDate[1].split(':');

    enableDate = new Date(
      parseInt(date[0], 10),
      parseInt(date[1] - 1, 10),
      parseInt(date[2], 10),
      parseInt(hour[0], 10),
      parseInt(hour[1], 10)
    ).getTime();
  }

  if (disableDate !== null && typeof disableDate === 'string') {
    //convertendo a data para milisegundos
    disableDate = disableDate.split('T');

    let date = disableDate[0].split('-');
    let hour = disableDate[1].split(':');

    disableDate = new Date(
      parseInt(date[0], 10),
      parseInt(date[1] - 1, 10),
      parseInt(date[2], 10),
      parseInt(hour[0], 10),
      parseInt(hour[1], 10)
    ).getTime();
  }

  if (
    disableDateCoupon !== null &&
    disableDateCoupon !== undefined &&
    typeof disableDateCoupon === 'string'
  ) {
    //convertendo a data para milisegundos
    disableDateCoupon = disableDateCoupon.split('T');

    let date = disableDateCoupon[0].split('-');
    let hour = disableDateCoupon[1].split(':');

    disableDateCoupon = new Date(
      parseInt(date[0], 10),
      parseInt(date[1] - 1, 10),
      parseInt(date[2], 10),
      parseInt(hour[0], 10),
      parseInt(hour[1], 10)
    ).getTime();
  }

  const numberToMillis = (number) => {
    return number * 86400000;
  };

  //dados da Store

  autoValidate = autoValidate === 'cliente' ? true : false;

  //checando a prioridade da região
  region = region === 'local' ? null : region;

  if (fileStoreThumbnail !== null) {
    storeThumbnail =
      storeType === 'promotion'
        ? 'storage:' + idStore + ':thumbnail:' + currentDate
        : 'triibomania-premios/storage:' + idStore + ':thumbnail:' + currentDate;

    if (fileStoreThumbnail.indexOf('https') === 0) {
      fileStoreThumbnail = await downloadImageAsBlob(fileStoreThumbnail);
    } else {
      fileStoreThumbnail = b64toBlob(fileStoreThumbnail);
    }
  }

  if (fileStorePhoto !== null) {
    storePhoto =
      storeType === 'promotion'
        ? 'storage:' + idStore + ':foto:' + currentDate
        : 'triibomania-premios/storage:' + idStore + ':foto:' + currentDate;

    if (fileStorePhoto.indexOf('https') === 0) {
      fileStorePhoto = await downloadImageAsBlob(fileStorePhoto);
    } else {
      fileStorePhoto = b64toBlob(fileStorePhoto);
    }
  }

  let listaFotos = [];
  if (photoListFile !== null) {
    for (const key in photoListFile) {
      if (typeof photoListFile[key] === 'string') {
        // Verifica se é uma string
        listaFotos[key] = 'gal' + key + '_' + idStore + ':' + currentDate;

        if (photoListFile[key].indexOf('https') === 0) {
          photoListFile[key] = await downloadImageAsBlob(photoListFile[key]);
        } else {
          photoListFile[key] = b64toBlob(photoListFile[key]);
        }
      }
    }
  } else {
    listaFotos = oldPhotoList;
  }

  //cadastro no banco
  // const pathname = '/admin/estabelecimento/' + idEstabelecimento + '/ofertas';

  let pathnameArray = window.location.pathname.split('/');

  let pathname = '/';

  if (pathnameArray[1] === 'admin' && pathnameArray[2] === 'estabelecimento') {
    pathname = '/admin/estabelecimento/' + idEstabelecimento + '/ofertas';
  } else if (
    pathnameArray[1] === 'admin' &&
    pathnameArray[2] === 'aprovacao' &&
    pathnameArray[3] === 'promocao'
  ) {
    pathname = '/admin/aprovar/promocoes';
  } else {
    pathname = '/estabelecimento/' + idEstabelecimento + '/promocoes';
  }

  //ajustando erro de products /triibomania-premios

  //nome das imagens novas STORE
  let storeThumbnailName = storeThumbnail;
  let storePhotoName = storePhoto;

  if (storeType === 'product') {
    //nome das imagens novas
    storeThumbnailName = storeThumbnail !== null ? storeThumbnail.split('/')[1] : null;
    storePhotoName = storePhoto !== null ? storePhoto.split('/')[1] : null;
  }

  //dados do template
  const dataTemplate = {
    campanhaTriibo,
    sendSMS: sendSMS ?? false,
    consumoUnico,
    consumed: consumed ?? false,
    cooldown: cooldown ?? 0,
    dataGeracao,
    descricao,
    detailsDescription: detailsDescription ?? descricao,
    credit: credit ?? 0,
    detailsTitle: detailsTitle ?? titulo,
    generator,
    neighborhood: neighborhood ?? ' ',
    foto,
    thumbnail,
    nomeTemplate: nomeTemplate ?? ' ',
    plural: plural ?? 'Cupons',
    qtdSimultaneo,
    singular: singular ?? 'Cupom',
    titulo,
    type: type ?? 'promotion',
    idEstabelecimento,
    idGestor,
    nomeEstabelecimento,
    lat: lat === undefined ? 0 : lat,
    long: long === undefined ? 0 : long,
    address: address === '' ? 'Brasil' : address,
    cost: cost ?? 0,
    tags,
    expirationType,
    disableDate:
      expirationType === 'fixed' ? (disableDateCoupon !== null ? disableDateCoupon : 0) : 0,
    onSendExpiration: expirationType === 'onSend' ? numberToMillis(onSendExpiration) : 0,
    couponGetMode: couponGetMode === null ? ' ' : couponGetMode,
  };

  if (!dataTemplate.couponGetMode) {
    delete dataTemplate.couponGetMode;
  }

  //dados da store
  const storageFolderStore = storeType === 'promotion' ? 'promocao' : 'triibomania-premios';

  const photoList = photoListFile === null ? oldPhotoList : listaFotos;

  // const isAdmin = getUserInfo().admin;

  let dataStore = {
    id: idStore,
    level: parseInt(level, 10),
    description,
    dueDate: disableDate,
    showQRCode,
    campanhaTriibo,
    thumbnail: storeThumbnail,
    title,
    type: storeType,
    establishmentId: idEstabelecimento,
    establishmentName: nomeEstabelecimento,
    region: region === null ? ' ' : region,
    lat: lat === undefined ? 0 : lat,
    long: long === undefined ? 0 : long,
    photo: storePhoto,
    templateId: id,
    cost: cost === null ? '' : cost,
    deliverType: deliverType === null ? '' : deliverType,
    enableDate,
    disableDate,
    // isPublished: isAdmin === true ? true : isPublished,
    isPublished: isPublished,
    keyWordsSet: keywordsList,
    autoValidate: autoValidate === null ? '' : autoValidate,
    photoList: photoList === null ? [] : photoList,
    hasVoucher:
      generator === true ? true : qtdDisponivel === null || qtdDisponivel < 1 ? null : true,
    tags,
    // CASO O CAMPO JÁ EXISTA NO FIREBASE, E O USUÁRIO EDITAR ESSE CAMPO E ENVIA-LO VAZIO, SERA REMOVIMO DO BANDO.
    buttonLabel: buttonLabel === '' ? null : buttonLabel,

    // CASO O CAMPO JÁ EXISTA NO FIREBASE, E O USUÁRIO EDITAR ESSE CAMPO E ENVIA-LO VAZIO, SERA REMOVIMO DO BANDO.
    buttonDescription: buttonDescription === '' ? null : buttonDescription,
    showButtonDescription,
    // CASO O CAMPO JÁ EXISTA NO FIREBASE, E O USUÁRIO EDITAR ESSE CAMPO E ENVIA-LO VAZIO, SERA REMOVIMO DO BANDO.
    goToPlaceLabel: goToPlaceLabel === '' ? null : goToPlaceLabel,
    showGoToPlace,
    couponGetMode: couponGetMode === null ? null : couponGetMode,
  };

  let dataStoreChange = {
    id: idStore,
    level: parseInt(level, 10),
    description,
    dueDate: disableDate,
    showQRCode,
    campanhaTriibo,
    thumbnail: storeThumbnail,
    title,
    type: storeType,
    establishmentId: idEstabelecimento,
    establishmentName: nomeEstabelecimento,
    region: region === null ? ' ' : region,
    lat: lat === undefined ? 0 : lat,
    long: long === undefined ? 0 : long,
    photo: storePhoto,
    templateId: id,
    cost: cost === null ? '' : cost,
    enableDate,
    disableDate,
    // isPublished: isAdmin === true ? true : isPublished,
    keyWordsSet: keywordsList,
    isPublished: isPublished,
    autoValidate: autoValidate === null ? '' : autoValidate,
    hasVoucher:
      generator === true ? true : qtdDisponivel === null || qtdDisponivel < 1 ? null : true,
    tags,
    buttonLabel: buttonLabel === '' ? null : buttonLabel,
    buttonDescription: buttonDescription === '' ? null : buttonDescription,
    showButtonDescription,
    goToPlaceLabel: goToPlaceLabel === '' ? null : goToPlaceLabel,
    showGoToPlace,
    photoList: photoList === null ? [] : photoList,
  };

  if (keyWordsSetConsumptionList) {
    dataStore.keyWordsSetConsumption = keyWordsSetConsumptionList;
    dataStoreChange.keyWordsSetConsumption = keyWordsSetConsumptionList;
  }

  if (messageBlockedKw) {
    dataStore.message = messageBlockedKw;
    dataStoreChange.message = messageBlockedKw;
  }

  const uId = getUserInfo().uId;

  //Remove as imagens antigas
  if (oldStoreThumbnail !== storeThumbnail) {
    //await deleteImage(storageFolderStore, oldStoreThumbnail)
  }

  if (oldPhotoList !== listaFotos) {
    //await deleteImage(storageFolderStore, oldPhotoList)
  }

  if (oldStorePhoto !== storePhoto) {
    //await deleteImage(storageFolderStore, oldStorePhoto)
  }

  if (oldThumbnail !== thumbnail) {
    //await deleteImage(storageFolderTemplate, oldThumbnail)
  }

  if (oldFoto !== foto) {
    //await deleteImage(storageFolderTemplate, oldFoto)
  }

  const statusPromotion = localStorage.getItem('statusPromotion');

  if (newPromotion) {
    const idTemplate = await createVoucherTemplate(uId, dataTemplate);
    dataStore.templateId = idTemplate.data.result;
    return dispatcher(
      dispatch,
      'STORE_PROMOTION',
      createStore(uId, idStore, dataStore, pathname).then(() => {
        updateHasPromo(idEstabelecimento);
      }),

      uploadImage(storageFolderStore, photoListFile, listaFotos),
      uploadImage(storageFolderTemplate, fileThumbnail, thumbnail),
      uploadImage(storageFolderTemplate, fileFoto, foto),
      uploadImage(storageFolderStore, fileStoreThumbnail, storeThumbnailName),
      uploadImage(storageFolderStore, fileStorePhoto, storePhotoName)
    );
  } else {
    let action;
    // Se a promoção estiver ativa/reprovada/inativa, usa a nova API para atualizar e
    // criar uma réplica de edição para a curadoria de promoção
    if (
      statusPromotion === 'Ativo' ||
      statusPromotion === 'Reprovado' ||
      statusPromotion === 'Inativo'
    ) {
      action = createStoreChange(
        uId,
        idEstabelecimento,
        nomeEstabelecimento,
        idStore,
        dataStoreChange
      ).then(() => {
        updateHasPromo(idEstabelecimento);
      });
    } else {
      //senão usa a api que edita a promoção existente
      action = updateStore(idStore, dataStore, pathname).then(() => {
        updateHasPromo(idEstabelecimento);
      });
    }

    return dispatcher(
      dispatch,
      'STORE_PROMOTION',
      action,
      updateVoucherTemplate(uId, id, dataTemplate),
      uploadImage(storageFolderStore, photoListFile, listaFotos),
      uploadImage(storageFolderTemplate, fileThumbnail, thumbnail),
      uploadImage(storageFolderTemplate, fileFoto, foto),
      uploadImage(storageFolderStore, fileStoreThumbnail, storeThumbnailName),
      uploadImage(storageFolderStore, fileStorePhoto, storePhotoName)
    );
  }
};

