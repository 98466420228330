import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { Button, Typography, Grid, Checkbox } from '@mui/material';
import { List, ArrowBack } from '@mui/icons-material';
import { withStyles } from '@mui/styles';

import { SortableContainer, SortableElement } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import placeholder from 'styles/assets/placeholder/placeholder_card.png';
import { downloadImage } from 'js/library/services/StorageManager';

import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';
import { updateDatabase } from 'js/library/services/DatabaseManager';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

const styles = (theme) => ({
  buttonAddCard: {
    float: 'right',
    marginBottom: '1rem',
    [theme.breakpoints.down('xs')]: {
      float: 'left',
    },
  },
});

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc'
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

class SortCards extends Component {
  constructor(props) {
    super(props);

    this.state = {
      priorityIncrement: 100,

      order: 'asc',
      orderBy: 'priority',

      items: [],
      keywords: [],
      filter: [],
      showActiveOnly: true,

      keywordScroll: 0,
      cardScroll: 0,
    };

    getDataListRestApi('Cards')
      .then((dataReceived) => {
        let cards = [];
        let keywords = [];
        Object.keys(dataReceived).map(function (item) {
          dataReceived[item].downloadedThumb = placeholder;

          return cards.push(dataReceived[item]);
        });

        cards = stableSort(
          cards,
          getSorting(this.state.order, this.state.orderBy)
        );

        cards.forEach((card) => {
          if (card.keyWordsSet !== undefined) {
            return card.keyWordsSet.map((key) => {
              return keywords.push(key);
            });
          }
        });

        this.setState({
          items: cards,
          keywords: [...new Set(keywords)],
        });
      })
      .catch((error) => {});
  }

  downloadImage = (fotoThumb, key, downloadedThumb) => {
    if (fotoThumb !== undefined && downloadedThumb === placeholder) {
      let data = this.state.items;

      const index = data.findIndex((x) => x.cardId === key);

      let storageInfo = fotoThumb.split('/');
      downloadImage(storageInfo[0], storageInfo[1])
        .then((downloadedImage) => {
          data[index].downloadedThumb = downloadedImage;
          this.setState({ items: data });
        })
        .catch(() => {});
    }
  };

  saveScrollState = () => {
    let keyword = document.getElementById('keywordList').scrollTop;
    let card = document.getElementById('cardList').scrollTop;
    this.setState({
      keywordScroll: keyword,
      cardScroll: card,
    });
  };

  loadScrollState = () => {
    document.getElementById('keywordList').scrollTop = this.state.keywordScroll;
    document.getElementById('cardList').scrollTop = this.state.cardScroll;
  };

  onSortKeywordEnd = ({ oldIndex, newIndex }) => {
    this.saveScrollState();

    this.setState(({ keywords }) => ({
      keywords: arrayMove(keywords, oldIndex, newIndex),
    }));

    this.loadScrollState();
  };

  onSortCardEnd = ({ oldIndex, newIndex }) => {
    this.saveScrollState();

    this.setState(({ items }) => ({
      items: arrayMove(items, oldIndex, newIndex),
    }));

    this.loadScrollState();
  };

  savePriority = () => {
    let finalList = [];
    let data = this.state.items;

    let keywordCounter = 0;
    let innerCounter = 0;

    this.state.keywords.map((keyword, index) => {
      if (keyword !== 'triibo') {
        keywordCounter = index * this.state.priorityIncrement;
        innerCounter = 0;
        return data.map((item) => {
          if (item.keyWordsSet.indexOf(keyword) >= 0) {
            if (item.downloadedThumb) {
              item.downloadedThumb = null;
            }
            item.priority = keywordCounter + innerCounter;
            innerCounter += 1;
            return finalList.push(item);
          } else {
            return null;
          }
        });
      } else {
        return null;
      }
    });

    //Triibo por último
    keywordCounter = keywordCounter + 1000;
    innerCounter = 0;
    data.map((item) => {
      if (item.keyWordsSet.indexOf('triibo') >= 0) {
        item.priority = keywordCounter + innerCounter;
        innerCounter += 1;
        return finalList.push(item);
      } else {
        return null;
      }
    });

    finalList.map(function (item) {
      return updateDatabase('Cards', item, item.cardId, '');
    });
  };

  handleCheckArray = (name) => (event) => {
    this.saveScrollState();

    //let aux = this.state.filter;
    let aux = [];

    if (event.target.checked === true) {
      aux.push(event.target.value);
      //this.setState({filter: [...new Set(aux)]});
      this.setState({ filter: aux });
      this.loadScrollState();
    } else {
      // for (var i=aux.length-1; i>=0; i--) {
      // 	if (aux[i] === event.target.value) {
      // 		aux.splice(i, 1);
      // 	}
      // }

      // this.setState({filter: [...new Set(aux)]});
      this.setState({ filter: [] });
    }
  };

  handleCheck = (name) => (event) => {
    this.setState({ showActiveOnly: event.target.checked });
  };

  inFilter = (keywords) => {
    let exists = false;
    if (keywords !== undefined) {
      keywords.map((item) => {
        if (this.state.filter.indexOf(item) >= 0) {
          return (exists = true);
        } else {
          return null;
        }
      });
    }
    return exists;
  };

  render = () => {
    const { classes } = this.props;
    const SortableItem = SortableElement(({ value }) => (
      <li
        key={value.cardId}
        index={value.cardId}
        style={{
          listStyleType: 'none',
          zIndex: 99999999,
          display: this.state.showActiveOnly
            ? value.isPublished &&
              value.enableDate <= Date.now() &&
              value.disableDate >= Date.now()
              ? 'block'
              : 'none'
            : 'block',
        }}>
        <img
          key={value.cardId}
          src={value.downloadedThumb}
          alt='Thumb do Card'
          width='500px'
          onLoad={() =>
            this.downloadImage(
              value.images.mobile,
              value.cardId,
              value.downloadedThumb
            )
          }
        />
      </li>
    ));
    const SortableList = SortableContainer(({ items }) => {
      return (
        <ul
          id='cardList'
          style={{
            listStyleType: 'none',
            overflowY: 'scroll',
            maxHeight: '100vh',
            backgroundColor: '#fafafa',
          }}>
          {items.map((value, index) =>
            this.inFilter(value.keyWordsSet) ? (
              <SortableItem
                key={`${items[index].cardId}`}
                index={index}
                value={value}
              />
            ) : null
          )}
        </ul>
      );
    });

    const SortableKeyword = SortableElement(({ value }) => (
      <div
        key={value}
        index={value}
        style={{ marginLeft: '10px', zIndex: 99999999 }}>
        <Checkbox
          key={value}
          checked={this.state.filter.indexOf(value) >= 0}
          onChange={this.handleCheckArray(value)}
          value={value}
          color='primary'
        />
        {value}
      </div>
    ));

    const SortableKeywordList = SortableContainer(({ items }) => {
      return (
        <div
          id='keywordList'
          style={{
            overflowY: 'scroll',
            maxHeight: '200px',
            backgroundColor: '#fafafa',
            marginBottom: '30px',
          }}>
          {items.map((value, index) => (
            <SortableKeyword key={`${value}`} index={index} value={value} />
          ))}
        </div>
      );
    });

    return (
      <div style={{ padding: '4rem 32px' }}>
        <GoBackHeader />
        <HeaderTitle pageInfo=' Cards'/>
        <Grid container spacing={5}>
          <Grid item md={6} xs={12}>
          </Grid>

          <Grid item md={6} xs={12}>
            <div className={classes.buttonAddCard}>
              <Link
                to={{ pathname: '/admin/configuracoes/cards', state: '' }}
                style={{ textDecoration: 'none' }}>
                <Button
                  style={{
                    fontWeight: '550',
                    marginRight: '10px',
                    color: 'white',
                    textTransform: 'capitalize',
                  }}
                  variant='contained'
                  color='secondary'
                  startIcon={<ArrowBack />}>
                  Voltar
                </Button>
              </Link>

              <Link
                to={{ pathname: '/admin/configuracoes/', state: '' }}
                style={{ textDecoration: 'none' }}>
                <Button
                  style={{
                    fontWeight: '550',
                    color: 'white',
                    textTransform: 'capitalize',
                  }}
                  title='Salvar Lista'
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={this.savePriority}
                  startIcon={<List />}>
                  Salvar Lista
                </Button>
              </Link>
            </div>
          </Grid>
        </Grid>
        <br />
        <Checkbox
          checked={this.state.showActiveOnly}
          onChange={this.handleCheck(this.state.showActiveOnly)}
          color='primary'
        />
        Mostrar apenas cards ativos
        <Typography variant='body2' color='secondary'>
          Ordem das keywords
        </Typography>
        <SortableKeywordList
          items={this.state.keywords}
          onSortEnd={this.onSortKeywordEnd}
        />
        <Typography variant='body2' color='secondary'>
          Ordem dos cards
        </Typography>
        <SortableList items={this.state.items} onSortEnd={this.onSortCardEnd} />
      </div>
    );
  };
}

export default withStyles(styles)(SortCards);
