import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { getSeuClubList } from 'js/library/utils/API/seuClube';
import { apiListEstablishment } from 'js/library/utils/API/Establishment/apiListEstablishments';
import { store } from 'js/core/configureStore';

import { cloneDeep } from 'lodash';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import { FormControlLabel, Radio, RadioGroup, CircularProgress } from '@mui/material';

function sortByClosestKey(arrayOfObjects, key, targetString) {
  return arrayOfObjects.sort((a, b) => {
    const aDistance = Math.abs(a[key].localeCompare(targetString));
    const bDistance = Math.abs(b[key].localeCompare(targetString));
    return aDistance - bDistance;
  });
}

export default function TypeCommunication({ formCommunication, setFormCommunication }) {
  const [isFocused, setIsFocused] = useState(false);
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [input, setInput] = useState('');

  let isSuperAdmin = useSelector((state) => state.authModel.superUser);

  useEffect(() => {
    if (formCommunication.sender['type'] === 'establishment') {
      setIsLoading(true);
      if (input.length > 0) {
        apiListEstablishment(input, 0, 10).then((response) => {
          setIsLoading(false);
          setOptions(sortByClosestKey(response, 'nome', input));
        });
      }
    }
    if (formCommunication.sender['type'] === 'club') {
      setIsLoading(true);
      // if (input.length > 0) {
      getSeuClubList(store.getState().codeInputModel.userInfo.id).then((response) => {
        setIsLoading(false);
        setOptions(sortByClosestKey(response, 'name', input));
      });
      // }
    }
  }, [formCommunication.sender.type]);

  let establishmentTimeout;
  useEffect(() => {
    setIsLoading(true);
    if (formCommunication.sender['type'] === 'club') {
      establishmentTimeout = setTimeout(() => {
        getSeuClubList(store.getState().codeInputModel.userInfo.id).then((response) => {
          setIsLoading(false);
          setOptions(sortByClosestKey(response, 'name', input));
        });
      }, 1200);
    }
    if (formCommunication.sender['type'] === 'establishment') {
      establishmentTimeout = setTimeout(() => {
        apiListEstablishment(input, 0, 10).then((response) => {
          setIsLoading(false);
          setOptions(sortByClosestKey(response, 'nome', input));
        });
      }, 1200);
    }
    return () => {
      clearTimeout(establishmentTimeout);
    };
  }, [input]);

  const handleChangeTypeSender = (event) => {
    let cloneForm = cloneDeep(formCommunication);
    cloneForm.sender['type'] = event.target.value;
    cloneForm.orgs = [];
    setFormCommunication(cloneForm);
    setIsLoading(false);
  };

  if (!isSuperAdmin) {
    return <></>;
  }
  return (
    <S.Container>
      <D.TriiboH4>
        <b>Informações da origem</b>
      </D.TriiboH4>
      <div>
        <D.TriiboH4>Escolha o tipo</D.TriiboH4>
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
        >
          <FormControlLabel
            checked={formCommunication.sender['type'] === 'establishment' ? true : false}
            onClick={handleChangeTypeSender}
            value="establishment"
            control={
              <Radio
                sx={{
                  '&.Mui-checked': {
                    color: '#6E3296',
                  },
                }}
              />
            }
            label="Estabelecimento"
          />
          <FormControlLabel
            value="club"
            checked={formCommunication.sender['type'] === 'club' ? true : false}
            onClick={handleChangeTypeSender}
            control={
              <Radio
                sx={{
                  '&.Mui-checked': {
                    color: '#6E3296',
                  },
                }}
              />
            }
            label="Clube"
          />
        </RadioGroup>
      </div>
      <S.InputGroupContainer style={{ width: '100%' }}>
        <S.OptionsDisplayContainer isOpen={isFocused}>
          {options.map((element, index) => (
            <S.OptionSelectable
              onClick={(e) => {
                e.preventDefault();

                if (element) {
                  let cloneForm = cloneDeep(formCommunication);
                  cloneForm.sender['id'] = element.id;

                  if (formCommunication.sender['type'] === 'establishment') {
                    cloneForm.sender['id'] = element.id;
                    cloneForm.sender['name'] = element.nome;
                    cloneForm.sender['logo'] =
                      'estabelecimento/' + (element.fotoThumb ? element.fotoThumb : '');
                  } else if (formCommunication.sender['type'] === 'club') {
                    cloneForm.sender['id'] = element.clubeId;
                    cloneForm.sender['name'] = element.name;
                    cloneForm.sender['logo'] = 'seuClube/' + (element.logo ? element.logo : '');
                  }

                  setFormCommunication(cloneForm);
                }
              }}
              key={`selectable-option-${index}`}
            >
              {formCommunication.sender['type'] === 'establishment' ? element.nome : element.name}
            </S.OptionSelectable>
          ))}
        </S.OptionsDisplayContainer>
        <D.TriiboH4>Remetente: {formCommunication.sender.name}</D.TriiboH4>
        <S.InputContainer>
          <S.Input
            value={input}
            placeholder={'Buscar remetente de postagem'}
            onChange={(e) => {
              setInput(e.target.value);
            }}
            onFocus={() => {
              setIsFocused(true);
            }}
            onBlur={() => {
              setIsFocused(false);
            }}
          />
          <S.SpinnerContainer isLoading={isLoading}>
            <CircularProgress />
          </S.SpinnerContainer>
        </S.InputContainer>
      </S.InputGroupContainer>
    </S.Container>
  );
}

