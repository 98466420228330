import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  FormControl,
  Input,
  InputLabel,
  InputAdornment,
  Checkbox,
  Button,
  Snackbar,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import { Search } from '@mui/icons-material';
import { MdContentCopy } from 'react-icons/md';

import placeholder from 'styles/assets/placeholder/placeholder_card.png';
import { getUserInfo, isAdminPage } from 'js/library/utils/helpers';
import { firebaseDatabase } from 'js/library/utils/firebaseUtils';
import { updateDatabase } from 'js/library/services/DatabaseManager';

//arquivo com as actions
import { downloadImage } from 'js/library/services/StorageManager';
import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const styles = (theme) => ({
  root: {
    width: '100%',
    marginTop: theme.spacing(3),
  },
  table: {
    minWidth: 1020,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
});

class DataTableCards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      order: 'asc',
      orderBy: 'priority',
      dataTotal: [],
      dataPesquisa: [],
      page: 0,
      maxPage: 0,
      rowsPerPage: 25,
      thumbnail: [props.folder, props.src],
      pesquisa: '',
      activeOnly: false,
      organizations: {},
      disabledButtons: [],
      messageAlert: '',
    };

    getDataListRestApi('Cards')
      .then((dataReceived) => {
        let data = [];
        Object.keys(dataReceived).map(function (item) {
          dataReceived[item].key = item;
          dataReceived[item].downloadedThumb = placeholder;

          return data.push(dataReceived[item]);
        });
        this.setState({
          dataTotal: data,
          dataPesquisa: data,
        });
      })
      .catch((error) => {});
    this.fetchOrganizations();
  }

  async fetchOrganizations() {
    const result = await getDataListRestApi('Organizations/');
    result &&
      this.setState({
        organizations: { ...result },
      });
  }

  handleRequestSort = (property) => (event) => {
    const orderBy = property;
    let order = 'desc';

    if (this.state.orderBy === property && this.state.order === 'desc') {
      order = 'asc';
    }

    this.setState({
      order,
      orderBy,
    });
  };

  handleChangePage = (event, page) => {
    this.setState({ page });
  };

  downloadImage = (fotoThumb, key, downloadedThumb) => {
    if (fotoThumb !== undefined && downloadedThumb === placeholder) {
      let dataTotal = this.state.dataTotal;
      let dataPesquisa = this.state.dataPesquisa;

      const indexTotal = dataTotal.findIndex((x) => x.key === key);
      const indexPesquisa = dataPesquisa.findIndex((x) => x.key === key);

      let storageInfo = fotoThumb.split('/');
      downloadImage(storageInfo[0], storageInfo[1])
        .then((downloadedImage) => {
          dataTotal[indexTotal].downloadedThumb = downloadedImage;
          dataPesquisa[indexPesquisa].downloadedThumb = downloadedImage;

          this.setState({ dataTotal, dataPesquisa });
        })
        .catch(() => {
          // console.log("Foto não encontrada:", fotoThumb);
        });
    }
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: event.target.value });
  };

  createSortHandler = (property) => (event) => {
    this.props.onRequestSort(event, property);
  };

  handleChange = (name) => (event) => {
    this.cardFilter(event.target.value, this.state.activeOnly);
    this.setState({ [name]: event.target.value });
  };

  handleCheck = (name) => (event) => {
    this.cardFilter(this.state.pesquisa, event.target.checked);
    this.setState({ [name]: event.target.checked });
  };

  cardFilter = (pesquisa, ativos) => {
    let dataTodos = Object.assign(this.state.dataTotal);
    let dataPesquisa = [];

    if (pesquisa.length === 0) {
      if (ativos === false) {
        this.setState({ dataPesquisa: dataTodos });
      } else {
        for (const key in dataTodos) {
          if (dataTodos[key].title !== undefined) {
            if (
              dataTodos[key].isPublished === true &&
              dataTodos[key].enableDate < Date.now() &&
              dataTodos[key].disableDate > Date.now()
            ) {
              dataPesquisa.push(dataTodos[key]);
            }
          }
        }
        this.setState({ dataPesquisa: dataPesquisa });
      }
    } else {
      for (let key in dataTodos) {
        if (dataTodos[key].title !== undefined) {
          if (
            dataTodos[key].title?.toLowerCase().indexOf(pesquisa.toLowerCase()) >= 0 ||
            dataTodos[key].subtitle?.toLowerCase().indexOf(pesquisa.toLowerCase()) >= 0 ||
            dataTodos[key].keyWordsSet
              ?.toString()
              .toLowerCase()
              .split(',')
              .toString()
              .indexOf(pesquisa.toLowerCase()) >= 0
          ) {
            if (
              (ativos === true &&
                dataTodos[key].isPublished === true &&
                dataTodos[key].enableDate < Date.now() &&
                dataTodos[key].disableDate > Date.now()) ||
              ativos === false
            ) {
              dataPesquisa.push(dataTodos[key]);
            }
          }
        }
      }
      this.setState({ dataPesquisa: dataPesquisa });
    }
  };

  copyCards = async (cardKey) => {
    try {
      const card = await getDataListRestApi(`Cards/${cardKey}`);
      const copyCardKey = firebaseDatabase.ref('Cards').push().key;
      //gera uma chave aleatória no firebase

      card.cardId = copyCardKey; //Inserimos a nova chave na cópia do card
      card.title = card.title + ' - Cópia';

      await updateDatabase('Cards', card, copyCardKey, null);

      window.location.reload();
    } catch (err) {
      this.setState({
        messageAlert: '❌ Erro ao duplicar card.',
        disabledButtons: false,
      });
    }
  };

  render() {
    const { order, orderBy, rowsPerPage, page } = this.state;

    const renderOrgs = (orgs) =>
      orgs.map(
        (org, index) =>
          ` ${this.state.organizations[org]?.name}${index === orgs.length - 1 ? '' : ','}`
      );

    return (
      <Route
        render={({ history }) => (
          <div
            style={{
              maxHeight: '100%',
              width: '100%',
              overflowY: 'auto',
            }}
          >
            <FormControl fullWidth style={{ marginBottom: '20px', marginTop: '20px' }}>
              <InputLabel htmlFor="adornment-amount">Pesquise seu card:</InputLabel>
              <Input
                id="adornment-amount"
                value={this.state.pesquisa}
                onChange={this.handleChange('pesquisa')}
                startAdornment={
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                }
              />
            </FormControl>
            <Checkbox
              checked={this.state.activeOnly}
              onChange={this.handleCheck('activeOnly')}
              value={'activeOnly'}
            />
            Mostrar apenas cards ativos
            <span className="scrollbar" style={{ marginLeft: '20rem' }}></span>
            <div
              style={{
                maxHeight: '100%',
                width: '100%',
                overflowY: 'auto',
              }}
            >
              <Table stickyHeader aria-labelledby="tableTitle" style={{ marginTop: '1rem' }}>
                <TableHead>
                  <TableRow>
                    {this.props.rows.map((row) => {
                      return (
                        <TableCell
                          key={row.id}
                          numeric={row.numeric}
                          padding={row.disablePadding ? 'none' : 'default'}
                          sortDirection={orderBy === row.id ? order : false}
                          style={{ fontWeight: '550' }}
                        >
                          <Tooltip
                            title={
                              row.id === 'thumbnail' ||
                              row.id === 'platform' ||
                              row.id === 'keyWordsSet' ||
                              row.id === 'duplicate'
                                ? row.label
                                : 'Ordenar'
                            }
                            placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                            enterDelay={300}
                          >
                            <TableSortLabel
                              active={orderBy === row.id}
                              direction={order}
                              onClick={
                                row.id === 'thumbnail' ||
                                row.id === 'platform' ||
                                row.id === 'keyWordsSet' ||
                                row.id === 'duplicate'
                                  ? null
                                  : this.handleRequestSort(row.id)
                              }
                              style={{
                                cursor:
                                  row.id === 'thumbnail' ||
                                  row.id === 'platform' ||
                                  row.id === 'keyWordsSet' ||
                                  row.id === 'duplicate'
                                    ? 'default'
                                    : 'pointer',
                              }}
                            >
                              {row.label}
                            </TableSortLabel>
                          </Tooltip>
                        </TableCell>
                      );
                    }, this)}
                  </TableRow>
                </TableHead>

                <TableBody>
                  {stableSort(this.state.dataPesquisa, getSorting(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((data, i) => {
                      let pathname = null;

                      if (isAdminPage()) {
                        pathname = '/admin/configuracoes/cards' + data.key;
                      }

                      return (
                        <TableRow id={data.key} key={data.key} hover>
                          <TableCell
                            scope="row"
                            padding="none"
                            folder="cards"
                            onClick={() => {}}
                            tabIndex={-1}
                          >
                            <img
                              src={data.downloadedThumb}
                              onLoad={() =>
                                this.downloadImage(
                                  data.images.mobile,
                                  data.key,
                                  data.downloadedThumb
                                )
                              }
                              alt="Thumb do Card"
                              className="thumbnail"
                              width="65px"
                            />
                          </TableCell>
                          <TableCell
                            scope="row"
                            onClick={() =>
                              history.push({
                                pathname: this.props.pathName + data.key,
                                state: data,
                              })
                            }
                          >
                            {' '}
                            {data.title}{' '}
                          </TableCell>
                          <TableCell
                            scope="row"
                            onClick={() =>
                              history.push({
                                pathname: this.props.pathName + data.key,
                                state: data,
                              })
                            }
                          >
                            {' '}
                            {data.subtitle}{' '}
                          </TableCell>
                          <TableCell
                            scope="row"
                            onClick={() =>
                              history.push({
                                pathname: this.props.pathName + data.key,
                                state: data,
                              })
                            }
                          >
                            {' '}
                            {data.keyWordsSet !== null && data.keyWordsSet !== undefined
                              ? Object.values(data.keyWordsSet) + ' '
                              : '---'}{' '}
                          </TableCell>
                          <TableCell
                            style={{
                              maxWidth: 200,
                              whiteSpace: 'nowrap',
                              overflow: 'hidden',
                              textOverflow: 'ellipsis',
                            }}
                            scope="row"
                            onClick={() =>
                              history.push({
                                pathname: this.props.pathName + data.key,
                                state: data,
                              })
                            }
                          >
                            {' '}
                            {data.orgs ? renderOrgs(data.orgs) : '---'}{' '}
                          </TableCell>
                          <TableCell
                            scope="row"
                            onClick={() =>
                              history.push({
                                pathname: this.props.pathName + data.key,
                                state: data,
                              })
                            }
                          >
                            {' '}
                            {data.priority}{' '}
                          </TableCell>
                          <TableCell>
                            <Tooltip title="Duplicar Promoção">
                              <Button
                                onClick={() => {
                                  const disabledButtons = [...this.state.disabledButtons];
                                  disabledButtons[i] = true;
                                  this.setState({ disabledButtons });
                                  this.copyCards(data.key);
                                }}
                                disabled={this.state.disabledButtons[i] === true}
                                style={{
                                  margin: '0 5px',
                                  fontWeight: '550',
                                  color: 'white',
                                  textTransform: 'capitalize',
                                }}
                                variant="contained"
                                color="primary"
                                type="button"
                              >
                                <MdContentCopy fontSize="22px" />
                              </Button>
                            </Tooltip>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </div>
            <TablePagination
              component="div"
              rowsPerPageOptions={[10, 25, 50, 100]}
              count={this.state.dataPesquisa.length}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onPageChange={this.handleChangePage}
              onRowsPerPageChange={this.handleChangeRowsPerPage}
            />
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={this.state.messageAlert !== ''}
              autoHideDuration={4000}
              onClose={() => this.setState({ messageAlert: '' })}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id="message-id">{this.state.messageAlert}</span>}
            />
          </div>
        )}
      />
    );
  }
}

DataTableCards.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DataTableCards);
