import { updateDatabase, removeDatabase } from "js/library/services/DatabaseManager";
import dispatcher from "js/core/dispatcher";

export const updateAdministratorsAction = (dispatch, listaPerfis, email, userCreated) =>
{   
    let emailRemovido =  null;
    if (listaPerfis.indexOf('Usuario') < 0 && listaPerfis.indexOf('Prestador') < 0 && listaPerfis.indexOf('Administrador') < 0 && listaPerfis.indexOf('Administrador-Triibo') < 0) {
        emailRemovido = email;
        email = null;
    }
    
    const pathname = '/admin/configuracoes';

    const data = userCreated !== false ? {'dataCriacao' : Date.now(),'listaPerfis' : listaPerfis} : {'listaPerfis' : listaPerfis};

    return dispatcher(dispatch, "ADMIN_INFORMATION", updateDatabase('Perfil-Users', data, email, pathname), removeDatabase('Perfil-Users', emailRemovido) );
}