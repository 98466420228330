import React, { Component } from 'react';
import { compose } from 'recompose';
import { Save, AttachFile, ArrowBack } from '@mui/icons-material';

import {
  TextField,
  Typography,
  Button,
  FormLabel,
  Chip,
  Checkbox,
  Paper,
  FormControl,
  MenuItem,
  Select,
  Snackbar,
  Radio,
} from '@mui/material';
import { withStyles } from '@mui/styles';

import Loading from 'js/containers/Loading/Loading';
import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';
import { getDataRedshift } from 'js/library/utils/API/apiRedshift.js';
import { removeDuplicateUsingFilter } from 'js/library/utils/helpers.js';
import { setUserInfo_v1 } from 'js/library/utils/API/setUserInfo_v1.js';

import { userReadCellphone_v3 } from 'js/library/utils/API/apiUserRead';

import Downshift from 'downshift';
import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';

const styles = () => ({
  root: {
    background: '#ffffff',
    borderRadius: 5,
    border: '1px solid #6c6c6c',
    color: 'white',
  },
});

let suggestions = [];

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

function renderSuggestion({
  suggestion,
  index,
  itemProps,
  highlightedIndex,
  selectedItem,
}) {
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component='div'
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}>
      {suggestion.label}
    </MenuItem>
  );
}

renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

function getSuggestions(value) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;
  let count = 0;

  return inputLength === 0
    ? []
    : suggestions.filter((suggestion) => {
        const keep =
          count < 5 &&
          suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }

        return keep;
      });
}

class KeywordsBatch extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      emailFile: null,
      query: null,
      queries: [],

      keywordInput: '',

      keywordsLimitCheck: false,
      keywordsCustomCheck: false,

      batchAction: '0',
      batchOption: '0',
      openCheck: false,
      alertMessage:
        'Insira o arquivo com os e-mails dos usuários que terão as keywords alteradas.',
      status: null,
      backgroundColor: '#dbda6e',

      keywordsList: [],

      loading: false,
    };

    getDataListRestApi('Keywords').then((dataReceived) => {
      let uniqueArray = [];
      let keywordsDownload = [];

      Object.values(dataReceived).map(function (item) {
        return uniqueArray.push(item.value);
      });

      Object.values(removeDuplicateUsingFilter(uniqueArray)).map(function (
        item
      ) {
        return keywordsDownload.push({ label: item });
      });

      suggestions = keywordsDownload;
      this.setState({ keywordsDownload });
    });

    getDataListRestApi('queries-redshift')
      .then((dataReceived) => {
        dataReceived = null;
        let data = [];

        if (dataReceived) {
          Object.keys(dataReceived).map(function (item) {
            return data.push(dataReceived[item]);
          });

          this.setState({
            queries: data,
            query: data[0].query,
          });
        } else {
          this.setState({
            queries: [],
            query: '',
          });
        }
      })
      .catch((error) => {
        // console.log('Erro na data:', error);
      });

    window.openModal = false;
  }

  //altera os states dos campos, caso o usuário insira informações nos input
  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value, notificationKey: null });
  };

  fileChangedHandler = (name) => (event) => {
    if (event.target.files.length !== 0) {
      this.setState({ [name]: event.target.files[0] });
    }
  };

  checkInformation = async () => {
    // Gera array de usuários e envia pro changeKeywords

    this.setState({ loading: true });

    let userList = [];

    if (this.state.batchOption === '0') {
      if (this.state.emailFile === null) {
        this.setState({
          loading: false,
          openCheck: true,
          alertMessage: 'Por favor insira um arquivo.',
        });
      } else {
        userList = await this.readTxt(this.state.emailFile);
      }
    } else {
      if (this.state.query === null) {
        this.setState({ loading: false, openCheck: true });
      } else {
        getDataRedshift(this.state.query).then((result) => {
          result.map((item, i) => {
            item.contato = item.contato.replace(/-|\(|\)|\s/g, '');

            if (item.contato.substring(0, 3) === '+55') {
              if (item.contato.substring(0, 2) === '55') {
                item.contato = '+' + item.contato;
              }
            }

            return userList.push(item.contato);
          });
        });
      }
    }

    if (userList.length > 0) {
      this.changeKeywords(userList).then(() => {
        this.setState({
          loading: false,
          openCheck: true,
          alertMessage: 'Alteração realizada com sucesso!',
          keywordInput: '',
          keywordsList: [],
          keywordsCustomCheck: false,
          keywordsLimitCheck: false,
          emailFile: null,
          query: null,
          batchAction: '0',
          batchOption: '0',
        });
      });
    } else {
      this.setState({
        loading: false,
        openCheck: true,
        alertMessage: 'Arquivo vazio ou com informação inválida.',
      });
    }
  };

  readTxt = (txtFile) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = function () {
        let data = this.result.split('\n');
        let listaCellphone = [];
        for (let index = 0; index < data.length; index++) {
          const numero = data[index].trim();
          if (numero === '' || numero === undefined || numero === null);
          else listaCellphone.push(numero.replace(/@\S+/gi, ''));
        }
        resolve(listaCellphone);
      };
      reader.readAsText(txtFile);
    }).catch((error) => {
      this.setState({
        loading: false,
        openCheck: true,
        alertMessage: 'Erro ao ler conteúdo do arquivo!' + error,
      });
    });
  };

  changeKeywords = async (userList) => {
    await Promise.all(
      userList.map(async function (item, i) {
        await userReadCellphone_v3(item)
          .then(async (result) => {
            if (result !== false) {
              let userInfo = {
                updateDate: new Date().getTime(),
                syncType: 'merge',
                uId: result.userInfo.uId,
                keyWordsSetCustom: result.userInfo.keyWordsSetCustom,
                keyWordsSetLimit: result.userInfo.keyWordsSetLimit,
              };

              // Verifica se usuário não tem os campos e gera os campos
              if (!result.userInfo.keyWordsSetCustom) {
                result.userInfo.keyWordsSetCustom = [];
              }

              if (!result.userInfo.keyWordsSetLimit) {
                result.userInfo.keyWordsSetLimit = [];
              }

              if (this.state.batchAction === '0') {
                if (this.state.keywordsCustomCheck) {
                  userInfo['keyWordsSetCustom'] = removeDuplicateUsingFilter(
                    result.userInfo.keyWordsSetCustom.concat(
                      this.state.keywordsList
                    )
                  );
                }

                if (this.state.keywordsLimitCheck) {
                  userInfo['keyWordsSetLimit'] = removeDuplicateUsingFilter(
                    result.userInfo.keyWordsSetLimit.concat(
                      this.state.keywordsList
                    )
                  );
                }
              } else {
                userInfo['syncType'] = 'override';

                if (this.state.keywordsCustomCheck) {
                  userInfo['keyWordsSetCustom'] =
                    result.userInfo.keyWordsSetCustom.filter(
                      (keyword) => this.state.keywordsList.indexOf(keyword) < 0
                    );
                }

                if (this.state.keywordsLimitCheck) {
                  userInfo['keyWordsSetLimit'] =
                    result.userInfo.keyWordsSetLimit.filter(
                      (keyword) => this.state.keywordsList.indexOf(keyword) < 0
                    );
                }
              }

              setUserInfo_v1(
                result.userInfo.uId,
                ['setUserInfo'],
                userInfo
              ).catch((error) => {
                this.setState({
                  loading: false,
                  openCheck: true,
                  alertMessage:
                    'Erro ao alterar informação do usuário: ' + item,
                });
              });
            } else {
              // console.log("Usuário", item ,"não encontrado.");
            }
          })
          .catch((error) => {
            this.setState({
              loading: false,
              openCheck: true,
              alertMessage: 'Erro ao buscar informação do usuário: ' + item,
            });
          });
      }, this)
    ).catch((error) => {
      this.setState({
        loading: false,
        openCheck: true,
        alertMessage: 'Erro ao buscar ou alterar informação do usuário!',
      });
    });
  };

  handleCloseAlert = () => {
    this.setState({ openCheck: false });
  };

  handleKeywords = (name) => {
    if (this.state.keywordInput !== name) {
      this.setState({ keywordInput: name });
    }
  };

  handleCheck = (name) => (event) => {
    this.setState({
      [name]: event.target.checked,
    });
  };

  handleChangeDownshift = (item) => {
    let { keywordsList } = this.state;

    if (keywordsList.indexOf(item) === -1) {
      keywordsList = [...keywordsList, item];
    }

    this.setState({
      keywordInput: '',
      keywordsList,
    });
  };

  handleDeleteDownshift = (item) => () => {
    this.setState((state) => {
      const keywordsList = [...state.keywordsList];
      keywordsList.splice(keywordsList.indexOf(item), 1);
      return { keywordsList };
    });
  };

  render = () => {
    const { classes } = this.props;
    const selectedItem = this.state.keywordsList ? this.state.keywordsList : [];

    if (this.props.loading || this.state.loading) {
      return (
        <div className='loading'>
          <Loading />
          <Typography variant='body2'>Por favor, aguarde.</Typography>
        </div>
      );
    }
    if (!this.props.loading)
      return (
        <div style={{ padding: '100px 50px' }}>
          <Typography
            variant='h5'
            gutterBottom
            color='primary'
            style={{ fontWeight: '600' }}>
            Gerenciamento de Keywords em Lote
          </Typography>

          <form onSubmit={(e) => {
            e.preventDefault();
            this.checkInformation();
            }}>
            <FormControl className='input-field'>
              {/* input with suggestions */}
              <Downshift
                id='downshift-multiple'
                className='input-field'
                inputValue={this.state.keywordInput}
                onChange={this.handleChangeDownshift}
                selectedItem={selectedItem}
                required>
                {({
                  getInputProps,
                  getItemProps,
                  isOpen,
                  inputValue: inputValue2,
                  selectedItem: selectedItem2,
                  highlightedIndex,
                }) => (
                  <div className={classes.container}>
                    {renderInput({
                      fullWidth: false,
                      style: { minWidth: '500px' },
                      classes,
                      InputProps: getInputProps({
                        startAdornment: selectedItem.map((item) => (
                          <Chip
                            key={item}
                            tabIndex={-1}
                            label={item}
                            className={classes.chip}
                            onDelete={this.handleDeleteDownshift(item)}
                          />
                        )),
                        onChange: this.handleChange('keywordInput'),
                        onKeyDown: this.handleKeyDown,
                        placeholder: 'Selecione a keyword na lista abaixo',
                      }),
                      label: 'Keywords',
                    })}
                    {isOpen ? (
                      <Paper className={classes.paper} square>
                        {getSuggestions(inputValue2).map((suggestion, index) =>
                          renderSuggestion({
                            suggestion,
                            index,
                            itemProps: getItemProps({ item: suggestion.label }),
                            highlightedIndex,
                            selectedItem: selectedItem2,
                          })
                        )}
                      </Paper>
                    ) : null}
                  </div>
                )}
              </Downshift>

              <FormLabel style={{ marginTop: '20px' }} component='legend'>
                Tipo
              </FormLabel>
              <div style={{ display: 'inline-block' }}>
                <Checkbox
                  checked={this.state.keywordsLimitCheck}
                  onChange={this.handleCheck('keywordsLimitCheck')}
                  value={'keywordsLimitCheck'}
                />
                Limitação
                <Checkbox
                  checked={this.state.keywordsCustomCheck}
                  onChange={this.handleCheck('keywordsCustomCheck')}
                  value={'keywordsCustomCheck'}
                />
                Customização
              </div>

              <FormLabel style={{ marginTop: '20px' }} component='legend'>
                Ação
              </FormLabel>
              <div style={{ width: '300px' }}>
                <div style={{ display: 'inline-block', textAlign: 'left' }}>
                  <span style={{ minWidth: '50%', whiteSpace: 'nowrap' }}>
                    <Radio
                      checked={this.state.batchAction === '0'}
                      onChange={this.handleChange('batchAction')}
                      value='0'
                      name='radio-button-demo'
                    />
                    Adicionar
                  </span>
                  <span style={{ minWidth: '50%', whiteSpace: 'nowrap' }}>
                    <Radio
                      checked={this.state.batchAction === '1'}
                      onChange={this.handleChange('batchAction')}
                      value='1'
                      name='radio-button-demo'
                    />
                    Remover
                  </span>
                </div>
              </div>

              <FormLabel style={{ marginTop: '20px' }} component='legend'>
                Ação em lote
              </FormLabel>

              <div style={{ width: '300px' }}>
                <div style={{ display: 'inline-block', textAlign: 'left' }}>
                  <span style={{ minWidth: '50%', whiteSpace: 'nowrap' }}>
                    <Radio
                      checked={this.state.batchOption === '0'}
                      onChange={this.handleChange('batchOption')}
                      value='0'
                      name='radio-button-demo'
                    />
                    Arquivo
                  </span>
                  <span style={{ minWidth: '50%', whiteSpace: 'nowrap' }}>
                    <Radio
                      checked={this.state.batchOption === '1'}
                      onChange={this.handleChange('batchOption')}
                      value='1'
                      name='radio-button-demo'
                    />
                    Query do Redshift
                  </span>
                </div>
              </div>

              {this.state.batchOption === '0' ? (
                <div>
                  <FormLabel style={{ marginTop: '20px' }} component='legend'>
                    Arquivo para o envio da notificação
                  </FormLabel>

                  <input
                    id='thumbnailStore'
                    accept='.txt'
                    style={{ display: 'none' }}
                    type='file'
                    onChange={this.fileChangedHandler('emailFile')}
                  />

                  <div>
                    <label htmlFor='thumbnailStore'>
                      <Button
                        style={{
                          textTransform: 'capitalize',
                          marginTop: '20px',
                          fontWeight: '550',
                          color: '#6c6c6c',
                        }}
                        variant='contained'
                        component='span'
                        classes={{
                          root: classes.root,
                          disabled: classes.disabled,
                        }}
                        startIcon={<AttachFile color='secondary' />}>
                        Adicionar Arquivo
                      </Button>
                      <span style={{ marginTop: '10px' }}>
                        {' '}
                        {this.state.emailFile === null
                          ? null
                          : this.state.emailFile.name}
                      </span>
                    </label>
                  </div>
                </div>
              ) : (
                <div>
                  <Select
                    style={{ minWidth: '500px' }}
                    value={this.state.query}
                    onChange={this.handleChange('query')}
                    inputProps={{ id: 'query' }}>
                    {this.state.queries.map(function (item) {
                      return (
                        <MenuItem key={item.queryId} value={item.query}>
                          {item.name} ("{item.query}")
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              )}
            </FormControl>

            <Button
              style={{
                fontWeight: '550',
                marginRight: '20px',
                marginTop: '20px',
                color: 'white',
                textTransform: 'capitalize',
              }}
              variant='contained'
              color='secondary'
              onClick={() => window.history.back()}
              startIcon={<ArrowBack />}>
              Voltar
            </Button>

            <Button
              style={{
                marginTop: '20px',
                color: 'white',
                textTransform: 'capitalize',
                fontWeight: '550',
              }}
              variant='contained'
              color='primary'
              type='submit'
              startIcon={<Save />}>
              Realizar alteração
            </Button>
          </form>

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={this.state.openCheck}
            onClose={this.handleCloseAlert}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id='message-id'>{this.state.alertMessage}</span>}
          />
        </div>
      );
  };
}

export default compose(withStyles(styles))(KeywordsBatch);
