import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

import { liveloGetSentPoints } from 'js/library/utils/API/Livelo/apiLiveloGetSentPoints';
import { liveloRecoverReport } from 'js/library/utils/API/Livelo/apiLiveloRecoverReport';

import { convertToDateDefault } from 'js/library/utils/helpers';

import * as D from 'js/components/DesignSystem/styles';
import * as S from './styles';

import { toast, ToastContainer } from 'react-toastify';

import FileDownloadIcon from '@mui/icons-material/FileDownload';

import GoBackRow from 'js/components/DesignSystem/GoBackRow';
import CustomButtonsTable from 'js/components/TriiboComponents/Tables/CustomButtonsTable';
import { TableTD, TableTH } from 'js/components/DesignSystem/Table/styles';

const Livelo = () => {
  const [feed, setFeed] = useState([]);
  const [filteredFeed, setFilteredFeed] = useState([
    {
      dateString: '01/01/1970',
      productId: 'Placeholder',
      status: 'Enviado',
    },
  ]);
  const history = useHistory();

  useEffect(() => {
    liveloGetSentPoints()
      .then((res) => {
        // console.log("***res", res);
        setFeed(res);
      })
      .catch(() => {
        setFeed([]);
      });
  }, []);

  useEffect(() => {
    setFilteredFeed(filterFeed(feed));
  }, [feed]);

  function filterFeed(feed) {
    const resultArr = feed.map((feedItem) => {
      feedItem.status =
        feedItem.processing === 0
          ? `${feedItem.success} sucessos, ${feedItem.errors} erros`
          : 'Processando';
      feedItem.dateString = convertToDateDefault(feedItem.creationDate);

      return feedItem;
    });

    return resultArr;
  }

  const column = [
    { heading: 'Data de cadastro', value: 'dateString' },
    { heading: 'Nome interno', value: 'productId' },
    { heading: 'Status', value: 'status' },
  ];

  const redirectToPage = () => {
    history.push('/admin/configuracoes/pontos-livelo/post');
  };

  const componentReceiver = (column, childrens) => {
    return {
      columnComponent: (
        <TableTH>
          <S.ButtonsContainer>
            <S.AddNotificationButton style={{ marginLeft: 'auto' }} onClick={redirectToPage}>
              <span>+</span> Creditar pontos
            </S.AddNotificationButton>
          </S.ButtonsContainer>
        </TableTH>
      ),
      childrenComponent: (
        <>
          {childrens ? (
            <TableTD style={{ textAlign: 'right' }}>
              <S.EditOrDeleteContainer>
                <S.StatusButton
                  onClick={(e) => {
                    e.preventDefault();
                    liveloRecoverReport(childrens.batchId)
                      .then((res) => {
                        window.open(res, '_blank');
                      })
                      .catch(() => {
                        toast.error('Ocorreu um erro, tente novamente mais tarde.');
                      });
                  }}
                >
                  <FileDownloadIcon />
                </S.StatusButton>
              </S.EditOrDeleteContainer>
            </TableTD>
          ) : (
            <></>
          )}
        </>
      ),
    };
  };

  return (
    <>
      <ToastContainer autoClose={6000} />

      <S.Container>
        <GoBackRow />
        <D.TriiboH1>Histórico de pontos Livelo</D.TriiboH1>
        <CustomButtonsTable
          data={filteredFeed}
          perPage={10}
          column={column}
          componentReceiver={componentReceiver}
        />
      </S.Container>
    </>
  );
};

export default Livelo;

