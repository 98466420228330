import React, { useEffect } from 'react';

import placeholder from 'styles/assets/placeholder/placeholder_thumbnail.png';

// context
import { useOptions, usePromotions, useModal } from '../Context/Attendance';

import {
  Box,
  Button,
  Typography,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from '@mui/material';
import { useState } from 'react';

const CardHover = ({ classes, handleSetModal, data }) => {
  const { setSelectedPromotion } = usePromotions();
  const { setOptionSelected, setNameButtonLabel } = useOptions();
  const { setOpenModal } = useModal();

  const handleSelectPromotion = (type, nameLabel) => {
    setSelectedPromotion(data);
    setOptionSelected(type);
    setNameButtonLabel(nameLabel);
    setOpenModal(true);
  };

  const nameButtonLabel = data.hasGroup ? 'Consumir Cupom' : 'Dar cupom';

  return (
    <Box
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent='center'
      className={classes.cardHover}>
      <Box display='flex' alignItems='center' justifyContent='center'>
        <Button
          onClick={() =>
            handleSelectPromotion(
              `${data.hasGroup ? 'finalVoucher' : 'promotions'}`,
              nameButtonLabel
            )
          }
          style={{ width: '120px', height: '70px', background: 'purple', borderRadius: '5px'}}>
          <Typography variant='h6' component='h4' style={{ color: 'white', fontSize: '18px' }}>
            {data?.hasGroup ? 'Consumir cupom' : 'Dar cupom'}
          </Typography>
        </Button>
      </Box>

      {/* <span /> */}
      {data?.hasGroup !== true && (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Button
            onClick={() =>
              handleSelectPromotion('consumeVoucher', 'Consumir cupom')
            }
            style={{ width: '120px', height: '70px', background: 'purple', borderRadius: '5px'}}>
            <Typography variant='h6' component='h4' style={{ color: 'white', fontSize: '18px' }}>
              Consumir cupom
            </Typography>
          </Button>
        </Box>
      )}

      {data?.hasGroup === true && (
        <>
          <span />
          <Box display='flex' alignItems='center' justifyContent='center'>
            <Button
              onClick={() =>
                handleSelectPromotion('finalVoucher', 'Dar Cupom Final')
              }
              style={{ width: '120px', height: '70px', background: 'purple', borderRadius: '5px'}}>
              <Typography variant='h6' component='h4' style={{ color: 'white', fontSize: '18px' }}>
                Dar prêmio
              </Typography>
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
};

export const PromotionCard = ({ classes, promotion, attendanceServices }) => {
  const { currentPromotions } = usePromotions();

  const [thumbnail, setThumbnail] = useState(placeholder);

  const [hasFinalVoucher, setHasFinalVoucher] = useState(false);
  const [finalVoucher, setFinalVoucher] = useState({});

  const { id, templateId, title, hasGroup } = promotion;
  const { getPromotionImage, getVouchers, getVoucherGroup } =
    attendanceServices;

  useEffect(() => {
    const handleGetVoucher = (templateId) => {
      templateId &&
        getVouchers(templateId)
          .then(
            (voucherTemplate) =>
              hasGroup &&
              getVoucherGroup(voucherTemplate.groupId)
                .then(({ counterMatch, counterRoof, ...voucherGroup }) => {
                  if (counterMatch && counterRoof) {
                    setFinalVoucher({
                      ...voucherGroup,
                      templateId: counterMatch,
                    });
                    setHasFinalVoucher(true);
                  }
                })
                .catch((err) => console.log(err))
          )
          .catch((err) => console.log(err));
    };
    handleGetVoucher(templateId);
  }, [templateId]);

  useEffect(() => {
    hasFinalVoucher &&
      getVouchers(finalVoucher.templateId).then((voucher) => {
        setFinalVoucher({ ...voucher, templateId: finalVoucher.templateId });
        setHasFinalVoucher(false);
      });
    // .catch((err) => console.log(err));
  }, [hasFinalVoucher]);

  return (
    <Card className={classes.card}>
      <CardActionArea>
        <CardHover
          data={{
            id,
            templateId,
            title,
            hasGroup,
            hasFinalVoucher,
            finalVoucher,
          }}
          {...{ classes }}
        />

        <CardMedia
          component='img'
          alt='Contemplative Reptile'
          height='204'
          image={thumbnail}
          title='Contemplative Reptile'
          onLoad={getPromotionImage(
            promotion.thumbnail,
            promotion.key,
            promotion.downloadedThumb,
            promotion.type,
            currentPromotions,
            setThumbnail
          )}
        />
        <CardContent>
          <Typography
            gutterBottom
            variant='body2'
            component='h3'
            align='center'
            classes={{ body2: classes.cardText }}>
            {promotion.title || 'Nome da promoção'}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

// export const TransactionCard = React.memo(({ classes }) => (
//   <Card className={classes.card}>
//     <CardActionArea>
//       <CardHover {...{ classes }} />

//       <CardMedia
//         component="img"
//         alt="Contemplative Reptile"
//         height="204"
//         image="https://cdn.concreteplayground.com/content/uploads/2018/08/establishment-sydney-trent-van-der-jagt-02.jpg"
//         title="Contemplative Reptile"
//       />
//       <CardContent>
//         <Typography
//           gutterBottom
//           variant="body2"
//           component="h3"
//           align="center"
//           classes={{ body2: classes.cardText }}
//         >
//           Nome da promoção fidelidade
//         </Typography>
//         <Typography
//           variant="subtitle3"
//           color="textSecondary"
//           component="span"
//           classes={{ root: classes.subtitleCardText }}
//         >
//           Nome usuário
//         </Typography>
//       </CardContent>
//     </CardActionArea>
//   </Card>
// ));
