import React, { useRef, useEffect, useState } from 'react';
import 'froala-editor/js/plugins.pkgd.min.js';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import 'froala-editor/css/plugins.pkgd.min.css';
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
} from '@mui/material';
import ModelTextModal from './model';
import ModelLPSignUp from '../../LandingPageSignUp/model';
import useForm from '../../Hooks/useForm';
import { ChromePicker } from 'react-color';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  overflowY: 'scroll',
  p: 4,
};

const TextModal = ({ isActiveModals, closeModal, setPayload, selectedModalInfos }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const { form, handleChange, setForm, validateForm, clearForm } = useForm({
    textAlign: {
      value: '',
      required: false,
    },
    textColor: {
      value: '',
      required: false,
    },
    textType: {
      value: '',
      required: false,
    },
    textValue: {
      value: '',
      required: true,
    },
  });

  const currentModal = ModelLPSignUp.modalsList.text;

  const renderAlignOptions = ModelTextModal.textAlignOptions?.map(({ option, align }) => (
    <MenuItem key={align} value={align}>
      {option}
    </MenuItem>
  ));

  const renderTypeOptions = ModelTextModal.textTypes?.map(({ option, type }) => (
    <MenuItem key={type} value={type}>
      {option}
    </MenuItem>
  ));

  const handleSave = () => {
    const { textAlign, textColor, textType, textValue } = form;

    if (validateForm()) {
      setPayload((prevState) => {
        const modules = prevState.campaignLandpage.modules;
        modules[selectedModalInfos.currentIndex] = {
          ...selectedModalInfos.modalInfos,
          textColor: textColor?.value,
          textAlign: textAlign.value,
          textType: textType?.value,
          textValue: textValue?.value,
        };
        return {
          campaignLandpage: {
            ...prevState?.campaignLandpage,
            modules,
          },
        };
      });
      toast.success('Texto salvo com sucesso!');
      clearForm();
      closeModal(currentModal);
    } else toast.error('Preencha todos os campos obrigatórios!');
  };

  const handleCloseModal = () => {
    clearForm();
    closeModal(currentModal);
  };

  useEffect(() => {
    if (isActiveModals) {
      const { textAlign, textColor, textType, textValue } = selectedModalInfos?.modalInfos;

      setForm((prevState) => ({
        ...prevState,
        textAlign: {
          value: textAlign || '',
          required: false,
        },
        textColor: {
          value: textColor || '#000000',
          required: true,
        },
        textType: {
          value: textType || '',
          required: true,
        },
        textValue: {
          value: textValue || '',
          required: true,
        },
      }));
    }
  }, [isActiveModals, selectedModalInfos]);

  return (
    <Modal defaultValue={false} open={isActiveModals}>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        flexDirection={'column'}
        alignItems={'center'}
        width={'100%'}
        maxHeight={'90vh'}
        maxWidth={'1000px'}
        bgcolor={'white'}
        borderRadius={'5px'}
        sx={style}
      >
        <FormControl fullWidth margin="normal">
          <InputLabel id="alignTextLabel">Selecione o alinhamento do texto</InputLabel>
          <Select
            inputProps={{
              name: 'textAlign',
            }}
            onChange={(event) => handleChange(event)}
            label="Selecione o alinhamento do texto"
            value={form?.textAlign?.value || ''}
            margin="dense"
            size="20"
          >
            {renderAlignOptions}
          </Select>
        </FormControl>

        <Box width={'100%'} display={'flex'} flexDirection={'column'} rowGap={'8px'}>
          <Box display={'flex'} alignItems={'center'} width={'100%'} columnGap={'12px'}>
            <TextField
              fullWidth
              label="Cor"
              id="textColor"
              margin="normal"
              value={form?.textColor?.value || ''}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              sx={{
                backgroundColor: form.textColor?.value || '',
                width: '304px',
                height: 'inherit',
              }}
              variant="contained"
              onClick={() => setDisplayColorPicker((prevState) => !prevState)}
            >
              {displayColorPicker ? 'Salvar' : 'Selecione a cor do texto'}
            </Button>
          </Box>
          {displayColorPicker && (
            <ChromePicker
              color={form?.textColor?.value}
              onChange={(event) =>
                setForm((prevState) => ({
                  ...prevState,
                  textColor: { ...prevState.textColor, value: event.hex },
                }))
              }
            />
          )}
        </Box>

        <FormControl fullWidth margin="normal">
          <InputLabel id="alignTextLabel">Selecione o tipo do texto</InputLabel>
          <Select
            inputProps={{
              name: 'textType',
            }}
            onChange={(event) => handleChange(event)}
            label="Alinhamento de texto"
            value={form?.textType?.value || ''}
            margin="dense"
            size="20"
          >
            {renderTypeOptions}
          </Select>
        </FormControl>

        <TextField
          fullWidth
          multiline
          minRows={3}
          label="Texto*"
          id="textValue"
          margin="normal"
          value={form.textValue?.value || ''}
          onChange={(event) => handleChange(event)}
        />

        <Box
          marginTop={'20px'}
          display={'flex'}
          alignItems={'center'}
          columnGap={'12px'}
          justifyContent={'center'}
          width={'100%'}
        >
          <Button variant="contained" color="primary" onClick={handleSave}>
            salvar
          </Button>

          <Button variant="contained" color="primary" onClick={handleCloseModal}>
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TextModal;
