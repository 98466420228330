import React, { useState } from 'react';
import styled from 'styled-components';

import * as D from 'js/components/DesignSystem/styles';

import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const CropperContainer = styled.span`
  max-width: 24rem;
  /* max-height: 15rem; */
`;

const ButtonContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.4rem;
  width: 100%;
  margin-top: 0;

  @media (min-width: 900px) {
    flex-direction: row;
  }
`;

const CropperTool = (props) => {
  const { setOpen, img, setImg, ratio, setIsActiveOverflow } = props;

  const [cropper, setCropper] = useState();

  const getCropData = () => {
    if (typeof cropper !== 'undefined') {
      setImg(cropper.getCroppedCanvas().toDataURL());
    }
  };

  return (
    <>
      <CropperContainer>
        <div style={{ width: '100%' }}>
          <Cropper
            style={{ maxHeight: '15rem', width: '100%' }}
            zoomTo={0.2}
            aspectRatio={ratio || 4 / 3}
            preview=".img-preview"
            src={img}
            viewMode={2}
            dragMode={'move'}
            minCropBoxWidth={100}
            background={false}
            responsive={true}
            autoCropArea={ratio || 4 / 3}
            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            guides={true}
          />
        </div>
      </CropperContainer>
      <ButtonContainer>
        <D.TriiboFilledButton
          blue={true}
          onClick={(e) => {
            e.preventDefault();
            getCropData();
            setOpen(false);
            setIsActiveOverflow && setIsActiveOverflow(true);
          }}
        >
          Cortar
        </D.TriiboFilledButton>
        <D.TriiboWhiteButton
          blue={true}
          onClick={(e) => {
            setIsActiveOverflow && setIsActiveOverflow(true);
            e.preventDefault();
            if (img !== '') {
              setOpen(false);
            } else {
              setImg('');
              setOpen(false);
            }
          }}
        >
          Cancelar
        </D.TriiboWhiteButton>
      </ButtonContainer>
    </>
  );
};

export default CropperTool;
