import axios from 'axios';

import { findIndexInArray } from 'js/library/utils/helpers.js';
import { getUserInfo_v1 } from 'js/library/utils/API/getUserInfo_v1.js';
import { loginUser } from 'js/library/services/AuthenticationManager.js';
import { getChannelTokenUidNull } from 'js/library/utils/API/getChannelTokenUidNull_v1';
import { LoginHistory } from './api_loginHistory';
import cfac22 from '../cfac22';

export function validateSMS_v1(code, cellPhone, transactionId) {
  return new Promise((resolve, reject) => {
    getChannelTokenUidNull()
      .then((token) => {
        //console.log('RESULTADO GET CHANNEL TOKEN C/ UID NULL ===>', result);

        axios
          .post(cfac22('API_HOST_V1') + 'validateSMS_v1', {
            triiboHeader: {
              apiToken: cfac22('keyapiv1triibowebadmin'),
              channelGroup: cfac22('channelGroup'),
              channelName: cfac22('channelName'),
              uId: null,
              channelTokenId: token,
              channelId: cfac22('channelId'),
              sessionId: '001',
              transactionId: '002',
            },
            transactionId: transactionId,
            cellphone: cellPhone,
            code: code,
          })
          .then((result) => {
            if (result.data.error !== null) {
              resolve({
                cellPhone: null,
                userInfo: null,
                error: result.data.error.errorCode,
              });
            } else {
              //pesquisar usuário no banco
              const userInfo = {
                contactList: [
                  {
                    type: 'cellPhone',
                    value: cellPhone,
                  },
                ],
              };

              getUserInfo_v1(['getUserInfo'], userInfo)
                .then((result) => {
                  const cellPhone =
                    userInfo.contactList[
                      findIndexInArray(userInfo.contactList, 'type', 'cellPhone')
                    ].value;

                  if (
                    result.data.success.userInfo === null ||
                    result.data.success.userInfo.triiboId === undefined
                  ) {
                    window.pathname = '/signIn';

                    resolve({
                      cellPhone: cellPhone,
                      userInfo: null,
                      error: null,
                    });
                  } else {
                    loginUser(
                      result.data.success.userInfo.triiboId.replace(/[,]/gi, '.'),
                      result.data.success.userInfo.passPhrase
                    )
                      .then(async function () {
                        await LoginHistory({
                          tag: 'LOGIN_WEBADM',
                          uid: result.data.success.userInfo.uId,
                        });

                        resolve({
                          cellPhone: cellPhone,
                          userInfo: result.data.success.userInfo,
                          error: null,
                        });

                        window.pathname = '/admin';
                      })
                      .catch((error) => {
                        // console.log('ERROR LOGIN FIREBASE ===>', error);
                        reject(error);
                      });
                  }
                })
                .catch((error) => {
                  //console.log('ERROR VALIDATE SMS ===>', error);
                  reject(error);
                });
            }
          })
          .catch((error) => {
            // console.log('***ERROR VALIDATE SMS ===>', error);
            reject(error);
          });
      })
      .catch((error) => {
        //console.log('ERROR GET CHANNEL TOKEN ===>', error);
        reject(error);
      });
  });
}
