import axios from 'axios';
import authToken from './authToken';
import cfac22 from '../cfac22';

export function getStoreSegment(id) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .get(cfac22('API_HOST_V2') + `/resourceSegment/getStoreSegment?segmentId=${id}`, {
          headers: { authorization: 'Bearer ' + token },
        })
        .then((result) => {
          resolve(result.data.response);
        })
        .catch((error) => {
          return reject(error.data.response);
        });
    });
  });
}
