import {
  Button,
  Snackbar,
  Input,
  Grid,
  Checkbox,
  Typography,
} from '@mui/material';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { Search } from '@mui/icons-material';

import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

import Loading from 'js/containers/Loading/Loading';

import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase';
import { updateAdministratorsAction } from 'js/core/actions/adminConfigurationActions';
import { userReadCellphone_v3 } from 'js/library/utils/API/apiUserRead';

import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={inputRef}
      mask={[
        '+',
        5,
        5,
        ' ',
        '(',
        /\d/,
        /\d/,
        ')',
        ' ',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};

class AdminConfiguration extends Component {
  constructor(props) {
    super(props);

    this.state = {
      administrador: '',
      nome: '',
      email: '',
      listaPerfis: [],
      userCreated: false,
      openAlert: false,

      loading: false,
    };

    window.pathname = null;
  }

  searchUser = (name) => {
    userReadCellphone_v3('+' + name.replace(/\D+/g, '')).then(
      (verification) => {
        if (verification !== false) {
          getDataListRestApi(
            'Perfil-Users/' + verification.userInfo.triiboId.replace('+', '%2b')
          ).then((dataReceived) => {
            this.setState({
              listaPerfis:
                dataReceived === null ? [] : dataReceived.listaPerfis,
              userCreated: dataReceived === null ? true : null,
            });
          });
          this.setState({
            nome: verification.userInfo.name,
            email: verification.userInfo.triiboId,
          });
        } else {
          this.setState({ nome: false, openAlert: true });
        }
      }
    );
  };

  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleChangeCheckBox = (perfil, hasPerfil) => (event) => {
    let listaPerfis = this.state.listaPerfis;
    if (hasPerfil >= 0) {
      listaPerfis.splice(hasPerfil, 1);
    } else {
      listaPerfis.push(perfil);
    }

    this.setState({ listaPerfis });
  };

  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  };

  handleSubmit = () => {
    this.setState({ loading: true });
    this.props.updateAdministratorsComponent(
      this.state.listaPerfis,
      this.state.email,
      this.state.userCreated
    );
  };

  render = () => {
    if (this.props.loading && this.state.loading)
      return (
        <div className='loading'>
          <Loading />
        </div>
      );
    else {
      if (window.pathname !== null) {
        return (
          <Redirect
            to={{
              pathname: window.pathname,
            }}
          />
        );
      } else {
        return (
          <div style={{ padding: '4rem 32px' }}>
           <GoBackHeader />
           <HeaderTitle pageInfo='Cadastro de Administradores da Triibo'/>
            <br />
            <br />

            <Grid container>
              <Grid key='administrador' item md={6} sm={12} xs={12}>
                <Input
                  fullWidth={true}
                  value={this.state.administrador}
                  id='formatted-text-mask-input'
                  inputComponent={TextMaskCustom}
                  onChange={this.handleChange('administrador')}
                  endAdornment={
                    <Search
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.searchUser(this.state.administrador)}
                    />
                  }
                />
              </Grid>
            </Grid>

            {this.state.nome !== '' ? (
              this.state.nome !== false ? (
                <Grid container>
                  <Grid
                    item
                    md={3}
                    sm={6}
                    xs={12}
                    style={{ marginTop: '20px' }}>
                    <Typography
                      variant='subtitle2'
                      color='secondary'
                      style={{ fontWeight: '500' }}>
                      {' '}
                      Nome:{' '}
                    </Typography>
                    {this.state.nome}
                  </Grid>

                  <Grid
                    item
                    md={3}
                    sm={6}
                    xs={12}
                    style={{ marginTop: '20px' }}>
                    <Typography
                      variant='subtitle2'
                      color='secondary'
                      style={{ fontWeight: '500' }}>
                      {' '}
                      Email:{' '}
                    </Typography>
                    {this.state.email.replace(/[,]/gi, '.')}
                  </Grid>

                  <Grid item md={12} style={{ marginTop: '20px' }}>
                    Usuário
                    <Checkbox
                      onClick={this.handleChangeCheckBox(
                        'Usuario',
                        this.state.listaPerfis.indexOf('Usuario')
                      )}
                      checked={
                        this.state.listaPerfis.indexOf('Usuario') >= 0
                          ? true
                          : false
                      }
                    />{' '}
                    | Prestador{' '}
                    <Checkbox
                      onClick={this.handleChangeCheckBox(
                        'Prestador',
                        this.state.listaPerfis.indexOf('Prestador')
                      )}
                      checked={
                        this.state.listaPerfis.indexOf('Prestador') >= 0
                          ? true
                          : false
                      }
                    />{' '}
                    | Administrador{' '}
                    <Checkbox
                      onClick={this.handleChangeCheckBox(
                        'Administrador',
                        this.state.listaPerfis.indexOf('Administrador')
                      )}
                      checked={
                        this.state.listaPerfis.indexOf('Administrador') >= 0
                          ? true
                          : false
                      }
                    />{' '}
                    | Administrador Triibo{' '}
                    <Checkbox
                      onClick={this.handleChangeCheckBox(
                        'Administrador-Triibo',
                        this.state.listaPerfis.indexOf('Administrador-Triibo')
                      )}
                      checked={
                        this.state.listaPerfis.indexOf(
                          'Administrador-Triibo'
                        ) >= 0
                          ? true
                          : false
                      }
                    />{' '}
                    | Dono de Estabelecimento{' '}
                    <Checkbox
                      onClick={this.handleChangeCheckBox(
                        'Dono-Estabelecimento',
                        this.state.listaPerfis.indexOf('Dono-Estabelecimento')
                      )}
                      checked={
                        this.state.listaPerfis.indexOf(
                          'Dono-Estabelecimento'
                        ) >= 0
                          ? true
                          : false
                      }
                    />{' '}
                    | Administrador de Estabelecimento{' '}
                    <Checkbox
                      onClick={this.handleChangeCheckBox(
                        'Administrador-Estabelecimento',
                        this.state.listaPerfis.indexOf(
                          'Administrador-Estabelecimento'
                        )
                      )}
                      checked={
                        this.state.listaPerfis.indexOf(
                          'Administrador-Estabelecimento'
                        ) >= 0
                          ? true
                          : false
                      }
                    />
                  </Grid>
                </Grid>
              ) : null
            ) : null}

            <Button
              disabled={
                this.state.nome === '' || this.state.nome === false
                  ? true
                  : false
              }
              style={{
                marginTop: '20px',
                color: 'white',
                textTransform: 'capitalize',
                fontWeight: '550',
              }}
              variant='contained'
              color='primary'
              onClick={() => this.handleSubmit()}>
              Salvar
            </Button>

            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
              open={this.state.openAlert}
              onClose={this.handleCloseAlert}
              ContentProps={{
                'aria-describedby': 'message-id',
              }}
              message={<span id='message-id'>Este usuário não existe</span>}
            />
          </div>
        );
      }
    }
  };
}

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    loading: state.adminInformationComponent.loading,
    success: state.adminInformationComponent.success,
    error: state.adminInformationComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  updateAdministratorsComponent: (listaPerfis, email, userCreated) =>
    updateAdministratorsAction(dispatch, listaPerfis, email, userCreated),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdminConfiguration);
