import React, { useEffect, useState } from 'react';
import { cellPhoneMask } from 'js/library/utils/helpers';
import { toast } from 'react-toastify';

//STYLES
import {
  Box,
  Button,
  CircularProgress,
  InputLabel,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import { BoxInfo, BoxModal, ContentInfoModalAdd } from './segmentStyles';

//API
import { userSegmentWhiteList } from 'js/library/utils/API/userSegmentWhiteList';
import { getUsersByPartner } from 'js/library/utils/API/apiGetUsersByPartner';

//ICONS
import { MdPeopleAlt } from 'react-icons/md';
import { HiDocumentSearch } from 'react-icons/hi';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

export const ModalNewMember = ({
  setOpenModalAdd,
  open,
  userSegmentId,
  attApi,
  isChangeApi,
  data,
}) => {
  const [loading, setLoading] = useState(false);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [cellPhone, setCellPhone] = useState('');
  const [userInfo, setuserInfo] = useState({
    name: '',
    uId: '',
  });
  const [errorMessage, setErrorMessage] = useState('');

  const partnerId = localStorage.getItem('partnerIdClube');

  useEffect(() => {
    const fetchData = async () => {
      if (cellPhone.length === 15) {
        setLoading(true);
        try {
          const data = await getUsersByPartner(partnerId);

          if (Array.isArray(data)) {
            const cellPhoneFormated = '+55' + cellPhone.replace(/[()\s-]/g, '');

            // verifica se o cellPhone digitado existe em data
            const member = data.find(
              (item) =>
                Array.isArray(item.contactList) &&
                item.contactList.some((contact) => contact.value === cellPhoneFormated)
            );

            if (member) {
              setuserInfo({
                name: member.aliasName ?? member.nome ?? 'Não cadastrado',
                uId: member.uid,
              });
            } else {
              setErrorMessage(
                'O número de telefone fornecido não corresponde a nenhum membro do clube.'
              );

              setuserInfo({ name: '', uId: '' });
            }
          } else {
            console.error('Data não é um array:', data);
          }
        } catch (error) {
          console.error('fetchData', error);
        } finally {
          setLoading(false);
        }
      }
    };

    setErrorMessage(''); // Limpa a mensagem de erro sempre que cellPhone mudar
    setuserInfo({ name: '', uId: '' }); // Limpa os dados do usuário sempre que cellPhone mudar
    fetchData();
  }, [cellPhone]);

  const addNewMember = async () => {
    try {
      setLoadingAdd(true);

      const payload = {
        userSegmentId,
        uid: userInfo.uId,
      };

      const checkIfMemberExists = data.some((item) => item.uid === userInfo.uId);

      if (checkIfMemberExists) {
        toast.error('Esse membro já foi adicionado.', { autoClose: 2500 });
        setLoadingAdd(false);
      } else {
        await userSegmentWhiteList(payload);
        toast.success('Membro adicionado com sucesso.', { autoClose: 2000 });
        setuserInfo({
          name: '',
          uId: '',
        });
        setCellPhone('');
        attApi(!isChangeApi);
        handleClose();
      }
    } catch (error) {
      console.error('Erro ao adicionar membro: ', error);
    } finally {
      setLoadingAdd(false);
    }
  };

  const handleClose = () => {
    setOpenModalAdd(false);
    setuserInfo({ name: '', uId: '' });
    setCellPhone('');
    setErrorMessage('');
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={open}
      onClose={handleClose}
    >
      <BoxModal sx={{ position: 'relative' }}>
        <Box
          sx={{ position: 'absolute', top: '15px', right: '15px', cursor: 'pointer' }}
          onClick={handleClose}
        >
          <CloseRoundedIcon fontSize="large" />
        </Box>

        <h3>Adicionar um novo membro</h3>

        <span
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
          }}
        >
          <InputLabel required>Telefone:</InputLabel>
          <TextField
            placeholder="(99) 99999-9999"
            variant="outlined"
            required
            value={cellPhone}
            onChange={(e) => setCellPhone(cellPhoneMask(e.target.value))}
            sx={{ width: 300 }}
          />
          {errorMessage !== '' && (
            <Typography
              variant="subtitle2"
              sx={{
                color: '#dd2424',
                paddingLeft: '0px',
                marginTop: '5px',
                fontWeight: '600',
                width: 300,
              }}
            >
              {errorMessage}
            </Typography>
          )}
        </span>
        {loading ? (
          <CircularProgress size={25} thickness={6} />
        ) : !userInfo.uId ? (
          ''
        ) : (
          <ContentInfoModalAdd>
            <BoxInfo>
              <MdPeopleAlt size={20} style={{ color: '#08bad0' }} />
              <p>
                <span>Nome: </span> {userInfo.name}
              </p>
            </BoxInfo>
            <BoxInfo>
              <HiDocumentSearch size={20} style={{ color: '#08bad0' }} />
              <p>
                <span>Uid: </span> {userInfo.uId}
              </p>
            </BoxInfo>
          </ContentInfoModalAdd>
        )}
        <Button
          color="primary"
          disabled={userInfo.uId === ''}
          variant="contained"
          onClick={addNewMember}
        >
          {loadingAdd ? (
            <CircularProgress size={25} thickness={6} style={{ color: '#fff' }} />
          ) : (
            'Adicionar'
          )}
        </Button>
      </BoxModal>
    </Modal>
  );
};
