import React, { useCallback, useEffect, useRef, useState } from 'react';
import ModelLPSignUp from '../../LandingPageSignUp/model';
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
  Typography,
} from '@mui/material';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
import useForm from '../../Hooks/useForm';
import { ChromePicker } from 'react-color';
import ModelHighlightsModal from './model';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import ImportImage from 'js/components/TriiboComponents/ImportImage';
import { useParams } from 'react-router';
import { colorText, hexToRgba, rgbaToHex } from 'js/library/utils/helpers';

function HighlightsModal({ isActiveModals, setPayload, closeModal, selectedModalInfos }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [inputSearch, setInputSearch] = useState('');
  const [establishmentsOptions, setEstablishmentsOptions] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [isActiveOverflow, setIsActiveOverflow] = React.useState(true);
  const [primaryImg, setPrimaryImg] = React.useState('');
  const [loadingSearch, setLoadingSearch] = useState(false);
  const [selectedOptionsName, setSelectedOptionsName] = useState([]);
  const { form, validateForm, handleChange, setForm, clearForm } = useForm({
    highlightsBackground: {
      value: '',
      required: false,
    },
    highlightsData: {
      value: [],
      required: true,
    },
    title: {
      value: '',
      required: true,
    },
    subtitle: {
      value: '',
      required: true,
    },
    highlightsType: {
      value: '',
      required: true,
    },
    primaryImage: {
      value: '',
      required: true,
    },
  });

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    boxShadow: 24,
    p: 4,
    overflowY: isActiveOverflow ? 'scroll' : 'hidden',
  };

  const currentModal = ModelLPSignUp.modalsList.highlights;

  const renderTypeOptions = ModelHighlightsModal.highlightsTypes?.map(({ option, type }) => (
    <MenuItem key={type} value={type}>
      {option}
    </MenuItem>
  ));

  const modalRef = useRef();
  const params = useParams();

  const setScrollPosition = (element, scroll) => {
    element.current.scrollTop = scroll;

    if (scroll === 0) setIsActiveOverflow(false);
    else setIsActiveOverflow(true);
  };

  const handleSave = () => {
    if (validateForm()) {
      setPayload((prevState) => {
        const modules = prevState?.campaignLandpage?.modules;
        modules[selectedModalInfos.currentIndex] = {
          ...selectedModalInfos.modalInfos,
          highlightsBackground: form?.highlightsBackground?.value,
          highlightsData: form?.highlightsData?.value,
          title: form?.title?.value,
          subtitle: form?.subtitle?.value,
          highlightsType: form?.highlightsType?.value,
          primaryImage: form?.primaryImage?.value,
        };
        return {
          campaignLandpage: {
            ...prevState?.campaignLandpage,
            modules,
          },
        };
      });
      toast.success('Destaque salvo com sucesso!');
      closeModal(currentModal);
    } else {
      toast.error('Preencha todos os campos!');
    }
  };

  const setListTypes = async (input, highlightsType) => {
    setLoadingSearch(true);
    const response =
      highlightsType === 'promotion'
        ? await ModelHighlightsModal.getListPromotions(input, true, 0, 10)
        : await ModelHighlightsModal.getListEstablishments(input, 0, 10);
    const options = response?.map(({ nome, id, title }) => ({ nome: nome || title, id }));
    setEstablishmentsOptions(options);
    setLoadingSearch(false);
  };

  function debounce(func, delay) {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  }

  const debouncedSearch = useCallback(
    debounce((input) => {
      setListTypes(input, form.highlightsType.value);
    }, 500),
    [form.highlightsType.value]
  );

  const setPrimaryImgForm = async (partnerId) => {
    if (primaryImg.includes('base64')) {
      const imgPath = await ModelHighlightsModal.uploadPrimaryImg(primaryImg, partnerId);
      setForm((form) => ({
        ...form,
        primaryImage: {
          ...form.primaryImage,
          value: imgPath,
        },
      }));
    }
  };

  const handleCloseModal = () => {
    clearForm();
    closeModal(currentModal);
  };

  const getPromoOrEstabName = async (idsList) => {
    let response;
    if (form.highlightsType.value === 'promotion')
      response = await ModelHighlightsModal.getStoreNameById(idsList);
    else if (form.highlightsType.value === 'establishment')
      response = (await ModelHighlightsModal.getEstablishmentsNameById(idsList)).result;
    const selectedOptionsName = (response || [])?.map(({ title, nome }) => title || nome);
    setSelectedOptionsName(selectedOptionsName);
  };

  useEffect(() => {
    if (selectedOptions?.length) getPromoOrEstabName(selectedOptions);
  }, [selectedOptions]);

  useEffect(() => {
    if (form.highlightsType.value && inputSearch)
      setListTypes(inputSearch, form.highlightsType.value);
  }, [form.highlightsType.value]);

  useEffect(() => {
    setPrimaryImgForm(params?.id);
  }, [primaryImg]);

  useEffect(() => {
    if (isActiveModals) {
      const {
        highlightsBackground,
        highlightsData,
        title,
        subtitle,
        highlightsType,
        primaryImage,
      } = selectedModalInfos?.modalInfos;

      const hashrgx = /#/;

      setForm((prevState) => ({
        ...prevState,
        highlightsBackground: {
          value: hashrgx.test(highlightsBackground)
            ? hexToRgba(highlightsBackground)
            : highlightsBackground || 'rgba(255, 255, 255, 1)',
          required: false,
        },
        highlightsData: {
          value: highlightsData || [],
          required: true,
        },
        title: {
          value: title || '',
          required: true,
        },
        subtitle: {
          value: subtitle || '',
          required: true,
        },
        highlightsType: {
          value: highlightsType || '',
          required: true,
        },
        primaryImage: {
          value: primaryImage || '',
          required: true,
        },
      }));

      getPromoOrEstabName(highlightsData);
      setSelectedOptions(highlightsData || []);
      setPrimaryImg(primaryImage || '');
    }
  }, [isActiveModals, selectedModalInfos]);

  useEffect(() => {
    if (form.highlightsType.value && inputSearch) debouncedSearch(inputSearch);
  }, [form.highlightsType.value, inputSearch]);

  return (
    <Modal defaultValue={false} open={isActiveModals}>
      <Box
        justifyContent={'space-between'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        width={'100%'}
        maxHeight={'90vh'}
        maxWidth={'1000px'}
        bgcolor={'white'}
        borderRadius={'5px'}
        ref={modalRef}
        sx={style}
      >
        <Box
          width={'100%'}
          display={'flex'}
          alignItems={'flex-start'}
          rowGap={'8px'}
          flexDirection={'column-reverse'}
          margin={'8px 0'}
        >
          <ImportImage
            imageDefault={null}
            autoWidth={false}
            image={primaryImg}
            setImage={setPrimaryImg}
            ratio={6 / 5}
            refScroll={modalRef}
            setIsActiveOverflow={setIsActiveOverflow}
            setScrollPosition={setScrollPosition}
          />
          <Typography paddingLeft={'3px'}>Selecione uma imagem primária:</Typography>
        </Box>

        <Box width={'100%'} display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} alignItems={'center'} width={'100%'} columnGap={'12px'}>
            <TextField
              fullWidth
              label="Cor de fundo"
              id="highlightsBackground"
              margin="normal"
              value={form?.highlightsBackground?.value || ''}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              sx={{
                backgroundColor: form.highlightsBackground?.value || '',
                width: '304px',
                height: 'inherit',
                color: form.highlightsBackground.value
                  ? colorText(rgbaToHex(form.highlightsBackground?.value))
                  : '#000',
              }}
              variant="contained"
              onClick={() => setDisplayColorPicker((prevState) => !prevState)}
            >
              {displayColorPicker ? 'Salvar' : 'Selecione a cor de fundo'}
            </Button>
          </Box>
          {displayColorPicker && (
            <ChromePicker
              color={form?.highlightsBackground?.value || 'rgba(255, 255, 255, 1)'}
              onChange={(event) =>
                setForm((prevState) => ({
                  ...prevState,
                  highlightsBackground: {
                    ...prevState.color,
                    value: `rgba(${event.rgb.r}, ${event.rgb.g}, ${event.rgb.b}, ${event.rgb.a})`,
                  },
                }))
              }
            />
          )}
        </Box>

        <TextField
          fullWidth
          label="Texto"
          id="title"
          margin="normal"
          value={form.title?.value || ''}
          onChange={(event) => handleChange(event)}
        />

        <TextField
          fullWidth
          label="Subtítulo"
          id="subtitle"
          margin="normal"
          value={form?.subtitle?.value || ''}
          onChange={(event) => handleChange(event)}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="establishmentOrPromotion">
            Selecione um estabelecimento ou promoção
          </InputLabel>
          <Select
            inputProps={{
              name: 'highlightsType',
            }}
            onChange={(event) => {
              setSelectedOptions([]);
              setForm((prevState) => ({
                ...prevState,
                highlightsData: {
                  ...prevState,
                  value: [],
                },
              }));
              handleChange(event);
            }}
            label="Selecone um estabelecimento ou promoção"
            value={form?.highlightsType?.value || ''}
            margin="dense"
            size="20"
          >
            {renderTypeOptions}
          </Select>
        </FormControl>

        {form.highlightsType.value && (
          <Autocomplete
            fullWidth
            multiple
            id="search-bar"
            options={establishmentsOptions}
            value={selectedOptions}
            getOptionLabel={(option) => `Nome: ${option.nome} | ID: ${option.id}`}
            isOptionEqualToValue={(option, selectedValue) => option.id === selectedValue}
            onChange={(_, newValue) => {
              const result = newValue.map((item) => (item.id ? item.id : item));
              setForm((form) => ({
                ...form,
                highlightsData: {
                  ...form.highlightsData,
                  value: result,
                },
              }));
              setSelectedOptions(result);
            }}
            renderTags={(_, getTagProps) =>
              selectedOptionsName?.map((option, index) => (
                <Chip
                  key={option.id || option}
                  label={option.id || option}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                onChange={(event) => setInputSearch(event.target.value)}
                variant="outlined"
                label={
                  loadingSearch ? (
                    <>
                      <CircularProgress size={20} color="inherit" />
                      &nbsp;Pesquisando...
                    </>
                  ) : form?.highlightsType?.value === 'promotion' ? (
                    'Selecione as promoções'
                  ) : (
                    'Selecione os estabelecimentos'
                  )
                }
                placeholder="Pesquise"
                disabled={loadingSearch}
              />
            )}
          />
        )}

        <Box
          marginTop={'20px'}
          display={'flex'}
          alignItems={'center'}
          columnGap={'12px'}
          justifyContent={'center'}
          width={'100%'}
        >
          <Button variant="contained" color="primary" onClick={handleSave}>
            salvar
          </Button>

          <Button variant="contained" color="primary" onClick={handleCloseModal}>
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default HighlightsModal;
