import { makeStyles } from "@mui/styles";
import { padding } from "polished";
import styled from 'styled-components';

export const useModalsStyles = makeStyles((theme) => ({
  card: {
    width: '100%',

    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(5),

    '&:last-child': {
      marginRight: 0,
    },
  },

  cardTitle: {
    fontWeight: 600,

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  cardText: {
    overflow: 'hidden',

    display: '-webkit-box',
    '-webkit-line-clamp': 4,
    '-webkit-box-orient': 'vertical',
  },

  button: {
    alignSelf: 'center',

    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),

    textTransform: 'capitalize',
    fontWeight: 600,
  },

  content: {
    maxWidth: '100%',
    overflowY: 'auto',
  },

  walletContainer: {
    height: 160,

    boxShadow: theme.shadows[10],

    borderRadius: 15,

    '&+div': {
      marginLeft: theme.spacing(2),
    },

    '&>img': {
      borderRadius: '15px 0 0 15px',
    },
  },

  walletTextContainer: {
    width: 186,

    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingLeft: 0,

    marginLeft: theme.spacing(3),
  },

  walletDescriptionText: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: '-webkit-box',
    '-webkit-line-clamp': 5,
    '-webkit-box-orient': 'vertical',
  },

  iconButton: {
    float: 'right',
  },

  searchInput: {
    width: '90%',

    marginTop: theme.spacing(5),

    alignSelf: 'center',
  },

  loadingIcon: {
    marginLeft: theme.spacing(2),
  },

  sadIcon: {
    height: 150,
    width: 150,
  },
}));

export default makeStyles((theme) => ({
  root: {
    maxHeight: '100%',
  },

  card: {
    width: 273,

    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(5),

    '&:last-child': {
      marginRight: 0,
    },
  },

  cardText: {
    fontWeight: 600,

    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  subtitleCardText: {
    float: 'right',
    marginBottom: theme.spacing(1),
  },

  cardHover: {
    height: '100%',
    width: '100%',

    transition: 'opacity .2s',

    opacity: 0,

    position: 'absolute',
    zIndex: 3,

    backgroundColor: 'rgba(197, 197, 197, 0.53)',
    paddingTop: '4px',

    '&>div': {
      height: '100%',
      width: '100%',
    },

    '&>span': {
      height: 1,
      width: '100%',

      backgroundColor: 'black',
    },

    '&:hover': {
      transition: 'opacity .2s',
      opacity: 1,
    },
  },

  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  paper: {
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    width: '700px',
    maxHeight: '95vh',
    overflow: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxWidth: '340px',
      padding: '15px 0px 15px 20px',
    },
  },

  promotions: {
    [theme.breakpoints.down('xs')]: {
      marginLeft: '8%',
    },
  },
}));

export const cellPhoneBox = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 0.5rem;
  height: ${({ height }) => height || '40px'};
  border: 1px solid #ccc;
  border-radius: 5px;

  select {
    border: none;
    height: 100%;
    outline: none;
    border-radius: 5px;
  }

  .inputCellphone {
    flex: 1;
    border: none;
    width: 100%;
    height: 100%;
    outline: none;
    border-radius: 5px;
  }
`;
