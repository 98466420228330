import React, { useEffect, useState } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import { cloneDeep } from 'lodash';

//icons
import { MdArrowBackIosNew } from 'react-icons/md';

//styles
import * as S from './styles';
import * as T from 'js/components/DesignSystem/styles';
import { AreaHeader, GoBack } from '../styles';

//components
import SelectAccessKey from './SelectAccessKey';
import FieldsList from './FieldsList';
import ModalAddField from './ModalAddField';
import { CircularProgress } from '@mui/material';

//APIs
import { GetPartnerUserForms } from 'js/library/utils/API/getPartnerUserById';
import { getSeuClube } from 'js/library/utils/API/seuClube';
import { EditPartnerUserForms } from 'js/library/utils/API/apiEditPartnerUserForms';
import { CreatePartnerUserForms } from 'js/library/utils/API/apiCreatePartnerUserForms';
import { ListAllFieldTypes } from 'js/library/utils/API/apiListAllTypes';

const data = [
  {
    id: '0',
    fieldName: 'aliasName',
    label: 'Insira seu apelido',
    fieldType: 'text',
    inputType: 'text',
    inputMask: '',
    allowEdition: true,
    required: true,
    type: 'others',
  },
];

export default function FormSignUp() {
  const [seuClubeInfos, setSeuClubeInfos] = useState({});
  const [formsFields, setFormsFields] = useState(new Set());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [fieldIndexKey, setFieldIndexKey] = useState(null);
  const [indexKeyAlreadyExists, setIndexKeyAlreadyExists] = useState(false);
  const [fieldToEdit, setFieldToEdit] = useState('');
  const [isUpdateForm, setIsUpdateForm] = useState(false);
  const [loadingForm, setLoadingForm] = useState(true);
  const [loading, setLoading] = useState(false);
  const [fieldTypes, setFieldTypes] = useState([]);

  useEffect(() => {
    const clubeIdUrl = document.location.pathname.split('/')[4];

    const getForm = async () => {
      const seuClubeInfo = await getSeuClube(clubeIdUrl);
      setSeuClubeInfos(seuClubeInfo);
      try {
        const response = await GetPartnerUserForms(seuClubeInfo.partnerId);

        const addIdIntoFields = response.formFields.map((field, index) => {
          return {
            ...field,
            id: index.toString(),
          };
        });

        setFormsFields(new Set(addIdIntoFields));
        setIndexKeyAlreadyExists(true);
        setIsUpdateForm(true);
        setLoadingForm(false);
      } catch (error) {
        setFormsFields(new Set(data));
        setIsUpdateForm(false);
        setLoadingForm(false);
      }
    };

    ListAllFieldTypes()
      .then((res) => {
        setFieldTypes(res);
      })
      .catch((err) => {
        console.log(err);
      });

    getForm();
  }, []);

  useEffect(() => {
    const field = Array.from(formsFields).find((field) => field.indexKey);
    if (field) {
      setFieldIndexKey(field);
    } else {
      setFieldIndexKey(null);
    }
  }, [formsFields]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = () => {
    setLoading(true);
    const copyFields = cloneDeep(formsFields);
    Array.from(copyFields).map((field) => {
      delete field.id;
      return field;
    });

    delete fieldIndexKey.id;

    const formattedInfos = {
      clubeId: seuClubeInfos.clubeId,
      clubePushId: seuClubeInfos.id,
      tokenType: fieldIndexKey.fieldName,
      formFields: [...Array.from(copyFields)],
    };

    if (isUpdateForm) {
      formattedInfos.partnerId = seuClubeInfos.partnerId;
      EditPartnerUserForms(formattedInfos)
        .then((res) => {
          toast.success('Formulário alterado com sucesso!');
          setLoading(false);
        })
        .catch((err) => {
          toast.error('Erro ao editar formulário.');
          setLoading(false);
        });
    } else {
      CreatePartnerUserForms(formattedInfos)
        .then((res) => {
          toast.success('Formulário adicionado com sucesso!');
          setLoading(false);
        })
        .catch((err) => {
          toast.error('Erro ao cadastrar formulário.');
          setLoading(false);
        });
    }
  };

  if (loadingForm) {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: '100%',
          height: '100vh',
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <S.Container>
      <ToastContainer autoClose={2500} />

      <AreaHeader style={{ color: '#08bad0', justifyContent: 'end', padding: '1rem' }}>
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>

      <T.TriiboH1>Formulário de cadastro</T.TriiboH1>
      <T.TriiboH3>
        {!fieldIndexKey
          ? 'Para configurar o formulário de cadastro dos membros do seu clube você deve primeiro definir a chave de acesso que será utilizada por eles para acessar o ambiente.'
          : 'Gerencie os campos do formulário de cadastro do seu clube. Os campos listados abaixo serão requeridos durante a fase de registro dos seus membros.'}
      </T.TriiboH3>

      {!fieldIndexKey ? (
        <SelectAccessKey setFormsFields={setFormsFields} formsFields={formsFields} />
      ) : (
        <>
          <FieldsList
            formsFields={formsFields}
            setFormsFields={setFormsFields}
            fieldIndexKey={fieldIndexKey}
            loading={loading}
            handleSubmit={handleSubmit}
            setFieldToEdit={setFieldToEdit}
            setIsModalOpen={setIsModalOpen}
            indexKeyAlreadyExists={indexKeyAlreadyExists}
            fieldTypes={fieldTypes}
          />
        </>
      )}

      {isModalOpen && (
        <ModalAddField
          open={isModalOpen}
          handleClose={handleCloseModal}
          formsFields={formsFields}
          setFormsFields={setFormsFields}
          fieldToEdit={fieldToEdit}
          setFieldToEdit={setFieldToEdit}
          fieldTypes={fieldTypes}
        />
      )}
    </S.Container>
  );
}
