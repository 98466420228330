import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import {
  TextField,
  Typography,
  Snackbar,
  Button,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Grid,
  CircularProgress,
} from "@mui/material";
import { Delete } from "@mui/icons-material";

import Loading from "js/containers/Loading/Loading";

import {
  removeDatabase,
  createDatabase,
} from "js/library/services/DatabaseManager.js";
import { getDataListRestApi } from "js/library/utils/API/restApiFirebase";
import { GoBackHeader } from "./CreateClub/RelationshipClub/GoBackHeader";
import { HeaderTitle } from "./CreateClub/RelationshipClub/HeaderTitle";

class Stamping extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      carimbo: "",
      tag: "",
      stampList: null,

      openSnackbar: false,
    };

    getDataListRestApi("template-carimba-users").then((dataReceived) => {
      let data = [];

      if (dataReceived !== null && dataReceived !== undefined) {
        Object.keys(dataReceived).map(function (item) {
          dataReceived[item].key = item;

          return data.push(dataReceived[item]);
        });
      } else {
        return null;
      }

      this.setState({ stampList: data });
    });

    window.pathname = null;
  }

  //altera os states dos campos, caso o usuário insira informações nos input
  handleChange = (name) => (event) => {
    this.setState({
      [name]: event.target.value,
    });
  };

  handleCloseAlert = () => {
    this.setState({ openSnackbar: false });
  };

  addStamp = (carimbo, tag) => {
    let validation = 0;

    if (this.state.stampList !== null && this.state.stampList !== undefined) {
      this.state.stampList.map(
        (item) =>
          (validation =
            item.carimbo === carimbo && item.tag === tag
              ? validation + 1
              : validation)
      );
    }

    if (validation === 0) {
      const stampInfo = {
        carimbo,
        tag,
      };

      createDatabase("template-carimba-users", stampInfo, null).then(
        (response) => {
          this.setState({ carimbo: "", tag: "", stampList: null });

          getDataListRestApi("template-carimba-users").then((dataReceived) => {
            let data = [];

            Object.keys(dataReceived).map(function (item) {
              dataReceived[item].key = item;

              return data.push(dataReceived[item]);
            });

            this.setState({ stampList: data });
          });
        }
      );
    } else {
      this.setState({ openSnackbar: true });
    }
  };

  removeStamp = (key) => {
    removeDatabase("template-carimba-users", key).then((response) => {
      this.setState({ carimbo: "", tag: "", stampList: null });

      getDataListRestApi("template-carimba-users").then((dataReceived) => {
        let data = [];
        if (dataReceived !== null && dataReceived !== undefined) {
          Object.keys(dataReceived).map(function (item) {
            dataReceived[item].key = item;

            return data.push(dataReceived[item]);
          });
        } else {
          return null;
        }

        this.setState({ stampList: data });
      });
    });
  };

  render = () => {
    if (this.props.loading) {
      return (
        <div className="loading">
          <Loading />
        </div>
      );
    } else {
      if (window.pathname !== null) {
        return <Redirect to={window.pathname} />;
      } else {
        return (
          <div style={{ padding: "4rem 32px" }}>
           <GoBackHeader />
           <HeaderTitle pageInfo='Cadastro de carimbos'/>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                this.addStamp(this.state.carimbo, this.state.tag);
              }}
            >
              <TextField
                style={{ width: "250px" }}
                id="carimbo"
                label="Carimbo"
                placeholder="Keyword que usuário receberá"
                value={this.state.carimbo}
                onChange={this.handleChange("carimbo")}
                margin="normal"
              />

              <TextField
                style={{ marginLeft: "10px", width: "250px" }}
                id="tag"
                label="Tag"
                placeholder="Tag cadastrada na promoção"
                value={this.state.tag}
                onChange={this.handleChange("tag")}
                margin="normal"
              />

              <Button
                type="submit"
                disabled={
                  this.state.carimbo.length === 0 || this.state.tag.length === 0
                }
                style={{
                  fontWeight: "550",
                  color: "white",
                  marginLeft: "10px",
                  marginTop: "25px", 
                  textTransform: "capitalize",
                }}
                variant="contained"
                color="primary"
              >
                Adicionar
              </Button>
            </form>

            <Grid container spacing={5}>
              <Grid item md={6} sm={12}>
                {this.state.stampList === false ? (
                  <div align="center">
                    <CircularProgress style={{ padding: "20px" }} />
                  </div>
                ) : this.state.stampList === null ? (
                  <div>
                    <br />
                    <br />
                    <Typography variant="body2">
                      Nenhum carimbo cadastrado.
                    </Typography>
                  </div>
                ) : (
                  <List>
                    {this.state.stampList.map((item) => (
                      <ListItem key={item.key} role={undefined} dense button>
                        <ListItemText
                          primary={`${item.carimbo} | ${item.tag}`}
                        />
                        <ListItemSecondaryAction>
                          <IconButton
                            onClick={() => this.removeStamp(item.key)}
                            edge="end"
                            aria-label="Comments"
                          >
                            <Delete />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ))}
                  </List>
                )}
              </Grid>
            </Grid>

            <Snackbar
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
              open={this.state.openSnackbar}
              onClose={this.handleCloseAlert}
              ContentProps={{ "aria-describedby": "message-id" }}
              message={<span id="message-id">Carimbo já existe!</span>}
            />
          </div>
        );
      }
    }
  };
}

export default Stamping;
