import React, { useEffect, useState } from 'react';

// STYLES
import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';

// ASSETS/ICONS AND COMPONENTS
import { Checkbox, CircularProgress } from '@mui/material';
import { ToastContainer, toast } from 'react-toastify';
import calendarIcon from '../../../../../styles/assets/communications/calendarPurple.svg';
import { AreaHeader, GoBack } from '../../CreateClub/styles';
import { MdArrowBackIosNew } from 'react-icons/md';

// APIS
import { createCampaign } from 'js/library/utils/API/Campaigns/apiRegisterCampaign';
import { getCampaign } from 'js/library/utils/API/Campaigns/apiGetCampaign';
import { updateCampaign } from 'js/library/utils/API/Campaigns/apiUpdateCampaign';

import { useHistory, useParams } from 'react-router';

export default function RegisterCampaigns() {
  // REACT ROUTER
  const params = useParams();

  // DATA CAMPAIGN
  const [dataCampaign, setDataCampaign] = useState({
    campaignId: '',
    campaignName: '',
    description: '',
  });

  // DATA PERIOD
  const [periods, setPeriods] = useState([]);
  const [startPeriod, setStartPeriod] = useState('');
  const [endPeriod, setEndPeriod] = useState('');
  const [addPeriodIsTrue, setAddPeriodIsTrue] = useState(false);
  const [editPeriodsIsTrue, setEditPeriodIsTrue] = useState(false);
  const [periodId, setPeriodId] = useState('');

  // DATA OWNER
  const [isUpdatePath, setIsUpdatePath] = useState(false);
  const [owner, setOwner] = useState(params?.id ? params?.id : '');

  // LOADINGS
  const [loadingPage, setLoadingPage] = useState(false);
  const [loading, setLoading] = useState(false);

  // DATA MODULES
  const initialModulesState = {
    campaignLp: false,
    customLogin: false,
    userTarget: false,
    vouchersSeed: false,
    partnerCode: false,
    partnerFields: false,
  };
  const [modules, setModules] = useState(initialModulesState);

  const handleCheckboxChange = (moduleName) => {
    setModules((prevModules) => ({
      ...prevModules,
      [moduleName]: !prevModules[moduleName],
    }));
  };

  const formattedInitialDate = (timestamp) => {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  useEffect(() => {
    setLoadingPage(true);
    const url = window.location.href;

    const updatePath = url?.split('/').includes('atualizarCampanha');

    if (updatePath) {
      setIsUpdatePath(true);
      const campaignId = url?.split('/')[7].split('=')[1];

      getCampaign(campaignId)
        .then((response) => {
          setStartPeriod(formattedInitialDate(response.periods[0].startAt));
          setEndPeriod(formattedInitialDate(response.periods[0].endsAt));
          setOwner(response.owner);
          setDataCampaign({
            ...dataCampaign,
            campaignId: campaignId,
            campaignName: response.name,
            description: response.description,
          });

          const updatedModules = { ...modules };
          if (response.modules && Array.isArray(response.modules)) {
            response.modules.forEach((moduleName) => {
              // (hasOwnProperty) verifica se o modulo existe a partir do nome e retorna um boolean
              if (updatedModules.hasOwnProperty(moduleName)) {
                updatedModules[moduleName] = true;
              }
            });
          }

          setModules(updatedModules);
          setPeriods(Array.isArray(response.periods) ? response.periods : []);
          setTimeout(() => {
            setLoadingPage(false);
          }, 500);
        })
        .catch((error) => {
          setLoadingPage(false);
          console.log(error);
        });
    } else {
      setLoadingPage(false);
    }
  }, []);

  const handleAddPeriod = () => {
    setEditPeriodIsTrue(false);

    if (!startPeriod) {
      toast.warn('Informe uma data de início.');
      return;
    }

    if (!endPeriod) {
      toast.warn('Informe uma data de fim');
      return;
    }

    const startAt = new Date(startPeriod).getTime();

    const endsAt = new Date(endPeriod).getTime();

    if (endsAt < startAt) {
      toast.warn('Data de início é maior que a de término.');
      return;
    }

    const period = {
      endsAt: endsAt,
      id: periods.length === 0 ? 1 : periods[periods.length - 1].id + 1,
      startAt: startAt,
    };

    setPeriods([...periods, period]);
    setStartPeriod('');
    setEndPeriod('');
    setAddPeriodIsTrue(false);
  };

  const handleSetPeriodToEdit = (id) => {
    setAddPeriodIsTrue(false);

    const periodFiltered = periods.find((period) => period.id === id);

    if (periodFiltered) {
      const startDate = formattedInitialDate(periodFiltered.startAt);
      const endDate = formattedInitialDate(periodFiltered.endsAt);

      setStartPeriod(startDate);
      setEndPeriod(endDate);
      setPeriodId(id);
      setEditPeriodIsTrue(true);
    }
  };

  const handleUpdatePeriod = (id) => {
    if (!startPeriod) {
      toast.warn('Informe uma data de início.');
      return;
    }

    if (!endPeriod) {
      toast.warn('Informe uma data de fim');
      return;
    }

    const startAt = new Date(startPeriod).getTime() + 1000 * 60 * 60 * 3;
    const endsAt = new Date(endPeriod).getTime() + 1000 * 60 * 60 * 3;

    if (endsAt < startAt) {
      toast.warn('Data de início é maior que a de término.');
      return;
    }

    const period = {
      endsAt: endsAt,
      id: id,
      startAt: startAt,
    };

    const deletePeriod = periods.filter((period) => period.id !== id);

    const newPeriods = [...deletePeriod, { ...period }];
    const orderPeriods = newPeriods.sort((period1, period2) => period1.id - period2.id);
    setPeriods(orderPeriods);
    setEditPeriodIsTrue(false);
    toast.success('Periodo atualizado com sucesso!');
  };

  const handleDeletePeriod = (id) => {
    const newPeriods = periods.filter((period) => period.id !== id);
    const orderPeriods = newPeriods.sort((period1, period2) => period1.id - period2.id);
    setPeriods(orderPeriods);
    setEditPeriodIsTrue(false);
    setStartPeriod('');
    setEndPeriod('');
    toast.success('Periodo deletado com sucesso!');
  };

  const handleSubmit = () => {
    const regex = /^[a-zA-Z0-9_-]*$/;
    if (!regex.test(dataCampaign.campaignId)) {
      toast.warn(
        'O campo Id da campanha só aceita caracteres normais, números, traço ou underline dentro.'
      );
      return;
    }

    if (
      dataCampaign.campaignId &&
      dataCampaign.campaignName &&
      dataCampaign.description &&
      owner &&
      periods.length > 0
    ) {
      setLoading(true);

      const selectedModules = Object.keys(modules).filter((moduleName) => modules[moduleName]);

      const url = window.location.href;

      const urlCampaignId = url.split('/')[7]?.split('=')[1];

      const data = {
        description: dataCampaign.description,
        name: dataCampaign.campaignName,
        owner: owner,
        periods: periods,
        modules: selectedModules,
      };

      if (isUpdatePath) {
        if (!urlCampaignId) {
          toast.error('Erro ao obter o ID da campanha para atualização.');
          setLoading(false);
          return;
        }
        updateCampaign(data, urlCampaignId)
          .then(() => {
            setDataCampaign({
              description: '',
              campaignName: '',
              campaignId: '',
            });
            setOwner('');
            setPeriods([]);
            setPeriodId('');
            setLoading(false);
            setModules(initialModulesState);
            toast.success('Campanha atualizada com sucesso!');
            setTimeout(() => {
              window.history.back();
            }, 1000);
          })
          .catch((error) => {
            toast.error('Erro ao atualizar campanha.');
            setLoading(false);
            console.error(error);
          });
      } else {
        if (!dataCampaign.campaignId) {
          toast.error('Erro ao obter o ID da campanha para criação.');
          setLoading(false);
          return;
        }
        createCampaign(data, dataCampaign.campaignId)
          .then(() => {
            setDataCampaign({
              description: '',
              campaignName: '',
              campaignId: '',
            });
            setOwner('');
            setPeriods([]);
            setPeriodId('');
            setLoading(false);
            setModules(initialModulesState);
            toast.success('Campanha cadastrada com sucesso!');
            setTimeout(() => {
              window.history.back();
            }, 1000);
          })
          .catch((error) => {
            toast.error('Erro ao cadastrar campanha.');
            setLoading(false);
            console.error(error);
          });
      }
    } else {
      toast.warn('Preencha todos os campos.');
    }
  };

  if (loadingPage) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size="50px" />
      </div>
    );
  }

  return (
    <div style={{ padding: '4rem' }}>
      <AreaHeader
        style={{
          color: '#08bad0',
          justifyContent: 'end',
          padding: '1rem',
          marginLeft: '3rem',
        }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <ToastContainer autoClose={6000} />
      <S.Container>
        <D.TriiboH1
          style={{
            marginTop: '-3rem',
          }}
        >
          {isUpdatePath ? 'Atualizar campanha' : 'Cadastrar Campanha'}
        </D.TriiboH1>
        <S.Cols>
          <S.Col>
            <D.TriiboH4>
              <strong>Id da Campanha</strong>
            </D.TriiboH4>
            <S.Input
              disabled={isUpdatePath}
              value={dataCampaign.campaignId}
              onChange={(e) => {
                const value = e.target.value;

                // Remove acentos e caracteres especiais
                const sanitizedValue = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

                // Substitui espaços por "_"
                const formattedValue = sanitizedValue.replace(/\s/g, '_').toLowerCase();

                setDataCampaign((prevState) => ({
                  ...prevState,
                  campaignId: formattedValue,
                }));
              }}
            />

            <D.TriiboH4>
              <strong>Nome da Campanha</strong>
            </D.TriiboH4>
            <S.Input
              value={dataCampaign.campaignName}
              onChange={(e) =>
                setDataCampaign((prevState) => ({ ...prevState, campaignName: e.target.value }))
              }
            />

            {!params?.id && (
              <>
                <D.TriiboH4>
                  <strong>Owner</strong>
                </D.TriiboH4>
                <S.Input value={owner} onChange={(e) => setOwner(e.target.value)} />
              </>
            )}
            <D.TriiboH4>
              <strong>Descrição</strong>
            </D.TriiboH4>
            <S.TextCountContainer>
              <S.TextArea
                placeholder="Escreva aqui"
                maxLength="1400"
                value={dataCampaign.description}
                onChange={(e) =>
                  setDataCampaign((prevState) => ({
                    ...prevState,
                    description: e.target.value,
                  }))
                }
              />

              <S.TextCounter>{dataCampaign.description.length}/1400</S.TextCounter>
            </S.TextCountContainer>
          </S.Col>
          <S.Col>
            <S.PeriodsHeader>
              <D.TriiboH4>
                <strong>Periodos</strong>
              </D.TriiboH4>
              <S.Button
                onClick={() => {
                  setAddPeriodIsTrue(true);
                  setEditPeriodIsTrue(false);
                }}
              >
                <D.TriiboH5>+ Adicionar</D.TriiboH5>
              </S.Button>
            </S.PeriodsHeader>

            {periods.length > 0 && (
              <S.Periods>
                {periods.map((period, index) => (
                  <S.PeriodItem key={index} onClick={() => handleSetPeriodToEdit(period.id)}>
                    {index + 1}
                  </S.PeriodItem>
                ))}
              </S.Periods>
            )}

            {addPeriodIsTrue && (
              <S.AddPeriod>
                <S.DatesContainer>
                  <S.Date>
                    <D.TriiboH4>
                      <strong>Data de Início</strong>
                    </D.TriiboH4>
                    <S.SelectDateContainer>
                      <img src={calendarIcon} alt="" />
                      <S.InputDate
                        type="datetime-local"
                        value={startPeriod}
                        onChange={(e) => setStartPeriod(e.target.value)}
                      />
                    </S.SelectDateContainer>
                  </S.Date>

                  <S.Date>
                    <D.TriiboH4>
                      <strong>Data de Término</strong>
                    </D.TriiboH4>
                    <S.SelectDateContainer>
                      <img src={calendarIcon} alt="" />
                      <S.InputDate
                        type="datetime-local"
                        value={endPeriod}
                        onChange={(e) => setEndPeriod(e.target.value)}
                      />
                    </S.SelectDateContainer>
                  </S.Date>
                </S.DatesContainer>

                <S.ButtonsContainer>
                  <S.Button w={'100%'} type={'cancel'} onClick={() => setAddPeriodIsTrue(false)}>
                    <D.TriiboH5>Cancelar</D.TriiboH5>
                  </S.Button>
                  <S.Button w={'100%'} onClick={handleAddPeriod}>
                    <D.TriiboH5>Salvar</D.TriiboH5>
                  </S.Button>
                </S.ButtonsContainer>
              </S.AddPeriod>
            )}

            {editPeriodsIsTrue && (
              <S.AddPeriod>
                <S.DatesContainer>
                  <S.Date>
                    <D.TriiboH4>
                      <strong>Data de Início</strong>
                    </D.TriiboH4>
                    <S.SelectDateContainer>
                      <img src={calendarIcon} alt="" />
                      <S.InputDate
                        type="datetime-local"
                        value={startPeriod}
                        onChange={(e) => setStartPeriod(e.target.value)}
                      />
                    </S.SelectDateContainer>
                  </S.Date>

                  <S.Date>
                    <D.TriiboH4>
                      <strong>Data de Término</strong>
                    </D.TriiboH4>
                    <S.SelectDateContainer>
                      <img src={calendarIcon} alt="" />
                      <S.InputDate
                        type="datetime-local"
                        value={endPeriod}
                        onChange={(e) => setEndPeriod(e.target.value)}
                      />
                    </S.SelectDateContainer>
                  </S.Date>
                </S.DatesContainer>

                <S.ButtonsContainer>
                  <S.Button w={'100%'} type={'cancel'} onClick={() => handleDeletePeriod(periodId)}>
                    <D.TriiboH5>Deletar</D.TriiboH5>
                  </S.Button>
                  <S.Button w={'100%'} onClick={() => handleUpdatePeriod(periodId)}>
                    <D.TriiboH5>Salvar</D.TriiboH5>
                  </S.Button>
                </S.ButtonsContainer>
              </S.AddPeriod>
            )}
          </S.Col>
          <S.Col>
            <D.TriiboH4>
              <strong>Módulos</strong>
            </D.TriiboH4>
            <S.Module>
              <D.TriiboH5>Voucher pré gerados</D.TriiboH5>
              <Checkbox
                name="vouchersSeed"
                checked={modules.vouchersSeed}
                onChange={() => handleCheckboxChange('vouchersSeed')}
              />
            </S.Module>

            {/* <S.Module>
              <D.TriiboH5>LP de campanha</D.TriiboH5>
              <Checkbox
                name="campaignLp"
                checked={modules.campaignLp}
                onChange={() => handleCheckboxChange('campaignLp')}
              />
            </S.Module>
            <S.Module>
              <D.TriiboH5>Login customizado</D.TriiboH5>
              <Checkbox
                name="customLogin"
                checked={modules.customLogin}
                onChange={() => handleCheckboxChange('customLogin')}
              />
            </S.Module>
            <S.Module>
              <D.TriiboH5>Lista de usuários (Target)</D.TriiboH5>
              <Checkbox
                name="userTarget"
                checked={modules.userTarget}
                onChange={() => handleCheckboxChange('userTarget')}
              />
            </S.Module>
            
            <S.Module>
              <D.TriiboH5>Códigos do parceiro</D.TriiboH5>
              <Checkbox
                name="partnerCode"
                checked={modules.partnerCode}
                onChange={() => handleCheckboxChange('partnerCode')}
              />
            </S.Module>
            <S.Module>
              <D.TriiboH5>Campos do parceiro na tabela</D.TriiboH5>
              <Checkbox
                name="partnerFields"
                checked={modules.partnerFields}
                onChange={() => handleCheckboxChange('partnerFields')}
              />
            </S.Module> */}
          </S.Col>
        </S.Cols>

        <S.SubmitButtonContainer>
          <S.Button w={'232px'} onClick={handleSubmit} disabled={loading}>
            {loading && <CircularProgress style={{ marginRight: '1rem' }} size="24px" />}
            <D.TriiboH5>Cadastrar</D.TriiboH5>
          </S.Button>
        </S.SubmitButtonContainer>
      </S.Container>
    </div>
  );
}
