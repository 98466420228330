import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { DebounceInput } from 'react-debounce-input';

import { connect } from 'react-redux';
import {
  Button,
  Typography,
  Grid,
  Menu,
  MenuItem,
  InputLabel,
  Checkbox,
  Switch,
  CircularProgress,
} from '@mui/material';

import { Search, Add } from '@mui/icons-material';
import { duplicatePromotionAction } from 'js/core/actions/duplicatePromotionAction';
import { downloadImage } from 'js/library/services/StorageManager';
import placeholder from 'styles/assets/placeholder/placeholder_thumbnail.png';
import DataTableStoreExternal from 'js/containers/DataTable/DataTableStoreExternal';
import VoucherSendExternal from 'js/components/Store/VoucherSend/VoucherSendExternal';
import { isAdminPage, getUserInfo } from 'js/library/utils/helpers';
import ModalSelectClients from './VoucherSend/ModalSelectClients';
import { HeaderTitle } from '../Configurations/CreateClub/RelationshipClub/HeaderTitle';
import { GoBackHeader } from '../Configurations/CreateClub/RelationshipClub/GoBackHeader';
import { getStoreByEstablishment } from 'js/library/utils/API/Store/apiGetStoreByEstablishment';

class StoreListExternal extends Component {
  constructor(props) {
    super(props);
    const userAdmin = getUserInfo().admin;

    localStorage.setItem('fromWherePromotion', 'estabelecimento-promo');
    localStorage.removeItem('statusPromotion');
    delete window.promoListOrganized;

    if (window?.promoListOrganized?.type === 'promo-edit') {
      window.promoListOrganized = {
        ...window.promoListOrganized,
        type: null,
      };
    } else {
      delete window.promoListOrganized;
    }

    this.state = {
      establishmentId: props.match.params.id,
      photoTime: true,
      dataEstabelecimento: props.location.state,
      rows: [
        {
          id: 'thumbnail',
          numeric: 'false',
          disablePadding: true,
          label: 'Thumbnail',
        },
        {
          id: 'promocao',
          numeric: 'false',
          disablePadding: false,
          label: 'Nome da Promoção',
        },
        {
          id: 'type',
          numeric: 'false',
          disablePadding: true,
          label: 'Tipo da Promoção',
        },
        {
          id: 'active',
          numeric: 'false',
          disablePadding: false,
          label: 'Status',
        },
        { id: 'acao', numeric: 'false', disablePadding: false, label: 'Ação' },
      ],
      promotionList: window?.promoListOrganized
        ? window?.promoListOrganized?.promotionListActive
        : [],
      copyPromotionList: window?.promoListOrganized
        ? window?.promoListOrganized?.originalPromotionList
        : [],
      newFilteredPromotionList: window?.promoListOrganized
        ? window?.promoListOrganized?.newFilteredPromotionList
        : [],
      pesquisa: '',
      loading: true,
      pathName: '',
      openSendVoucherModal: false,
      n: null,
      isChecked: false,
      isDisableCheck: window?.promoListOrganized
        ? window?.promoListOrganized?.isDisableCheck
        : false,
      loadingList: window?.promoListOrganized ? window?.promoListOrganized?.loadingList : true,
      page: window?.promoListOrganized?.page ? window?.promoListOrganized?.page : 0,
      modalSelectClientsIsOpen: false,
      adminToggle: false, // toggle que permite super admin escolher qual a página que quer visitar
      loadingPromotionList: true,
      isUserAdmin: userAdmin,
    };

    getStoreByEstablishment(this.state.establishmentId)
      .then((dataReceived) => {
        const listActivePromotions = [];
        const copyPromotionList = [];

        dataReceived.forEach((data) => {
          const [, item] = Object.entries(data)[0];
          item.storeInfo.key = item.storeInfo.id;
          item.storeInfo['downloadedThumb'] = placeholder;

          if (!item.storeInfo?.id) {
            const establishmentIdKey = Object.keys(data)[0];
            item.storeInfo.id = establishmentIdKey;
          }

          //incluí os dados de aprovação no objeto da promoção
          if (item.storeApprovals) {
            const firstApproval = Object.values(item.storeApprovals)[0];
            item.storeInfo.aprovacao = firstApproval;
          }

          //incluí os dados de edição no objeto da promoção
          if (item.storeEdits) {
            const dataStoreEdits = Object.values(item.storeEdits)[0];
            item.storeInfo.edicao = dataStoreEdits;
          }

          const isActive =
            item.storeInfo.isPublished === true &&
            ((item.storeInfo.aprovacao && item.storeInfo.aprovacao.status === 'aprovado') ||
              !item.storeInfo.aprovacao) &&
            item.storeInfo.enableDate <= Date.now() &&
            item.storeInfo.disableDate >= Date.now();

          if (isActive) {
            listActivePromotions.push(item.storeInfo);
          }

          copyPromotionList.push(item.storeInfo);
        });

        this.setState({
          isDisableCheck: false,
          promotionList: listActivePromotions,
          copyPromotionList: copyPromotionList,
          newFilteredPromotionList: listActivePromotions,
          loadingList: false,
        });
      })
      .catch(() => {})
      .finally(() => {
        this.setState({ loadingPromotionList: false });
      });
  }

  downloadImage = (fotoThumb, key, downloadedThumb, type) => {
    const { promotionList, copyPromotionList } = this.state;
    setTimeout(() => {
      if (fotoThumb !== undefined && downloadedThumb === placeholder) {
        const image = fotoThumb.split('/');

        const indexPromotionList = promotionList.findIndex((x) => x.key === key);

        const indexCopyPromotionList = copyPromotionList.findIndex((x) => x.key === key);

        if (type === 'product') {
          downloadImage(image[0], image[1])
            .then((downloadedImage) => {
              promotionList[indexPromotionList].downloadedThumb = downloadedImage;
              copyPromotionList[indexCopyPromotionList].downloadedThumb = downloadedImage;

              this.setState({
                promotionList,
                copyPromotionList,
              });
            })
            .catch(() => {
              // console.log("Foto não encontrada:", image[1]);
            });
        } else {
          downloadImage('promocao', fotoThumb)
            .then((downloadedImage) => {
              promotionList[indexPromotionList].downloadedThumb = downloadedImage;
              copyPromotionList[indexCopyPromotionList].downloadedThumb = downloadedImage;

              this.setState({
                promotionList,
                copyPromotionList,
              });
            })
            .catch((error) => {
              // console.log("ERROOO ", error);
            });
        }
      }
    }, 2000);
  };

  componentDidMount() {
    this.loadOnTop();
  }

  loadOnTop = () => {
    window.scrollTo(0, 0);
  };

  toggleSendVoucherModal = (open, n = null) => {
    this.setState({ n, openSendVoucherModal: open });
  };

  duplicatePromotion = (storeKey) => {
    this.props.duplicatePromotionComponent(storeKey);
  };

  closeModalSelectClients = () => {
    this.setState({ modalSelectClientsIsOpen: false });
  };

  openModalSelectClients = () => {
    this.setState({ modalSelectClientsIsOpen: true });
  };

  handleChangeCheckbox = (e) => {
    this.setState({ adminToggle: e.target.checked });
  };

  handleChange = (event) => {
    const { value } = event.target;

    this.setState({ loading: true });

    const { promotionList, copyPromotionList, isChecked, newFilteredPromotionList } = this.state;

    // let data = [...promotionList]
    let dataPesquisa = [];

    if (value.length > 0) {
      for (const key in promotionList) {
        if (promotionList[key].title !== undefined) {
          if (
            promotionList[key].title
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .replace(/'/g, '')
              .trim()
              .includes(
                value
                  .toLowerCase()
                  .normalize('NFD')
                  .replace(/[\u0300-\u036f]/g, '')
                  .replace(/'/g, '')
                  .trim()
              )
          ) {
            dataPesquisa.push(promotionList[key]);
          }
        }
      }

      let dataSort = dataPesquisa.sort(function (a, b) {
        return a.title.length - b.title.length;
      });

      this.setState({ promotionList: dataSort });
    } else {
      if (window?.promoListOrganized) {
        this.setState({
          promotionList: !isChecked ? copyPromotionList : newFilteredPromotionList,
        });
      } else {
        this.setState({
          promotionList: !isChecked ? newFilteredPromotionList : copyPromotionList,
        });
      }
    }
    this.setState({ pesquisa: value, loading: false });
  };

  filteredInactivePromotion = (isChecked) => {
    const { copyPromotionList, newFilteredPromotionList } = this.state;

    if (isChecked) {
      this.setState({
        promotionList: copyPromotionList,
      });
    } else {
      this.setState({ promotionList: newFilteredPromotionList });
    }
  };

  resetPagination = (isChecked) => {
    const { copyPromotionList } = this.state;

    if (isChecked) {
      const originaList = window?.promoListOrganized?.originalPromotionList;

      const filteredListPromo = originaList.filter((promo) => {
        const isActive =
          promo.isPublished === true &&
          promo.enableDate <= Date.now() &&
          promo.disableDate >= Date.now();
        const isAwaiting = promo.aprovacao?.status === 'aberto';

        if (isActive || isAwaiting) {
          return promo;
        }
      });

      window.promoListOrganized = {
        ...window.promoListOrganized,
        promotionListActive: filteredListPromo,
        newFilteredPromotionList: filteredListPromo,
        page: 0,
      };

      this.setState({
        promotionList: filteredListPromo,
        newFilteredPromotionList: filteredListPromo,
        page: 0,
      });

      return;
    }

    window.promoListOrganized = {
      ...window.promoListOrganized,
      page: 0,
    };

    this.setState({
      page: 0,
      promotionList: copyPromotionList,
    });
  };

  handleChecked = (isChecked) => {
    if (window?.promoListOrganized) {
      this.resetPagination(isChecked);
    }

    this.setState({
      isChecked: !isChecked,
      page: 0,
    });

    if (!window?.promoListOrganized) {
      this.filteredInactivePromotion(!isChecked);
    }
  };

  handleChangePage = (_, page) => {
    this.setState({
      page,
    });
  };

  render = () => {
    const { promotionList, copyPromotionList, isChecked, isDisableCheck } = this.state;

    return (
      <div
        style={{
          maxHeight: '100%',
          padding: '4rem 32px',
          paddingBottom: '70px',
          maxWidth: '100%',
        }}
      >
        <GoBackHeader />
        <HeaderTitle pageInfo="Ofertas do Estabelecimento" />
        <Grid item md={6} xs={6} />

        <Grid item sm={6} md={12} xs={12}>
          {isAdminPage() && this.state.isUserAdmin ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <span>Visão usuário</span>
              <Switch
                checked={this.state.adminToggle}
                onChange={(e) => {
                  this.handleChangeCheckbox(e);
                }}
              />
              <span>Visão admin</span>
            </div>
          ) : (
            <></>
          )}
          <div className="position-btn2">
            <Button
              startIcon={<Add />}
              variant="contained"
              color="primary"
              className="style-botao-list"
              onClick={(event) => this.setState({ anchorEl: event.currentTarget })}
              style={{
                height: '2.2em',
                fontWeight: '550',
                width: '100%',
                textDecoration: 'none',
                color: 'white',
                textTransform: 'capitalize',
              }}
            >
              Adicionar Promoção
            </Button>
          </div>
        </Grid>

        <Grid container>
          <Grid item sm={12} xs={12} xl={12}>
            <div style={{ position: 'relative', marginTop: '15px' }}>
              <InputLabel
                style={{
                  fontSize: '13px',
                  marginBottom: '15px',
                  focusVisible: 'none',
                }}
              >
                Pesquise sua promoção:
              </InputLabel>
              <Search style={{ position: 'absolute', bottom: '14px', left: '-1px' }} />
              <DebounceInput
                style={{
                  fontSize: '16px',
                  borderLeft: 'none',
                  borderRight: 'none',
                  borderTop: 'none',
                  width: '100%',
                  marginBottom: '10px',
                  boxShadow: '0 0 0 0',
                  outline: '0',
                  paddingLeft: '26px',
                  paddingBottom: '5px',
                  borderBottom: '1px solid #6b6b6b',
                }}
                debounceTimeout={200}
                value={this.state.pesquisa}
                onChange={this.handleChange}
                disabled={this.state.loadingList}
              />
            </div>
            <div style={{ margin: '-7px -10px -5px', fontSize: '15px' }}>
              <Checkbox
                checked={isChecked}
                onChange={() => this.handleChecked(isChecked)}
                value={isChecked}
                disableRipple
                disabled={isDisableCheck}
                color="default"
                inputProps={{ 'aria-label': 'decorative checkbox' }}
              />
              Mostrar promoções inativas
            </div>
          </Grid>
        </Grid>
        <div>
          {this.state.loadingPromotionList ? (
            <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
              <CircularProgress size="50px" />
            </div>
          ) : promotionList.length !== 0 || window?.promoListOrganized?.promotionListActive ? (
            <DataTableStoreExternal
              style={{ marginBottom: '50px' }}
              rows={this.state.rows}
              tableNode={'Store'}
              pathName={this.state.pathName}
              dataEstabelecimento={this.props.location.state}
              establishmentId={this.state.establishmentId}
              toggleSendVoucherModal={this.toggleSendVoucherModal}
              duplicatePromotion={this.duplicatePromotion}
              promotionList={promotionList}
              copyPromotionList={copyPromotionList}
              newFilteredPromotionList={this.state.newFilteredPromotionList}
              downloadImage={this.downloadImage}
              isDisableCheck={this.state.isDisableCheck}
              loadingList={this.state.loadingList}
              isChecked={this.state.isChecked}
              handleChangePage={this.handleChangePage}
              page={this.state.page}
              adminToggle={this.state.adminToggle}
            />
          ) : (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '60vh',
              }}
            >
              <Typography variant="h5">Não há promoções ativas</Typography>
            </div>
          )}
          <br />
          <br />
          <br />
        </div>

        {this.state.openSendVoucherModal && (
          <VoucherSendExternal
            location={{
              state: this.state.n,
              dataEstabelecimento: this.state.dataEstabelecimento,
            }}
            toggleSendVoucherModal={this.toggleSendVoucherModal}
            openSendVoucherModal={this.state.openSendVoucherModal}
            openModalSelectClients={this.openModalSelectClients}
          />
        )}

        <ModalSelectClients
          modalSelectClientsIsOpen={this.state.modalSelectClientsIsOpen}
          closeModalSelectClients={this.closeModalSelectClients}
          toggleSendVoucherModal={this.toggleSendVoucherModal}
          location={{
            state: this.state.n,
            dataEstabelecimento: this.state.dataEstabelecimento,
          }}
        />

        {/* Menu de promoções */}
        <Menu
          id="simple-menu"
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={() => this.setState({ anchorEl: false })}
        >
          <MenuItem>
            <Link
              to={{
                pathname:
                  isAdminPage() && this.state.adminToggle
                    ? '/admin/estabelecimento/' +
                      this.state.establishmentId +
                      '/ofertas/promocao/add'
                    : '/estabelecimento/' + this.state.establishmentId + '/promocao/adicionar',
                state: { dataEstabelecimento: this.state.dataEstabelecimento },
              }}
              onClick={() => localStorage.removeItem('fromWherePromotion')}
              style={{ color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' }}
            >
              Promoção Simples Customizada
            </Link>
          </MenuItem>

          <MenuItem>
            <Link
              to={{
                pathname:
                  isAdminPage() && this.state.adminToggle
                    ? '/admin/estabelecimento/' +
                      this.state.establishmentId +
                      '/ofertas/promocaoAgrupada/add'
                    : '/estabelecimento/' +
                      this.state.establishmentId +
                      '/promocaofidelidade/adicionar',
                state: { dataEstabelecimento: this.state.dataEstabelecimento },
              }}
              onClick={() => localStorage.removeItem('fromWherePromotion')}
              style={{ color: 'rgba(0, 0, 0, 0.87)', textDecoration: 'none' }}
            >
              Promoção de Fidelidade Customizada
            </Link>
          </MenuItem>
          {/* <MenuItem>
            <Link
              to={{
                pathname: isAdminPage()
                  ? "/admin/estabelecimento/" +
                    (this.state.dataEstabelecimento.key
                      ? this.state.dataEstabelecimento.key
                      : this.state.dataEstabelecimento.id) +
                    "/promotions/simple"
                  : "/estabelecimento/" +
                    (this.state.dataEstabelecimento.key
                      ? this.state.dataEstabelecimento.key
                      : this.state.dataEstabelecimento.id) +
                    "/promotions/simple",
                state: {
                  dataEstabelecimento: this.state.dataEstabelecimento,
                  type: "simple",
                },
              }}
              style={{ color: "rgba(0, 0, 0, 0.87)", textDecoration: "none" }}
            >
              Promoção Simples
            </Link>
          </MenuItem> */}
          {/* <MenuItem>
            <Link
              to={{
                pathname: isAdminPage()
                  ? "/admin/estabelecimento/" +
                    (this.state.dataEstabelecimento.key
                      ? this.state.dataEstabelecimento.key
                      : this.state.dataEstabelecimento.id) +
                    "/promotions/loyalty"
                  : "/estabelecimento/" +
                    (this.state.dataEstabelecimento.key
                      ? this.state.dataEstabelecimento.key
                      : this.state.dataEstabelecimento.id) +
                    "/promotions/loyalty",
                state: {
                  dataEstabelecimento: this.state.dataEstabelecimento,
                  type: "loyalty",
                },
              }}
              style={{ color: "rgba(0, 0, 0, 0.87)", textDecoration: "none" }}
            >
              Promoção de Fidelidade
            </Link>
          </MenuItem> */}
          {isAdminPage() && this.state.adminToggle && (
            <MenuItem>
              <Link
                to={{
                  pathname: isAdminPage()
                    ? '/admin/estabelecimento/' +
                      this.state.establishmentId +
                      '/ofertas/empresasParceiras/add'
                    : '/estabelecimento/' +
                      this.state.establishmentId +
                      '/promocaoexterna/adicionar',
                  state: {
                    dataEstabelecimento: this.state.dataEstabelecimento,
                  },
                }}
                onClick={() => localStorage.removeItem('fromWherePromotion')}
                style={{
                  color: 'rgba(0, 0, 0, 0.87)',
                  textDecoration: 'none',
                }}
              >
                Promoção de Link Externo
              </Link>
            </MenuItem>
          )}
        </Menu>
      </div>
    );
  };
}

const mapStateToProps = (state) => {
  return {
    duplicate: {
      loading: state.duplicatePromotionComponent.loading,
      success: state.duplicatePromotionComponent.success,
      error: state.duplicatePromotionComponent.error,
    },
  };
};

const mapDispatchToProps = (dispatch) => ({
  duplicatePromotionComponent: (storeKey) => duplicatePromotionAction(dispatch, storeKey),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreListExternal);

