import React, { useState, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import {
  Grid,
  Typography,
  Button,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  Snackbar,
  FormControlLabel,
  FormGroup,
  Checkbox,
  TextField,
  Switch,
  CircularProgress,
  Card,
  CardContent,
} from '@mui/material';
import { RegisterUsersBatch } from '../../library/utils/API/apiRegisterUsersBatch';
import { getStorePlaces_v2 } from 'js/library/utils/API/getStorePlaces_v2';
import { Autocomplete } from '@mui/lab';
import { debounce } from 'lodash';
import readXlsxFile from 'read-excel-file';
import exampleExcelImg from 'styles/assets/estabelecimentos/listClientsExample.png';
import { toast, ToastContainer } from 'react-toastify';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';

const Users = () => {
  const [clientList, setClientList] = useState([]);
  const [snackBarMessage, setSnackBarMessage] = useState('');
  const [message, setMessage] = useState('');
  const [loadingRegister, setLoadingRegister] = useState(false);
  const [establishmentList, setEstablishmentList] = useState([]);
  const [enableSwitch, setEnableSwitch] = useState(false);
  const [openSelect, setOpenSelect] = useState('');
  const [textOpen, setTextOpen] = useState('');
  const [nameValue, setNameValue] = useState('');
  const [establishmentName, setEstablishmenName] = useState('');
  const [establishmentId, setEstablishmentId] = useState('');
  const loadingSelect = openSelect && establishmentList.length === 0;

  const fileUploader = useRef(null);

  const handleClick = (event) => {
    const { name, checked } = event.target;
    const optInList = [
      {
        accept: true,
        dateAcceptance: Date.now(),
        optInId: '-Kzj_F6wDcfHkLMO_q4c',
        type: 'Termos de Uso da Triibo',
        version: 1,
      },
      {
        accept: true,
        dateAcceptance: Date.now(),
        optInId: '-KzjMOHvzce-PUYpoWHU',
        type: 'Optin - Termos de consentimento de comunicações opcionais',
        version: 1,
      },
    ];

    if (name === 'allSelect') {
      let selected = clientList.map((user) => {
        return {
          ...user,
          optInList,
          isChecked: checked,
        };
      });
      setClientList(selected);
    } else {
      let selected = clientList.map((user) =>
        user.name === name
          ? {
              ...user,
              optInList,
              isChecked: checked,
            }
          : user
      );
      setClientList(selected);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];

    const fileExt = file.name.split('.').find((item) => item === 'xlsx');

    if (fileExt === 'xlsx' && fileExt !== undefined) {
      const fileXlsx = await readXlsxFile(event.target.files[0]);
      fileXlsx.shift(); // Exclui o header do array (nome, telefone, email, data de nascimento)

      const userInfo = fileXlsx.map((row) => {
        let obj = {};

        if (row[0] !== undefined) {
          obj.name = row[0];
        }

        if (row[1] !== undefined) {
          obj.cellphone = row[1];
        }

        if (row[2] !== undefined) {
          obj.email = row[2];
        }

        if (row[3] !== undefined) {
          obj.birthDate = row[3] ? Date.parse(row[3]) + 10800000 : null;
        }

        return obj;
      });

      const filteredUserInfo = userInfo.filter((row) => row.cellphone !== null);
      // Filter para que venha apenas os usuários que possuem um número de telefone cadastrado.

      if (filteredUserInfo.length === 0) {
        return setSnackBarMessage('O número de telefone é obrigatório!');
      }

      if (filteredUserInfo.length > 70)
        return setSnackBarMessage('Limite máximo de 70 usuários excedido!');

      const validateUserData = filteredUserInfo.map((row) => {
        const startsWithPlus = row.cellphone.toString().includes('+');

        let cellPhone = '';
        !startsWithPlus
          ? (cellPhone = '+' + row.cellphone.toString().replace(/\D/g, ''))
          : (cellPhone = row.cellphone.toString().replace(/\D/g, ''));

        return {
          ...row,
          cellphone: cellPhone,
        };
      });

      const copyValidateUserData = validateUserData;

      validateUserData.map((client, index) => {
        Object.keys(client).map((item) => {
          if (validateUserData[index][item] === null) {
            delete copyValidateUserData[index][item];
          }
        });
      });

      setClientList(copyValidateUserData);
    } else {
      toast.error('Arquivo não é no formato .xlsx');
    }
  };

  const handleChangeLoading = (value) => {
    setLoadingRegister(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // const clientListValid = clientList.filter((e) => {
    //   return e.cellphone.length === 14;
    // });

    try {
      if (enableSwitch) {
        if (establishmentName === '') {
          toast.warn('Digite o nome do estabelecimento!');
          handleChangeLoading(false);
          return;
        }
        RegisterUsersBatch(clientList, establishmentId, establishmentName).then(() => {
          toast.success('Usuários cadastrados com sucesso!');
          handleChangeLoading(false);
          setClientList([]);
        });
      } else {
        RegisterUsersBatch(clientList).then(() => {
          toast.success('Usuários cadastrados com sucesso!');
          handleChangeLoading(false);
          setClientList([]);
        });
      }
    } catch (erro) {
      toast.error('Ops, usuários não foram cadastrados.');
      handleChangeLoading(false);
    } finally {
      handleChangeLoading(false);
    }
  };

  const handleSearchEstab = () => {
    setEnableSwitch(true);
  };

  const handleOpenSelect = () => {
    setOpenSelect(true);
    setTextOpen('Pesquise o estabelecimento');
  };

  const handleCloseSelect = () => {
    setOpenSelect(false);
  };

  const handleSelect = (value) => {
    setNameValue(value);
    setEstablishmenName(value.nome);
    setEstablishmentId(value.id);
  };

  const handleSearch = debounce((event) => {
    setEstablishmenName(event.target.value);
    setTextOpen('Carregando...');

    if (event.target.value.length <= 0) {
      setTextOpen('Pesquise o estabelecimento');
    }

    getStorePlaces_v2(null, 'establishment', 0, 0, event.target.value.trim(), null, null, 0, 10)
      .then((dataReceived) => {
        let data = [];
        Object.keys(dataReceived).map((item) => {
          return data.push(dataReceived[item]);
        });

        const dataEstablishment = data[2].map((item) => item.establishment);

        if (dataEstablishment.length === 0) {
          setTextOpen('Nenhum estabelecimento encontrado');
          setEstablishmentList([]);
          return;
        }

        setEstablishmentList(dataEstablishment);
      })
      .catch(() => {
        setTextOpen('Carregando...');
      });
  }, 100);

  return (
    <div style={{ padding: '4rem 32px', maxWidth: '100%' }}>
      <GoBackHeader />
      <HeaderTitle pageInfo="Cadastrar usuários" />
      <ToastContainer autoClose={6000} />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography variant="subtitle1" style={{ marginTop: 4 }}>
                Carregue um arquivo no formato de xlsx, com o seguinte padrão:
                <br />
                <strong>"Nome | Telefone | E-mail | Nascimento".</strong>
              </Typography>{' '}
              <br />
              <div>
                <FormControlLabel
                  control={
                    <Switch
                      checked={enableSwitch}
                      name="checkedB"
                      color="primary"
                      onChange={() => {
                        handleSearchEstab('checkedB');
                        setEnableSwitch(!enableSwitch);
                      }}
                    />
                  }
                  label="Vincular a algum estabelecimento?"
                />
                <div style={{ maxWidth: '350px' }}>
                  {enableSwitch && (
                    <Autocomplete
                      style={{ width: '100%' }}
                      id="establishmentName"
                      value={nameValue}
                      onChange={(_, newValue) => {
                        if (newValue !== null) {
                          handleSelect(newValue);
                        }
                      }}
                      freeSolo={true}
                      open={openSelect}
                      onOpen={handleOpenSelect}
                      onClose={handleCloseSelect}
                      getOptionSelected={(option, value) => option.nome === value.nome}
                      disableClearable={true}
                      getOptionLabel={(option) => (!option.nome ? '' : option.nome)}
                      options={establishmentList}
                      noOptionsText="Nenhum estabelecimento encontrado"
                      loadingText={textOpen}
                      loading={loadingSelect}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Nome do Estabelecimento"
                          className="input-field"
                          value={establishmentName}
                          onChange={(e) => handleSearch(e)}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: <>{params.InputProps.endAdornment}</>,
                          }}
                        />
                      )}
                    />
                  )}
                </div>
              </div>
              <Typography variant="subtitle1" style={{ fontWeight: 600, marginTop: '22px' }}>
                <img src={exampleExcelImg} alt="Exemplo de envio" />
                <div>Obs: Exemplo de envio, o campo telefone é obrigatório.</div>
              </Typography>{' '}
              <br />
              <div>
                <Button
                  style={{
                    fontWeight: '550',
                    color: 'white',
                    textTransform: 'capitalize',
                  }}
                  variant="contained"
                  color="secondary"
                  type="button"
                  onClick={() => fileUploader.current.click()}
                >
                  Carregar arquivo
                </Button>
                <input
                  type="file"
                  accept=".xlsx"
                  ref={fileUploader}
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                />
              </div>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12}>
          {clientList.length > 0 && (
            <>
              <strong>Usuários Listados: </strong>
              {clientList.length}
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <strong>Nome</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Telefone</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>E-mail</strong>
                    </TableCell>
                    <TableCell align="center">
                      <strong>Data de nascimento</strong>
                    </TableCell>
                    <TableCell align="center">
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              onChange={handleClick}
                              name="allSelect"
                              color="primary"
                              size="small"
                            />
                          }
                          label={
                            <Typography variant="caption">
                              <strong>Selecionar Todos</strong>
                            </Typography>
                          }
                        />
                      </FormGroup>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {clientList.map((client, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">{client.name}</TableCell>
                      <TableCell
                        style={{
                          backgroundColor: `${client.cellphone.length < 14 ? 'red' : '#ffff'}`,
                        }}
                        align="center"
                      >
                        {client.cellphone}
                      </TableCell>
                      <TableCell align="center">{client.email}</TableCell>
                      <TableCell align="center">
                        {client?.birthDate &&
                          new Intl.DateTimeFormat('pt-BR').format(client.birthDate)}
                      </TableCell>
                      <TableCell width={220}>
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox
                                key={index}
                                name={client.name}
                                onChange={handleClick}
                                checked={client?.isChecked || false}
                                color="primary"
                                size="small"
                              />
                            }
                            label={
                              <Typography variant="caption">
                                Aceitar Comunicação e Marketing
                              </Typography>
                            }
                          />
                        </FormGroup>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>

              <div align="center" style={{ marginTop: 15 }}>
                <Button
                  style={{
                    fontWeight: '550',
                    color: loadingRegister ? '#383838' : '#fff',
                    textTransform: 'capitalize',
                    background: loadingRegister && '#dbdbdb',
                  }}
                  variant="contained"
                  color="primary"
                  type="button"
                  onClick={(event) => {
                    handleSubmit(event);
                    setLoadingRegister(true);
                  }}
                  disabled={(enableSwitch && establishmentId === '') || loadingRegister === true}
                >
                  {loadingRegister && (
                    <CircularProgress size="23px" style={{ marginRight: '0.5rem' }} />
                  )}
                  Cadastrar todos
                </Button>
              </div>
            </>
          )}
        </Grid>
      </Grid>

      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={snackBarMessage.length > 0}
        onClose={() => setSnackBarMessage('')}
        message={snackBarMessage}
      />
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={message.length > 0}
        onClose={() => setMessage('')}
        message={message}
      />
    </div>
  );
};

function mapStateToProps(state) {
  return {
    establishmentArray: state.codeInputModel.userInfo.estAdmin,
  };
}

export default compose(connect(mapStateToProps))(Users);

