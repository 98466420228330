import React, { useState } from "react";
import * as S from "../styles";
import { Box } from "@mui/material";
import { EditTextInputModal } from "../EditTextInputModal";
import EditTimesTableModalCuradoria from "./EditTimesTableModalCuradoria";
import EditTimesTableModal from "../../EstablishmentSteps/EditTimesTableModal";
import { ToastContainer } from "react-toastify";
import OpeningHours from "./OpeningHours";
import FormStepOne from "./FormStepOne";
import FormStepTwo from "./FormStepTwo";
import FormStepThree from "./FormStepThree";

const StepOneCuration = ({
  formState,
  newFormState,
  finalFormState,
  setFinalFormState,
  checked,
  setChecked,
}) => {
  const [dataFuncionamentoEdit, setDataFuncionamentoEdit] = useState([]);
  const [editModalText, setEditModalText] = useState(false);
  const [editModal, setEditModal] = useState(false);
  const [editModalTwo, setEditModalTwo] = useState(false);
  const [tagCapture, setTagCapture] = useState("");
  const [input, setInput] = useState("");
  const [valueInputModal, setValueInputModal] = useState({
    antigo: "",
    novo: "",
  });

  const disableDateField = new Date(formState.disableDate);
  const disableDateFieldNew = new Date(newFormState.disableDate);

  const infoOfTheWeek = [
    {
      dia: "Segunda",
      antiga: formState.funcionamento[0],
      nova: newFormState.funcionamentoAntigos[0],
    },
    {
      dia: "Terça",
      antiga: formState.funcionamento[1],
      nova: newFormState.funcionamentoAntigos[1],
    },
    {
      dia: "Quarta",
      antiga: formState.funcionamento[2],
      nova: newFormState.funcionamentoAntigos[2],
    },
    {
      dia: "Quinta",
      antiga: formState.funcionamento[3],
      nova: newFormState.funcionamentoAntigos[3],
    },
    {
      dia: "Sexta",
      antiga: formState.funcionamento[4],
      nova: newFormState.funcionamentoAntigos[4],
    },
    {
      dia: "Sábado",
      antiga: formState.funcionamento[5],
      nova: newFormState.funcionamentoAntigos[5],
    },
    {
      dia: "Domingo",
      antiga: formState.funcionamento[6],
      nova: newFormState.funcionamentoAntigos[6],
    },
  ];

  const handleClickBtnEdit = (e, inputT) => {
    e.preventDefault();
    setInput(inputT);

    switch (inputT) {
      case "nome":
        setValueInputModal({
          ...valueInputModal,
          antigo: formState.nome,
          novo: newFormState.nome,
        });
        break;
      case "descricao":
        setValueInputModal({
          ...valueInputModal,
          antigo: formState.descricao,
          novo: newFormState.descricao,
        });
        break;
      case "Complemento":
        setValueInputModal({
          ...valueInputModal,
          antigo: formState.complemento,
          novo: newFormState.complemento,
        });
        break;
      case "CEP":
        setValueInputModal({
          ...valueInputModal,
          antigo: formState.cep,
          novo: newFormState.cep,
        });
        break;
      case "Rua":
        setValueInputModal({
          ...valueInputModal,
          antigo: formState.logradouro,
          novo: newFormState.logradouro,
        });
        break;
      case "Numero":
        setValueInputModal({
          ...valueInputModal,
          antigo: formState.numero,
          novo: newFormState.numero,
        });
        break;
      case "Bairro":
        setValueInputModal({
          ...valueInputModal,
          antigo: formState.bairro,
          novo: newFormState.bairro,
        });
        break;
      case "Cidade":
        setValueInputModal({
          ...valueInputModal,
          antigo: formState.cidade,
          novo: newFormState.cidade,
        });
        break;
      case "UF":
        setValueInputModal({
          ...valueInputModal,
          antigo: formState.estado,
          novo: newFormState.estado,
        });
        break;
      case "Data":
        setValueInputModal({
          ...valueInputModal,
          antigo:
            ("0" + disableDateField.getDate()).slice(-2) +
            "/" +
            ("0" + (disableDateField.getMonth() + 1)).slice(-2) +
            "/" +
            disableDateField.getFullYear(),
          novo:
            ("0" + disableDateFieldNew.getDate()).slice(-2) +
            "/" +
            ("0" + (disableDateFieldNew.getMonth() + 1)).slice(-2) +
            "/" +
            disableDateFieldNew.getFullYear(),
        });
        break;
      case "email":
        setValueInputModal({
          ...valueInputModal,
          antigo: formState.email,
          novo: newFormState.email,
        });
        break;
      case "site":
        setValueInputModal({
          ...valueInputModal,
          antigo: formState.site,
          novo: newFormState.site,
        });
        break;
      case "Telefone Privado":
        setFinalFormState((prevState) => {
          let newArr = [];
          const remove55 = finalFormState.contatos[0].replace(/\+55/g, "");
          newArr.push(remove55);
          return {
            ...prevState,
            contatos: newArr,
          };
        });

        let newArrEdits = [];
        let newArrInfo = [];
        let contatoEdits = newFormState.contact;
        let contatoInfo = formState.contact;
        const includes55Edits = contatoEdits.includes("+55");
        const includes55Info = contatoInfo.includes("+55");
        const remove55Edits = contatoEdits[0].replace(/\+55/g, "");
        const remove55Info = contatoInfo[0].replace(/\+55/g, "");
        newArrEdits.push(remove55Edits);
        newArrInfo.push(remove55Info);
        setValueInputModal({
          ...valueInputModal,
          antigo: includes55Info ? newArrInfo : formState.contact[0],
          novo: includes55Edits ? newArrEdits : newFormState.contact[0],
        });
        break;
      case "tags":
        setValueInputModal({
          ...valueInputModal,
          antigo: formState.tags,
          novo: newFormState.tags,
        });
        break;
      default:
        break;
    }

    setEditModalText(true);
  };

  return (
    <>
      <ToastContainer autoClose={2500} />
      <EditTimesTableModalCuradoria
        open={editModal}
        setOpen={setEditModal}
        newFormState={newFormState}
        setFinalFormState={setFinalFormState}
        dataFuncionamentoEdit={dataFuncionamentoEdit}
        setDataFuncionamentoEdit={setDataFuncionamentoEdit}
      />
      <EditTimesTableModal
        open={editModalTwo}
        formState={formState}
        setOpen={setEditModalTwo}
        finalFormState={finalFormState}
        setFinalFormState={setFinalFormState}
      />
      <EditTextInputModal
        input={input}
        open={editModalText}
        formState={formState}
        tagCapture={tagCapture}
        setOpen={setEditModalText}
        setTagCapture={setTagCapture}
        finalFormState={finalFormState}
        valueInputModal={valueInputModal}
        setFinalFormState={setFinalFormState}
        setValueInputModal={setValueInputModal}
      />
      <Box sx={{ py: "3.6rem", px: { xs: "1.4rem", md: "6rem" } }}>
        <S.MainContainer>
          <FormStepOne
            formState={formState}
            newFormState={newFormState}
            finalFormState={finalFormState}
            setFinalFormState={setFinalFormState}
            handleClickBtnEdit={handleClickBtnEdit}
          />

          <OpeningHours
            formState={formState}
            newFormState={newFormState}
            setEditModal={setEditModal}
            infoOfTheWeek={infoOfTheWeek}
            finalFormState={finalFormState}
            setEditModalTwo={setEditModalTwo}
            setFinalFormState={setFinalFormState}
            dataFuncionamentoEdit={dataFuncionamentoEdit}
            setDataFuncionamentoEdit={setDataFuncionamentoEdit}
          />

          <FormStepTwo
            checked={checked}
            formState={formState}
            setChecked={setChecked}
            newFormState={newFormState}
            finalFormState={finalFormState}
            setFinalFormState={setFinalFormState}
            handleClickBtnEdit={handleClickBtnEdit}
          />

          <FormStepThree
            formState={formState}
            newFormState={newFormState}
            finalFormState={finalFormState}
            disableDateField={disableDateField}
            setFinalFormState={setFinalFormState}
            handleClickBtnEdit={handleClickBtnEdit}
            disableDateFieldNew={disableDateFieldNew}
          />
        </S.MainContainer>
      </Box>
    </>
  );
};

export default StepOneCuration;
