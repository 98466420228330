import React, { Component } from "react";
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";

import { TextField, Typography, Snackbar, Button, Checkbox, List, ListItem, ListItemText, Grid, CircularProgress, FormControlLabel } from '@mui/material';
import Loading from 'js/containers/Loading/Loading';

import { getDataRedshift } from 'js/library/utils/API/apiRedshift.js';
import { updateBatchActionsAction } from "js/core/actions/batchActionsActions";
import { getUserInfo } from "js/library/utils/helpers";
import { GoBackHeader } from "./CreateClub/RelationshipClub/GoBackHeader";
import { HeaderTitle } from "./CreateClub/RelationshipClub/HeaderTitle";

class BatchActions extends Component {

	constructor(props, context) {
		super(props, context);

		this.state = {
			queryId: props.location.state.queryId === undefined ? null : props.location.state.queryId,
			name: props.location.state.name=== undefined ? "" : props.location.state.name,
			createdDate: props.location.state.createdDate === undefined ? null : props.location.state.createdDate,
			creatorUId: props.location.state.creatorUId === undefined ? getUserInfo().uId : props.location.state.creatorUId,
			status: props.location.state.status === undefined ? 1 : props.location.state.status,
			
			query: props.location.state.query === undefined ? "" : props.location.state.query,
			lastQuery: "",
			userList: [],

			exportField: '',

			openSnackbar: false,
			snackMessage: '',
			authenticated: false
		};

		if(props.location.state.query){
			this.redshiftQuery(props.location.state.query);
		};

		window.pathname = null;
	}

	handleCloseAlert = () => {
		this.setState({ openSnackbar: false });
	};

	redshiftQuery = (query) => {
		// loading
		this.setState({
			userList: false,
			lastQuery: '',
			queryResult: []
		});

		getDataRedshift(query).then((result) =>{
			
			if(result !== false){
				
				if(result.length === 0){
					this.setState({openSnackbar: true, snackMessage: 'Nenhum resultado encontrado', userList: []});
				}else{
					this.setState({
						userList: result,
						lastQuery: query,
						queryResult: result
					});
				}

			}else{
				this.setState({openSnackbar: true, snackMessage: 'Busca inválida', userList: []});
			}
		}).catch((error) => {
            this.setState({openSnackbar: true, snackMessage: 'Erro ao realizar busca: '+ error.toString(), userList: [] });
		});
	}

	clearList = () => {
		this.setState({
			userList: [],
			lastQuery: "",
			queryResult: [],
		});
	}

	getExportList = () => {
		if(this.state.queryResult){
			let finalList = ""

			Object.keys(this.state.queryResult).map((item,index) => {
				return finalList += (index === 0?"":"\n") + (this.state.queryResult[item][this.state.exportField]);
			});
			if(this.state.exportField === "linked_account"){
				return finalList = finalList.replace(/[.]/gi, ",");
			}
				return finalList
		}
	}

	SaveQuery = () => {
		this.setState({
			openSnackbar: true,
			snackMessage: this.state.name+" "+this.state.status+" "+this.state.lastQuery+" "+Date.now()+" "+this.state.creatorUId,
		});
		
		let data = {
			queryId: this.state.queryId,
			name: this.state.name,
			query: this.state.lastQuery,
			createdDate: Date.now(),
			creatorUId: this.state.creatorUId,
			status: this.state.status,
		};
		
		this.props.updateBatchActionsComponent( data )
	}

	//altera os states dos campos, caso o usuário insira informações nos input
	handleChange = name => event => {
		if(name === "query"){
			this.setState({query: event.target.value, lastQuery: "", queryResult: [], userList: []})
		}else{
			this.setState({
				[name]: event.target.value
			});
		}
	};

	handleCheck = name => event => {
		this.setState({
			[name]: event.target.checked?1:0
		});
	};

	render = () => {
		
		if (this.props.loading)
			return 	<div className="loading">
						<Loading />
					</div>;
		else{

			if (window.pathname !== null){
				return <Redirect to={ window.pathname } />;
			}
			else{
				return (
					<div style={{padding: '4rem 32px'}} >
					<GoBackHeader />
					<HeaderTitle pageInfo='Ações em Lote' /> 
					<br/>
					<Typography variant="body2" gutterBottom color='primary' style={{fontWeight: '600'}}>
						Consulta no Redshift
					</Typography>

					<TextField
						id="name"
						label="Nome da Query"
						value={ this.state.name }
						onChange={this.handleChange('name')}
						margin="normal"
						style={{width: "50%"}}
						required
					/>

					<br/>
					
					<FormControlLabel 
					control={				
						<Checkbox
							checked={this.state.status===1?true:false}
							onChange={this.handleCheck('status')}
							value="status"
							inputProps={{'aria-label': 'primary checkbox'}}
						/>
					}
					label="Status"
					color='primary'
					/>
					
					<form onSubmit = {(e) => {
						e.preventDefault();
						this.redshiftQuery(this.state.query);
						}}>
					<TextField
						id="query"
						label="Query"
						value={ this.state.query }
						onChange={this.handleChange('query')}
						margin="normal"
						multiline
						style={{width: "50%"}}
						required
					/>

					<br/>

					<TextField
						id="exportField"
						label="Campo que será exportado para arquivo"
						value={ this.state.exportField }
						onChange={this.handleChange('exportField')}
						margin="normal"
						style={{width: "50%"}}
					/>
					
					<br/>

					<Button type="submit" disabled={ this.state.query.length === 0 } style={{ color: 'white', marginLeft: '0px', fontWeight: '550', textTransform: 'capitalize' }} variant="contained" color="primary">
						Realizar consulta
					</Button>
					</form>

					<br/>

					<Typography variant="body2" gutterBottom color='primary' style={{fontWeight: '600'}}>
						Lista de usuários
					</Typography>

					<Grid container spacing={5}>
						<Grid item md={6} sm={12}>
							{
							this.state.userList === false
							? 	<div align='center' >
									<CircularProgress style={{ padding: '20px' }} />
								</div>
							:	this.state.userList === null ?
								<div>
									<br/>
									<br/>
									<Typography variant="body2">Nenhum resultado encontrado.</Typography>
								</div>
								:
								<List style={{height: '500px', overflowY:'scroll', backgroundColor: '#fafafa'}}>
								{this.state.userList.map((item,i) => (
								<ListItem key={i} role={undefined} dense button >
									<ListItemText primary={ <pre> {JSON.stringify(this.state.userList[i], null, 2)} </pre> } />
								</ListItem>
								))}
								</List>
								
							}
								<Button disabled={ this.state.userList.length === 0 || this.state.userList === false } onClick={() => this.clearList()} style={{ color: 'white', marginLeft: '0px', fontWeight: '550', marginTop: '10px', textTransform: 'capitalize' }} variant="contained" color="primary" >
									Limpar Lista
								</Button>
								
								<Button disabled={ this.state.lastQuery === "" || this.state.queryResult === [] || this.state.name.length === 0 } href={"data:text/plain;charset=utf-8," + encodeURIComponent(this.getExportList())} download={this.state.name+".txt"} style={{ color: 'white', fontWeight: '550', marginLeft: '10px', marginTop: '10px', textTransform: 'capitalize' }} variant="contained" color="primary" >
									Exportar resultado
								</Button>
								
								<Button disabled={ this.state.lastQuery === "" || this.state.queryResult === [] || this.state.name.length === 0 } onClick={() => this.SaveQuery()} style={{ color: 'white', marginLeft: '10px', fontWeight: '550', marginTop: '10px', textTransform: 'capitalize' }} variant="contained" color="primary" >
									Salvar Query
								</Button>

						</Grid>
					</Grid>
						
					<Snackbar
						anchorOrigin={{ vertical : 'top', horizontal : 'right' }}
						open={this.state.openSnackbar}
						onClose={this.handleCloseAlert}
						ContentProps={{ 'aria-describedby': 'message-id' }}
						message={<span id="message-id">{this.state.snackMessage}</span>}
					/>

					</div>
				)
			}
		}
	};
}

//recebe as props dos Reducers
function mapStateToProps(state) {
	return {
		loading: state.batchActionsComponent.loading,
		success: state.batchActionsComponent.success,
		error: state.batchActionsComponent.error
	};
}

//envia as props para as Actions
const mapDispatchToProps = dispatch => ({
	updateBatchActionsComponent: ( value, eventType, eventDescription, clientList ) =>
	updateBatchActionsAction ( dispatch, value, eventType, eventDescription, clientList )
})

export default connect(mapStateToProps, mapDispatchToProps)(BatchActions);