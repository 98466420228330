import React, { useState, useEffect } from 'react';
import { GoBackHeader } from '../../CreateClub/RelationshipClub/GoBackHeader';

// STYLES, ICONS AND COMPONENTS
import * as S from './styles';
import {
  TextField,
  Button,
  IconButton,
  Typography,
  Box,
  Grid,
  CircularProgress,
} from '@mui/material';
import { Add as AddIcon, Remove as RemoveIcon } from '@mui/icons-material';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// APIS
import { postPartnerCode } from 'js/library/utils/API/Campaigns/apiPostPartnerCode';
import { getPartnerCodesByCampaign } from 'js/library/utils/API/Campaigns/apiGetPartnerCode';
import { deletePartnerCode } from 'js/library/utils/API/Campaigns/apiDeletePartnerCode';

export default function PartnerCode(props) {
  // STATES
  const [fields, setFields] = useState([{ partnerCode: '', relatedPromotion: '' }]);
  const [originalFields, setOriginalFields] = useState([]);
  const [removedFields, setRemovedFields] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [duplicateIndexes, setDuplicateIndexes] = useState([]);
  const [hasDuplicateError, setHasDuplicateError] = useState(false);

  // ID'S
  const campaignId = props.match.params.id;
  const ownerId = props.match.params.ownerId;

  const loadPartnerCodes = async () => {
    try {
      const response = await getPartnerCodesByCampaign(campaignId);

      if (response.success) {
        const formattedFields = Object.entries(response)
          .filter(([key, value]) => key !== 'success' && typeof value === 'object')
          .map(([key, value]) => ({
            partnerCode: key,
            relatedPromotion: value.store,
          }));

        setOriginalFields(formattedFields);
        setFields(formattedFields);
      } else {
        toast.error('Erro ao carregar códigos de parceiro existentes.');
      }
      setLoadingPage(false);
    } catch (error) {
      toast.error('Erro ao carregar códigos de parceiro existentes.');
      setLoadingPage(false);
    }
  };

  useEffect(() => {
    loadPartnerCodes();
  }, []);

  const handleAddField = () => {
    const newField = { partnerCode: '', relatedPromotion: '' };
    setFields([...fields, newField]);
  };

  const handleRemoveField = (index) => {
    const fieldToRemove = fields[index];
    const newFields = fields.slice();
    newFields.splice(index, 1);
    setFields(newFields);

    if (
      originalFields.some(
        (originalField) => originalField.partnerCode === fieldToRemove.partnerCode
      )
    ) {
      setRemovedFields([...removedFields, fieldToRemove]);
    }
  };

  const handleChangeField = (index, event) => {
    const { name, value } = event.target;
    const newFields = fields.slice();
    newFields[index][name] = value;
    setFields(newFields);

    if (name === 'partnerCode') {
      const duplicate =
        fields.some((field, fieldIndex) => fieldIndex !== index && field.partnerCode === value) ||
        originalFields.some((field) => field.partnerCode === value);

      if (duplicate) {
        toast.error(`O código de parceiro ${value} já existe. Por favor, tente outro.`);
        setDuplicateIndexes((prevIndexes) => [...prevIndexes, index]);
        setHasDuplicateError(true);
      } else {
        setDuplicateIndexes((prevIndexes) => prevIndexes.filter((idx) => idx !== index));
        setHasDuplicateError(false);
      }
    }
  };

  const handleSubmit = async () => {
    try {
      const fieldsToAdd = fields.filter(
        (field) =>
          !originalFields.some(
            (originalField) =>
              originalField.partnerCode === field.partnerCode &&
              originalField.relatedPromotion === field.relatedPromotion
          )
      );

      const existingCodes = originalFields.map((field) => field.partnerCode);
      for (const field of fieldsToAdd) {
        if (existingCodes.includes(field.partnerCode)) {
          toast.error(
            `O código de parceiro ${field.partnerCode} já existe. Por favor, tente outro.`
          );
          return;
        }
      }

      const addOperations = fieldsToAdd.length > 0;
      const deleteOperations = removedFields.length > 0;

      if (addOperations && deleteOperations) {
        await Promise.all([
          Promise.all(removedFields.map((field) => deletePartnerCode(field.partnerCode))),
          postPartnerCode(
            fieldsToAdd.map((field) => ({
              code: field.partnerCode,
              campaign: campaignId,
              partnerId: ownerId,
              store: field.relatedPromotion,
            }))
          ),
        ]);
        toast.success('Códigos de parceiro adicionados e deletados com sucesso!');
      } else if (deleteOperations) {
        await Promise.all(removedFields.map((field) => deletePartnerCode(field.partnerCode)));
        toast.success('Códigos de parceiro deletados com sucesso!');
      } else if (addOperations) {
        const response = await postPartnerCode(
          fieldsToAdd.map((field) => ({
            code: field.partnerCode,
            campaign: campaignId,
            partnerId: ownerId,
            store: field.relatedPromotion,
          }))
        );
        if (response.success) {
          toast.success('Códigos de parceiro salvos com sucesso!');
        } else {
          toast.error('Erro ao salvar alguns códigos de parceiro.');
        }
      }

      setTimeout(() => {
        window.history.back();
      }, 2000);
      loadPartnerCodes();
    } catch (error) {
      toast.error('Erro ao processar códigos de parceiro. Por favor, tente novamente.');
    }
  };

  if (loadingPage) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ToastContainer autoClose={2000} />
        <CircularProgress size="50px" />
      </div>
    );
  }

  return (
    <S.Container>
      <GoBackHeader />
      <ToastContainer autoClose={2000} />
      <S.Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          Códigos do Parceiro
        </Typography>
        <Grid container spacing={2} direction="column" alignItems="center" padding={2}>
          {fields.map((field, index) => {
            const isOriginalField = originalFields.some(
              (originalField) => originalField.partnerCode === field.partnerCode
            );

            return (
              <Grid
                container
                item
                spacing={2}
                key={index}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item xs={12} sm={5}>
                  <TextField
                    label="Código do parceiro"
                    name="partnerCode"
                    value={field.partnerCode}
                    onChange={(event) => handleChangeField(index, event)}
                    error={duplicateIndexes.includes(index)}
                    fullWidth
                    disabled={isOriginalField}
                  />
                </Grid>
                <Grid item xs={12} sm={5}>
                  <TextField
                    label="Promoção relacionada"
                    name="relatedPromotion"
                    value={field.relatedPromotion}
                    onChange={(event) => handleChangeField(index, event)}
                    error={duplicateIndexes.includes(index)}
                    fullWidth
                    disabled={isOriginalField}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={2}
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    maxWidth: '2rem',
                    width: '100%',
                  }}
                >
                  {index === 0 ? (
                    <IconButton onClick={handleAddField} color="primary">
                      <AddIcon />
                    </IconButton>
                  ) : (
                    <IconButton onClick={() => handleRemoveField(index)} color="secondary">
                      <RemoveIcon sx={{ color: 'red' }} />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Box mt={4} display="flex" justifyContent="center">
          <Button
            disabled={hasDuplicateError}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Salvar
          </Button>
        </Box>
      </S.Box>
    </S.Container>
  );
}
