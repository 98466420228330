//TODO refazer isso tudo, a página é só uma solução temporária até recebermos um design novo. Se isso estiver do jeito que tá em 2023 significa que falhamos miseravelmente
import React, { useEffect, useState } from 'react';

import * as D from 'js/components/DesignSystem/styles';
import * as S from './styles';

import { useHistory } from 'react-router-dom';

import { toast, ToastContainer } from 'react-toastify';

import { BsLink45Deg } from 'react-icons/bs';

import { updateEstablishment } from 'js/library/utils/API/Establishment/apiUpdateEstablishment';

import LinkField from './LinkField';
import UsefullLinkImagePickerModal from './UsefullLinkImagePickerModal';
import { HeaderTitle } from 'js/components/Configurations/CreateClub/RelationshipClub/HeaderTitle';
import { GoBackHeader } from 'js/components/Configurations/CreateClub/RelationshipClub/GoBackHeader';

const RegisterUsefullLinks = (props) => {
  const history = useHistory();
  const [newLinks, setNewLinks] = useState([
    {
      icon: '',
      link: '',
      title: '',
    },
  ]);

  const [modalStatus, setModalStatus] = useState(false);
  const ESTAB_LINK = `https://triibo.com.br/estabelecimento-links/?id=${props.location.state.id}`;

  const handleClick = (e) => {
    e.preventDefault();

    let newArr = [...newLinks];
    newArr.push({
      icon: '',
      link: '',
      title: '',
    });
    setNewLinks(newArr);
  };

  const copyLink = (url) => {
    navigator.clipboard.writeText(url);
  };

  useEffect(() => {
    if (props.location.state.useFullLinks) {
      setNewLinks(props.location.state.useFullLinks);
    }
  }, []);

  return (
    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <S.Container style={{ gap: '1rem' }}>
        <ToastContainer autoClose={2500} />
        <UsefullLinkImagePickerModal modalStatus={modalStatus} setModalStatus={setModalStatus} />
        <HeaderTitle pageInfo="Links úteis" />
        <S.AreaSubtitle>
          Adicione links importantes do seu negócio. Eles serão exibidos para seus clientes na aba{' '}
          <b>"Sobre"</b> de seu estabelecimento sendo ideais para divulgar suas redes sociais, site,
          blogs, landing pages, loja digital e muito mais!
        </S.AreaSubtitle>

        <S.LinkPlacerSpan>
          {newLinks.map(({ icon, links, title }, index) => (
            <LinkField index={index} icon={index} newLinks={newLinks} setNewLinks={setNewLinks} />
          ))}
        </S.LinkPlacerSpan>

        <S.ButtonContainer>
          <D.TriiboFilledButton
            blue={true}
            onClick={(e) => {
              handleClick(e);
            }}
          >
            Novo Link
          </D.TriiboFilledButton>
        </S.ButtonContainer>

        <S.LinkSpanLabel>
          Tenha todos seus links úteis em uma única URL, copie o link abaixo e divulgue em suas
          redes sociais e canais de divulgação para que seus clientes tenham acesso.
        </S.LinkSpanLabel>

        <S.LinkSpan
          onClick={() => {
            copyLink(ESTAB_LINK);
            toast.success('Link copiado!');
          }}
        >
          <S.EstablishmentLinkHolder>{ESTAB_LINK}</S.EstablishmentLinkHolder>{' '}
          <BsLink45Deg style={{ fontSize: '1.4rem' }} />
        </S.LinkSpan>

        <S.ButtonContainer>
          <D.TriiboFilledButton
            blue={true}
            onClick={(e) => {
              e.preventDefault();
              let edittedEst = {};
              let payload = { ...props.location.state };
              edittedEst.useFullLinks = [...newLinks];
              payload.useFullLinks = [...newLinks];
              try {
                updateEstablishment(props.location.state.id, edittedEst, '').then(
                  toast.success('Editado com sucesso!')
                );
              } catch (error) {
                toast.error(error);
              }

              history.push({
                pathname: `/estabelecimento/${props.location.state.id}`,
                state: payload,
              });
            }}
          >
            Salvar
          </D.TriiboFilledButton>
        </S.ButtonContainer>
      </S.Container>
    </div>
  );
};

export default RegisterUsefullLinks;

