import React, { useState } from 'react';

import * as D from 'js/components/DesignSystem/styles';
import * as S from '../styles';

import { toast, ToastContainer } from 'react-toastify';

import ToggleSwitch from 'js/components/TriiboComponents/ToggleSwitch/ToggleSwitch';

import { compareDatesFromStr } from 'js/library/utils/helpers';

const EditTimesTableModalCuradoria = (props) => {
  const { open, setOpen, newFormState, dataFuncionamentoEdit, setFinalFormState } = props;
  const times = dataFuncionamentoEdit.map(({ data }) => {
    return data;
  });
  const valueDefault = [...newFormState.funcionamentoAntigos];
  const [newTimes, setNewTimes] = useState(times);
  const backup = valueDefault;

  const weekArr = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado', 'Domingo'];

  return (
    <>
      <ToastContainer autoClose={4500} />
      <D.ModalContainer show={open}>
        <D.ModalBody>
          <S.ModalLabel>
            Configure os horários de <b>funcionamento</b>
          </S.ModalLabel>
          <S.TimesEditContainer>
            <S.TimesEditElements>
              {times.map(({ ativo, horarioFim, horarioInicio }, index) => (
                <S.TimesEditRow key={index}>
                  <S.WeekdayContainer>{weekArr[index]}</S.WeekdayContainer>
                  <div
                    onClick={() => {
                      let tempData = [...times];

                      if (tempData[index].ativo === true) {
                        delete tempData[index].horarioFim;
                        delete tempData[index].horarioInicio;
                        tempData[index].ativo = false;
                      } else {
                        tempData[index].ativo = true;
                        tempData[index].horarioFim = '18:00';
                        tempData[index].horarioInicio = '09:00';
                      }

                      setNewTimes([...tempData]);
                    }}
                  >
                    <ToggleSwitch checked={times[index].ativo} />
                  </div>
                  <S.InputHours
                    value={times[index].horarioInicio ? times[index].horarioInicio : '  :  '}
                    onChange={(e) => {
                      let tempData = [...times];

                      tempData[index].horarioInicio = e.target.value;

                      setNewTimes([...tempData]);

                      if (
                        compareDatesFromStr(
                          tempData[index].horarioFim,
                          tempData[index].horarioInicio
                        )
                      ) {
                        toast.error(
                          'Horário inválido: data de fechamento deve ser posterior à data de abertura.'
                        );
                      }
                    }}
                    disabled={!times[index].ativo}
                  />
                  <S.At>às</S.At>
                  <S.InputHours
                    value={times[index].horarioFim ? times[index].horarioFim : '  :  '}
                    onChange={(e) => {
                      let tempData = [...times];

                      tempData[index].horarioFim = e.target.value;

                      setNewTimes([...tempData]);
                    }}
                    disabled={!times[index].ativo}
                  />
                </S.TimesEditRow>
              ))}
            </S.TimesEditElements>
          </S.TimesEditContainer>
          <S.ButtonContainer modal={true}>
            <D.TriiboWhiteButton
              blue={true}
              onClick={() => {
                setOpen(false);
              }}
            >
              Cancelar
            </D.TriiboWhiteButton>
            <D.TriiboFilledButton
              blue={true}
              onClick={() => {
                if (newTimes.length !== 0) {
                  setFinalFormState((prevState) => {
                    return {
                      ...prevState,
                      funcionamento: newTimes,
                    };
                  });
                } else {
                  setFinalFormState((prevState) => {
                    return {
                      ...prevState,
                      funcionamento: backup,
                    };
                  });
                }

                setOpen(false);
              }}
            >
              Salvar
            </D.TriiboFilledButton>
          </S.ButtonContainer>
        </D.ModalBody>
        <D.ModalBackground
          onClick={() => {
            setOpen(false);
          }}
        />
      </D.ModalContainer>
    </>
  );
};

export default EditTimesTableModalCuradoria;

