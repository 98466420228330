import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getSeuClube, updateSeuClube } from 'js/library/utils/API/seuClube';
import { getUserInfo_v1 } from 'js/library/utils/API/getUserInfo_v1';

import { cloneDeep } from 'lodash';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';

import EditClubAdmin from './EditClubAdmin';

import { FaTrash } from 'react-icons/fa';
import { toast, ToastContainer } from 'react-toastify';
import { RegisterUsersBatch_v2 } from 'js/library/utils/API/apiRegisterUsersBatch';
import { LoadingTableClube } from 'js/components/Clubs/clubeTableStyles';

//verifica se existe um instância de chave de objeto com o valor da str
function isSingleMatch(obj, str) {
  let count = 0;
  for (let key in obj) {
    if (obj[key] === str) {
      count++;
    } else if (typeof obj[key] === 'object') {
      if (isSingleMatch(obj[key], str)) {
        count++;
      }
    }
    if (count > 1) {
      return false;
    }
  }
  return count === 1;
}

//verifica se o usuário tem privilégio de administrador ou gestor
function stringMatchesKeyAndGetValue(str, obj, state) {
  if (state.superUser) {
    return 'gestor';
  } else {
    for (let key in obj) {
      if (key === str) {
        return obj[key]['role'];
      }
    }
    return '';
  }
}

//remove qualquer chave que não for especificado o array de strings
function removeKeysNotInArray(obj, keysToKeep) {
  for (let key in obj) {
    if (!keysToKeep.includes(key)) {
      delete obj[key];
    }
  }
}

const ClubManageAdmin = ({ history, ...props }) => {
  const [adminsForm, setAdminsForm] = useState({});
  const [displayData, setDisplayData] = useState([]);
  const [clubeId] = useState(props.match.params.id);
  const state = useSelector((state) => state.authModel);
  const [userStatus, setUserStatus] = useState('');
  const [adminAddInfo, setAdminAddInfo] = useState({
    cellphone: 0,
    name: '',
  });

  useEffect(() => {
    getSeuClube(clubeId).then((response) => {
      let newObj = cloneDeep(response.admins);
      for (let key in newObj) {
        removeKeysNotInArray(newObj[key], ['role']);
      }

      setAdminsForm(newObj);
      setUserStatus(stringMatchesKeyAndGetValue(state.authenticated.uid, response.admins, state));
    });
  }, []);

  useEffect(() => {
    setDisplayData([]);
    if (adminsForm) {
      const promises = Object.keys(adminsForm).map((key, index) => {
        if (!adminsForm[key]) {
          return Promise.resolve(null);
        }

        return getUserInfo_v1(['getUserInfo'], { uId: key }).then((res) => {
          if (res.data.success.userInfo) {
            const userInfo = res.data.success.userInfo;

            let thing = Object.fromEntries(
              Object.entries(adminsForm).filter(([keyForm]) => keyForm.includes(key))
            );

            const newForm = {
              name: userInfo.name,
              phone: userInfo.contactList[0].value,
              role: thing[key].role,
              uId: userInfo.uId,
            };

            return newForm;
          } else {
            return null;
          }
        });
      });

      Promise.all(promises).then((results) => {
        const filteredResults = results.filter((result) => result !== null);
        setDisplayData(filteredResults);
      });
    }
  }, [adminsForm]);

  function AddAdmin(key, payload) {
    setAdminsForm({
      ...adminsForm,
      [key]: payload,
    });
    return;
  }

  return (
    <S.AdminManagerContainer>
      <ToastContainer autoClose={6000} />
      <EditClubAdmin history={history} AddAdmin={AddAdmin} setAdminAddInfo={setAdminAddInfo} />
      <S.AdminManagerListContainer>
        <S.AdminManagerTable>
          <S.AdminManagerTableRow>
            <S.AdminManagerTableHeader>Nome</S.AdminManagerTableHeader>
            <S.AdminManagerTableHeader>Telefone</S.AdminManagerTableHeader>
            <S.AdminManagerTableHeader>Função</S.AdminManagerTableHeader>
            <S.AdminManagerTableHeader>Ações</S.AdminManagerTableHeader>
          </S.AdminManagerTableRow>
          {displayData.length !== 0 ? (
            displayData.map(({ name, phone, role, uId }, index) => {
              return (
                <S.AdminManagerTableRow key={index}>
                  <S.AdminManagerTableDiv>{name}</S.AdminManagerTableDiv>
                  <S.AdminManagerTableDiv>{phone}</S.AdminManagerTableDiv>
                  <S.AdminManagerTableDiv>{role}</S.AdminManagerTableDiv>
                  {userStatus === 'administrador' && role === 'gestor' ? (
                    <></>
                  ) : (
                    <S.AdminManagerTableDiv>
                      <S.RemoveButton
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          let newObj = cloneDeep(adminsForm);
                          newObj[uId] = null;
                          setAdminsForm(newObj);
                        }}
                      >
                        <FaTrash color={'#fff'} />
                      </S.RemoveButton>
                    </S.AdminManagerTableDiv>
                  )}
                </S.AdminManagerTableRow>
              );
            })
          ) : (
            <LoadingTableClube>
              <strong>Aguarde estamos carregando as informações...</strong>
            </LoadingTableClube>
          )}
        </S.AdminManagerTable>
      </S.AdminManagerListContainer>
      <D.SaveAdminsButton
        blue={true}
        onClick={() => {
          if (isSingleMatch(adminsForm, 'gestor')) {
            let payload = { admins: cloneDeep(adminsForm) };
            payload.clubeId = clubeId;

            let bunch = [
              {
                cellphone: '+' + adminAddInfo.cellphone,
                clubeId: clubeId,
                name: adminAddInfo.name,
              },
            ];

            updateSeuClube(payload).then((result) => {
              if (result.data.success) {
                toast.success('Registrado com sucesso!');
              }
            });

            if (bunch[0].name !== '' && bunch[0].cellphone !== '+0') {
              RegisterUsersBatch_v2(bunch);
            }
          } else {
            toast.error('Registre exatamente um gestor');
          }
        }}
      >
        {'Salvar'}
      </D.SaveAdminsButton>
    </S.AdminManagerContainer>
  );
};

export default ClubManageAdmin;

