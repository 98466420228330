import React, { useEffect, useState } from 'react';
import { withStyles } from '@mui/styles';
import { Link } from 'react-router-dom';
import {
  TextField,
  Typography,
  Button,
  FormControl,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Radio,
  RadioGroup,
  Chip,
  Paper,
  Switch,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material';

import { ArrowBack, Save } from '@mui/icons-material';

import PropTypes from 'prop-types';
import deburr from 'lodash/deburr';

import { updateCardsStoreAction } from 'js/core/actions/listCardsActions.js';
import { downloadImage } from 'js/library/services/StorageManager';
import Downshift from 'downshift';

import { convertToDate } from 'js/library/utils/helpers';
import { GoBackHeader } from './CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from './CreateClub/RelationshipClub/HeaderTitle';
import { GetOrgsPaginatedSearch } from 'js/library/utils/API/Org/apiGetOrgsPaginatedSearch';
import { GetKeywords } from 'js/library/utils/API/SearchUser/apiGetKeywords';
import { useHistory } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import ImportImage from '../TriiboComponents/ImportImage';
import { Box } from '@mui/system';

let suggestions = [];
let orgsSuggestions = [];

const styles = () => ({
  root: {
    background: '#ffffff',
    borderRadius: 5,
    border: '1px solid #6c6c6c',
    color: 'white',
  },
});

function renderInput(inputProps) {
  const { InputProps, classes, ref, ...other } = inputProps;

  return (
    <TextField
      InputProps={{
        inputRef: ref,
        classes: {
          root: classes.inputRoot,
          input: classes.inputInput,
        },
        ...InputProps,
      }}
      {...other}
    />
  );
}

const SetCards = (props) => {
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    cardId: props.location.state.cardId === undefined ? null : props.location.state.cardId,
    cardType: props.location.state.type === undefined ? 'card_list' : props.location.state.type,
    title: props.location.state.title === undefined ? '' : props.location.state.title,
    subtitle: props.location.state.subtitle === undefined ? '' : props.location.state.subtitle,
    description:
      props.location.state.description === undefined ? '' : props.location.state.description,
    orderType:
      props.location.state.orderType === undefined ? 'alphabetic' : props.location.state.orderType,
    platform: props.location.state.platform === undefined ? [] : props.location.state.platform,

    priority: !props.location.state.priority ? 1 : props.location.state.priority,
    action:
      props.location.state.action === undefined ? 'promotion_list' : props.location.state.action,
    isPublished:
      props.location.state.isPublished === undefined ? false : props.location.state.isPublished,
    status: props.location.state.status === undefined ? 1 : props.location.state.status,
    keywords: '',

    icon:
      props.location.state.images === undefined || props.location.state.images.icon === undefined
        ? ''
        : props.location.state.images.icon,
    imageWeb:
      props.location.state.images === undefined || props.location.state.images.web === undefined
        ? ''
        : props.location.state.images.web,
    imageMobile:
      props.location.state.images === undefined || props.location.state.images.mobile === undefined
        ? ''
        : props.location.state.images.mobile,
    imageMobileDetails:
      props.location.state.images === undefined ||
      props.location.state.images.mobileDetails === undefined
        ? ''
        : props.location.state.images.mobileDetails,

    itemId:
      props.location.state.appFilter === undefined ||
      props.location.state.appFilter.itemId === undefined
        ? ''
        : props.location.state.appFilter.itemId,
    query:
      props.location.state.appFilter === undefined ||
      props.location.state.appFilter.query === undefined
        ? ''
        : props.location.state.appFilter.query,
    url:
      props.location.state.appFilter === undefined ||
      props.location.state.appFilter.url === undefined
        ? ''
        : props.location.state.appFilter.url,

    enableDate:
      props.location.state.enableDate === undefined
        ? ''
        : convertToDate(props.location.state.enableDate),
    disableDate:
      props.location.state.disableDate === undefined
        ? ''
        : convertToDate(props.location.state.disableDate),
    createDate:
      props.location.state.createDate === undefined
        ? ''
        : convertToDate(props.location.state.createDate),

    location: {
      latitude:
        props.location.state.location === undefined ||
        props.location.state.location.latitude === undefined
          ? 0
          : props.location.state.location.latitude,
      longitude:
        props.location.state.location === undefined ||
        props.location.state.location.longitude === undefined
          ? 0
          : props.location.state.location.longitude,
      radius:
        props.location.state.location === undefined ||
        props.location.state.location.radius === undefined
          ? 0
          : props.location.state.location.radius,
    },
    organizations: '',

    orgIDList: props.location.state.orgs
      ? [...props.location.state.orgs.map((orgID) => orgID)]
      : [],

    organizationsList: [],

    orgs: [],

    option: '0',
    openCheck: false,

    loadedWebFile: null,
    loadedMobileFile: null,
    loadedDetailsFile: null,

    snackMessage: '',

    actionsList: [],

    disabledButtons: [],

    listActions: [
      {
        value: 'promotion_list',
        label: 'Lista de promoções',
      },
      {
        value: 'establishment_list',
        label: 'Lista de estabelecimentos',
      },
      {
        value: 'product_list',
        label: 'Lista de produtos',
      },
      {
        value: 'wifi_list',
        label: 'Lista de WiFi',
      },
    ],

    itemActions: [
      {
        value: 'promotion_businessPartner',
        label: 'Promoção de parceiro',
      },
      {
        value: 'promotion_qrCode',
        label: 'Promoção com QR Code',
      },
      {
        value: 'promotion_selfValidation',
        label: 'Promoção com validação pelo usuário',
      },
      {
        value: 'promotion_takeVoucher',
        label: 'Promoção de voucher',
      },
      {
        value: 'product_qrCode',
        label: 'Produto com QR Code',
      },
      {
        value: 'product_selfValidation',
        label: 'Produto com validação pelo usuário',
      },
      {
        value: 'product_takeVoucher',
        label: 'Produto de voucher',
      },
      {
        value: 'establishment_info',
        label: 'Informação de estabelecimento',
      },
      {
        value: 'app_screen',
        label: 'Navegação no App',
      },
    ],

    urlActions: [
      {
        value: 'browser',
        label: 'Browser',
      },
      {
        value: 'webview',
        label: 'Webview',
      },
    ],

    keywordsList:
      props.location.state.keyWordsSet === undefined ? [] : props.location.state.keyWordsSet,
  });
  const [cardDefaultWeb, setCardDefaultWeb] = useState('');
  const [cardDefaultMobile, setCardDefaultMobile] = useState('');
  const [cardDefaultDetails, setCardDefaultDetails] = useState('');

  const history = useHistory();

  useEffect(() => {
    GetKeywords().then((dataReceived) => {
      let keywordsDownload = [];

      Object.values(dataReceived).map(function (item) {
        return keywordsDownload.push({ label: item.value });
      });

      suggestions = keywordsDownload;
    });
  }, []);

  useEffect(() => {
    fetchOrganizations();

    if (props.location.state.images !== undefined) {
      if (props.location.state.images.web !== undefined) {
        downloadImage('cards', form.imageWeb)
          .then((downloadedImage) => {
            setCardDefaultWeb(downloadedImage);
          })
          .catch(() => {});
      }

      if (props.location.state.images.mobile !== undefined) {
        downloadImage('cards', form.imageMobile.split('/')[1])
          .then((downloadedImage) => {
            setCardDefaultMobile(downloadedImage);
          })
          .catch(() => {});
      }

      if (props.location.state.images.mobileDetails !== undefined) {
        downloadImage('cards', form.imageMobileDetails.split('/')[1])
          .then((downloadedImage) => {
            setCardDefaultDetails(downloadedImage);
          })
          .catch(() => {});
      }
    }
  }, []);

  async function fetchOrganizations() {
    const currentOrganizationsList = [];
    const payload = {
      from: 0,
      size: 10000,
      accessMethod: 'all',
      visibility: 'all',
    };
    const data = await GetOrgsPaginatedSearch(payload);

    if (data) {
      const resultArrayValues = data.results;

      form.orgIDList.forEach((org) => {
        currentOrganizationsList.push(...resultArrayValues.filter((value) => value.orgID === org));
      });

      orgsSuggestions.push(...resultArrayValues.map((value) => ({ ...value })));

      setForm((prevForm) => ({
        ...prevForm,
        organizationsList: [...currentOrganizationsList],
      }));
    }
  }

  function renderSuggestion({
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
    type,
  }) {
    const isHighlighted = highlightedIndex === index;
    const isSelected = (selectedItem || '').indexOf(suggestion.label) > -1;

    return (
      <MenuItem
        {...itemProps}
        key={suggestion.label}
        selected={isHighlighted}
        component="div"
        style={{
          fontWeight: isSelected ? 500 : 400,
        }}
      >
        {type === 'keywords' ? suggestion.label : suggestion.name}
      </MenuItem>
    );
  }

  renderSuggestion.propTypes = {
    highlightedIndex: PropTypes.number,
    index: PropTypes.number,
    itemProps: PropTypes.object,
    selectedItem: PropTypes.string,
    suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
  };

  function getSuggestions(type, value) {
    const inputValue = deburr(value.trim()).toLowerCase();
    const inputLength = inputValue.length;
    let count = 0;

    if (type === 'orgs')
      return inputLength === 0
        ? []
        : orgsSuggestions.filter((suggestion) => {
            const keep =
              count < 5 && suggestion.name.slice(0, inputLength).toLowerCase() === inputValue;

            if (keep) {
              count += 1;
            }

            return keep;
          });

    return inputLength === 0
      ? []
      : suggestions.filter((suggestion) => {
          const keep =
            count < 5 && suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

          if (keep) {
            count += 1;
          }

          return keep;
        });
  }

  const url = window.location.pathname;
  const isCreatingCard = url.includes('cadastrarCards');

  const handleSubmitCard = () => {
    const data = { ...form };
    data.orgs = form.organizationsList.map(({ orgID }) => orgID);

    if (cardDefaultWeb === '') {
      toast.warn('A imagem web é obrigatória!');
    } else {
      data.loadedWebFile =
        isCreatingCard || cardDefaultWeb.includes('base64') ? cardDefaultWeb : null;
    }

    if (cardDefaultMobile === '') {
      toast.warn('A imagem mobile é obrigatória!');
    } else {
      data.loadedMobileFile =
        isCreatingCard || cardDefaultMobile.includes('base64') ? cardDefaultMobile : null;
    }

    if (cardDefaultDetails === '') {
      toast.warn('A imagem detalhes é obrigatória!');
    } else {
      data.loadedDetailsFile =
        isCreatingCard || cardDefaultDetails.includes('base64') ? cardDefaultDetails : null;
    }

    if (cardDefaultWeb !== '' && cardDefaultMobile !== '' && cardDefaultDetails !== '') {
      setLoading(true);
      updateCardsStoreAction(data)
        .then(() => {
          if (isCreatingCard) {
            toast.success('Card criado com sucesso!');
            console.log('criar card', data);
          } else {
            toast.success('Card atualizado com sucesso!');
            console.log('editar card', data);
          }
          setTimeout(() => {
            history.push('/admin/configuracoes/cards');
          }, 2000);
        })
        .catch(() => {
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleChangeKeywordDownshift = (item) => {
    if (form.keywordsList.indexOf(item) === -1) {
      setForm((prevForm) => ({
        ...prevForm,
        keywords: '',
        keywordsList: [...form.keywordsList, item],
      }));
    }
  };

  const handleChangeOrganizationDownshift = (item) => {
    if (form.organizationsList.indexOf(item) === -1)
      setForm((prevForm) => ({
        ...prevForm,
        organizations: '',
        organizationsList: [...form.organizationsList, item],
      }));
  };

  const handleDeleteDownshift = (name, item) => () => {
    setForm((prevForm) => {
      const updatedKeywordsList = [...prevForm.keywordsList];
      const updatedOrganizationsList = [...prevForm.organizationsList];

      if (name === 'keywords') {
        updatedKeywordsList.splice(updatedKeywordsList.indexOf(item), 1);
        return { ...prevForm, keywordsList: updatedKeywordsList };
      }

      updatedOrganizationsList.splice(updatedOrganizationsList.indexOf(item), 1);
      return { ...prevForm, organizationsList: updatedOrganizationsList };
    });
  };

  //altera os states dos campos, caso o usuário insira informações nos input
  const handleChange = (name) => (event) => {
    if (name === 'enableDate' || name === 'disableDate') {
      setForm((prevForm) => ({
        ...prevForm,
        [name]: convertToDate(event.target.value),
      }));
    } else if (name === 'cardType') {
      let aux = event.target.value.split('_')[1] + 'Actions';

      switch (event.target.value) {
        case 'card_list':
          setForm((prevForm) => ({
            ...prevForm,
            itemId: '',
            url: '',
            [name]: event.target.value,
            actionsList: prevForm[aux],
            action: prevForm[aux][0].value,
          }));
          break;
        case 'card_item':
          setForm((prevForm) => ({
            ...prevForm,
            query: '',
            url: '',
            [name]: event.target.value,
            actionsList: prevForm[aux],
            action: prevForm[aux][0].value,
          }));
          break;
        case 'card_url':
          setForm((prevForm) => ({
            ...prevForm,
            itemId: '',
            query: '',
            [name]: event.target.value,
            actionsList: prevForm[aux],
            action: prevForm[aux][0].value,
          }));
          break;
        default:
          break;
      }
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        [name]: event.target.value,
      }));
    }
  };

  const handleCheckArray = (name) => (event) => {
    const checked = event.target.checked;
    const value = event.target.value;
    setForm((prevForm) => {
      const aux = [...prevForm[name]];

      if (checked) {
        aux.push(value);
      } else {
        const index = aux.indexOf(value);
        if (index !== -1) {
          aux.splice(index, 1);
        }
      }

      return {
        ...prevForm,
        [name]: aux,
      };
    });
  };

  const handleCloseAlert = () => {
    setForm((prevForm) => ({
      ...prevForm,
      openCheck: false,
    }));
  };

  const handleChangeSwitch = (name) => (event) => {
    setForm((prevForm) => ({
      ...prevForm,
      [name]: event.target.checked,
      goBack: true,
    }));
  };

  const { classes } = props;
  const {
    keywords,
    organizations,
    keywordsList: selectedKeywords,
    organizationsList: selectedOrgs,
  } = form;

  const itemIDList = [
    {
      value: 'screen_home',
      label: 'Home',
    },
    {
      value: 'screen_wallet',
      label: 'Carteira',
    },
    {
      value: 'screen_profile',
      label: 'Perfil',
    },
    {
      value: 'screen_notifications',
      label: 'Notificações',
    },
    {
      value: 'screen_chat',
      label: 'Chat',
    },
    {
      value: 'screen_recommended_orgs_list',
      label: 'Comunidades',
    },
  ];

  return (
    <>
      <ToastContainer autoClose={2000} />
      {form.cardId !== null &&
        form.actionsList.length === 0 &&
        (() => {
          let aux = form.cardType.split('_')[1] + 'Actions';
          setForm((prevForm) => ({
            ...prevForm,
            actionsList: form[aux],
            // action: form.action,
          }));
        })()}
      {form.actionsList.length === 0 &&
        (() => {
          setForm((prevForm) => ({
            ...prevForm,
            actionsList: form.listActions,
            // action: form.listActions[0].value,
          }));
        })()}

      <div style={{ padding: '4rem 32px', width: '100%' }}>
        <GoBackHeader />
        <HeaderTitle pageInfo={isCreatingCard ? 'Cadastrar card' : 'Editar card'} />
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmitCard();
          }}
        >
          <FormControl className="input-field" style={{ color: '#6c6c6c' }}>
            <Typography variant="body2" color="secondary">
              Tipo
            </Typography>
            <div style={{ width: '100%' }}>
              <RadioGroup
                style={{ display: 'inline-block', textAlign: 'left' }}
                aria-label="type"
                name="Tipo"
                value={form.cardType}
                onChange={handleChange('cardType')}
              >
                <FormControlLabel
                  value="card_list"
                  control={<Radio color="primary" />}
                  label="Lista"
                  labelPlacement="end"
                />

                <FormControlLabel
                  value="card_item"
                  control={<Radio color="primary" />}
                  label="Item"
                  labelPlacement="end"
                />

                <FormControlLabel
                  value="card_url"
                  control={<Radio color="primary" />}
                  label="URL"
                  labelPlacement="end"
                />
              </RadioGroup>
            </div>

            <br />

            <Typography variant="body2" color="secondary">
              Detalhes
            </Typography>

            <TextField
              style={{ width: '100%', whiteSpace: 'nowrap' }}
              className="input-field"
              onChange={handleChange('title')}
              value={form.title}
              type="text"
              id="title"
              required
              label="Título"
            />

            <TextField
              style={{ width: '100%' }}
              className="input-field"
              onChange={handleChange('subtitle')}
              value={form.subtitle}
              type="text"
              id="subtitle"
              required
              multiline
              label="Subtítulo"
            />

            <TextField
              style={{ width: '100%' }}
              className="input-field"
              onChange={handleChange('description')}
              value={form.description}
              type="text"
              id="description"
              required
              multiline
              label="Descrição"
            />

            <br />

            <FormControl className={classes.formControl}>
              <InputLabel shrink id="demo-simple-select-placeholder-label-label">
                Ordenação
              </InputLabel>
              <Select
                labelId="orderType"
                label="Ordenação"
                id="orderType"
                value={form.orderType}
                onChange={handleChange('orderType')}
                required
              >
                <MenuItem value="alphabetic">Ordenação Alfabetica</MenuItem>
                <MenuItem value="location">Ordenação por Localização</MenuItem>
              </Select>
            </FormControl>

            <br />

            <Typography variant="body2" color="secondary">
              Filtros
            </Typography>

            {/* desaparece quando for app_screen */}
            {form.cardType === 'card_item' && form.action !== 'app_screen' ? (
              <TextField
                style={{ width: '100%' }}
                className="input-field"
                onChange={handleChange('itemId')}
                value={form.itemId}
                type="text"
                id="itemId"
                label="ID do item"
                required
              />
            ) : (
              ''
            )}

            {form.cardType === 'card_list' ? (
              <TextField
                style={{ width: '100%' }}
                className="input-field"
                onChange={handleChange('query')}
                value={form.query}
                type="text"
                id="query"
                label="Query"
                required
              />
            ) : (
              ''
            )}

            {form.cardType === 'card_url' ? (
              <TextField
                style={{ width: '100%' }}
                className="input-field"
                onChange={handleChange('url')}
                value={form.url}
                type="text"
                id="url"
                helperText="Em caso de link externo, inserir https:// no início da sua URL."
                label="URL"
                required
              />
            ) : (
              ''
            )}

            {/* apenas é renderizado quando for app_screen */}
            {form.action === 'app_screen' ? (
              <TextField
                style={{ width: '100%' }}
                className="input-field"
                onChange={handleChange('itemId')}
                value={form.itemId}
                type="text"
                id="itemId"
                label="ID do item"
                required
                select
              >
                {itemIDList.map(({ label, value }, index) => (
                  <MenuItem
                    key={itemIDList[index].value}
                    value={itemIDList[index].value}
                    color="secondary"
                  >
                    {itemIDList[index].label}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              ''
            )}

            <br />
            <br />
            {console.log(form.cardType)}
            {form.cardType === 'card_list' ? (
              <TextField
                id="action"
                className="input-field"
                select
                label="Ação"
                value={form.action}
                onChange={handleChange('action')}
                margin="normal"
                color="secondary"
              >
                {form.actionsList.map((option) => (
                  <MenuItem key={option.value} value={option.value} color="secondary">
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            ) : (
              <></>
            )}

            <TextField
              style={{ width: '100%' }}
              className="input-field"
              onChange={handleChange('priority')}
              value={form.priority}
              type="number"
              id="priority"
              required
              label="Prioridade (0 a 9)"
            />

            <br />
            <br />

            <Typography variant="body2" color="secondary">
              Plataformas
            </Typography>
            <div style={{ width: '300px' }}>
              <div style={{ display: 'inline-block', textAlign: 'left' }}>
                <Checkbox
                  checked={form.platform.indexOf('Web') >= 0}
                  onChange={handleCheckArray('platform')}
                  value={'Web'}
                  color="primary"
                />
                Web
                <Checkbox
                  checked={form.platform.indexOf('iOS') >= 0}
                  onChange={handleCheckArray('platform')}
                  value={'iOS'}
                  color="primary"
                />
                iOS
                <Checkbox
                  checked={form.platform.indexOf('Android') >= 0}
                  onChange={handleCheckArray('platform')}
                  value={'Android'}
                  color="primary"
                />
                Android
              </div>
            </div>

            <br />

            <Downshift
              id="downshift-multiple"
              inputValue={keywords}
              onChange={handleChangeKeywordDownshift}
              selectedItem={selectedKeywords}
            >
              {({
                getInputProps,
                getItemProps,
                isOpen,
                inputValue: keywordInputValue,
                selectedItem: selectedKeywordItem,
                highlightedIndex,
              }) => (
                <div className={classes.container}>
                  {renderInput({
                    fullWidth: true,
                    classes,
                    InputProps: getInputProps({
                      startAdornment: selectedKeywords.map((keyword) => (
                        <Chip
                          key={keyword}
                          tabIndex={-1}
                          label={keyword}
                          className={classes.chip}
                          onDelete={handleDeleteDownshift('keywords', keyword)}
                        />
                      )),
                      onChange: handleChange('keywords'),
                      placeholder: 'Selecione a keyword na lista abaixo',
                    }),
                    label: 'Keywords',
                  })}
                  {isOpen ? (
                    <Paper className={classes.paper} square>
                      {getSuggestions('keywords', keywordInputValue).map((suggestion, index) =>
                        renderSuggestion({
                          suggestion,
                          index,
                          itemProps: getItemProps({
                            item: suggestion.label,
                          }),
                          highlightedIndex,
                          selectedItem: selectedKeywordItem,
                          type: 'keywords',
                        })
                      )}
                    </Paper>
                  ) : null}
                </div>
              )}
            </Downshift>

            <br />
            <br />

            <Downshift
              id="downshift-multiple"
              inputValue={organizations}
              onChange={handleChangeOrganizationDownshift}
              selectedItem={selectedOrgs}
            >
              {({
                getInputProps,
                getItemProps,
                isOpen,
                inputValue: orgInputValue,
                selectedItem: selectedOrgItem,
                highlightedIndex,
              }) => (
                <div className={classes.container}>
                  {renderInput({
                    fullWidth: true,
                    classes,
                    InputProps: getInputProps({
                      startAdornment: selectedOrgs.map((item, index) => (
                        <Chip
                          key={item.orgID}
                          tabIndex={-1}
                          label={item.name}
                          className={classes.chip}
                          onDelete={handleDeleteDownshift('organizations', item)}
                        />
                      )),
                      onChange: handleChange('organizations'),
                      placeholder: 'Selecione a Organização na lista abaixo',
                    }),
                    label: 'Organizações',
                  })}
                  {isOpen ? (
                    <Paper className={classes.paper} square>
                      {getSuggestions('orgs', orgInputValue).map((suggestion, index) =>
                        renderSuggestion({
                          suggestion,
                          index,
                          itemProps: getItemProps({
                            item: suggestion,
                          }),
                          highlightedIndex,
                          selectedItem: selectedOrgItem,
                        })
                      )}
                    </Paper>
                  ) : null}
                </div>
              )}
            </Downshift>

            <br />
            <br />

            <Typography variant="body2" color="secondary">
              Imagens
            </Typography>
            <TextField
              style={{ width: '100%' }}
              className="input-field"
              onChange={handleChange('icon')}
              value={form.icon}
              type="text"
              id="icon"
              label="Ícone"
            />

            <br />

            <Typography variant="body2" color="secondary">
              Imagem web
            </Typography>
            <Box sx={{ width: 600, height: 'auto' }}>
              <ImportImage
                imageDefault={true}
                autoWidth={true}
                image={cardDefaultWeb}
                setImage={setCardDefaultWeb}
                ratio={2.81 / 1}
              />
            </Box>

            <br />

            <Typography variant="body2" color="secondary">
              Imagem mobile
            </Typography>
            <Box sx={{ width: 600, height: 'auto' }}>
              <ImportImage
                imageDefault={true}
                autoWidth={true}
                image={cardDefaultMobile}
                setImage={setCardDefaultMobile}
                ratio={2.81 / 1}
              />
            </Box>

            <br />

            <Typography variant="body2" color="secondary">
              Imagem detalhes
            </Typography>
            <Box sx={{ width: 400, height: 300 }}>
              <ImportImage
                imageDefault={true}
                autoWidth={true}
                image={cardDefaultDetails}
                setImage={setCardDefaultDetails}
                ratio={4 / 3}
              />
            </Box>

            <br />
            <br />

            <TextField
              id="datetime-local"
              label="Data de ativação"
              type="datetime-local"
              value={form.enableDate}
              onChange={handleChange('enableDate')}
              InputLabelProps={{
                shrink: true,
              }}
              color="primary"
              required
            />

            <br />

            <TextField
              id="datetime-local"
              label="Data de desativação"
              type="datetime-local"
              value={form.disableDate}
              onChange={handleChange('disableDate')}
              InputLabelProps={{
                shrink: true,
              }}
              color="primary"
              required
            />

            <br />

            <div style={{ display: 'inline-block', textAlign: 'left' }}>
              <FormControlLabel
                style={{ marginTop: '10px' }}
                control={
                  <Switch
                    checked={form.isPublished}
                    onChange={handleChangeSwitch('isPublished')}
                    color="primary"
                  />
                }
                label={'Publicar card?'}
              />
            </div>
          </FormControl>

          <br />
          <br />
          <Link
            to={{ pathname: '/admin/configuracoes/cards', state: '' }}
            style={{ textDecoration: 'none' }}
          >
            <Button
              style={{
                fontWeight: '550',
                marginTop: '20px',
                color: 'white',
                textTransform: 'capitalize',
              }}
              variant="contained"
              color="secondary"
              startIcon={<ArrowBack />}
            >
              Voltar
            </Button>
          </Link>
          <Button
            style={{
              fontWeight: '550',
              marginTop: '20px',
              marginLeft: '24px',
              color: 'white',
              textTransform: 'capitalize',
              background: loading && '#60B9C0',
            }}
            variant="contained"
            color="primary"
            type="submit"
            startIcon={loading ? <CircularProgress size={20} color="inherit" /> : <Save />}
            disabled={loading}
          >
            {loading ? 'Salvando...' : 'Salvar'}
          </Button>
        </form>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          open={form.openCheck}
          onClose={handleCloseAlert}
          ContentProps={{
            'aria-describedby': 'message-id',
          }}
          message={<span id="message-id">{form.snackMessage}</span>}
        />
      </div>
    </>
  );
};

export default withStyles(styles)(SetCards);

