import React, { useEffect, useState } from 'react';
import View from './View';
import ModelLPSignUp from './model';
import { useParams } from 'react-router-dom';
import useForm from '../Hooks/useForm';
import { toast } from 'react-toastify';

function LandingPageSignUp({ location }) {
  const selectedLP = location?.state || {};
  const { id: lpId } = useParams();

  const [lpIdState, setLpIdState] = useState('');
  const [saveLPLoading, setSaveLPLoading] = useState(false);
  const [campaigns, setCampaigns] = useState([]);
  const [periods, setPeriods] = useState([]);

  const [payload, setPayload] = useState({ campaignLandpage: { modules: [] } });
  const [selectedModalInfos, setSelectedModalInfos] = useState({ modalInfos: {}, currentIndex: 0 });
  const [isActiveModals, setIsActiveModals] = useState({
    bgModal: false,
    bannerModal: false,
    textModal: false,
    blogModal: false,
    carouselModal: false,
    highlightsModal: false,
  });

  const params = useParams();
  const clubId = params?.id;

  const { form, handleChange, setForm, validateForm } = useForm({
    title: { value: selectedLP?.title || '', required: true },
    description: { value: selectedLP?.description || '', required: true },
    campaignId: { value: selectedLP?.campaignId || '', required: true },
    periodsId: { value: selectedLP?.periodsId?.toString() || '', required: true },
    isActive: { value: false, required: true },
  });

  const getCampaignsByOwner = async () => {
    try {
      const campaignsByOwner = await ModelLPSignUp.getCampaignsByOwner(clubId);
      setCampaigns(campaignsByOwner || []);
    } catch (error) {
      console.error('Erro ao obter campanhas:', error);
    }
  };

  const setInitialsInfos = async (lpId) => {
    try {
      const response = await ModelLPSignUp.getLandingPageById(lpId);
      const { title, description, campaignId, modules, periodsId, background, isActive } = response;
      setForm({
        title: { value: title, required: true },
        description: { value: description, required: true },
        campaignId: { value: campaignId, required: true },
        periodsId: { value: periodsId?.toString(), required: true },
        isActive: { value: isActive, required: true },
      });
      setPayload({
        campaignLandpage: {
          ...payload.campaignLandpage,
          background,
          modules: modules?.sort((a, b) => a.priority - b.priority),
        },
      });
      setLpIdState(lpId);
    } catch (error) {
      console.error('Erro ao definir informações iniciais:', error);
    }
  };

  const setSelectedPeriods = (selectedPeriod) => {
    setPeriods(selectedPeriod || []);
  };

  const formatDate = (timestamp) => {
    return new Date(timestamp).toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  const toggleModal = (currentModal, isActive) =>
    setIsActiveModals((prevState) => ({ ...prevState, [currentModal]: isActive }));

  const openModal = (currentModal) => toggleModal(currentModal, true);
  const closeModal = (currentModal) => toggleModal(currentModal, false);

  const saveLandingPage = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      const { title, description, campaignId, periodsId, isActive } = form;
      const body = {
        campaignLandpage: {
          ...payload.campaignLandpage,
          partnerId: selectedLP.partnerId,
          title: title.value,
          description: description.value,
          campaignId: campaignId.value,
          periodsId: periodsId.value,
          isActive: isActive?.value,
        },
      };
      try {
        setSaveLPLoading(true);
        const response =
          selectedLP.id || lpIdState
            ? await ModelLPSignUp.updateLP(selectedLP.id || lpIdState, { payload: { ...body } })
            : await ModelLPSignUp.createLP(body);

        if (response.success) {
          !lpIdState && setLpIdState(response.result.id);
          setInitialsInfos(response.result.id || lpIdState);
          toast.success('Landing page salva com sucesso!');
          setSaveLPLoading(false);
        }
      } catch (error) {
        console.error('Erro ao salvar a landing page:', error.message);
        setSaveLPLoading(false);
      }
    } else {
      toast.error('Preencha todos os campos obrigatórios!');
    }
  };

  const removeModule = (currentIndex) => {
    const filtredModules = payload.campaignLandpage.modules.filter(
      (_, index) => index !== currentIndex
    );
    const newModules = filtredModules?.map((item, index) => ({ ...item, priority: index }));
    setPayload((prevState) => ({
      campaignLandpage: {
        ...prevState.campaignLandpage,
        modules: newModules,
      },
    }));
  };

  useEffect(() => {
    if (selectedLP.id) setInitialsInfos(selectedLP.id);
  }, [selectedLP]);

  useEffect(() => {
    if (lpId) getCampaignsByOwner();
  }, [lpId]);

  useEffect(() => {
    const [campaign] = campaigns?.filter((campaign) => campaign.id === form.campaignId.value);
    setSelectedPeriods(campaign?.periods);
  }, [campaigns, form]);

  const formData = {
    form,
    selectedLP,
    campaigns,
    periods,
    modules: payload.campaignLandpage.modules,
  };

  const modalData = {
    isActiveModals,
    selectedModalInfos,
    modalsList: ModelLPSignUp.modalsList,
    modulesImages: ModelLPSignUp.modulesImages,
    modulesList: ModelLPSignUp.modulesList,
    backgroundModule: payload?.campaignLandpage?.background,
  };

  const handlers = {
    setSelectedModalInfos,
    closeModal,
    openModal,
    setSelectedPeriods,
    handleChange,
    saveLandingPage,
    removeModule,
    setPayload,
  };

  const loaders = {
    saveLPLoading,
  };

  return (
    <View
      formData={formData}
      modalData={modalData}
      handlers={handlers}
      loaders={loaders}
      formatDate={formatDate}
    />
  );
}

export default LandingPageSignUp;
