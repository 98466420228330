import React, { useState, useEffect } from 'react';
import { Link, useHistory, useParams, useLocation } from 'react-router-dom';

// context
import { useCharts } from '../Context/Establishment';
import {
  Grid,
  Box,
  Button,
  IconButton,
  Typography,
  Container,
  useMediaQuery,
  Backdrop,
  Modal,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  DialogActions as MuiDialogActions,
  CircularProgress,
  Dialog,
  Alert,
} from '@mui/material';
import PreviewMobalEstablishment from './PreviewMobalEstablishment';
import { getUserInfo } from 'js/library/utils/helpers.js';

// icons
import { Cached as CachedIcon, Close as CloseIcon } from '@mui/icons-material';

// charts
import BarChart from '../EstablishmentCharts/BarChart';
import PieChart from '../EstablishmentCharts/PieChart';

// styles
import useStyles, { Line } from './styles';
import { ChartDataModal, Fade } from './Modals';

//utils
import { parseDate } from 'js/library/utils/helpers';
import signatureStatus from 'js/library/utils/API/apiSignatureStatus';
import dataGraphics from 'js/library/utils/API/apiGraphics';
import { withStyles } from '@mui/styles';
import { HeaderTitle } from 'js/components/Configurations/CreateClub/RelationshipClub/HeaderTitle';
import { GoBackHeader } from 'js/components/Configurations/CreateClub/RelationshipClub/GoBackHeader';
import { userReadUid_v3 } from 'js/library/utils/API/apiUserRead';
import { getEstablishmentChangesRequest } from 'js/library/utils/API/Curation/getEstablishmentChangesRequest';
import getEstablishmentId from 'js/library/utils/API/Establishment/apiReadEstablishment';
import { ToastContainer, toast } from 'react-toastify';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    textAlign: 'center',
    color: '#06BAD0',
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
  [theme.breakpoints.down('xs')]: {
    padding: theme.spacing(1),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(1),
    },
  },
}))(MuiDialogActions);

export default function EstablishmentScreen(props) {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();

  const [openModal, setOpenModal] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);

  const [state, setPlaceState] = useState(null);

  const loggedUid = getUserInfo().uId;

  const [signature, setSignature] = useState([]);

  //STATES DADOS DOS GRAFICOS
  const [dataFirstGraphic, setDataFirstGraphic] = useState([]);
  const [dataSecondGraphic, setDataSecondGraphic] = useState([]);
  const [dataPieGraphic, setDataPieGraphic] = useState([]);

  const [isAdmin, setIsAdmin] = useState(false);
  const [edit, setEdit] = useState(false);
  const [loading, setLoading] = useState(true);

  const [dataEstApprovals, setDataEstApprovals] = useState([]);

  const { setSelectedModalType } = useCharts();

  //GET ITEM DO LOCAL STORAGE - ITEM SELECIONADO NO SELECT PARA RENDERIZAR OS GRAFICOS
  const getTableFirstGraphics = JSON.parse(localStorage.getItem(`${params.id}_favFirstGraphics`));
  const getTableSecondGraphics = JSON.parse(localStorage.getItem(`${params.id}_favSecondGraphics`));
  const getTablePieGraphics = JSON.parse(localStorage.getItem(`${params.id}_favPieGraphics`));

  //VALIDAÇÃO TITULO DO GRAFICO
  const titleFirstGraphics = getTableFirstGraphics
    ? getTableFirstGraphics[0].title
    : 'Quantidade de cupons consumidos';
  const titleSecondGraphics = getTableSecondGraphics
    ? getTableSecondGraphics[0].title
    : 'Quantidade de clientes com consumo';
  const titlePieGraphics = getTablePieGraphics
    ? getTablePieGraphics[0].title
    : 'Quantidade de registros de visitas';

  //VALIDAÇÃO TABELA DOS GRAFICOS
  const valueFirstGraphics = getTableFirstGraphics
    ? getTableFirstGraphics[0].value
    : 'rep.portal_estabelecimento';
  const valueSecondGraphics = getTableSecondGraphics
    ? getTableSecondGraphics[0].value
    : 'rep.consumo_voucher';
  const valuePieGraphics = getTablePieGraphics
    ? getTablePieGraphics[0].value
    : 'rep.voucher_registro_visita';

  const classes = useStyles();
  const matches = {
    sm: useMediaQuery('(max-width: 600px)'),
    md: useMediaQuery('(max-width: 1600px), (max-width: 1366px)'),
  };

  const optionButtonsWidth = {
    width: matches.sm || matches.md ? '205px' : '280px',
    fontSize: matches.sm || matches.md ? '14px' : '16px',
  };

  const handleSetModal = (type) => {
    type && setSelectedModalType(type);
    setOpenModal(!openModal);
  };

  const handleOpenAlert = () => {
    setOpenAlert(true);
  };

  const handleCloseAlert = () => {
    setOpenAlert(false);
  };

  //função para condicionar o click nos botões e bloquear não assinantes
  const handleLink = (path) => {
    if (signature === 'active' || isAdmin) {
      history.push(path);
    } else {
      handleOpenAlert();
    }
  };

  const handleLinkNoSignature = (path) => {
    history.push(path);
  };

  useEffect(() => {
    setEdit(false);
    setLoading(true);
    const promises = [
      localStorage.getItem('isChangedEstablishment') && getEstablishmentChangesRequest(params.id),
      getEstablishmentId(params.id),
    ];

    Promise.allSettled(promises)
      .then((res) => {
        if (
          res[0].status === 'fulfilled' &&
          res[0].value &&
          res[0].value.response.establishmentEdits
        ) {
          setEdit(true);
          setLoading(false);
          setPlaceState(res[0].value.response.establishmentEdits.changedFields);
        } else {
          setLoading(false);
          setPlaceState(res[1].value);
        }
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [params, location]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await getEstablishmentChangesRequest(params.id);
        const data = result.response;

        const dataApprovals = data.establishmentApprovals;
        let estApprovals;

        // Verifica se dataApprovals está definido e não vazio
        if (dataApprovals && Object.keys(dataApprovals).length > 0) {
          // Pega o primeiro elemento do objeto dataApprovals
          const firstApprovalKey = Object.keys(dataApprovals)[0];
          estApprovals = dataApprovals[firstApprovalKey];
        }

        setDataEstApprovals(estApprovals);
      } catch (error) {
        console.error('Erro na requisição:', error);
      }
    };

    fetchData();
  }, [params.id]);

  useEffect(() => {
    //REQUISIÇÃO GRAFICO DE BARRAS 1
    const firstGraphics = async () => {
      try {
        const response = await dataGraphics(params.id, valueFirstGraphics);

        if (valueFirstGraphics === 'rep.portal_estabelecimento') {
          const chartData = response.map((coupon) => ({
            [`dia ${parseDate(coupon.data_consumido)}`]: coupon.cupons_consumidos_dia,
          }));
          setDataFirstGraphic(chartData);
        }

        if (valueFirstGraphics === 'rep.consumo_voucher') {
          const chartData = response.map((coupon) => ({
            [`dia ${parseDate(coupon.dia_consumido)}`]: coupon.qtd_clientes,
          }));
          setDataFirstGraphic(chartData);
        }

        if (valueFirstGraphics === 'rep.voucher_registro_visita') {
          const chartData = response.map((coupon) => ({
            [`dia ${parseDate(coupon.dia_consumido)}`]: coupon.qtd_clientes,
          }));
          setDataFirstGraphic(chartData);
        }

        if (valueFirstGraphics === 'rep.clientes_novos') {
          const chartData = response.map((coupon) => ({
            [`dia ${parseDate(coupon.primeiro_consumo)}`]: coupon.qtd_clientes,
          }));
          setDataFirstGraphic(chartData);
        }
      } catch (error) {
        // console.log("ERROR_RESPONSE_GRAPHICS===>", error);
      }
    };

    firstGraphics();
  }, [valueFirstGraphics, params]);

  useEffect(() => {
    //REQUISIÇÃO GRAFICO DE BARRAS 2
    const secondGraphics = async () => {
      try {
        const response = await dataGraphics(params.id, valueSecondGraphics);

        if (valueSecondGraphics === 'rep.portal_estabelecimento') {
          const chartData = response.map((coupon) => ({
            [`dia ${parseDate(coupon.data_consumido)}`]: coupon.cupons_consumidos_dia,
          }));
          setDataSecondGraphic(chartData);
        }

        if (valueSecondGraphics === 'rep.consumo_voucher') {
          const chartData = response.map((coupon) => ({
            [`dia ${parseDate(coupon.dia_consumido)}`]: coupon.qtd_clientes,
          }));
          setDataSecondGraphic(chartData);
        }

        if (valueSecondGraphics === 'rep.voucher_registro_visita') {
          const chartData = response.map((coupon) => ({
            [`dia ${parseDate(coupon.dia_consumido)}`]: coupon.qtd_clientes,
          }));
          setDataSecondGraphic(chartData);
        }

        if (valueSecondGraphics === 'rep.clientes_novos') {
          const chartData = response.map((coupon) => ({
            [`dia ${parseDate(coupon.primeiro_consumo)}`]: coupon.qtd_clientes,
          }));
          setDataSecondGraphic(chartData);
        }
      } catch (error) {
        // console.log("ERROR_RESPONSE_GRAPHICS===>", error);
      }
    };

    secondGraphics();
  }, [valueSecondGraphics, params]);

  useEffect(() => {
    //REQUISIÇÃO GRAFICO DE TORTA
    const pieGraphics = async () => {
      try {
        const response = await dataGraphics(params.id, valuePieGraphics);

        if (valuePieGraphics === 'rep.portal_estabelecimento') {
          const chartData = response.map((coupon) => ({
            [`dia ${parseDate(coupon.data_consumido)}`]: coupon.cupons_consumidos_dia,
          }));
          setDataPieGraphic(chartData);
        }

        if (valuePieGraphics === 'rep.consumo_voucher') {
          const chartData = response.map((coupon) => ({
            [`dia ${parseDate(coupon.dia_consumido)}`]: parseInt(coupon.qtd_clientes),
          }));
          setDataPieGraphic(chartData);
        }

        if (valuePieGraphics === 'rep.voucher_registro_visita') {
          const chartData = response.map((coupon) => ({
            [`dia ${parseDate(coupon.dia_consumido)}`]: parseInt(coupon.qtd_clientes),
          }));
          setDataPieGraphic(chartData);
        }

        if (valuePieGraphics === 'rep.clientes_novos') {
          const chartData = response.map((coupon) => ({
            [`dia ${parseDate(coupon.primeiro_consumo)}`]: parseInt(coupon.qtd_clientes),
          }));
          setDataPieGraphic(chartData);
        }
      } catch (error) {
        // console.log("ERROR_RESPONSE_GRAPHICS===>", error);
      }
    };
    pieGraphics();
  }, [valuePieGraphics, params]);

  useEffect(() => {
    userReadUid_v3(loggedUid).then((userResult) => {
      setIsAdmin(userResult.userInfo.admin);
    });
  }, [loggedUid]);

  useEffect(() => {
    //REQUISIÇAÕ STATUS DA ASSINATURA DO ESTABELECIMENTO
    if (state) {
      const statusSignature = async () => {
        try {
          const idSignature =
            state.signatureId === '' ||
            state.signatureId === null ||
            state.signatureId === undefined
              ? '00000'
              : state.signatureId;
          const response = await signatureStatus(params.id, idSignature);
          setSignature(response);
        } catch (error) {
          // console.log("ERROR_RESPONSE_SIGNATURE===>", error);
        }
      };

      statusSignature();
    }
  }, [params, state]);

  const handleButtonEstablishment = (e) => {
    if (dataEstApprovals.status === 'aberto' && isAdmin) {
      e.preventDefault();
      toast.error(
        'Não é possivel acessar esse estabelecimento com status "Em Revisão", utilize a aba de curadoria!'
      );
    }
  };

  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size="100px" />
      </div>
    );
  }
  return (
    <div style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      {state ? (
        <>
          <HeaderTitle pageInfo={state.nome || 'Nome do Estabelecimento'} />
          <Container maxWidth={false} className={classes.root}>
            <ToastContainer autoClose="4000" />
            <Grid className={classes.alert}>
              {/* alert reprovação */}
              {!edit && dataEstApprovals && dataEstApprovals.status === 'negado' && (
                <Alert variant="filled" severity="error">
                  {dataEstApprovals.comment}
                </Alert>
              )}
              {/* alert edição */}
              {dataEstApprovals.status === 'aberto' && (
                <Alert variant="filled" severity="info">
                  Seu estabelecimento possui uma versão em processo de revisão, o prazo de resposta
                  é de 48h úteis.
                </Alert>
              )}
            </Grid>
            <Modal
              aria-labelledby="spring-modal-title"
              aria-describedby="spring-modal-description"
              className={classes.modal}
              open={openModal}
              onClose={handleSetModal}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
              disableAutoFocus
              disableEnforceFocus
            >
              <Fade in={openModal}>
                <div className={classes.paper}>
                  <ChartDataModal closeModal={handleSetModal} />
                </div>
              </Fade>
            </Modal>
            <Grid container>
              <Grid item lg={6}></Grid>
              <Grid item lg={6} container justify="flex-end">
                {/* <Typography color="primary" variant="h5" className={classes.title}>
            Saldo: 420 Créditos
          </Typography> */}
              </Grid>
            </Grid>
            <Grid container className={classes.boxContent}>
              <Grid item xs={12} md={3} lg={3} xl={3}>
                <Box display="flex" justifyContent="center" height="100%" pl={matches.md ? 6 : 5}>
                  <Box display="flex" alignItems="center">
                    <PreviewMobalEstablishment
                      title={state.nome}
                      description={state.descricao}
                      link={state.site}
                      imagesList={state.listaFotos}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={4} xl={3}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  alignItems="center"
                  mt={matches.md ? 6 : 0}
                >
                  <Box className={classes.boxStatusButton}>
                    <Typography variant="subtitle1" className={classes.label}>
                      Status do Plano
                    </Typography>
                    <Button
                      variant="contained"
                      size="large"
                      classes={
                        signature === 'active'
                          ? { root: classes.statusButtonActive }
                          : { root: classes.statusButtonInactive }
                      }
                      disableFocusRipple={false}
                      fullWidth
                      disableRipple
                    >
                      <Typography
                        variant="h4"
                        style={{
                          fontWeight: 'bold',
                          textTransform: 'capitalize',
                        }}
                      >
                        {signature === 'active' ? 'Ativo' : 'Gratuito'}
                      </Typography>
                    </Button>
                  </Box>
                  {/* <Box>
                <Typography variant="subtitle1" className={classes.label}>
                  Avaliação dos clientes
                </Typography>
                <span>
                  <StarRateIcon classes={{ root: classes.starIcon }} />
                  <StarRateIcon classes={{ root: classes.starIcon }} />
                </span>
              </Box> */}
                  <Box
                    minWidth={250}
                    display="flex"
                    alignItems="center"
                    flexDirection="column"
                    justifyContent="center"
                    mt={4}
                  >
                    <Typography variant="subtitle1" className={classes.label}>
                      {titlePieGraphics}
                      <IconButton onClick={() => handleSetModal('pie')}>
                        <CachedIcon color="primary" />
                      </IconButton>
                    </Typography>
                    {dataPieGraphic ? (
                      <PieChart
                        data={dataPieGraphic.slice(Math.max(dataPieGraphic.length - 5, 0))}
                      />
                    ) : (
                      <Box height={250} display="flex" alignItems="center" justifyContent="center">
                        <CircularProgress size={70} />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
              <Grid item md={1} lg={1} xl={1}>
                <Box height="100%" display="flex" pt={5}>
                  <Line />
                </Box>
              </Grid>
              <Grid item xs={12} md={4} lg={4} xl={5}>
                <Box
                  width={matches.sm || matches.md ? '100%' : '80%'}
                  display="flex"
                  flexDirection="column"
                  justifyContent="space-between"
                  pt={matches.md ? 10 : 3}
                  pb={matches.md ? 6 : 3}
                >
                  <Box>
                    <Typography variant="subtitle1" className={classes.label}>
                      {titleFirstGraphics}
                      <IconButton onClick={() => handleSetModal('firstBar')}>
                        <CachedIcon color="primary" />
                      </IconButton>
                    </Typography>
                    {dataFirstGraphic ? (
                      <BarChart
                        data={dataFirstGraphic.slice(Math.max(dataFirstGraphic.length - 5, 0))}
                      />
                    ) : (
                      <Box height={250} display="flex" alignItems="center" justifyContent="center">
                        <CircularProgress size={70} />
                      </Box>
                    )}
                  </Box>
                  <Box>
                    <Typography variant="subtitle1" className={classes.label}>
                      {titleSecondGraphics}
                      <IconButton onClick={() => handleSetModal('secondBar')}>
                        <CachedIcon color="primary" />
                      </IconButton>
                    </Typography>
                    {dataSecondGraphic ? (
                      <BarChart
                        data={dataSecondGraphic.slice(Math.max(dataSecondGraphic.length - 5, 0))}
                      />
                    ) : (
                      <Box height={250} display="flex" alignItems="center" justifyContent="center">
                        <CircularProgress size={70} />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: '2rem' }} className={classes.boxGroupButton}>
              <Grid item sm={12} container direction="column" alignItems="center">
                <Box mb={1}>
                  <Link
                    className={classes.link}
                    to={{
                      pathname: isAdmin
                        ? `/admin/estabelecimento/${params.id}/ofertas`
                        : `/estabelecimento/${params.id}/promocoes`,
                      state,
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.optionButton}
                      classes={{ root: classes.warningButton }}
                      style={optionButtonsWidth}
                    >
                      Promoções
                    </Button>
                  </Link>
                  <span className={classes.link} style={{ cursor: 'not-allowed' }}>
                    <Link
                      className={classes.link}
                      to={{
                        pathname: `/estabelecimento/${params.id}/relacionamento/`,
                        state,
                      }}
                    >
                      <Button
                        // disabled
                        variant="contained"
                        color="primary"
                        className={classes.optionButton}
                        classes={{ root: classes.infoButton }}
                        style={optionButtonsWidth}
                      >
                        Relacionamento
                      </Button>
                    </Link>
                  </span>
                  <span className={classes.link}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.optionButton}
                      classes={{ root: classes.successButton }}
                      style={optionButtonsWidth}
                      onClick={() =>
                        handleLinkNoSignature({
                          pathname: `/estabelecimento/${params.id}/resultados`,
                          state: {
                            establishmentId: params.id,
                            establishmentName: params.nome,
                            ...state,
                          },
                        })
                      }
                    >
                      Resultados
                    </Button>
                  </span>
                  <Link
                    className={classes.link}
                    onClick={handleButtonEstablishment}
                    to={
                      dataEstApprovals.status !== 'aberto' && !isAdmin
                        ? { pathname: `/estabelecimento/${params.id}/editar`, state }
                        : { pathname: `/estabelecimento/${params.id}/editar`, state }
                    }
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.optionButton}
                      classes={{ root: classes.errorButton }}
                      style={optionButtonsWidth}
                    >
                      Estabelecimento
                    </Button>
                  </Link>
                </Box>
                <Box mb={5}>
                  <span className={classes.link} style={{ cursor: 'not-allowed' }}>
                    <Button
                      disabled
                      variant="contained"
                      color="primary"
                      className={classes.optionButton}
                      classes={{ root: classes.warningButton }}
                      style={optionButtonsWidth}
                      onClick={() =>
                        handleLink({
                          pathname: `/estabelecimento/${params.id}/comprarcreditos`,
                        })
                      }
                    >
                      Comprar Pontos
                    </Button>
                  </span>
                  <span className={classes.link}>
                    <Button
                      variant="contained"
                      color="primary"
                      // disabled
                      className={classes.optionButton}
                      classes={{ root: classes.infoButton }}
                      style={optionButtonsWidth}
                      // onClick={() =>
                      //     handleLink({
                      //         pathname: `/estabelecimento/${params.id}/promover/`,
                      //         state,
                      //     })
                      // }
                      onClick={() =>
                        handleLinkNoSignature({
                          pathname: `/estabelecimento/${params.id}/promover/`,
                          state: {
                            establishmentId: params.id,
                            establishmentName: params.nome,
                            ...state,
                          },
                        })
                      }
                    >
                      Promover
                    </Button>
                  </span>
                  <span className={classes.link}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.optionButton}
                      classes={{ root: classes.successButton }}
                      style={optionButtonsWidth}
                      // onClick={() =>
                      //     handleLink({
                      //         pathname: `/estabelecimento/${params.id}/administradores`,
                      //         state,
                      //     })
                      // }
                      onClick={() =>
                        handleLinkNoSignature({
                          pathname: isAdmin
                            ? `${location.pathname}/administradores`
                            : `${location.pathname}/administradores`,
                          state: {
                            establishmentId: params.id,
                            establishmentName: params.nome,
                            ...state,
                          },
                        })
                      }
                    >
                      Equipe
                    </Button>
                  </span>
                  <Link className={classes.link} to={`/estabelecimento/${params.id}/suporte`}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.optionButton}
                      classes={{ root: classes.errorButton }}
                      style={optionButtonsWidth}
                    >
                      Suporte
                    </Button>
                  </Link>
                  <span className={classes.link}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.optionButton}
                      classes={{ root: classes.errorButton }}
                      style={optionButtonsWidth}
                      onClick={() =>
                        handleLinkNoSignature({
                          pathname: `/estabelecimento/${params.id}/atendimento`,
                          state: {
                            establishmentId: params.id,
                            establishmentName: params.nome,
                            ...state,
                          },
                        })
                      }
                    >
                      Atendimento
                    </Button>
                  </span>
                </Box>
              </Grid>
            </Grid>
            <Dialog
              onClose={handleCloseAlert}
              aria-labelledby="customized-dialog-title"
              open={openAlert}
            >
              <DialogTitle id="customized-dialog-title" onClose={handleCloseAlert}>
                Faça um Upgrade para ter acesso a essa funcionalidade!
              </DialogTitle>
              <DialogContent dividers>
                Para ter acesso à funcionalidade é necessário um Plano Básico ou Premium
                <div className={classes.dialogButton}>
                  <a
                    href="https://triibo.com.br/forma-de-pagamento"
                    target="new"
                    rel="noopener noreferrer"
                    style={{ textDecoration: 'none' }}
                  >
                    <Button
                      variant="contained"
                      style={{
                        color: 'white',
                        backgroundColor: '#78AA1E',
                        borderRadius: '20px',
                        textTransform: 'none',
                      }}
                    >
                      <Typography
                        align="center"
                        variant="subtitle2"
                        style={{
                          fontSize: '0.7rem',
                          fontWeight: 'bold',
                          lineHeight: '1em',
                        }}
                      >
                        <Typography
                          align="center"
                          variant="subtitle2"
                          style={{
                            fontSize: '0.9rem',
                            fontWeight: 'bold',
                          }}
                        >
                          Conhecer Planos
                        </Typography>
                      </Typography>
                    </Button>
                  </a>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={handleCloseAlert}
                  variant="contained"
                  style={{ backgroundColor: '#f44336', color: 'white' }}
                >
                  Cancelar
                </Button>
              </DialogActions>
            </Dialog>
          </Container>
        </>
      ) : (
        <div align="center">
          <CircularProgress size={70} />
        </div>
      )}
    </div>
  );
}

