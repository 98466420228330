import React from 'react';
import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import { StyledButton } from './styledButton';
import { AreaHeader, GoBack } from '../../Configurations/CreateClub/styles';
import { MdArrowBackIosNew } from 'react-icons/md';
import { useParams } from 'react-router-dom';

const AdminFunctions = () => {
  const params = useParams();
  const concatId = params?.id ? `/${params?.id}` : '';

  const cardsList = [
    {
      value: 'Envio Notificações',
      url: '/admin/configuracoes/listaNotificacoes',
    },
    {
      value: 'Cadastro de administradores',
      url: '/admin/configuracoes/administradores',
    },
    {
      value: 'Criar usuários',
      url: '/admin/configuracoes/usuarios',
    },
    {
      url: '/admin/configuracoes/creditarPontos',
      value: 'Creditar Pontos',
    },
    {
      url: '/admin/configuracoes/pesquisarUsuario',
      value: 'Pesquisar Usuário',
    },
    {
      url: '/admin/configuracoes/cards',
      value: 'Cadastro de Cards',
    },
    {
      url: '/admin/configuracoes/keywords',
      value: 'Cadastro de Keywords',
    },
    {
      url: '/admin/configuracoes/acoeslote',
      value: 'Ações em Lote',
    },
    {
      url: '/admin/configuracoes/carimbos',
      value: 'Carimbo',
    },
    {
      url: '/admin/configuracoes/gerarLink',
      value: 'Gerar Link',
    },
    {
      url: '/admin/configuracoes/sorteios',
      value: 'Campanha Triibo',
    },
    {
      url: '/admin/configuracoes/qrcodes',
      value: 'Lista de QRCode',
    },
    {
      url: '/admin/configuracoes/organizacoes',
      value: 'Cadastrar Organização',
    },
    {
      url: '/admin/configuracoes/templates',
      value: 'Criar Templates',
    },
    {
      url: `/admin/configuracoes/campanhas/listarCampanhas${concatId}`,
      value: 'Campanhas',
    },
    {
      url: '/admin/configuracoes/listaPostagem',
      value: 'Cadastro de Postagem',
    },
    {
      url: '/admin/configuracoes/mapfre',
      value: 'Área MAPFRE',
    },
  ];

  const cardsListOrdered = cardsList.sort((a, b) => {
    let x = a.value.toUpperCase(),
      y = b.value.toUpperCase();

    return x === y ? 0 : x > y ? 1 : -1;
  });

  return (
    <div style={{ padding: '4rem' }}>
      <AreaHeader
        style={{ color: '#08bad0', justifyContent: 'end', padding: '1rem', marginLeft: '3rem' }}
      >
        <GoBack onClick={() => window.history.back()}>
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <S.Container>
        <D.TriiboH1
          style={{
            textAlign: 'center',
            marginBottom: '3rem',
            marginTop: '-5rem',
          }}
        >
          Funções Administrativas
        </D.TriiboH1>

        <S.Grid>
          {cardsListOrdered.map((card, index) => (
            <StyledButton value={card.value} url={card.url} index={index} key={index} />
          ))}
        </S.Grid>
      </S.Container>
    </div>
  );
};

export default AdminFunctions;
