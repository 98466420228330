import { Box, MenuItem, RadioGroup, Select, TextField, Switch } from '@mui/material';
import styled from 'styled-components';

//CardManagement
export const TitleCards = styled.h2`
  display: flex;
  align-items: center;
  color: #06bad0;
  font-weight: 700;
  margin-bottom: 2rem;
`;

//DragDrop
export const ContainerDragDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  line-height: 2rem;
  margin: 0 auto;

  li {
    list-style: none;
  }
`;

export const ContentDragDrop = styled.div`
  border-radius: 1rem;
  box-shadow: 0px -3px 10px rgb(168, 166, 166);
  padding: 2rem;
  width: 750px;

  img {
    max-width: 100%;
    margin: 0.3rem 0;
  }

  .img-logo {
    height: 1.8rem;
    width: auto;
  }

  @media screen and (max-width: 900px) {
    width: 80%;
  }
`;

export const CardDefault = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #e9e9e9;
  height: 14rem;
  margin: 1.2rem 0;
  width: 100%;

  div {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      cursor: pointer;
      width: 8rem;

      @media screen and (max-width: 900px) {
        width: 100%;
        cursor: pointer;
        height: 4rem;
      }
    }
  }

  @media screen and (max-width: 900px) {
    height: 7rem;
    width: 100%;
  }

  @media screen and (max-width: 900px) {
    height: 9rem;
    width: 100%;
  }

  @media screen and (max-width: 750px) {
    height: 7rem;
  }

  @media screen and (max-width: 480px) {
    height: 5rem;
  }
`;

export const Cards = styled.img`
  height: 13rem;
  width: 100%;

  transition: filter 0.4s;

  &:hover {
    filter: brightness(0.6);
  }

  @media screen and (max-width: 900px) {
    width: 100%;
    height: auto;
  }
`;

export const FlutuantButton = styled.button`
  background: #06bad0;
  border: 0;
  border-radius: 0.8rem;
  box-shadow: 0px -3px 10px rgb(168, 166, 166);
  color: #fff;
  display: ${(props) => (props.movemode ? 'block' : 'none')};
  font-size: 1rem;
  font-weight: 700;
  height: 3rem;
  width: 8rem;

  position: fixed;
  top: 26rem;
  right: 8rem;

  transition: filter 0.3s;

  &:hover {
    cursor: pointer;
    filter: brightness(0.9);
  }
  @media screen and (max-width: 1357px) {
    width: 4rem;
    height: 4rem;
    right: 6rem;
    font-size: 0.7rem;

    span {
      img {
        width: 33px;
      }
    }
  }

  @media screen and (max-width: 1156px) {
    right: 2rem;
  }

  @media screen and (max-width: 1026px) {
    right: 0rem;
  }

  @media screen and (max-width: 900px) {
    border-radius: 0.5rem;
    font-size: 0.6rem;
    position: fixed;
    right: 0.3rem;
    top: 33.5rem;
    width: 2.5rem;
    height: 2.5rem;

    span {
      img {
        width: 25px;
      }
    }
  }
`;

export const ContainerNoHaveCard = styled.div`
  margin: 0 auto;

  p {
    font-size: 1.2rem;
    font-weight: 600;
    margin: 2rem 0;
    padding: 0;
    text-align: center;

    span {
      color: #06bad0;
      font-size: 1.7rem;
    }
  }

  @media screen and (max-width: 900px) {
    p {
      margin: 3rem 0;
      line-height: 1.2;
      font-size: 0.8rem;
      padding: 0;
      text-align: center;
    }
  }
`;

export const ContentHeaderDragDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media screen and (max-width: 450px) {
    img {
      max-width: 80%;
      height: auto;
    }
  }
`;

export const ButtonMoveCards = styled.button`
  background: #06bad0;
  display: flex;
  align-items: center;

  border: 0;
  border-radius: 6px;
  box-sizing: border-box;
  color: #fff !important;
  cursor: pointer;
  height: 2.3rem;
  padding: 0.6rem;

  transition: filter 0.3s;

  &:hover {
    cursor: pointer;
    filter: brightness(0.9);
  }

  @media screen and (max-width: 550px) {
    height: 2rem;

    svg {
      width: 0.8em;
      height: 0.8em;
    }
  }

  @media screen and (max-width: 500px) {
    height: 1.8rem;

    svg {
      width: 0.6em;
      height: 0.6em;
    }
  }
`;

export const EditCardButton = styled.button`
  border: 1px solid #bbb;
  border-radius: 1rem;
  height: 5.5rem;
  opacity: 0;
  width: 5.5rem;

  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);

  svg {
    font-size: 3rem;
  }

  @media screen and (max-width: 600px) {
    height: 2.5rem;
    width: 2.5rem;
    border-radius: 0.7rem;

    svg {
      font-size: 1.5rem;
    }
  }

  @media screen and (max-width: 400px) {
    height: 2rem;
    width: 2rem;
    border-radius: 0.5rem;
  }
`;

export const ContainerCards = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  &:hover button {
    opacity: 0.7;
    /* visibility: ${(props) => (props.moveOnMode ? 'hidden' : 'visible')}; */
    ${(props) => (props.cursor ? 'cursor: pointer;' : 'cursor: grab;')}
  }
`;

//ModalNewCard
export const RadioGroupRow = styled(RadioGroup)`
  margin: 0.5rem 0 0 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 0.5rem;

  span {
    margin: 0.1rem !important;
    font-weight: 600;
  }
`;

export const MenuItems = styled(MenuItem)`
  display: flex !important;
  flex-direction: column !important;
`;

export const TagText = styled.span`
  color: #06bad0;
  font-size: 0.7rem;

  font-weight: 700;
  margin-top: 0.8rem;
`;

export const ContentModalNewCard = styled.form`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`;

export const SelectsModalNewCard = styled(Select)`
  border-radius: 0.5rem;
  width: 100%;

  div {
    margin-left: 1rem !important;
  }
`;

export const SwitchPersonality = styled(Switch)`
  .MuiSwitch-switchBase {
    color: #aaa;
    &.Mui-checked {
      color: #06bad0;
    }
  }
  .MuiSwitch-track {
    background-color: #06bad0 !important;
  }
`;

export const InputFormNewCard = styled(TextField)`
  margin: 0 !important;
  width: 100% !important;

  div {
    border-radius: 0.5rem;
    height: ${(props) => (props.isexpansive ? props.isexpansive : '3.2rem')};
  }

  input {
    margin-left: 0.2rem;
  }
`;

