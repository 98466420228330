import React, { useEffect } from 'react';
import * as S from '../styles';
import { Box } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import Dropdown from '../../EstablishmentSteps/Dropdown';
import ContactInputFieldCuration from './ContactInputFieldCuration';
import {
  FaFacebookSquare,
  FaHandsHelping,
  FaInstagramSquare,
  FaLinkedinIn,
  FaPhoneAlt,
  FaSms,
  FaTwitter,
  FaWhatsapp,
} from 'react-icons/fa';
import { cloneDeep } from 'lodash';

const StepTwoCuration = ({ formState, setFormState, finalFormState, setFinalFormState }) => {
  const cloneFormState = cloneDeep(formState);

  const numbersProps = [
    {
      icon: <FaPhoneAlt style={{ color: '#06BAD0' }} />,
      placeholdeText: '00 00000-0000',
      type: 'telefone',
    },
    {
      icon: <FaWhatsapp style={{ color: '#42BF00' }} />,
      placeholdeText: '00 00000-0000',
      type: 'whatsapp',
    },
    {
      icon: <FaSms style={{ color: '#06BAD0' }} />,
      placeholdeText: '00 00000-0000',
      type: 'sms',
    },
    {
      icon: <FaHandsHelping style={{ color: '#AC10D1' }} />,
      placeholdeText: '0800 0800',
      type: '0800',
    },
  ];

  const socialsProps = [
    {
      icon: <FaInstagramSquare style={{ color: '#DD2A7B' }} />,
      placeholdeText: 'https://instagram.com/triibo/',
      type: 'instagram',
    },
    {
      icon: <FaFacebookSquare style={{ color: '#4267B2' }} />,
      placeholdeText: 'https://facebook.com/triibo/',
      type: 'facebook',
    },
    {
      icon: <FaTwitter style={{ color: '#1DA1F2' }} />,
      placeholdeText: 'https://twitter.com/triibo/',
      type: 'twitter',
    },
    {
      icon: <FaLinkedinIn style={{ color: '#0077b5' }} />,
      placeholdeText: 'https://linkedin.com/company/triibo/',
      type: 'linkedin',
    },
  ];

  useEffect(() => {
    // Função para remover objetos com informações iguais
    function removeObjEqual(arrOne, arrTwo) {
      return arrOne.filter(
        (objOne) => !arrTwo.some((objTwo) => objOne.id === objTwo.id && objOne.nome === objTwo.nome)
      );
    }

    // Chamada da função e atualização do estado da array1 com os objetos removidos
    const newArrContatos = removeObjEqual(cloneFormState.contato, cloneFormState.contatosExcluido);

    const newArrRedesSociais = removeObjEqual(
      cloneFormState.redesSociais,
      cloneFormState.redesSociaisExcluidas
    );

    setFormState({
      ...formState,
      contatosExcluido: newArrContatos,
      redesSociaisExcluidas: newArrRedesSociais,
    });
  }, []);

  return (
    <>
      <ToastContainer autoClose={2500} />
      <Box sx={{ px: { xs: '1.4rem', md: '6rem' } }}>
        <S.MainContainer stepTwo={'true'}>
          <S.CardContainer white={'true'}>
            <S.SectionLabelContainer>
              <S.SectionLabelBig>Contatos para os clientes</S.SectionLabelBig>
              <S.SectionLabelSmall>
                estes contatos estarão disponíveis para o público*
              </S.SectionLabelSmall>
            </S.SectionLabelContainer>
            <S.ContactInfoContainer>
              <Dropdown
                placeholder={'Selecione um meio de contato'}
                formState={formState}
                setFormState={setFormState}
                setFinalFormState={setFinalFormState}
                socials={false}
                numbersProps={numbersProps}
              />
              <S.ContactFieldsContainer>
                {formState.contato.map(({ value, type }, index) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                    key={index}
                  >
                    <ContactInputFieldCuration
                      key={index}
                      placeholder={numbersProps.filter((e) => e.type === type)[0].placeholdeText}
                      icon={numbersProps.filter((e) => e.type === type)[0].icon}
                      formState={formState}
                      setFormState={setFormState}
                      setFinalFormState={setFinalFormState}
                      index={index}
                      group={'contact'}
                      type={type}
                    />
                  </Box>
                ))}
              </S.ContactFieldsContainer>
            </S.ContactInfoContainer>
            <br />
            <S.SectionLabelBig>Telefones excluídos</S.SectionLabelBig>
            <S.ContactInfoContainer>
              <S.ContactFieldsContainer>
                {formState.contatosExcluido.length === 0 || !formState.contatosExcluido ? (
                  <S.SectionLabelSmall>Não a telefones excluídos !</S.SectionLabelSmall>
                ) : (
                  formState.contatosExcluido.map(({ value, type }, index) => (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                      key={index}
                    >
                      <ContactInputFieldCuration
                        key={index}
                        placeholder={numbersProps.filter((e) => e.type === type)[0].placeholdeText}
                        icon={numbersProps.filter((e) => e.type === type)[0].icon}
                        formState={formState}
                        setFormState={setFormState}
                        index={index}
                        group={'contactDeleted'}
                        type={type}
                      />
                    </Box>
                  ))
                )}
              </S.ContactFieldsContainer>
            </S.ContactInfoContainer>
          </S.CardContainer>
          <S.CardContainer blue={'true'}>
            <S.SectionLabelContainer white={'true'}>
              <S.SectionLabelBig>Sites e redes sociais</S.SectionLabelBig>
            </S.SectionLabelContainer>
            <S.ContactInfoContainer>
              <Dropdown
                placeholder={'Selecione uma rede social'}
                formState={formState}
                setFormState={setFormState}
                socials={true}
                socialsProps={socialsProps}
              />
              <S.ContactFieldsContainer>
                {formState.redesSociais.map(({ value, type }, index) => (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      width: '100%',
                    }}
                    key={index}
                  >
                    <ContactInputFieldCuration
                      key={index}
                      placeholder={socialsProps.filter((e) => e.type === type)[0].placeholdeText}
                      icon={socialsProps.filter((e) => e.type === type)[0].icon}
                      formState={formState}
                      setFormState={setFormState}
                      index={index}
                      type={type}
                      group={'socials'}
                    />
                  </Box>
                ))}
              </S.ContactFieldsContainer>
            </S.ContactInfoContainer>
            <br />
            <S.SectionLabelBig white={'true'}>Sites e redes sociais excluídos</S.SectionLabelBig>
            <S.ContactInfoContainer>
              <S.ContactFieldsContainer>
                {formState.redesSociaisExcluidas.length === 0 ||
                !formState.redesSociaisExcluidas ? (
                  <S.SectionLabelSmall white={'true'}>
                    Não a sites e redes sociais excluídos !
                  </S.SectionLabelSmall>
                ) : (
                  formState.redesSociaisExcluidas.map(({ value, type }, index) => (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        width: '100%',
                      }}
                      key={index}
                    >
                      <ContactInputFieldCuration
                        key={index}
                        placeholder={socialsProps.filter((e) => e.type === type)[0].placeholdeText}
                        icon={socialsProps.filter((e) => e.type === type)[0].icon}
                        formState={formState}
                        setFormState={setFormState}
                        index={index}
                        group={'socialsDeleted'}
                      />
                    </Box>
                  ))
                )}
              </S.ContactFieldsContainer>
            </S.ContactInfoContainer>
          </S.CardContainer>
        </S.MainContainer>
      </Box>
    </>
  );
};

export default StepTwoCuration;

