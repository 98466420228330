import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { compose } from 'recompose';
import { Redirect, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import FontPicker from 'font-picker-react';
import { firebaseDatabase } from 'js/library/utils/firebaseUtils';
import { Cropper } from 'react-image-cropper';
import Loading from 'js/containers/Loading/Loading';
import { updateMarketingTemplateAction } from 'js/core/actions/marketingTemplateActions';
import ModalImageAspecRation from './ModalImageAspectRatio';
import PreviewElementsForm from './PreviewElementsForm';
import './fontPicker.css';

import {
  TextField,
  Typography,
  Button,
  FormControl,
  Snackbar,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Tab,
  Tabs,
  AppBar,
  Modal,
  Fab,
  Tooltip,
  InputAdornment,
} from '@mui/material';

import { withStyles } from '@mui/styles';
import 'styles/css/App.css';
import {
  Add,
  Save,
  AddPhotoAlternate,
  ArrowBack,
  CropFree,
  Image,
  Height,
} from '@mui/icons-material';
import { downloadImage } from 'js/library/services/StorageManager';
import { HeaderTitle } from '../CreateClub/RelationshipClub/HeaderTitle';
import cfac22 from 'js/library/utils/cfac22';

const styles = (theme) => ({
  imageHover: {
    margin: '5px',
    borderRadius: '8px',
    boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',

    zIndex: 3,

    backgroundColor: 'rgba(125, 214, 225, .9)',

    '&:hover': {
      transition: 'opacity .2s',
      opacity: 2,
    },
  },
  blockedInput: {
    width: '100%',
    marginBottom: '10px',
  },
  fontInput: {
    width: '100%',
  },
});

const inputObjectText = {
  type: '',
  entity: 'text',
  initialText: '',
  blockText: false,
  fontColor: '#000000',
  blockColor: false,
  fontStyle: '',
  fontSize: '',
  blockStyle: false,
  heightPosition: '',
  heightSize: '',
  widthPosition: '',
  widthSize: '',
};

const inputObjectImage = {
  type: '',
  entity: '',
  initialImage: '',
  heightPosition: '',
  widthPosition: '',
  widthSize: 22,
  blockImage: false,
};

const inputObjectQrCode = {
  type: '',
  entity: '',
  qrCodeAction: '',
  heightPosition: '',
  widthPosition: '',
  widthSize: 22,
  blockQrCodeAction: false,
};

function UserTemplatesInformation(props) {
  const location = useLocation();
  const cropperRef = useRef(null);

  const [isShowPreview, setIsShowPreview] = useState(false);

  const ratio = {
    landscape: 1.41 / 1,
    portrait: 1 / 1.41,
    square: 1 / 1,
  };

  const imageSize = {
    landscape: { width: '282px', height: '200px' },
    portrait: { width: '200px', height: '282px' },
    square: { width: '282px', height: '282px' },
  };

  const [loading, setLoading] = useState(false);

  const [listImageURL, setListImageURL] = useState([]);
  const listAllImages =
    location.state?.backgroundImages === undefined ? '' : location.state?.backgroundImages;
  const [imageFormat, setImageFormat] = useState(
    location.state?.format === undefined ? 'landscape' : location.state?.format
  );
  const [copyImageFormat, setCopyImageFormat] = useState('');
  const [colorListChange, setColorListChange] = useState(['#000000']);
  const [templateName, setTemplateName] = useState(
    location.state?.name === undefined ? '' : location.state?.name
  );
  const [openCheck, setOpenCheck] = useState(false);
  const [messageAlert, setMessageAlert] = useState('');
  const [formElements, setFormElements] = useState(
    location.state?.elements === undefined ? [inputObjectText] : location.state?.elements
  );
  const [id, setId] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [currentImageCropped, setCurrentImageCropped] = useState(null);
  const [tutorialTemplate, setTutorialTemplate] = useState(
    location.state?.tutorial === undefined ? '' : location.state?.tutorial
  );

  const generateId = () => {
    const idTemplate = firebaseDatabase.ref('Marketing-Content-Templates').push().key;
    location.state?.key === undefined ? setId(idTemplate) : setId(location.state?.key);
  };
  // ESTADO DO FORMULARIO
  const [templateType, setTemplateType] = useState('');
  const { classes } = props;

  const [valueTab, setValueTab] = useState(0);
  const [openModalAspectRatio, setOpenModalAspecRatio] = useState(false);

  useEffect(() => {
    window.pathname = null;
    generateId();
  }, []);

  useEffect(() => {
    if (location.state?.backgroundImages !== undefined) {
      const colorArray = location.state?.backgroundImages;
      let listColor = [];

      colorArray.map((item) => {
        listColor.push(item.color);
      });

      setColorListChange([...listColor]);
    }
  }, []);

  useEffect(() => {
    const backgroundImages = location.state?.backgroundImages;

    if (backgroundImages !== undefined) {
      const imagePath = backgroundImages.map((item) => item.image);

      getImageUrl(imagePath);
    }
  }, []);

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValueTab(newValue);
  };

  async function getImageUrl(imagePath) {
    const imageUrl = await Promise.all(imagePath.map((path) => downloadImage('templates', path)));

    setListImageURL(imageUrl);
  }

  function removeImage(position) {
    setListImageURL([...listImageURL.filter((_, index) => index !== position)]);
    setColorListChange([...colorListChange.filter((_, index) => index !== position)]);
  }

  function checkInformation(e) {
    e.preventDefault();
    let isEmptyValue = false;

    setLoading(true);

    if (listImageURL.length === 0) {
      setLoading(false);
      setOpenCheck(true);
      setMessageAlert('É necessário adicionar uma imagem de template.');
      return;
    }

    if (tutorialTemplate.trim() === '') {
      const inputTutorial = document.getElementById('input-tutorial');
      inputTutorial.focus();
      setLoading(false);
      setOpenCheck(true);
      setTutorialTemplate('');
      setMessageAlert('Preencha os campos obrigatórios!');
      return;
    }

    // VERIFICA SE EXISTE ALGUM CAMPO DO TEMPLATE VAZIO
    formElements.forEach((item) => {
      const arrayTemplateInput = Object.values(item);
      arrayTemplateInput.forEach((value) => {
        if (value === '') {
          setLoading(false);
          isEmptyValue = true;
          setOpenCheck(true);
          setMessageAlert('Preencha os campos obrigatórios!');
        }
      });
    });

    // ENVIA O FORMULÁRIO PARA A ACTION
    if (!isEmptyValue) {
      props.updateMarketingTemplateComponent(
        id,
        templateName,
        listAllImages,
        listImageURL,
        formElements,
        imageFormat,
        colorListChange,
        tutorialTemplate
      );
    }
  }

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function selectTab(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function goBack() {
    window.history.back();
  }

  function handleFileChange(event) {
    if (event.target.files[0]) {
      const image = event.target.files[0];

      if (image.type === 'image/jpeg' || image.type === 'image/png') {
        const url = URL.createObjectURL(image);
        setCurrentImageCropped(url);
        setOpenModal(true);
      } else {
        alert('Envie uma imagem do tipo PNG, JPEG ou PDF');
        return null;
      }
    }
  }

  function onCropper() {
    const imageCropped = cropperRef.current.crop();

    let listURL = [];
    listURL = [...listImageURL, imageCropped];
    setListImageURL(listURL);

    if (listImageURL.length < 6) {
      setColorListChange([...colorListChange, '#000000']);
    }

    setOpenModal(false);
  }

  function onChangeColor(color, index) {
    colorListChange[index] = color;
    setColorListChange([...colorListChange]);
  }

  function onChangeElements(event, index) {
    const { name, value } = event.target;
    event.preventDefault();
    event.persist();

    // VERIFICA SE O VALOR DIGITADO É NEGATIVO
    if (
      (name === 'fontSize' ||
        name === 'heightPosition' ||
        name === 'widthPosition' ||
        name === 'widthSize' ||
        name === 'heightSize') &&
      value < 0
    ) {
      setOpenCheck(true);
      setMessageAlert('Não é permitido valores negativo.');
      return;
    }

    if (name === 'type' && formElements[index].entity !== 'qrCode') {
      formElements[index].initialText = '';
      setFormElements([...formElements]);
    }

    if (name === 'entity') {
      switch (value) {
        case 'text':
          formElements[index] = inputObjectText;
          formElements[index].type = templateType;

          setFormElements([...formElements]);
          break;
        case 'image':
          formElements[index] = inputObjectImage;
          formElements[index].type = templateType;

          setFormElements([...formElements]);
          break;
        case 'qrCode':
          formElements[index] = inputObjectQrCode;
          formElements[index].type = templateType;

          setFormElements([...formElements]);
          break;
        default:
          console.log('Nenhum valor encontrado.');
      }
      setFormElements((prev) => [...prev]);
    }
    setFormElements((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }

        return {
          ...item,
          [name]: value,
        };
      });
    });
  }

  function handleCheckbox(type, value, index, name) {
    const currentTemplateType =
      templateType === '' ? location.state?.elements[index].type : templateType;
    switch (type) {
      case 'text':
        formElements[index].type = currentTemplateType;
        setFormElements([...formElements]);
        break;

      case 'image':
        formElements[index].type = currentTemplateType;
        setFormElements([...formElements]);
        break;

      case 'qrCode':
        formElements[index].type = currentTemplateType;
        setFormElements([...formElements]);
        break;

      default:
        console.log('Nenhum valor encontrado.');
    }
    setFormElements((prev) => [...prev]);

    setFormElements((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }

        return {
          ...item,
          [name]: value,
        };
      });
    });
  }

  function handleAddEmptyStructure(event) {
    event.preventDefault();

    inputObjectText['type'] = '';

    setFormElements((prev) => [...prev, inputObjectText]);
  }

  function mouseOver(index) {
    let buttonClose = document.getElementById(`btn-close-${index}`);

    buttonClose.style.display = 'flex';
  }

  function mouseOut(index) {
    let buttonClose = document.getElementById(`btn-close-${index}`);

    buttonClose.style.display = 'none';
  }

  function getModalStyle() {
    return {
      position: 'absolute',
      top: '50%',
      left: '50%',
      backgroundColor: 'white',
      padding: '30px',
      width: '94%',
      height: '50%',
      maxWidth: '600px',
      maxHeight: '450px',
      transform: 'translate(-50%, -50%)',
    };
  }

  function handleSelectFonts(value, index, name) {
    setFormElements((prev) => [...prev]);

    setFormElements((prev) => {
      return prev.map((item, i) => {
        if (i !== index) {
          return item;
        }

        return {
          ...item,
          [name]: value,
        };
      });
    });
  }

  function handleResetImageAndColor() {
    setListImageURL([]);
    setColorListChange(['#000000']);
    setOpenModalAspecRatio(false);

    setImageFormat(copyImageFormat);
  }

  function handleCloseModalAspectRation() {
    setOpenModalAspecRatio(false);
  }

  function handleSelectFormat(event) {
    const { value } = event.target;

    if (listImageURL.length === 0) {
      setImageFormat(value);
    } else {
      setOpenModalAspecRatio(true);
      setCopyImageFormat(value);
    }
  }

  function handleRemoveElement(position) {
    setFormElements([...formElements.filter((_, index) => index !== position)]);
    setValueTab(position - 1);
  }

  if (props.loading) {
    return (
      <div className="loading">
        <Loading />
      </div>
    );
  } else if (window.pathname !== null) {
    return (
      <Redirect
        to={{
          pathname: window.pathname,
        }}
      />
    );
  } else {
    return (
      <div style={{ padding: '4rem 32px' }}>
        <ModalImageAspecRation
          handleClose={handleCloseModalAspectRation}
          open={openModalAspectRatio}
          handleResetImageAndColor={handleResetImageAndColor}
        />
        <>
          <HeaderTitle pageInfo="Adicionar Template" />
          <Typography variant="h6" color="primary" style={{ fontWeight: '600', marginTop: '2rem' }}>
            {' '}
            Informações do Template
          </Typography>
          <form onSubmit={checkInformation}>
            <div className="input-field">
              <TextField
                required
                className="input-field"
                type="text"
                value={templateName}
                id="templateName"
                label="Nome do Template:"
                onChange={(event) => setTemplateName(event.target.value)}
              />
              <br />
              <TextField
                required
                className="input-field"
                type="text"
                value={tutorialTemplate}
                id="input-tutorial"
                label="Tutorial do template:"
                placeholder="Template para abrir promoções do estabelecimento."
                onChange={(event) => setTutorialTemplate(event.target.value)}
              />
              <br />
              <br />
              <FormControl className={classes.blockedInput}>
                <InputLabel id="demo-simple-select-helper-label">Formato do Template:</InputLabel>
                <Select
                  required
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  value={imageFormat}
                  onChange={(event) => handleSelectFormat(event)}
                >
                  <MenuItem value="landscape">Paisagem</MenuItem>
                  <MenuItem value="portrait">Retrato</MenuItem>
                  <MenuItem value="square">Quadrado</MenuItem>
                </Select>
              </FormControl>
              <br />
              <div>
                {/* IMAGENS */}
                <Typography
                  variant="h6"
                  color="primary"
                  style={{ fontWeight: '600', marginTop: '2rem' }}
                >
                  {' '}
                  Imagens de fundo do template
                </Typography>
                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                    columnGap: '1px',
                    maxWidth: '900px',
                    padding: '10px',
                    overflowX: 'auto',
                  }}
                >
                  {listImageURL.length > 0 &&
                    listImageURL.map((url, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <div style={{ position: 'relative' }}>
                            <img
                              alt="Imagem Template"
                              src={url}
                              style={{
                                width: `${imageSize[imageFormat].width}`,
                                height: `${imageSize[imageFormat].height}`,
                                margin: '5px',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)',
                              }}
                              onMouseOver={() => mouseOver(index)}
                              onMouseOut={() => mouseOut(index)}
                            />
                            <div
                              onMouseOver={() => mouseOver(index)}
                              onMouseOut={() => mouseOut(index)}
                              id={`btn-close-${index}`}
                              onClick={() => removeImage(index)}
                              style={{
                                display: 'none',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'absolute',
                                top: '-2px',
                                right: '-2px',
                                width: '10px',
                                height: '10px',
                                border: 'solid 1px #000000',
                                backgroundColor: '#FFFFFF',
                                borderRadius: '50%',
                                padding: '10px',
                                cursor: 'pointer',
                              }}
                            >
                              <span style={{ fontSize: '14px' }}>X</span>
                            </div>
                          </div>
                          <div
                            style={{
                              display: 'flex',
                              marginBottom: '15px',
                              flexDirection: 'column',
                            }}
                          >
                            <TextField
                              style={{ width: '200px' }}
                              type="color"
                              id={`color-${index}`}
                              label="Selecione a cor do template"
                              value={colorListChange[index]}
                              onChange={(event) => onChangeColor(event.target.value, index)}
                            />
                          </div>
                        </div>
                      );
                    })}
                  {listImageURL.length < 6 && (
                    <div
                      className={classes.imageHover}
                      style={{
                        width: `${imageSize[imageFormat].width}`,
                        height: `${imageSize[imageFormat].height}`,
                        backgroundColor: '#e2e2e2',
                      }}
                    />
                  )}
                </div>

                {/* Botão add thumbnail */}
                <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
                  <input
                    value=""
                    disabled={listImageURL.length === 6}
                    style={{ display: 'none' }}
                    type="file"
                    accept="image/*"
                    onChange={handleFileChange}
                    id="thumbnail"
                  />

                  <label htmlFor="thumbnail">
                    <Button
                      variant="contained"
                      color="primary"
                      component="span"
                      style={{
                        marginTop: '15px',
                        fontWeight: '550',
                        color: '#fff',
                        textTransform: 'capitalize',
                      }}
                      startIcon={<AddPhotoAlternate />}
                    >
                      Adicionar Imagens
                    </Button>
                  </label>
                </div>
                <br />
                <br />
              </div>
              <Typography
                variant="h6"
                color="primary"
                style={{
                  fontWeight: '600',
                  marginTop: '1rem',
                  marginBottom: '1rem',
                }}
              >
                Adicionar elementos do template
              </Typography>

              <div>
                <AppBar position="static">
                  <Tabs
                    aria-label="simple tabs example"
                    id="demo-simple-select-helper"
                    value={valueTab}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                  >
                    {formElements.map((_, index) => {
                      return (
                        <Tab
                          style={{ fontWeight: 'bold' }}
                          label={`Elemento ${index + 1}`}
                          {...selectTab(index + 1)}
                        />
                      );
                    })}
                    {formElements.length <= 5 && (
                      <Tab onClick={(event) => handleAddEmptyStructure(event)} label={<Add />} />
                    )}
                  </Tabs>
                </AppBar>
              </div>
              <br />

              {formElements.map((elemento, index) => {
                return (
                  <>
                    {valueTab === index && (
                      <>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          {index !== 0 && (
                            <Button
                              onClick={() => handleRemoveElement(index)}
                              style={{
                                marginBottom: '10px',
                                maxWidth: '190px',
                              }}
                              variant="outlined"
                              color="primary"
                              disableElevation
                            >
                              Remover elemento
                            </Button>
                          )}

                          {/* <FormControlLabel
                            style={{ marginBottom: '20px', marginTop: '10px' }}
                            control={
                              <Switch
                                checked={isShowPreview}
                                onChange={() => setIsShowPreview(!isShowPreview)}
                                color="primary"
                              />
                            }
                            label={"Mostrar preview"}
                          /> */}
                        </div>

                        <div style={{ position: 'relative', width: '70%' }}>
                          <FormControl className={classes.blockedInput}>
                            <InputLabel required id="demo-simple-select-helper-label">
                              Tipo do template:
                            </InputLabel>
                            <Select
                              name="type"
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={elemento.type}
                              onChange={(event) => {
                                setTemplateType(event.target.value);
                                onChangeElements(event, index);
                              }}
                            >
                              <MenuItem value="establishment">Estabelecimento</MenuItem>
                              <MenuItem value="store">Promoção</MenuItem>
                              <MenuItem value="free">Livre</MenuItem>
                            </Select>
                          </FormControl>
                          <br />
                          <FormControl className={classes.blockedInput}>
                            <InputLabel required id="demo-simple-select-helper-label">
                              Tipo da entidade:
                            </InputLabel>
                            <Select
                              disabled={elemento.type === ''}
                              name="entity"
                              labelId="demo-simple-select-helper-label"
                              id="demo-simple-select-helper"
                              value={elemento.entity}
                              onChange={(event) => onChangeElements(event, index)}
                            >
                              <MenuItem value="text">Texto</MenuItem>
                              <MenuItem value="image">Imagem</MenuItem>
                              <MenuItem value="qrCode">QRcode</MenuItem>
                            </Select>
                          </FormControl>
                          <br />
                          {formElements[index].entity === 'text' && (
                            <>
                              {elemento.type === 'free' ? (
                                <TextField
                                  disabled={elemento.blockText}
                                  name="initialText"
                                  className={classes.blockedInput}
                                  id="initialText"
                                  label="Texto Inicial:"
                                  value={elemento.initialText}
                                  onChange={(event) => onChangeElements(event, index)}
                                />
                              ) : elemento.type === 'establishment' ? (
                                <FormControl className={classes.blockedInput}>
                                  <InputLabel required id="demo-simple-select-helper-label">
                                    Texto Inicial:
                                  </InputLabel>
                                  <Select
                                    disabled={elemento.blockText}
                                    name="initialText"
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={elemento.initialText}
                                    onChange={(event) => onChangeElements(event, index)}
                                  >
                                    <MenuItem value="name">Nome</MenuItem>
                                    <MenuItem value="description">Descrição</MenuItem>
                                    <MenuItem value="address">Endereço</MenuItem>
                                  </Select>
                                </FormControl>
                              ) : (
                                <FormControl className={classes.blockedInput}>
                                  <InputLabel required id="demo-simple-select-helper-label">
                                    Texto Inicial:
                                  </InputLabel>
                                  <Select
                                    disabled={elemento.blockText || elemento.type === ''}
                                    name="initialText"
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={elemento.initialText}
                                    onChange={(event) => onChangeElements(event, index)}
                                  >
                                    <MenuItem value="title">Nome</MenuItem>
                                    <MenuItem value="description">Descrição</MenuItem>
                                  </Select>
                                </FormControl>
                              )}
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    disabled={elemento.type === ''}
                                    name="blockText"
                                    checked={elemento.blockText}
                                    onChange={() =>
                                      handleCheckbox(
                                        elemento.entity,
                                        !elemento.blockText,
                                        index,
                                        'blockText'
                                      )
                                    }
                                  />
                                }
                                label="Bloquear texto inicial"
                              />
                              <TextField
                                disabled={elemento.type === '' || elemento.blockColor}
                                name="fontColor"
                                className={classes.blockedInput}
                                type="color"
                                id="fontColor"
                                label="Cor da fonte"
                                value={elemento.fontColor}
                                onChange={(event) => onChangeElements(event, index)}
                              />
                              <FormControlLabel
                                name="blockColor"
                                control={
                                  <Checkbox
                                    disabled={elemento.type === ''}
                                    name="blockColor"
                                    checked={elemento.blockColor}
                                    onChange={() =>
                                      handleCheckbox(
                                        elemento.entity,
                                        !elemento.blockColor,
                                        index,
                                        'blockColor'
                                      )
                                    }
                                  />
                                }
                                label="Bloquear cor da fonte"
                              />
                              <FontPicker
                                pickerId="fontStyle"
                                apiKey={cfac22('FIREBASE_APIKEY')}
                                activeFontFamily={elemento.fontStyle}
                                onChange={(nextFont) =>
                                  handleSelectFonts(nextFont.family, index, 'fontStyle')
                                }
                                // sort="popularity"
                              />
                              <FormControlLabel
                                name="blockStyle"
                                control={
                                  <Checkbox
                                    disabled={elemento.type === ''}
                                    name="blockStyle"
                                    checked={elemento.blockStyle}
                                    onChange={() =>
                                      handleCheckbox(
                                        elemento.entity,
                                        !elemento.blockStyle,
                                        index,
                                        'blockStyle'
                                      )
                                    }
                                  />
                                }
                                label="Bloquear estilo da fonte"
                              />
                              <br />
                              <TextField
                                disabled={elemento.type === ''}
                                name="fontSize"
                                className={classes.blockedInput}
                                type="number"
                                id="fontSize"
                                label="Tamanho da fonte"
                                value={elemento.fontSize}
                                onChange={(event) => onChangeElements(event, index)}
                              />
                              <TextField
                                disabled={elemento.type === ''}
                                name="heightPosition"
                                className={classes.blockedInput}
                                type="number"
                                id="standard-basic"
                                label="Posicionamento Vertical"
                                value={elemento.heightPosition}
                                onChange={(event) => onChangeElements(event, index)}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Height
                                        fontSize="small"
                                        style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <br />
                              <TextField
                                disabled={elemento.type === ''}
                                name="widthPosition"
                                className={classes.blockedInput}
                                type="number"
                                id="widthPosition"
                                label="Posicionamento Horizontal"
                                value={elemento.widthPosition}
                                onChange={(event) => onChangeElements(event, index)}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Height
                                        fontSize="small"
                                        style={{
                                          color: 'rgba(0, 0, 0, 0.54)',
                                          transform: 'rotate(90deg)',
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <br />
                              <TextField
                                disabled={elemento.type === ''}
                                name="widthSize"
                                className={classes.blockedInput}
                                type="number"
                                id="widthSize"
                                label="Tamanho da largura"
                                value={elemento.widthSize}
                                onChange={(event) => onChangeElements(event, index)}
                              />
                              <br />
                              <TextField
                                disabled={elemento.type === ''}
                                name="heightSize"
                                className={classes.blockedInput}
                                type="number"
                                id="heightSize"
                                label="Tamanho da altura"
                                value={elemento.heightSize}
                                onChange={(event) => onChangeElements(event, index)}
                              />
                              {/* FINAL ESTAB */}
                            </>
                          )}
                          {formElements[index].entity === 'image' && (
                            <>
                              <FormControl className={classes.blockedInput}>
                                <InputLabel required id="demo-simple-select-helper-label">
                                  Imagem Inicial:
                                </InputLabel>
                                <Select
                                  disabled={elemento.blockText || elemento.type === ''}
                                  name="initialImage"
                                  labelId="demo-simple-select-helper-label"
                                  id="demo-simple-select-helper"
                                  value={elemento.initialImage}
                                  onChange={(event) => onChangeElements(event, index)}
                                >
                                  <MenuItem value="thumbnail">Thumbnail</MenuItem>
                                  <MenuItem value="photoList">Lista de imagens</MenuItem>
                                </Select>
                                <br />
                                <FormControlLabel
                                  name="blockImage"
                                  control={
                                    <Checkbox
                                      disabled={elemento.type === ''}
                                      name="blockImage"
                                      checked={elemento.blockImage}
                                      onChange={() =>
                                        handleCheckbox(
                                          elemento.entity,
                                          !elemento.blockImage,
                                          index,
                                          'blockImage'
                                        )
                                      }
                                    />
                                  }
                                  label="Bloquear imagem"
                                />
                              </FormControl>
                              <br />
                              <TextField
                                disabled={elemento.type === ''}
                                name="heightPosition"
                                className={classes.blockedInput}
                                type="number"
                                id="standard-basic"
                                label="Posicionamento Vertical"
                                value={elemento.heightPosition}
                                onChange={(event) => onChangeElements(event, index)}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Height
                                        fontSize="small"
                                        style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <br />
                              <TextField
                                disabled={elemento.type === ''}
                                name="widthPosition"
                                className={classes.blockedInput}
                                type="number"
                                id="widthPosition"
                                label="Posicionamento Horizontal"
                                value={elemento.widthPosition}
                                onChange={(event) => onChangeElements(event, index)}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Height
                                        fontSize="small"
                                        style={{
                                          color: 'rgba(0, 0, 0, 0.54)',
                                          transform: 'rotate(90deg)',
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />

                              <br />
                              <TextField
                                disabled={elemento.type === ''}
                                name="widthSize"
                                className={classes.blockedInput}
                                type="number"
                                id="widthSize"
                                label="Tamanho da imagem"
                                value={elemento.widthSize}
                                onChange={(event) => onChangeElements(event, index)}
                              />

                              {/* <TextField
                              disabled={elemento.type === ""}
                              name="heightSize"
                              className={classes.blockedInput}
                              type="number"
                              id="heightSize"
                              label="Tamanho da altura"
                              value={elemento.heightSize}
                              onChange={(event) =>
                                onChangeElements(event, index)
                              }
                            /> */}
                            </>
                          )}
                          {formElements[index].entity === 'qrCode' && (
                            <>
                              {elemento.type === 'establishment' ? (
                                <FormControl className={classes.blockedInput}>
                                  <InputLabel required id="demo-simple-select-helper-label">
                                    Ação do QRcode:
                                  </InputLabel>
                                  <Select
                                    disabled={elemento.type === '' || elemento.blockQrCodeAction}
                                    name="qrCodeAction"
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={elemento.qrCodeAction}
                                    onChange={(event) => onChangeElements(event, index)}
                                  >
                                    <MenuItem value="place">Abrir Estabelecimento</MenuItem>
                                  </Select>
                                </FormControl>
                              ) : elemento.type === 'store' ? (
                                <FormControl className={classes.blockedInput}>
                                  <InputLabel required id="demo-simple-select-helper-label">
                                    Ação do QRcode:
                                  </InputLabel>
                                  <Select
                                    disabled={elemento.type === '' || elemento.blockQrCodeAction}
                                    name="qrCodeAction"
                                    labelId="demo-simple-select-helper-label"
                                    id="demo-simple-select-helper"
                                    value={elemento.qrCodeAction}
                                    onChange={(event) => onChangeElements(event, index)}
                                  >
                                    <MenuItem value="promo">Abrir Promoção</MenuItem>
                                  </Select>
                                </FormControl>
                              ) : (
                                <TextField
                                  disabled={elemento.type === ''}
                                  name="qrCodeAction"
                                  className={classes.blockedInput}
                                  type="text"
                                  id="standard-basic"
                                  placeholder="https://triibo.com.br"
                                  label="Link do qrCode"
                                  value={elemento.qrCodeAction}
                                  onChange={(event) => onChangeElements(event, index)}
                                />
                              )}
                              <FormControlLabel
                                name="blockQrCode"
                                control={
                                  <Checkbox
                                    disabled={elemento.type === ''}
                                    name="blockQrCodeAction"
                                    checked={elemento.blockQrCodeAction}
                                    onChange={() =>
                                      handleCheckbox(
                                        elemento.entity,
                                        !elemento.blockQrCodeAction,
                                        index,
                                        'blockQrCodeAction'
                                      )
                                    }
                                  />
                                }
                                label="Bloquear ação do QRcode"
                              />
                              <TextField
                                disabled={elemento.type === ''}
                                name="heightPosition"
                                className={classes.blockedInput}
                                type="number"
                                id="standard-basic"
                                label="Posicionamento Vertical"
                                value={elemento.heightPosition}
                                onChange={(event) => onChangeElements(event, index)}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Height
                                        fontSize="small"
                                        style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <br />
                              <TextField
                                disabled={elemento.type === ''}
                                name="widthPosition"
                                className={classes.blockedInput}
                                type="number"
                                id="widthPosition"
                                label="Posicionamento Horizontal"
                                value={elemento.widthPosition}
                                onChange={(event) => onChangeElements(event, index)}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <Height
                                        fontSize="small"
                                        style={{
                                          color: 'rgba(0, 0, 0, 0.54)',
                                          transform: 'rotate(90deg)',
                                        }}
                                      />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                              <br />
                              <TextField
                                disabled={elemento.type === ''}
                                name="widthSize"
                                className={classes.blockedInput}
                                type="number"
                                id="widthSize"
                                label="Tamanho do qrCode"
                                value={elemento.widthSize}
                                onChange={(event) => onChangeElements(event, index)}
                              />
                              {/* <br />
                            <TextField
                              disabled={elemento.type === ""}
                              name="heightSize"
                              className={classes.blockedInput}
                              type="number"
                              id="heightSize"
                              label="Tamanho da altura"
                              value={elemento.heightSize}
                              onChange={(event) =>
                                onChangeElements(event, index)
                              }
                            /> */}
                            </>
                          )}

                          {isShowPreview && (
                            <PreviewElementsForm
                              imageTemplate={listImageURL[0]}
                              formValues={formElements}
                              selectedFormat={imageFormat}
                            />
                          )}
                          <Tooltip
                            title={`${isShowPreview ? 'Fechar prévia' : 'Exibir prévia'}`}
                            placement="left"
                          >
                            <Fab
                              style={{
                                position: 'fixed',
                                bottom: '110px',
                                right: '28px',
                                zIndex: '99999',
                              }}
                              onClick={() => setIsShowPreview(!isShowPreview)}
                              color="secondary"
                              aria-label="edit"
                            >
                              <Image />
                            </Fab>
                          </Tooltip>
                        </div>
                      </>
                    )}
                  </>
                  // </TabPanel>
                );
              })}
            </div>
            {/* <Grid item xs={12} lg={5} xl={6}>
              <Typography variant="subtitle2" style={{ fontWeight: "550" }}>
                Preview
              </Typography>
              <PreviewElements preview={preview} />
            </Grid> */}
            <div>
              <Button
                style={{
                  fontWeight: '550',
                  marginTop: '20px',
                  color: 'white',
                  textTransform: 'capitalize',
                }}
                variant="contained"
                color="secondary"
                onClick={() => goBack()}
                startIcon={<ArrowBack />}
              >
                Voltar
              </Button>
              <Button
                style={{
                  fontWeight: '550',
                  marginTop: '20px',
                  color: 'white',
                  textTransform: 'capitalize',
                  marginLeft: '10px',
                }}
                disabled={loading}
                variant="contained"
                color="primary"
                type="submit"
                startIcon={<Save />}
              >
                Salvar
              </Button>
            </div>
          </form>

          {/* MODAL PARA CROPAR A IMAGEM */}
          <Modal open={openModal} onClose={() => setOpenModal(false)}>
            <div style={getModalStyle()}>
              <Typography
                variant="h5"
                gutterBottom
                color="primary"
                style={{ fontWeight: '600', textAlign: 'center' }}
              >
                Editar Imagem
              </Typography>
              <div className="style-modalCrop">
                <Cropper
                  style={{ overflow: 'hidden' }}
                  src={currentImageCropped}
                  ref={cropperRef}
                  ratio={ratio[imageFormat]}
                  fixedRatio={true}
                  allowNewSelection={false}
                />
              </div>
              <div
                style={{
                  position: 'fixed',
                  bottom: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  marginRight: '18px',
                  marginLeft: '-6',
                }}
              >
                <Button
                  style={{
                    fontWeight: '550',
                    color: 'white',
                    textTransform: 'capitalize',
                  }}
                  variant="contained"
                  color="secondary"
                  onClick={() => setOpenModal(false)}
                  startIcon={<ArrowBack />}
                >
                  Cancelar
                </Button>

                <Button
                  style={{
                    fontWeight: '550',
                    marginLeft: '10px',
                    color: 'white',
                    textTransform: 'capitalize',
                  }}
                  variant="contained"
                  color="primary"
                  onClick={onCropper}
                  startIcon={<CropFree />}
                >
                  Cortar Imagem
                </Button>
              </div>
            </div>
          </Modal>

          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={openCheck}
            autoHideDuration={4000}
            onClose={() => setOpenCheck(false)}
            ContentProps={{
              'aria-describedby': 'message-id',
            }}
            message={<span id="message-id">{messageAlert}</span>}
          />
        </>
      </div>
    );
  }
}

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    loading: state.marketingTemplateComponent.loading,
    success: state.marketingTemplateComponent.success,
    error: state.marketingTemplateComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  updateMarketingTemplateComponent: (
    id,
    templateName,
    listAllImages,
    listImageURL,
    formElements,
    imageFormat,
    colorListChange,
    tutorialTemplate
  ) =>
    updateMarketingTemplateAction(
      dispatch,
      id,
      templateName,
      listAllImages,
      listImageURL,
      formElements,
      imageFormat,
      colorListChange,
      tutorialTemplate
    ),
});

export default compose(
  withStyles(styles),
  connect(mapStateToProps, mapDispatchToProps)
)(UserTemplatesInformation);

