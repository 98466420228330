import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import TemplateModalLogin from './templateModalLogin';
import InputAdornment from '@mui/material/InputAdornment';
import { Box, CircularProgress, Button, Typography, TextField } from '@mui/material'
import { cpfMask } from 'js/library/utils/helpers';

//arquivo com as actions
import { signInAction } from 'js/core/actions/signInActions.js';
import { getUserInfo_v1 } from 'js/library/utils/API/getUserInfo_v1';

import { toast, ToastContainer } from 'react-toastify';

class PhoneInput extends Component {
  constructor() {
    super();
    this.state = {
      cpf: '',
      aliasname: '',
      name: '',
      email: '',
      emailCheck: false,
      loading: false,
      snackbarOpen: false,
      cpfIsInvalid: false,
      emailIsInvalid: true,
    };

    window.pathname = null;
  }

  //altera os states conforme escrevemos no formulário
  handleChange = (name) => (event) => {
    if (name === 'cpf') {
      this.setState({ cpf: cpfMask(event.target.value) });
    } else {
      this.setState({
        [name]: event.target.value,
      });
    }
  };

  handleChangeCPF = (event) => {
    this.setState({ cpf: cpfMask(event.target.value) });

    if (event.target.value !== this.state.cpf) {
      const strCPF = String(cpfMask(event.target.value)).replace(/[^\d]/g, '');

      if (strCPF.length !== 11) {
        this.setState({ cpfIsInvalid: true });
      } else {
        this.setState({ cpfIsInvalid: false });
      }
    }
  };

  handleChangeEmail = (event) => {
    this.setState({ email: event.target.value });

    const regex = /\S+@\S+\.\S+/;

    if (regex.test(event.target.value)) {
      this.setState({ emailIsInvalid: false });
      // this.setState({ emailCheck: true });
    } else {
      this.setState({ emailIsInvalid: true });
    }
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.setState({ loading: true });

    //PESQUISANDO SE USUARIO EXISTE PELO CPF
    const userInfo = {
      documentList: [
        {
          type: 'cpf',
          value: this.state.cpf,
        },
      ],
    };

    getUserInfo_v1(['getUserInfo'], userInfo).then((result) => {
      if (result.data.success.userInfo === false) {
        this.props.signInActionComponent(
          this.state.cpf,
          this.state.aliasname,
          this.state.name,
          this.state.email
        );
      } else {
        toast.error('Já existe um usuário com esse CPF');
        this.setState({
          loading: false,
          cpfIsInvalid: true,
        });
      }
    });
  };

  render = () => {
    if (window.pathname !== null) {
      return <Redirect to={window.pathname} />;
    } else {
      return (
        <>
          <ToastContainer autoClose={6000} />
          <TemplateModalLogin
            width='660px'
            handleSubmit={(e) => this.handleSubmit(e)}>
            <Typography
              sx={{ width: '80%' }}
              variant='subtitle1'
              fontSize={{ xs: '14px', md: '17px', lg: '22px' }}
              textAlign={'center'}
              color={'#383838'}
              fontWeight='regular'>
              Notamos que esta é sua primeira vez aqui. Diga-nos seus dados
              antes de começarmos.
            </Typography>
            <Box
              component='form'
              sx={{
                width: '100%',
                '& .MuiTextField-root': { m: 1, width: '95%' },
                display: 'flex',
                flexDirection: 'column',
              }}
              autoComplete='off'
              suggestions='off'>
              <TextField
                required
                id='outlined'
                value={this.state.name}
                onChange={(e) => this.setState({ name: e.target.value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>Nome* </InputAdornment>
                  ),
                }}
                sx={{
                  '.MuiOutlinedInput-root': {
                    borderRadius: '14px',
                    boxShadow: '0px 3px 6px #00000029',
                    borderColor: '#70707033',
                  },
                }}
              />
              <TextField
                required
                id='outlined'
                value={this.state.aliasname}
                onChange={(e) => this.setState({ aliasname: e.target.value })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>Apelido* </InputAdornment>
                  ),
                }}
                sx={{
                  '.MuiOutlinedInput-root': {
                    borderRadius: '14px',
                    boxShadow: '0px 3px 6px #00000029',
                    borderColor: '#70707033',
                  },
                }}
              />
              <TextField
                required
                id='outlined'
                error={this.state.cpfIsInvalid ? true : false}
                helperText={this.state.cpfIsInvalid ? 'CPF inválido.' : ''}
                // label="CPF"
                value={this.state.cpf}
                onChange={(e) => this.handleChangeCPF(e)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>CPF* </InputAdornment>
                  ),
                }}
                sx={{
                  '.MuiOutlinedInput-root': {
                    borderRadius: '14px',
                    boxShadow: '0px 3px 6px #00000029',
                    borderColor: '#70707033',
                  },
                }}
              />
              <TextField
                required
                error={
                  this.state.emailIsInvalid && this.state.email !== ''
                    ? true
                    : false
                }
                helperText={
                  this.state.emailIsInvalid && this.state.email !== ''
                    ? 'Email inválido'
                    : ''
                }
                id='outlined'
                value={this.state.email}
                onChange={(e) => this.handleChangeEmail(e)}
                inputProps={{ type: 'email' }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>E-mail* </InputAdornment>
                  ),
                }}
                sx={{
                  '.MuiOutlinedInput-root': {
                    borderRadius: '14px',
                    boxShadow: '0px 3px 6px #00000029',
                    borderColor: '#70707033',
                  },
                }}
              />
            </Box>
            <Button
              sx={{
                width: { xs: '160px', sm: '360px' },
                padding: '2',
                backgroundColor: '#08bad0',
                textTransform: 'capitalize',
                '&:hover': {
                  backgroundColor: '#08bad0',
                  filter: 'brightness(0.9)',
                },
              }}
              disabled={
                this.state.cpf.length < 14 ||
                this.state.aliasname === '' ||
                this.state.name === '' ||
                this.state.emailIsInvalid === true ||
                this.state.loading
              }
              type='submit'
              fullWidth
              variant='contained'>
              {this.state.loading ? (
                <CircularProgress size='2rem' style={{ color: 'white' }} />
              ) : (
                'continuar'
              )}
            </Button>
          </TemplateModalLogin>
        </>
      );
    }
  };
}

//recebe as props dos Reducers
function mapStateToProps(state) {
  return {
    loading: state.signInComponent.loading,
    success: state.signInComponent.success,
    error: state.signInComponent.error,
  };
}

//envia as props para as Actions
const mapDispatchToProps = (dispatch) => ({
  signInActionComponent: (cpf, aliasname, name, email) =>
    signInAction(dispatch, cpf, aliasname, name, email),
});

export default connect(mapStateToProps, mapDispatchToProps)(PhoneInput);
