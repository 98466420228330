import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import * as S from './styles';
import * as C from '../../../styles';
import * as D from 'js/components/DesignSystem/styles';

import { Modal } from '@mui/material';
import { AiFillCloseCircle } from 'react-icons/ai';

import { getClientsEstablishment } from 'js/library/utils/API/apiGetClients';
import MultipleSelectTable from 'js/components/TriiboComponents/Tables/MultipleSelectTable';
import InputSearch from 'js/components/TriiboComponents/InputSearch';

export default function ModalClients({
  handleCloseModal,
  isModalClientsOpen,
  setClientsList,
  clientsList,
  setTargetAudienceIsSelected,
  setSelectedTargetAudience,
}) {
  const [clients, setClients] = useState([]);
  const [checkedByCellPhone, setCheckedByCellPhone] = useState(
    Array.from(clientsList).length === 0 ? new Set() : clientsList
  );
  const [inputSearchClients, setInputSearchClients] = useState('');
  let establishmentList = useSelector(
    (state) => state.authModel.establishmentList
  );
  let currentEst = useSelector((state) => state.currentEstModel.currentEst);

  const setStateOpition = () => {
    if (inputSearchClients === '') {
      setSelectedTargetAudience('');
    }
  };

  useEffect(() => {
    async function getClientList() {
      let establishmentId = '';

      if (establishmentList.length < 1) {
        return;
      } else {
        if (Object.keys(currentEst).length !== 0) {
          establishmentId = currentEst.id;
        } else {
          establishmentId = establishmentList[0].id;
        }
      }

      const response = await getClientsEstablishment(establishmentId);

      const clientesFiltradosSemNull = response.registered.filter(
        (e) => e !== null
      );

      const newClintList = (arr) => {
        return arr.reduce((prev, next) => {
          let newCurrent = { ...next };

          newCurrent.checkedId =
            next.contactList[0].value + '@sms,triibo,com,br';
          newCurrent.cellPhone = next.contactList[0].value;

          prev.push(newCurrent);

          return prev;
        }, []);
      };

      setClients(newClintList(clientesFiltradosSemNull));
    }

    getClientList();
  }, []);

  // useEffect(() => {
  //   if (Array.from(checkedByCellPhone).length === 0) {
  //     setTargetAudienceIsSelected(false);
  //   } else {
  //     setTargetAudienceIsSelected(true);
  //   }
  // }, [checkedByCellPhone]);

  const handleChangeInputSearchCell = (event) => {
    setInputSearchClients(event.target.value);
  };

  const column = [
    { heading: 'Nome do cliente', value: 'name' },
    { heading: 'Telefone', value: 'cellPhone' },
  ];

  const filteredClients = inputSearchClients
    ? clients.filter((client) => {
        return (
          client.contactList[0].value
            .toLowerCase()
            .includes(inputSearchClients.toLowerCase()) ||
          client.name.toLowerCase().includes(inputSearchClients.toLowerCase())
        );
      })
    : clients;

  return (
    <Modal
      onClose={() => {
        handleCloseModal('clients');
        setStateOpition();
      }}
      open={isModalClientsOpen}
      aria-labelledby='modal-clients'>
      <C.ModalContainer desk_width={'800px'}>
        <C.ModalBox>
          <C.CloseModalIcon
            onClick={() => {
              handleCloseModal('clients');
              setStateOpition();
            }}>
            <AiFillCloseCircle size={20} />
          </C.CloseModalIcon>

          <D.TriiboH4 style={{ textAlign: 'center' }}>
            <strong>Selecione seus clientes</strong>
          </D.TriiboH4>

          <S.BoxSearch>
            <InputSearch
              value={inputSearchClients}
              onChange={handleChangeInputSearchCell}
              disabled={false}
              placeholder='Nome do usuário ou número de telefone'
            />
          </S.BoxSearch>

          {filteredClients.length < 1 ? (
            <D.TriiboH4>Nenhum cliente cadastrado.</D.TriiboH4>
          ) : (
            <MultipleSelectTable
              data={filteredClients}
              column={column}
              checkedById={checkedByCellPhone}
              setCheckedById={setCheckedByCellPhone}
              perPage={10}
            />
          )}

          <C.ActionButtons>
            <C.CancelButton
              onClick={() => {
                !clientsList && setCheckedByCellPhone(new Set());
                handleCloseModal('clients');
                setStateOpition();
              }}>
              <D.TriiboH5>Cancelar</D.TriiboH5>
            </C.CancelButton>
            <C.ContinueButton
              onClick={() => {
                setClientsList(checkedByCellPhone);
                handleCloseModal('clients');
                setTargetAudienceIsSelected(true);
                setStateOpition();
              }}>
              <D.TriiboH5>Confirmar</D.TriiboH5>
            </C.ContinueButton>
          </C.ActionButtons>
        </C.ModalBox>
      </C.ModalContainer>
    </Modal>
  );
}
