import React, { useEffect, useState } from 'react';

import * as S from '../styles';
import Input from 'js/components/DesignSystem/Input';
import { Checkbox, FormControlLabel, FormGroup, Modal } from '@mui/material';
import PencilIcon from '../../../../../styles/assets/SearchUser/pencil.svg';
import { ToastContainer, toast } from 'react-toastify';
import {
  convertToDate,
  convertToDateEdit,
  findValueInArray,
  maskCPF2,
  maskCnpj,
  randomizeNumbersString,
} from 'js/library/utils/helpers';

import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';
import PrimaryButton from 'js/components/TriiboComponents/Buttons/PrimaryButton';
import axios from 'axios';
import phone from 'phone';
import { EditUserPartner } from 'js/library/utils/API/SearchUser/apiEditUserPartner';

export default function EditInfosClube({
  userUid,
  formFields,
  handleCloseModal,
  isModalEditInfosClube,
  partnerList,
}) {
  const [formValues, setFormValues] = useState({});
  const [errors, setErrors] = useState({});

  const handleChangeCep = (cep, fieldNameAddress) => {
    if (cep.length === 8) {
      axios
        .get(`https://viacep.com.br/ws/${cep}/json/`)
        .then((response) => {
          response = response.data;
          if (response.erro) {
            toast.error('CEP inválido');
          }

          setFormValues({
            ...formValues,
            addressList: {
              ...formValues.addressList,
              [fieldNameAddress]: {
                ...formValues.addressList[fieldNameAddress],
                zipCode: cep,
                city: response.localidade,
                neighborhood: response.bairro,
                state: response.uf,
                streetAve: response.logradouro,
                streetNumber: '',
              },
            },
          });
        })
        .catch((error) => {
          console.error(error, 'Erro ao obter informações do CEP');
          toast.error('Erro ao obter informações do CEP');
        });
    }
  };

  const handleCountryChange = (e, field) => {
    setFormValues({
      ...formValues,
      [field.fieldName]: {
        ...formValues[field.fieldName],
        mask: {
          ...phoneMaskArray.filter((mask) => mask.ddd === e.target.value)[0],
        },
        value: '',
      },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    let cellPhoneInvalid = false;
    let userInfoObj = {};
    let contactList = [];
    let documentList = [];
    let optInList = [];
    let addressList = [];

    let newErrors = {};

    if (formValues.cpf && formValues.cpf.value.replace(/\D/g, '').length < 11) {
      newErrors['cpf'] = 'Número de CPF inválido.';
    }

    formFields.forEach((field) => {
      switch (field.type) {
        case 'contactList':
          if (field.fieldType === 'cellPhone') {
            const formatMask = '+' + formValues[field.fieldName].mask.fullMask.replace(/\D/g, '');

            const formatCellPhone =
              formValues[field.fieldName] &&
              `${formValues[field.fieldName].mask.ddd}${formValues[field.fieldName].value.replace(
                /\D/g,
                ''
              )}`;

            if (formValues[field.fieldName] && formatCellPhone.length < formatMask.length) {
              cellPhoneInvalid = true;
              newErrors[field.fieldName] = 'Número de celular inválido.';
            }

            contactList.push({
              type: field.fieldName,
              value: formatCellPhone,
            });
          } else {
            if (!formValues[field.fieldName] || formValues[field.fieldName].value === '') {
              newErrors[field.fieldName] = 'Este campo é obrigatório.';
            } else {
              contactList.push({
                type: field.fieldName,
                value: formValues[field.fieldName].value,
              });
            }
          }

          userInfoObj.contactList = contactList;
          break;

        case 'documentList':
          if (field.fieldType === 'cpf') {
            if (!formValues[field.fieldName] || formValues[field.fieldName].value === '') {
              newErrors[field.fieldName] = 'Este campo é obrigatório.';
            } else {
              documentList.push({
                type: field.fieldName,
                value: formValues[field.fieldName].value.replace(/\D/g, ''),
              });
            }
          } else if (field.fieldType === 'cnpj') {
            if (!formValues[field.fieldName] || formValues[field.fieldName].value === '') {
              newErrors[field.fieldName] = 'Este campo é obrigatório.';
            } else {
              documentList.push({
                type: field.fieldName,
                value: formValues[field.fieldName].value.replace(/\D/g, ''),
              });
            }
          } else {
            if (!formValues[field.fieldName] || formValues[field.fieldName].value === '') {
              newErrors[field.fieldName] = 'Este campo é obrigatório.';
            } else {
              documentList.push({
                type: field.fieldName,
                value: formValues[field.fieldName].value,
              });
            }
          }
          userInfoObj.documentList = documentList;
          break;

        case 'addressList':
          if (
            !formValues?.addressList?.[field.fieldName] ||
            formValues?.addressList?.[field.fieldName] === ''
          ) {
            newErrors[field.fieldName] = 'Este campo é obrigatório.';
          } else {
            addressList.push({
              zipCode: formValues.addressList[field.fieldName].zipCode,
              city: formValues.addressList[field.fieldName].city,
              extra: formValues.addressList[field.fieldName].extra,
              streetAve: formValues.addressList[field.fieldName].streetAve,
              streetNumber: formValues.addressList[field.fieldName].streetNumber,
              state: formValues.addressList[field.fieldName].state,
              neighborhood: formValues.addressList[field.fieldName].neighborhood,
            });
          }

          userInfoObj.addressList = addressList;

          break;

        case 'optInList':
          const optIn = `optIn_${field.optInId}`;
          if (formValues[optIn] && formValues[optIn].accept) {
            optInList.push({
              accept: formValues[optIn].accept,
              dateAcceptance: Date.now(),
              optInId: field.optInId,
              type: field.label,
              version: 1,
            });
            userInfoObj.optInList = optInList;
          }
          break;

        case 'root':
        case 'others':
          if (
            (!formValues[field.fieldName] || formValues[field.fieldName] === '') &&
            formValues[field.fieldType] !== 'checkbox'
          ) {
            newErrors[field.fieldName] = 'Este campo é obrigatório.';
          } else if (field.fieldType === 'date') {
            const date = new Date(formValues?.[field.fieldName]);
            const timestamp = date.getTime();

            userInfoObj[field.fieldName] = timestamp;
          } else {
            userInfoObj[field.fieldName] = formValues[field.fieldName];
          }
          break;

        default:
          userInfoObj[field.fieldName] = formValues?.[field.fieldName]?.value;
      }
    });

    setErrors(newErrors);

    if (cellPhoneInvalid || Object.keys(newErrors).length > 0) {
      return toast.error('Por favor, corrija os erros no formulário.');
    }

    userInfoObj.partnerId = partnerList.partnerId;
    userInfoObj.partnerName = partnerList.partnerName;
    userInfoObj.updateDate = new Date().getTime();

    EditUserPartner(userUid, userInfoObj)
      .then((res) => {
        toast.success('Dados atualizados com sucesso.');
      })
      .catch((err) => {
        toast.error('Erro ao atualizar dados.');
        console.error(err);
      });
  };

  const handleChange = (e, input) => {
    const value = e.target.value;

    const regex = new RegExp(input.regex);

    let newErrors = { ...errors };
    if (
      !value &&
      input.required &&
      input.inputType !== 'optInList' &&
      input.inputType !== 'checkbox'
    ) {
      newErrors[input.fieldName] = 'Este campo é obrigatório.';
    } else if (!regex.test(value) && input.type === 'aliasName') {
      newErrors[input.fieldName] = 'Formato inválido.';
    } else {
      delete newErrors[input.fieldName];
    }

    setErrors(newErrors);
    handleInputChange(e, input);
  };

  const handleInputChange = (event, input) => {
    const name = event.target.name;
    const checked = event.target.checked;
    const value = event.target.value;

    switch (input.type) {
      case 'root':
        if (input.fieldType === 'checkbox') {
          setFormValues((prevState) => ({
            ...prevState,
            [name]: checked,
          }));
        } else {
          setFormValues((prevState) => ({
            ...prevState,
            [name]: value,
          }));
        }
        break;

      case 'others':
        if (input.inputType === 'date') {
          setFormValues((prevState) => ({
            ...prevState,
            [name]: convertToDate(value),
          }));
        } else if (input.inputType === 'checkbox') {
          setFormValues((prevState) => ({
            ...prevState,
            [name]: { accept: checked },
          }));
        } else {
          setFormValues((prevState) => ({
            ...prevState,
            [name]: value,
          }));
        }
        break;

      case 'addressList':
        setFormValues((prevState) => ({
          ...prevState,

          addressList: {
            ...prevState.addressList,
            endereco: {
              ...prevState.addressList.endereco,
              [name]: value,
            },
          },
        }));
        break;

      case 'contactList':
        if (input.fieldType === 'cellPhone') {
          setFormValues((prevState) => ({
            ...prevState,

            ...prevState.contactList,
            [name]: {
              ...prevState[name],
              mask: prevState[name]?.mask || phoneMaskArray.filter((mask) => mask.ddd === '+55')[0],
              phone: value.replace(/\D/g, ''),
            },
          }));
        } else {
          setFormValues((prevState) => ({
            ...prevState,
            [name]: {
              ...prevState[name],
              value,
            },
          }));
        }
        break;

      case 'documentList':
        if (input.fieldType === 'cpf') {
          setFormValues((prevState) => ({
            ...prevState,
            [name]: {
              ...prevState[name],
              value: maskCPF2(value),
            },
          }));
        } else if (input.fieldType === 'cnpj') {
          setFormValues((prevState) => ({
            ...prevState,
            [name]: {
              ...prevState[name],
              value: maskCnpj(value),
            },
          }));
        } else {
          setFormValues((prevState) => ({
            ...prevState,
            [name]: {
              ...prevState[name],
              value,
            },
          }));
        }
        break;

      case 'optInList':
        const optIn = `optIn_${input.optInId !== undefined ? input.optInId : ''}`;
        setFormValues((prevState) => ({
          ...prevState,
          optInList: { ...prevState.optInList, [optIn]: { accept: checked } },
        }));
        break;

      default:
        setFormValues((prevState) => ({
          ...prevState,
          [name]: value,
        }));
    }
  };

  const renderInput = (input) => {
    const editInput = input.fieldType === 'cellPhone' || input.indexKey;
    switch (input.type) {
      case 'root':
      case 'others':
        if (input.fieldType === 'checkbox') {
          return (
            <S.ContainerInput>
              <FormGroup>
                <FormControlLabel
                  checked={formValues ? formValues[input.fieldName] : false}
                  onChange={(e) => handleChange(e, input)}
                  id={input.fieldName}
                  name={input.fieldName}
                  required={input.required}
                  disabled={editInput}
                  control={<Checkbox />}
                  label={input.label}
                />
              </FormGroup>
              {errors[input.fieldName] && (
                <p style={{ color: 'red', fontSize: '12px', paddingLeft: '0' }}>
                  {errors[input.fieldName]}
                </p>
              )}
            </S.ContainerInput>
          );
        } else {
          return (
            <>
              <Input
                type={input.inputType}
                label={input.required ? input.label + '*' : input.label}
                value={formValues[input.fieldName] && formValues[input.fieldName].value}
                onChange={(e) => handleChange(e, input)}
                id={input.fieldName}
                name={input.fieldName}
                required={input.required}
                disabled={editInput}
                hasError={!!errors[input.fieldName]}
              />
              {errors[input.fieldName] && (
                <p style={{ color: 'red', fontSize: '12px', paddingLeft: '0' }}>
                  {errors[input.fieldName]}
                </p>
              )}
            </>
          );
        }

      case 'addressList':
        return (
          <S.ContentAddress>
            <h3>{input.fieldName}:</h3>
            <Input
              type="text"
              label="Cep do Endereço*"
              value={
                formValues.addressList !== undefined
                  ? formValues.addressList[input.fieldName].zipCode
                  : ''
              }
              hasError={!!errors[input.fieldName]}
              handleChange={(e) => {
                handleChange(e, input);
                handleChangeCep(e.target.value, input.fieldName);
              }}
              id="cep"
              name="zipCode"
              required
              maxLength="8"
            />
            <S.ContentGroupInputAddress>
              <Input
                type="text"
                hasError={!!errors[input.fieldName]}
                label="Rua*"
                value={
                  formValues.addressList !== undefined
                    ? formValues.addressList[input.fieldName].streetAve
                    : ''
                }
                onChange={(e) => handleChange(e, input)}
                id="rua"
                name="streetAve"
                required
              />
              <Input
                type="text"
                hasError={!!errors[input.fieldName]}
                label="Número*"
                value={
                  formValues.addressList !== undefined
                    ? formValues.addressList[input.fieldName].streetNumber
                    : ''
                }
                onChange={(e) => handleChange(e, input)}
                id="numero"
                name="streetNumber"
                required
              />
            </S.ContentGroupInputAddress>
            <S.ContentGroupInputAddress>
              <Input
                type="text"
                hasError={!!errors[input.fieldName]}
                label="Bairro*"
                value={
                  formValues.addressList !== undefined
                    ? formValues.addressList[input.fieldName].neighborhood
                    : ''
                }
                onChange={(e) => handleChange(e, input)}
                id="bairro"
                name="neighborhood"
                required
              />
              <Input
                type="text"
                hasError={!!errors[input.fieldName]}
                label="Cidade*"
                value={
                  formValues.addressList !== undefined
                    ? formValues.addressList[input.fieldName].city
                    : ''
                }
                onChange={(e) => handleChange(e, input)}
                id="cidade"
                name="city"
                required
              />
              <Input
                type="text"
                hasError={!!errors[input.fieldName]}
                label="UF*"
                value={
                  formValues.addressList !== undefined
                    ? formValues.addressList[input.fieldName].state?.toUpperCase()
                    : ''
                }
                onChange={(e) => handleChange(e, input)}
                id="uf"
                name="state"
                required
                maxLength="2"
              />
            </S.ContentGroupInputAddress>
            <Input
              type="text"
              label="Complemento"
              value={
                formValues.addressList !== undefined
                  ? formValues.addressList[input.fieldName].extra
                  : ''
              }
              onChange={(e) => handleChange(e, input)}
              id="complemento"
              name="extra"
            />
            {errors[input.fieldName] && (
              <p style={{ color: 'red', fontSize: '12px', paddingLeft: '0' }}>
                {errors[input.fieldName]}
              </p>
            )}
          </S.ContentAddress>
        );

      case 'contactList':
        return input.fieldType === 'cellPhone' ? (
          <S.ContainerInput>
            <label>
              {input.label}
              {input.required && '*'}
            </label>
            <S.BoxCellPhone>
              <S.Adornment>
                <select
                  style={{
                    fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                    height: '100%',
                    border: 'none',
                    background: 'none',
                    outline: 'none',
                    fontSize: '17px',
                    cursor: 'pointer',
                  }}
                  value={
                    formValues.contactList
                      ? formValues.contactList[input.fieldName]?.mask.ddd
                      : '+55'
                  }
                  onChange={handleCountryChange}
                  disabled={editInput}
                >
                  {phoneMaskArray.map((country, index) => (
                    <option
                      style={{
                        fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                      }}
                      key={country.id}
                      value={[country.ddd, country.mask, country.fullMask]}
                    >
                      {country.emoji} {country.initials} {country.ddd}
                    </option>
                  ))}
                </select>
              </S.Adornment>

              <S.Input
                mask={
                  formValues[input.fieldName]?.value === '' || !formValues[input.fieldName]?.value
                    ? ''
                    : input.inputMask
                }
                value={formValues[input.fieldName] ? formValues[input.fieldName].value : ''}
                placeholder={
                  formValues.contactList
                    ? formValues.contactList[input.fieldName]?.mask.mask
                    : randomizeNumbersString('(99) 99999-9999')
                }
                onChange={(e) => handleChange(e, input)}
                id={input.fieldName}
                name={input.fieldName}
                required={input.required}
                disabled={editInput}
              />
            </S.BoxCellPhone>
            {errors[input.fieldName] && (
              <p style={{ color: 'red', fontSize: '12px', paddingLeft: '0' }}>
                {errors[input.fieldName]}
              </p>
            )}
          </S.ContainerInput>
        ) : (
          <>
            <Input
              type={input.inputType}
              label={input.required ? input.label + '*' : input.label}
              value={formValues[input.fieldName] && formValues[input.fieldName].value}
              onChange={(e) => handleChange(e, input)}
              id={input.fieldName}
              name={input.fieldName}
              required={input.required}
              disabled={editInput}
            />
            {errors[input.fieldName] && (
              <p style={{ color: 'red', fontSize: '12px', paddingLeft: '0' }}>
                {errors[input.fieldName]}
              </p>
            )}
          </>
        );

      case 'documentList':
        return (
          <>
            <Input
              mask={
                formValues[input.fieldName]?.value === '' || !formValues[input.fieldName]?.value
                  ? ''
                  : input.inputMask
              }
              type={input.inputType}
              label={input.required ? input.label + '*' : input.label}
              value={formValues[input.fieldName] && formValues[input.fieldName].value}
              onChange={(e) => handleChange(e, input)}
              id={input.fieldName}
              name={input.fieldName}
              required={input.required}
              disabled={editInput}
            />
            {errors[input.fieldName] && (
              <p style={{ color: 'red', fontSize: '12px', paddingLeft: '0' }}>
                {errors[input.fieldName]}
              </p>
            )}
          </>
        );

      case 'optInList':
        return (
          <S.ContainerInput>
            <FormGroup>
              <FormControlLabel
                checked={
                  formValues[
                    input.type === 'optInList' ? `optIn_${input.optInId}` : input.fieldName
                  ]?.accept || (formValues?.[input.fieldName] ? true : false)
                }
                onChange={(e) => handleChange(e, input)}
                id={input.type === 'optInList' ? `optIn_${input.optInId}` : input.fieldName}
                name={input.type === 'optInList' ? `optIn_${input.optInId}` : input.fieldName}
                required={input.required}
                disabled={editInput}
                control={<Checkbox />}
                label={input.label}
              />
            </FormGroup>
            {errors[input.fieldName] && (
              <p style={{ color: 'red', fontSize: '12px', paddingLeft: '0' }}>
                {errors[input.fieldName]}
              </p>
            )}
          </S.ContainerInput>
        );

      default:
        break;
    }
  };

  useEffect(() => {
    let valuesForm = {};

    if (formFields.length > 0) {
      formFields.forEach((field, index) => {
        switch (field.type) {
          case 'contactList':
            if (field.fieldType === 'cellPhone') {
              if (findValueInArray(partnerList.contactList, 'type', field.fieldName)) {
                const cellPhoneInfos = phone(
                  findValueInArray(partnerList.contactList, 'type', field.fieldName).value
                );

                const maskFilter = phoneMaskArray.filter(
                  (mask) => mask.ddd === cellPhoneInfos.countryCode
                );
                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    mask: { ...maskFilter[0] },
                    value: cellPhoneInfos.phoneNumber.split(maskFilter[0].ddd)[1],
                  },
                };
              } else {
                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    value: '',
                  },
                };
              }
            }

            if (field.inputType === 'email') {
              if (findValueInArray(partnerList.contactList, 'type', field.fieldName)) {
                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    value: findValueInArray(partnerList.contactList, 'type', field.fieldName)
                      ?.value,
                  },
                };
              } else {
                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    value: '',
                    id: field.id,
                  },
                };
              }
            }
            break;
          case 'documentList':
            if (field.fieldType === 'cpf') {
              if (findValueInArray(partnerList.documentList, 'type', field.fieldName)) {
                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    value: maskCPF2(
                      findValueInArray(partnerList.documentList, 'type', field.fieldName)?.value
                    ),
                    id: field.id,
                  },
                };
              } else {
                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    value: findValueInArray(partnerList.documentList, 'type', field.fieldName)
                      ?.value,

                    id: field.id,
                  },
                };
              }
            }

            if (field.fieldType === 'cnpj') {
              if (findValueInArray(partnerList.documentList, 'type', field.fieldName)) {
                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    value: maskCnpj(
                      findValueInArray(partnerList.documentList, 'type', field.fieldName)?.value
                    ),
                    id: field.id,
                  },
                };
              } else {
                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    value: findValueInArray(partnerList.documentList, 'type', field.fieldName)
                      ?.value,
                    id: field.id,
                  },
                };
              }
            }
            if (field.fieldType === 'rg') {
              if (partnerList?.documentList?.length > 0) {
                if (findValueInArray(partnerList.documentList, 'type', field.fieldName)) {
                  valuesForm = {
                    ...valuesForm,
                    [field.fieldName]: {
                      value: findValueInArray(partnerList.documentList, 'type', field.fieldName)
                        ?.value,
                      id: field.id,
                    },
                  };
                } else {
                  valuesForm = {
                    ...valuesForm,
                    [field.fieldName]: {
                      value: findValueInArray(partnerList.documentList, 'type', field.fieldName)
                        ?.value,
                      id: field.id,
                    },
                  };
                }
              }
            }
            break;
          case 'addressList':
            if (partnerList?.addressList?.length > 0) {
              const address = partnerList?.addressList[0];

              if (address) {
                valuesForm = {
                  ...valuesForm,
                  addressList: {
                    ...valuesForm.addressList,
                    [field.fieldName]: address,
                  },
                };
              } else {
                valuesForm = {
                  ...valuesForm,
                  addressList: {
                    ...valuesForm.addressList,
                    [field.fieldName]: {},
                  },
                };
              }
            } else {
              valuesForm = {
                ...valuesForm,
                [field.fieldName]: {
                  value: '',
                  id: field.id,
                },
              };
            }

            break;
          case 'optInList':
            const optIn = `optIn_${field.optInId}`;
            valuesForm = {
              ...valuesForm,
              [optIn]: {
                accept: findValueInArray(partnerList.optInList, 'optInId', field.optInId)
                  ? findValueInArray(partnerList.optInList, 'optInId', field.optInId).accept
                  : false,
                id: field.id,
              },
            };

            break;
          case 'others':
            if (field.fieldType === 'cellPhone') {
              const cellPhoneInfos = phone(partnerList[field.fieldName]);

              const maskFilter = phoneMaskArray.filter(
                (mask) => mask.ddd === cellPhoneInfos.countryCode
              );

              valuesForm = {
                ...valuesForm,
                [field.fieldName]: {
                  mask: { ...maskFilter[0] },
                  value: cellPhoneInfos.phoneNumber.split(maskFilter[0].ddd)[1],
                  id: field.id,
                },
              };
            } else if (field.inputType === 'checkbox') {
              valuesForm = {
                ...valuesForm,
                [field.fieldName]: {
                  accept: partnerList[field.fieldName],
                  id: field.id,
                },
              };
            } else {
              valuesForm = {
                ...valuesForm,
                [field.fieldName]: {
                  value: partnerList[field.fieldName],
                  id: field.id,
                },
              };
            }

            if (field.fieldType === 'cellPhone') {
              valuesForm = {
                ...valuesForm,
                [field.fieldName]: {
                  mask: {
                    id: '0',
                    initials: 'BR',
                    country: 'Brasil',
                    emoji: '🇧🇷',
                    fullMask: '+55(00)00000-0000',
                    mask: '(99) 99999-9999',
                    ddd: '+55',
                  },
                  value: '',
                  id: field.id,
                },
              };
            } else {
              valuesForm = {
                ...valuesForm,
                [field.fieldName]: {
                  value: '',
                  id: field.id,
                },
              };
            }

            break;

          case 'root':
            if (partnerList[field.fieldName]) {
              if (field.inputType === 'date') {
                const date = new Date(partnerList[field.fieldName]);

                date.setHours(date.getHours() + 3);
                const dateToTimestamp = date.getTime();

                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    value: convertToDateEdit(dateToTimestamp),
                    id: field.id,
                  },
                };
              } else {
                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    value: partnerList[field.fieldName].value
                      ? partnerList[field.fieldName].value
                      : partnerList[field.fieldName],
                    id: field.id,
                  },
                };
              }
            }

            break;
          default:
            break;
        }

        if (index === formFields.length - 1) {
          setFormValues(valuesForm);
        }
      });
    }
  }, [formFields, formFields.length]);

  return (
    <Modal
      onClose={() => handleCloseModal('closeModalEditInfosClube')}
      open={isModalEditInfosClube}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <>
        <ToastContainer autoClose={3000} />
        <S.ModalBox
          style={{
            msOverflowY: 'scroll',
            overflowY: 'scroll',
            maxHeight: '90%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <div className="header">
            <img src={PencilIcon} alt="" />
            <h2>Editar Informações {partnerList.partnerName}</h2>
          </div>
          <S.EditInfoUserModalBox>
            {formFields.map((field, index) => {
              return <div key={index}>{renderInput(field, index)}</div>;
            })}
          </S.EditInfoUserModalBox>

          <PrimaryButton style={{ margin: '1rem auto' }} onClick={handleSubmit}>
            Salvar
          </PrimaryButton>
        </S.ModalBox>
      </>
    </Modal>
  );
}

