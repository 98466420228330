import {
  getDataListOrderBy,
  verificarAprovacaoPromocao,
} from "js/library/services/DatabaseManager";
import { getDataListRestApi } from "js/library/utils/API/restApiFirebase";
import { downloadImage } from "js/library/services/StorageManager.js";
import getEstablishmentId from "js/library/utils/API/Establishment/apiReadEstablishment";

export function getTemplate(setTemplateData) {
  if (!setTemplateData)
    throw new Error(
      "[MarketingServives]: setTemplateData function is required!"
    );

  getDataListRestApi("Marketing-Content-Templates").then((dataReceived) => {
    const templatesList = Object.values(dataReceived).map(
      (template) => template
    );

    // BAIXA URL DAS IMAGENS DO TEMPLATE
    Promise.all(
      templatesList.map(({ backgroundImages }) => {
        return Promise.all(
          backgroundImages.map(({ image }) => downloadImage("templates", image))
        );
      })
    ).then((url) => {
      templatesList.map(({ backgroundImages }, index_01) => {
        backgroundImages.map((backgroundImages, index_02) => {
          backgroundImages.image = url[index_01][index_02];
        });
      });

      setTemplateData(templatesList);
    });
  });
}

export function getEstablishment(establishmentId, setEstablishmentData) {
  let data = {};
  getEstablishmentId(establishmentId).then((establishment) => {
    data = {
      id: establishment.id,
      name: establishment.nome,
      description: establishment.descricao,
      address: establishment.endereco,
      thumbnail: establishment.fotoThumb,
      photoList: establishment.listaFotos,
      links: establishment?.useFullLinks
        ? [...establishment.useFullLinks]
        : null,
    };
    setEstablishmentData(data);
  });
}

export async function getStore(
  establishment,
  setStoreData,
  setLoadingElements
) {
  const currentDate = Date.now();

  getDataListOrderBy("Store", "establishmentId", establishment.id)
    .then((storeReceived) => {
      const storeList = [...storeReceived];

      if (!storeReceived) {
        return;
      }

      Promise.all(
        storeList.map((store) => {
          return verificarAprovacaoPromocao(
            store.id,
            store.type,
            establishment.name,
            establishment.name
          );
        })
      )
        .then((approval) => {
          // PEGA SOMENTE AS PROMOÇÕES ATIVAS
          const listActiveStore = storeList.filter((store, index) => {
            if (
              store.isPublished === true &&
              store.enableDate <= currentDate &&
              store.disableDate >= currentDate &&
              approval[index].status === "aprovado"
            ) {
              return store;
            }
          });

          setStoreData(listActiveStore);
          setLoadingElements(false);
        })
        .catch((error) => {
          console.log("Error: ", error);
          setLoadingElements(false);
        });
    })
    .catch((error) => {
      console.log("Error: ", error);
      setLoadingElements(false);
    });
}

export function getImage(
  image,
  setDownloadedImage,
  value,
  downloadedImage,
  type,
  tabIndex,
  setListElementSelected,
  listElementSelected,
  setLoadingImage
) {
  setLoadingImage(true);

  if (image !== undefined) {
    if (value === "thumbnail") {
      downloadImage(type, image)
        .then((downloaded) => {
          getBase64FromUrl(downloaded).then((img) => {
            listElementSelected[tabIndex]["image"] = img;
            setListElementSelected([...listElementSelected]);
            setDownloadedImage(img);
            setLoadingImage(false);
          });
        })
        .catch((error) => {
          setLoadingImage(false);
          console.log("Error ao baixar thumbnail establishiment: ", error);
        });
    } else {
      Promise.all(
        image.map((item, index) => {
          return downloadImage(type, item)
            .then((downloaded) => {
              if (index === 0) {
                getBase64FromUrl(downloaded).then((img) => {
                  listElementSelected[tabIndex]["image"] = img;
                  setListElementSelected([...listElementSelected]);
                  setLoadingImage(false);
                });
              }

              downloadedImage[index] = downloaded;
              setDownloadedImage([...downloadedImage]);
            })
            .catch((error) => {
              setLoadingImage(false);
              console.log("Error ao baixar listPhoto establishiment: ", error);
            });
        })
      );
    }
  }
}

export function getStoreImage(
  image,
  setDownloadedImage,
  value,
  downloadedImage,
  type,
  tabIndex,
  setListElementSelected,
  listElementSelected,
  setLoadingImage
) {
  setLoadingImage(true);
  if (image !== undefined) {
    if (value === "thumbnail") {
      downloadImage(type, image)
        .then((downloaded) => {
          getBase64FromUrl(downloaded)
            .then((img) => {
              listElementSelected[tabIndex]["image"] = img;
              setListElementSelected([...listElementSelected]);
              setDownloadedImage(img);
              setLoadingImage(false);
            })
            .catch((error) => {
              setLoadingImage(false);
              console.log("Error ao baixar listPhoto store: ", error);
            });
        })
        .catch((error) => {
          setLoadingImage(false);
          console.log("Error ao baixar listPhoto store: ", error);
        });
    } else {
      Promise.all(
        image.map((item, index) => {
          return downloadImage(type, item)
            .then((downloaded) => {
              if (index === 0) {
                getBase64FromUrl(downloaded)
                  .then((img) => {
                    listElementSelected[tabIndex]["image"] = img;
                    setListElementSelected([...listElementSelected]);
                    setLoadingImage(false);
                  })
                  .catch((error) => {
                    setLoadingImage(false);
                    console.log("Error ao baixar listPhoto store: ", error);
                  });
              }

              downloadedImage[index] = downloaded;
              setDownloadedImage([...downloadedImage]);
            })
            .catch((error) => {
              setLoadingImage(false);
              console.log("Error ao baixar listPhoto store: ", error);
            });
        })
      );
    }
  } else {
    listElementSelected[tabIndex]["image"] = "";
    setListElementSelected([...listElementSelected]);
  }
}

const getBase64FromUrl = async (url) => {
  const data = await fetch(url);
  const blob = await data.blob();
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = () => {
      const base64data = reader.result;

      resolve(base64data);
    };
  });
};
