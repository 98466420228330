import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import usePagination from 'js/components/TriiboComponents/Tables/usePagination';

import View from './View.jsx';
import { getSeuClube } from 'js/library/utils/API/seuClube';
import LPModel from './model.js';
import routes from './routes.js';

export const LandingPageList = () => {
  const [partnerId, setPartnerId] = useState('');
  const [landingPageList, setLandingPageList] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);

  const params = useParams();
  const history = useHistory();

  const clubId = params?.id;

  console.log('-----', landingPageList);

  const PER_PAGE = 10;
  const _DATA = usePagination(landingPageList?.length, PER_PAGE);
  const count = Math.ceil(landingPageList?.length / PER_PAGE);

  const getPartnerId = async () => {
    const clubInfos = await getSeuClube(clubId);
    setPartnerId(clubInfos?.partnerId);
  };

  const setlandingPages = async (partnerId) => {
    const landingPages = await LPModel.getLandingPages(
      partnerId,
      inputSearch,
      landingPageList.length,
      landingPageList.length + 10
    );
    setLandingPageList(landingPages);
    setLoading(false);
  };

  const handleChangePage = (e, p) => {
    _DATA.jump(p);
    setPage(p);
  };

  const setInputValue = ({ target }) => {
    setInputSearch(target.value);
  };

  const goToLPSignUp = (LP) =>
    history?.push(`${routes.LPSignUpPage}/${clubId}`, LP.id ? LP : { partnerId });

  useEffect(() => {
    if (clubId) getPartnerId();
  }, [clubId]);

  useEffect(() => {
    if (partnerId) {
      setlandingPages(partnerId);
    }
  }, [partnerId, page]);

  return (
    <View
      partnerId={partnerId}
      clubId={clubId}
      loading={loading}
      inputSearch={inputSearch}
      landingPageList={landingPageList}
      page={page}
      count={count}
      setInputValue={setInputValue}
      setlandingPages={setlandingPages}
      goToLPSignUp={goToLPSignUp}
      handleChangePage={handleChangePage}
    />
  );
};

