import React, { useState } from 'react';
import { Route } from 'react-router-dom';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
} from '@mui/material';

import placeholder from 'styles/assets/placeholder/placeholder_thumbnail.png';

import { downloadImage } from 'js/library/services/StorageManager';
import { convertToDateDefault } from 'js/library/utils/helpers';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
  return order === 'desc' ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const DataTableLottery = (props) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const handleRequestSort = (property) => (event) => {
    const isDesc = orderBy === property && order === 'desc';
    setOrder(isDesc ? 'asc' : 'desc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const downloadImageHandler = (fotoThumb, key, downloadedThumb) => {
    if (fotoThumb !== undefined && fotoThumb !== null && downloadedThumb === placeholder) {
      let campaingList = [...props.campaingList];
      let copyCampaingList = [...props.copyCampaingList];

      const indexTotal = campaingList.findIndex((x) => x.key === key);
      const indexPesquisa = copyCampaingList.findIndex((x) => x.key === key);

      downloadImage('voucher', fotoThumb)
        .then((downloadedImage) => {
          campaingList[indexTotal].downloadedThumb = downloadedImage;
          copyCampaingList[indexPesquisa].downloadedThumb = downloadedImage;
          copyCampaingList[indexPesquisa].downloadedPhoto = downloadedImage;

          props.setCampaingList(campaingList);
          props.setCopyCampaingList(copyCampaingList);
        })
        .catch((error) => {
          // console.log("Erro:", error);
          // console.log("Foto não encontrada:", fotoThumb);
        });
    }
  };

  return (
    <Route
      render={({ history }) => (
        <div
          style={{
            width: '100%',
            maxHeight: '100%',
            overflowY: 'auto',
            paddingBottom: '20px',
          }}
        >
          <span className="scrollbar" style={{ marginLeft: '20rem' }}></span>
          <div style={{ maxHeight: '100%', overflowY: 'auto' }}>
            <Table stickyHeader aria-labelledby="tableTitle" style={{ marginTop: '1rem' }}>
              <TableHead>
                <TableRow>
                  {props.rows.map((row) => {
                    return (
                      <TableCell
                        key={row.id}
                        numeric={row.numeric}
                        padding={row.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === row.id ? order : false}
                        style={{ fontWeight: '550' }}
                      >
                        <Tooltip
                          title={row.id === 'thumbnail' ? 'Thumbnail' : 'Ordenar'}
                          placement={row.numeric ? 'bottom-end' : 'bottom-start'}
                          enterDelay={300}
                        >
                          <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={row.id === 'thumbnail' ? null : handleRequestSort(row.id)}
                            style={{
                              cursor: row.id === 'thumbnail' ? 'default' : 'pointer',
                            }}
                          >
                            {row.label}
                          </TableSortLabel>
                        </Tooltip>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {stableSort(props.copyCampaingList, getSorting(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((n) => {
                    return (
                      <TableRow
                        key={n.key}
                        hover
                        onClick={() =>
                          history.push({
                            pathname: '/admin/configuracoes/sorteio/' + n.key,
                            state: n,
                          })
                        }
                        tabIndex={-1}
                      >
                        <TableCell scope="row" padding="none" folder="estabelecimento">
                          <img
                            src={n.downloadedThumb}
                            onLoad={() =>
                              downloadImageHandler(n.walletThumbnail, n.key, n.downloadedThumb)
                            }
                            alt="Thumb do Estabelecimento"
                            className="thumbnail"
                            width="65px"
                          />
                        </TableCell>
                        <TableCell scope="row"> {n.walletTitle} </TableCell>
                        <TableCell scope="row"> {n.lotterySerie} </TableCell>
                        <TableCell scope="row">
                          {' '}
                          {convertToDateDefault(n.activationDate)}{' '}
                        </TableCell>
                        <TableCell scope="row"> {convertToDateDefault(n.dueDate)} </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </div>
          <TablePagination
            component="div"
            rowsPerPageOptions={[10, 25, 50, 100]}
            count={props.copyCampaingList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            backIconButtonProps={{ 'aria-label': 'Previous Page' }}
            nextIconButtonProps={{ 'aria-label': 'Next Page' }}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <br />
          <br />
          <br />
        </div>
      )}
    />
  );
};

export default DataTableLottery;
