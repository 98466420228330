import React, { useEffect, useState } from 'react';
import { HeaderTitle } from './HeaderTitle';

import { getSeuClube } from 'js/library/utils/API/seuClube';

import usePagination from './usePagination';
import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';

import { findValueInArray, randomizeNumbersString } from 'js/library/utils/helpers';

// css personalizado
import { stylesMui5 } from './useStyles';
import * as S from './styles';
import searchIcon from 'styles/assets/SearchUser/search.svg';

import { DeleteUserButton, DeleteButtonContainer } from './styles';

// componentes do material ui 5
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Pagination,
  CircularProgress,
} from '@mui/material/';

import { FaTrash } from 'react-icons/fa';

import DeleteUserModal from './DeleteUserModal';
import { getUsersByPartner } from 'js/library/utils/API/apiGetUsersByPartner';
import { Button } from '@mui/material';
import { GetPartnerUserForms } from 'js/library/utils/API/apiGetPartnerUserForms';
import { ToastContainer, toast } from 'react-toastify';
import { GoBackHeader } from './GoBackHeader';

export default function ListUsers({ history, ...props }) {
  const [inputSearchCell, setInputSearchCell] = useState('');
  const [clientList, setClientList] = useState([]);
  const [page, setPage] = useState(1);
  const [clubId] = useState(window.location.href.split('/')[7]);
  const [seuClubeInfos, setSeuClubeInfos] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentUId, setCurrentUId] = useState('');

  const [tableColumn, setTableColumn] = useState([]);
  const PER_PAGE = 20;
  const [loading, setLoading] = useState(true);

  const [currentDDD, setCurrentDDD] = useState('+55');
  const [inputMask, setInputMask] = useState('(99) 99999-9999');
  const [cellphone, setCellphone] = useState('');
  const [currentCountry, setCurrentCountry] = useState('');

  const handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(','); //transforma a string em um array a partir da vírgula
    setInputSearchCell('');
    setCurrentDDD(valuesArr[0]);
    setInputMask(valuesArr[1]);
    setCurrentCountry(e.target.value);
  };

  useEffect(() => {
    setCellphone(currentDDD + inputSearchCell.replace(/\D/g, ''));
  }, [inputSearchCell]);

  useEffect(() => {
    (async function getClientList() {
      if (clubId !== '') {
        try {
          const clubeInfos = await getSeuClube(clubId);
          const formClube = await GetPartnerUserForms(clubeInfos.partnerId);
          const response = await getUsersByPartner(clubeInfos.partnerId);

          const fieldNames = formClube.formFields.map((field) => {
            if (field.type !== 'optInList') {
              return field.fieldName;
            }
          });
          if (response.length > 0) {
            const clientesFiltradosSemNull = response.filter((e) => e !== null);

            const formattedUsers = clientesFiltradosSemNull.map((user) => {
              return {
                ...formatValues(formClube.formFields, user),
                uId: { value: user.uid },
              };
            });

            setTableColumn(fieldNames.filter((e) => e !== undefined));

            setClientList(formattedUsers);
            setLoading(false);
          } else {
            toast.error('Nenhum membro encontrado.');
            setLoading(false);
          }
          setSeuClubeInfos(clubeInfos);
        } catch (error) {
          toast.error('Erro ao buscar membros');
          setLoading(false);
        }
      }
    })();
  }, [clubId]);

  const filteredClients = cellphone
    ? clientList.filter((client) => {
        const cellphoneFilter = client['cellPhone']?.value;

        return cellphoneFilter
          ? cellphoneFilter.toLowerCase().includes(cellphone.toLowerCase())
          : 'não cadastrado';
      })
    : clientList;

  const count = Math.ceil(filteredClients.length / PER_PAGE);
  const _DATA = usePagination(filteredClients, PER_PAGE);

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  const formatValues = (formFields, userInfo) => {
    let valuesForm = {};
    //partnerId, uId do usuario
    if (formFields.length > 0) {
      formFields.forEach((field, index) => {
        switch (field.type) {
          case 'contactList':
            if (field.fieldType === 'cellPhone') {
              if (findValueInArray(userInfo.contactList, 'type', field.fieldName)) {
                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    value: findValueInArray(userInfo.contactList, 'type', field.fieldName).value,
                  },
                };
              } else {
                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    value: '',
                  },
                };
              }
            }

            if (field.inputType === 'email') {
              if (findValueInArray(userInfo.contactList, 'type', field.fieldName)) {
                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    value: findValueInArray(userInfo.contactList, 'type', field.fieldName).value,
                  },
                };
              } else {
                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    value: '',
                  },
                };
              }
            }
            break;
          case 'documentList':
            if (
              field.fieldType === 'cpf' ||
              field.fieldType === 'rg' ||
              field.fieldType === 'cnpj'
            ) {
              if (findValueInArray(userInfo.documentList, 'type', field.fieldName)) {
                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    value: findValueInArray(userInfo.documentList, 'type', field.fieldName).value,
                  },
                };
              } else {
                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    value: '',
                  },
                };
              }
            }

            break;
          case 'addressList':
            if (userInfo.addressList && Array.isArray(userInfo.addressList)) {
              userInfo.addressList.forEach((item) => {
                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    value:
                      item.streetAve +
                      ', ' +
                      item.streetNumber +
                      ', ' +
                      item.neighborhood +
                      ', ' +
                      item.city +
                      '-' +
                      item.state +
                      ', cep: ' +
                      item.zipCode +
                      (item.extra === undefined ? '' : ', ' + item.extra),
                  },
                };
              });
            } else {
              valuesForm = {
                ...valuesForm,
                [field.fieldName]: {
                  value: '',
                },
              };
            }

            break;
          case 'others':
            if (userInfo[field.fieldName]) {
              valuesForm = {
                ...valuesForm,
                [field.fieldName]: {
                  value: userInfo[field.fieldName],
                },
              };
            } else {
              if (field.fieldType === 'cellPhone') {
                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    mask: {
                      id: '0',
                      initials: 'BR',
                      country: 'Brasil',
                      emoji: '🇧🇷',
                      fullMask: '+55(00)00000-0000',
                      mask: '(99) 99999-9999',
                      ddd: '+55',
                    },
                    value: '',
                  },
                };
              } else {
                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    value: '',
                  },
                };
              }
            }
            break;
          case 'root':
            if (userInfo[field.fieldName]) {
              valuesForm = {
                ...valuesForm,
                [field.fieldName]: {
                  value: userInfo[field.fieldName],
                },
              };

              if (field.fieldType === 'date') {
                const dataOriginal = userInfo[field.fieldName];
                const dataObj = new Date(dataOriginal);
                dataObj.setDate(dataObj.getDate() + 1);
                const ano = dataObj.getFullYear();
                const mes = ('0' + (dataObj.getMonth() + 1)).slice(-2);
                const dia = ('0' + dataObj.getDate()).slice(-2);
                const dataInvertida = dia + '-' + mes + '-' + ano;

                valuesForm = {
                  ...valuesForm,
                  [field.fieldName]: {
                    value: dataInvertida,
                  },
                };
              }
            } else {
              valuesForm = {
                ...valuesForm,
                [field.fieldName]: {
                  value: '',
                },
              };
            }

          default:
            break;
        }
      });
    }
    return valuesForm;
  };

  const extractHeaders = (jsonArray) => {
    return Object.keys(jsonArray[0]);
  };

  const jsonToCSV = (jsonArray) => {
    const headers = extractHeaders(jsonArray);

    const csvRows = [];
    csvRows.push(headers.join(',')); // Adiciona os cabeçalhos ao CSV

    jsonArray.forEach((obj) => {
      const row = headers.map((header) => {
        const value = obj[header].value;
        // Envolver valores que contêm vírgulas em aspas duplas
        return value.includes(',') ? `"${value}"` : value;
      });
      csvRows.push(row.join(',')); // Adiciona a linha ao CSV
    });

    return csvRows.join('\n');
  };

  const downloadCSV = (jsonArray) => {
    const csv = jsonToCSV(jsonArray);
    const blob = new Blob([csv], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.setAttribute('hidden', '');
    a.setAttribute('href', url);
    a.setAttribute('download', 'data.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          height: '80vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={40} />
      </div>
    );
  }

  return (
    <div style={{ padding: '4rem 32px' }}>
      <ToastContainer />
      <GoBackHeader />
      <S.Container>
        <DeleteUserModal
          open={openDeleteModal}
          setOpen={setOpenDeleteModal}
          uId={currentUId}
          clubId={clubId}
          list={clientList}
          setList={setClientList}
          partnerId={seuClubeInfos.partnerId}
        />
        <HeaderTitle pageInfo="Seus clientes" />
        <Box>
          <Typography
            variant="h6"
            component="h3"
            gutterBottom
            style={{
              color: '#6E3296',
              fontSize: '1.5rem',
              marginBottom: '1rem',
              fontWeight: 'bold',
            }}
          >
            Procurar cliente
          </Typography>
          <S.BoxSearchInput style={{ marginBottom: '2rem' }}>
            <S.Adornment>
              <select
                style={{
                  fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                  flex: '1',
                  height: '100%',
                  border: 'none',
                  outline: 'none',
                  fontSize: '17px',
                  color: '#383838d6',
                  borderRadius: '10px',
                  cursor: 'pointer',
                }}
                value={currentCountry}
                onChange={handleCountryChange}
              >
                {phoneMaskArray.map((country, index) => (
                  <option
                    style={{
                      fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                    }}
                    key={country.id}
                    value={[country.ddd, country.mask, country.fullMask]}
                  >
                    {country.emoji} {country.initials} {country.ddd}
                  </option>
                ))}
              </select>
            </S.Adornment>

            <S.InputSearch
              mask={inputMask}
              value={inputSearchCell}
              placeholder={randomizeNumbersString(inputMask)}
              onChange={(e) => setInputSearchCell(e.target.value)}
            />

            <div className="searchIcon">
              <Button disabled>
                <img src={searchIcon} alt="" />
              </Button>
            </div>
          </S.BoxSearchInput>
        </Box>

        <Box marginBottom={'30px'} width={'100%'} display={'flex'} justifyContent={'flex-end'}>
          <Button variant={'outlined'} onClick={() => downloadCSV(clientList)}>
            Download CSV
          </Button>
        </Box>

        <S.Adornment>
          <Typography variant="subtitle1" ccomponent="h3" style={{ marginBottom: '2rem' }}>
            Membros recém-adicionados podem levar alguns minutos para serem exibidos na tabela de
            clientes. Caso não consiga visualizar o novo membro após alguns minutos, por favor,
            entre em contato com nossa equipe.
          </Typography>
        </S.Adornment>

        {filteredClients.length > 0 ? (
          <>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} size="medium" aria-label="">
                <TableHead sx={stylesMui5.tableHead}>
                  <TableRow>
                    <TableCell>
                      <strong>Excluir</strong>
                    </TableCell>
                    {tableColumn.map((nomeColuna, index) => (
                      <TableCell key={index}>
                        <strong>{nomeColuna}</strong>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_DATA.currentData().map((row, indexClients) => (
                    <TableRow
                      key={indexClients}
                      sx={{
                        '&:last-child td, &:last-child th': { border: 0 },
                      }}
                    >
                      <TableCell align="left">
                        <DeleteButtonContainer>
                          <DeleteUserButton
                            onClick={() => {
                              setCurrentUId(row.uId.value);
                              setOpenDeleteModal(true);
                            }}
                          >
                            <FaTrash style={{ color: 'white' }} />
                          </DeleteUserButton>
                        </DeleteButtonContainer>
                      </TableCell>
                      {tableColumn.map((nomeColuna, index) => (
                        <TableCell
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                          align="left"
                          key={index}
                        >
                          {row[nomeColuna]?.value || 'não cadastrado'}
                        </TableCell>
                      ))}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              sx={{ mt: 3 }}
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
            />
          </>
        ) : (
          'Nenhum membro encontrado'
        )}
      </S.Container>
    </div>
  );
}
