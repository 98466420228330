import { findStoreByProximity } from 'js/library/utils/API/CampaignLandinPage/apiFindStoreByProximity';
import { getEstablishmentInfoInBatch } from 'js/library/utils/API/Establishment/apiGetEstablishmentInfoInBatch';
import { apiListEstablishment } from 'js/library/utils/API/Establishment/apiListEstablishments';
import { getStoresByIds } from 'js/library/utils/API/Store/apiGetStoresById';

const getListEstablishments = async (search, from, size) => {
  const response = await apiListEstablishment(search, from, size);
  return response;
};

const getListPromotions = async (title, isPublished, from, size) => {
  const data = await findStoreByProximity(title, isPublished, from, size);
  if (data.success) {
    return data.response;
  }
};

const getStoreNameById = async (storesIdsList) => {
  const data = await getStoresByIds(storesIdsList);
  if (data.success) {
    return data.response;
  }
};

const getEstablishmentsNameById = async (IdsList) => {
  const data = await getEstablishmentInfoInBatch(IdsList);
  if (data.success) {
    return data.response;
  }
};

const carouselTypes = [
  { option: 'Estabelecimento', type: 'establishment' },
  { option: 'Promoção', type: 'promotion' },
];

export default {
  getListEstablishments,
  getListPromotions,
  getStoreNameById,
  getEstablishmentsNameById,
  carouselTypes,
};
