import placeholder from 'styles/assets/placeholder/placeholder_thumbnail.png';

import {
  getDataListOrderBy,
  verificarAprovacaoPromocao,
} from 'js/library/services/DatabaseManager';

import { downloadImage } from 'js/library/services/StorageManager';

import { getDataListRestApi } from 'js/library/utils/API/restApiFirebase.js';
import { CreateCard } from 'js/library/utils/API/apiCreateOrder';
import { getVoucherTemplate } from 'js/library/utils/API/getVoucherTemplate';

export class AttendanceServices {
  dataEstabelecimento = {};

  constructor(dataEstabelecimento) {
    if (!dataEstabelecimento)
      throw new Error('[AttendanceServices]: dataEstabelecimento is required!');

    this.dataEstabelecimento = dataEstabelecimento;
  }

  getPromotions(setPromotionsData) {
    if (!setPromotionsData)
      throw new Error('[AttendanceServices]: setPromotionsData function is required!');

    getDataListOrderBy('Store', 'establishmentId', this.dataEstabelecimento.id).then(
      (dataReceived) => {
        const listActivePromotions = [];
        for (let i = 0; i < dataReceived.length; i++) {
          //insere o placeholder nas imagens
          dataReceived[i].id = dataReceived[i].key;
          dataReceived[i]['downloadedThumb'] = placeholder;
          dataReceived[i]['aprovacao'] = null;
          verificarAprovacaoPromocao(
            dataReceived[i].id,
            dataReceived[i].type,
            this.dataEstabelecimento.id,
            this.dataEstabelecimento.nome
          ).then((approval) => {
            dataReceived[i]['aprovacao'] = approval;
            const isActive =
              dataReceived[i].isPublished === true &&
              dataReceived[i].enableDate <= Date.now() &&
              dataReceived[i].disableDate >= Date.now();
            const isAwaiting = dataReceived[i].aprovacao?.status === 'aberto';
            if (isActive || isAwaiting) {
              listActivePromotions.push(dataReceived[i]);
            }
            setPromotionsData(dataReceived);
          });
        }

        setPromotionsData(dataReceived);
      }
    );
  }

  getPromotionImage(fotoThumb, key, downloadedThumb, type, promotionData, setThumbnail) {
    if (!promotionData)
      throw new Error('[AttendanceServices]: promotionData param function is required!');

    if (fotoThumb !== undefined && downloadedThumb === placeholder) {
      let data = promotionData;
      const image = fotoThumb.split('/');

      const index = data.findIndex((x) => x.key === key);

      if (type === 'product') {
        downloadImage(image[0], image[1]).then((downloadedImage) => {
          data[index].downloadedThumb = downloadedImage;
          setThumbnail(downloadedImage);
        });
      } else {
        downloadImage('promocao', fotoThumb).then((downloadedImage) => {
          data[index].downloadedThumb = downloadedImage;
          setThumbnail(downloadedImage);
        });
      }
    }
  }

  getVouchers(templateId) {
    if (!templateId) throw new Error('[AttendanceServices]: templateId param is required!');

    return getVoucherTemplate(templateId).then((dataReceived) => {
      if (dataReceived) {
        const batchId = dataReceived.generator ? 'AUTO' : null;

        return { ...dataReceived, batchId };
      }
    });
  }

  getVoucherGroup(groupId) {
    if (!groupId) throw new Error('[AttendanceServices]: groupId param is required!');

    return getDataListRestApi('Voucher-Group/' + groupId)
      .then((dataReceived) => {
        return dataReceived;
      })
      .catch((error) => {
        // console.log("Voucher group fetch error: ", error);
        return error;
      });
  }

  validateVoucher(uId, sender, itemId, latitude, longitude, type, receiver) {
    if ((!sender, !itemId)) {
      throw new Error('[AttendanceServices]: sender, itemId are required!');
    }

    const status = 'requested';

    return CreateCard(uId, sender, itemId, latitude, longitude, type, receiver, status);
  }
}

