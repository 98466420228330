import axios from 'axios';

import authToken from 'js/library/utils/API/authToken';
import placeholder from 'styles/assets/placeholder/placeholder_thumbnail.png';

import {
  getDataListOrderBy,
  verificarAprovacaoPromocao,
} from 'js/library/services/DatabaseManager';
import cfac22 from '../../cfac22';

function getListPromotion(establishmentId, establishmentName, promotionId) {
  return new Promise((resolve, reject) => {
    getDataListOrderBy('Store', 'establishmentId', establishmentId)
      .then(async (dataReceived) => {
        await Promise.all(
          dataReceived.map((_, index) => {
            dataReceived[index].id = dataReceived[index].key;
            dataReceived[index]['downloadedThumb'] = placeholder;
            dataReceived[index]['aprovacao'] = null;

            verificarAprovacaoPromocao(
              dataReceived[index].id,
              dataReceived[index].type,
              establishmentId,
              establishmentName
            ).then((approval) => {
              dataReceived[index]['aprovacao'] = approval;
            });
          })
        );

        const result = dataReceived.filter((promo) => promo.id === promotionId);

        resolve(result[0]);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

export function updateStoreApprovals(uId, storeId, payload, pathname) {
  return new Promise((resolve, reject) => {
    if (storeId !== null) {
      authToken(uId)
        .then((token) => {
          axios
            .patch(
              `${cfac22('API_HOST_V2')}/stores/pending/${storeId}`,
              { payload },
              {
                headers: { authorization: `Bearer ${token}` },
              }
            )
            .then((result) => {
              if (pathname !== null) {
                window.pathname = pathname;
              }

              result.data.storeInfos.id = storeId;

              resolve(result.data.storeInfos);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      resolve((window.pathname = pathname));
    }
  });
}

export function updateStore(storeId, payload, pathname) {
  return new Promise((resolve, reject) => {
    if (storeId !== null) {
      authToken()
        .then((token) => {
          axios
            .patch(
              `${cfac22('API_HOST_V2')}/stores/${storeId}`,
              { payload },
              { headers: { authorization: `Bearer ${token}` } }
            )
            .then(async (result) => {
              if (window?.promoListOrganized) {
                const promotionId = result.data.storeInfo.id;
                const originalPromotionList = window.promoListOrganized?.originalPromotionList;
                const promotionListActive = window.promoListOrganized?.promotionListActive;
                const newFilteredPromotionList =
                  window.promoListOrganized?.newFilteredPromotionList;

                try {
                  const updatedPromotion = await getListPromotion(
                    result.data.storeInfo.establishmentId,
                    result.data.storeInfo.establishmentName,
                    promotionId
                  );

                  originalPromotionList.forEach((promo, index) => {
                    if (promo.id === promotionId) {
                      originalPromotionList.splice(index, 1, updatedPromotion);
                    }
                  });

                  promotionListActive.forEach((promo, index) => {
                    if (promo.id === promotionId) {
                      promotionListActive.splice(index, 1, updatedPromotion);
                    }
                  });

                  if (window.promoListOrganized?.newFilteredPromotionList) {
                    newFilteredPromotionList.forEach((promo, index) => {
                      if (promo.id === promotionId) {
                        const isActive =
                          promo.isPublished === true &&
                          promo.enableDate <= Date.now() &&
                          promo.disableDate >= Date.now();
                        const isAwaiting = promo.aprovacao?.status === 'aberto';

                        if (isActive || isAwaiting) {
                          newFilteredPromotionList.splice(index, 1, updatedPromotion);
                        } else {
                          newFilteredPromotionList.splice(index, 1);
                        }
                      }
                    });
                  }
                } catch (error) {
                  console.log('***Error get', error);
                }
              }

              if (pathname !== null) {
                window.pathname = pathname;
              }

              result.data.storeInfo.id = storeId;

              resolve(result.data.storeInfo);
            })
            .catch((error) => {
              reject(error);
            });
        })
        .catch((error) => {
          reject(error);
        });
    } else {
      resolve((window.pathname = pathname));
    }
  });
}

export function createStoreChange(uId, establishmentId, establishmentName, storeId, data) {
  return new Promise(async (resolve, reject) => {
    authToken(uId).then((token) => {
      const config = {
        method: 'post',
        url: `${cfac22('API_HOST_V2')}/storeChanges/createStoreChange`,
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        data: {
          payload: {
            establishmentId: establishmentId,
            establishmentName: establishmentName,
            storeId: storeId,
            changedFields: data,
          },
        },
      };

      axios(config)
        .then((response) => {
          resolve(response.data.storeInfo);
        })
        .catch((error) => {
          reject(error);
        });
    });
  });
}
