import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Modal,
  TextField,
  Typography,
  Button,
  ListItem,
  ListItemText,
  IconButton,
  List,
  CircularProgress,
} from "@mui/material";

import * as S from "../styles";
import { toast, ToastContainer } from "react-toastify";

import DeleteIcon from "@mui/icons-material/Delete";
import OrgIcon from "../../../../../styles/assets/SearchUser/org.svg";

import { GetOrganizations } from "js/library/utils/API/SearchUser/apiGetOrganizations";
import { insertUserOrgs } from "js/library/utils/API/SearchUser/insertUserOrgs";
import { getUserOrgs } from "js/library/utils/API/getUserOrgs";
import { removeUserOrgs } from "js/library/utils/API/SearchUser/removeUserOrgs";

export default function AddOrgs({
  isModalAddOrgsOpen,
  handleCloseModal,
  userv3,
}) {
  const [allOrganizations, setAllOrganizations] = useState([]);
  const [userOrgs, setUserOrgs] = useState([]);
  const [inputSearchOrgs, setInputSearchOrgs] = useState("");
  const [organizationsSelected, setOrganizationsSelected] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const resGetOrganizations = async () => {
      const result = await GetOrganizations().then((data) => {
        setAllOrganizations(data);
      });
      return result;
    };

    resGetOrganizations();
  }, []);

  useEffect(() => {
    const resGetUserOrgs = async () => {
      if (userv3 !== null) {
        const result = await getUserOrgs(userv3.uId).then((data) => {
          setUserOrgs(data.orgs);
        });
        return result;
      }
    };

    resGetUserOrgs();
  }, [userv3]);

  const filteredOrgsSuggestions = inputSearchOrgs
    ? allOrganizations.filter((org) => {
        return org.name.toLowerCase().includes(inputSearchOrgs.toLowerCase());
      })
    : [];

  const handleAddOrg = (orgs) => {
    setLoading(true);
    orgs.map((org) => {
      if (userv3 !== null) {
        insertUserOrgs(userv3.uId, org.orgID)
          .then((response) => {
            setOrganizationsSelected([]);
            setLoading(false);

            if (response.message === "User successfully inserted.") {
              setUserOrgs([...userOrgs, org]);
              toast.success("Organização cadastrada com sucesso.");
            } else if (
              response.message === "User already belonged to that organization."
            ) {
              toast.warning(`Usuário já pertence à organização ${org.name}`);
            } else if (org.accessMethod === "private") {
              toast.warning("Autorização pendente.");
            }
          })
          .catch((error) => {
            toast.error("Erro ao adicionar a organização");
          });
      }
    });
  };

  const handleDeleteOrg = (orgID) => {
    setLoading(true);
    removeUserOrgs(userv3.uId, orgID)
      .then((result) => {
        const newUserOrgs = userOrgs.filter((org) => org.orgID !== orgID);
        setUserOrgs(newUserOrgs);
        toast.success("Organização removida com sucesso.");
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Erro ao remover organização.");
        setLoading(false);
      });
  };

  return (
    <Modal
      onClose={()=>handleCloseModal('closeModalAddOrgs')}
      open={isModalAddOrgsOpen}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <div style={{ width: "100%", height: "100%" }}>
        <ToastContainer autoClose={6000} />
        <S.ModalBox>
          <div className="header">
            <img src={OrgIcon} alt="" />
            <Typography variant="h2" component="h2" id="modal-title">
              Cadastro de organizações
            </Typography>
          </div>
          <S.AddOrgsModalBox>
            <div className="inputSearchBox">
              <Typography variant="h4" component="h4" id="modal-description">
                Insira o nome da nova organização e adicione na lista abaixo.
              </Typography>
              <div className="inputSearch">
                <Autocomplete
                  className="input"
                  multiple
                  id="tags-outlined"
                  options={filteredOrgsSuggestions}
                  getOptionLabel={(option) => option.name}
                  value={organizationsSelected}
                  onChange={(event, value) => setOrganizationsSelected(value)}
                  filterSelectedOptions
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.orgID}>
                        {option.name}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      size="small"
                      value={inputSearchOrgs}
                      onChange={(e) => setInputSearchOrgs(e.target.value)}
                    />
                  )}
                />
              </div>

              <Button
                className="submitButton"
                variant="contained"
                size="medium"
                disabled={loading || organizationsSelected <= 0}
                onClick={() => handleAddOrg(organizationsSelected)}
              >
                {loading && (
                  <CircularProgress
                    style={{ marginRight: "0.5rem" }}
                    size="23px"
                  />
                )}
                Adicionar
              </Button>
            </div>

            <div className="linkedKeywordsLists">
              <Typography variant="h6" component="h6" className="titles">
                Organizações cadastradas
              </Typography>
              {userOrgs ? (
                <List
                  sx={{
                    width: "100%",
                    maxHeight: 195,
                    height: "100%",
                    overflow: "auto",
                    border: "1px solid #333",
                    bgcolor: "background.paper",
                    borderRadius: "5px",
                    padding: "0 0.3rem",
                  }}
                  className="list"
                >
                  {userOrgs.map((item, index) => (
                    <ListItem
                      className="listItem"
                      key={index}
                      disableGutters
                      secondaryAction={
                        <IconButton
                          aria-label="delete"
                          onClick={() => handleDeleteOrg(item.orgID)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                    >
                      <ListItemText primary={item.name} />
                    </ListItem>
                  ))}
                </List>
              ) : (
                <Typography
                  variant="h6"
                  component="h6"
                  className="messageNoOrg"
                >
                  Nenhuma organização cadastrada.
                </Typography>
              )}
            </div>

            <Button
              variant="contained"
              style={{
                marginTop: "2rem",
                maxWidth: "164px",
                margin: "2rem auto 0",
                width: "100%",
                background: "#DBDBDB",
                color: "#555555",
                textTransform: "capitalize",
                fontWeight: "bold",
              }}
              onClick={()=>handleCloseModal('closeModalAddOrgs')}
            >
              Voltar
            </Button>
          </S.AddOrgsModalBox>
        </S.ModalBox>
      </div>
    </Modal>
  );
}
