import axios from 'axios';
import authToken from './authToken';
import cfac22 from '../cfac22';

export function createUserSegment(payload) {
  return new Promise((resolve, reject) => {
    authToken().then((token) => {
      axios
        .post(
          cfac22('API_HOST_V2') +
            `/userSegment/create
            `,
          {
            ...payload, // active: BOLLEAN, name: STRING, partnerId: STRING
          },
          {
            headers: { authorization: 'Bearer ' + token },
          }
        )
        .then((result) => {
          resolve(result.data);
        })
        .catch((error) => {
          return reject(error.response.data);
        });
    });
  });
}
