import React, { useEffect, useRef, useState } from 'react';

//STYLES
import {
  Radio,
  FormControlLabel,
  FormLabel,
  Grid,
  MenuItem,
  TextField,
  InputLabel,
  Modal,
  FormControl,
  CircularProgress,
} from '@mui/material';
import * as S from '../styles';
import { ModalBoxClube } from '../BasicRegistration/styles';
import { Save } from '@mui/icons-material';
import {
  ContentModalNewCard,
  InputFormNewCard,
  MenuItems,
  RadioGroupRow,
  SelectsModalNewCard,
  SwitchPersonality,
  TagText,
  TitleCards,
} from './CardManagementStyles';

// IMGS DEFAULT
import cashBackImg from 'styles/assets/cardsGenerico/promocoes-cashback.jpg';
import estabProxImg from 'styles/assets/cardsGenerico/estabelecimentos-proximos.jpg';
import estabSelectImg from 'styles/assets/cardsGenerico/estabelecimentos-selecionados.jpg';
import promoItemImg from 'styles/assets/cardsGenerico/acessar-promocao.jpg';
import promoProxImg from 'styles/assets/cardsGenerico/promocoes-proximas.jpg';
import promoSelectImg from 'styles/assets/cardsGenerico/promocoes-selecionadas.jpg';
import urlImg from 'styles/assets/cardsGenerico/acessar-url.jpg';

//JSONS
import itemActions from './itemActions.json';
import listActions from './listActions.json';

//APIS
import { createCard, updateCard } from 'js/library/utils/API/apiCards';

//OTHERS
import { convertToMillis, handleModeUrl, separateBase64String } from 'js/library/utils/helpers';
import { toast } from 'react-toastify';

import { convertToDate } from 'js/library/utils/helpers';
import ImportImage from 'js/components/TriiboComponents/ImportImage';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';
import cfac22 from 'js/library/utils/cfac22';

export function ModalNewCard({
  close,
  openModal,
  arr,
  infosForEdit,
  isNewCard,
  getInfosCards,
  seeInactive,
  data,
  setCardsClub,
}) {
  const [error, setError] = useState(false);
  const [errorUrl, setErrorUrl] = useState(false);
  const [cardDefault, setCardDefault] = useState(promoProxImg);
  const [cardDefaultDetails, setCardDefaultDetails] = useState(promoProxImg);
  const [query, setQuery] = useState('');
  const [itemId, setItemId] = useState('');
  const [url, setUrl] = useState('');
  const [idActionList, setIdActionList] = useState('');
  const [tag, setTag] = useState('');
  const [isPrivateCard, setIsPrivateCard] = useState(false);
  const [chooseDate, setChooseDate] = useState(false);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [maxPriority, setMaxPriority] = useState('');
  const [loading, setLoading] = useState(false);
  const [formCard, setFormCard] = useState({
    action: '',
    cardType: 'card_list',
    description: '',
    keywords: [],
    orgs: [],
    platform: ['Web', 'Android', 'iOS'],
    thumbDesktop: '',
    thumbMobile: '',
    mobileDetails: '',
    title: '',
    disableDate: 1988161200000,
    enableDate: '',
    isPublished: seeInactive ? infosForEdit.isPublished : true,
  });
  const [filesForUpload, setFilesForUpload] = useState({
    thumbDesktop: '',
    mobileDetails: '',
  });

  function handleAction(event) {
    setFormCard({
      ...formCard,
      action: event.target.value,
    });
  }

  function handleChangeTypeCard(event) {
    setError(false);
    setFormCard({
      ...formCard,
      cardType: event.target.value,
    });
  }

  function handleMaskUrl(event) {
    const enteredUrl = event.target.value.trim();
    const formattedUrl = handleModeUrl(enteredUrl);

    setUrl(formattedUrl);
    setFormCard({ ...formCard, action: 'webview' });
  }

  function handleTag(event) {
    setTag(event.target.value);
  }

  function handlePrivacityCard(event) {
    setIsPrivateCard(event.target.checked);
  }

  function handlePublished(event) {
    setFormCard({ ...formCard, isPublished: event.target.checked });
  }

  function handleDateDefined(event) {
    setChooseDate(event.target.checked);
  }

  useEffect(() => {
    if (!isNewCard) return;
    const generateBase64 = async () => {
      try {
        const [firstImgRes, secoundImgRes] = await Promise.all([
          fetch(cardDefault),
          fetch(cardDefaultDetails),
        ]);
        const firstBlob = await firstImgRes.blob();
        const secoundBlob = await secoundImgRes.blob();

        const reader = new FileReader();
        const secoundReader = new FileReader();
        reader.readAsDataURL(firstBlob);
        secoundReader.readAsDataURL(secoundBlob);

        reader.onloadend = () => {
          setCardDefault(reader.result);
        };

        secoundReader.onloadend = () => {
          setCardDefaultDetails(secoundReader.result);
        };
      } catch (error) {
        console.error('Erro ao buscar a imagem:', error);
      }
    };

    generateBase64();
  }, [isNewCard]);

  // Seta as query de acordo com a função escolhida e seta as imagens default para cada tipo de card.
  useEffect(() => {
    switch (idActionList) {
      case '1':
        if (!cardDefault.includes('base64') && !cardDefault.includes('https')) {
          setCardDefault(promoProxImg);
        }
        if (!cardDefaultDetails.includes('base64') && !cardDefaultDetails.includes('https')) {
          setCardDefaultDetails(promoProxImg);
        }
        setQuery(listActions[0].query);
        setFormCard({
          ...formCard,
          action: listActions[0].value,
          thumbDesktop: isNewCard ? 'promocoes- proximas.jpg' : infosForEdit.images.web,
          thumbMobile: isNewCard ? 'promocoes- proximas.jpg' : infosForEdit.images.mobile,
          mobileDetails: isNewCard
            ? 'promocoes-proximas-mobile.jpg'
            : infosForEdit.images.mobileDetails,
        });
        break;
      case '2':
        if (!cardDefault.includes('base64') && !cardDefault.includes('https')) {
          setCardDefault(promoSelectImg);
        }
        if (!cardDefaultDetails.includes('base64') && !cardDefaultDetails.includes('https')) {
          setCardDefaultDetails(promoSelectImg);
        }
        setQuery(listActions[1].query);
        setFormCard({
          ...formCard,
          action: listActions[1].value,
          thumbDesktop: isNewCard ? 'promocoes-selecionadas.jpg' : infosForEdit.images.web,
          thumbMobile: isNewCard ? 'promocoes-selecionadas.jpg' : infosForEdit.images.mobile,
          mobileDetails: isNewCard
            ? 'promocoes-selecionadas-mobile.jpg'
            : infosForEdit.images.mobileDetails,
        });
        break;
      case '3':
        if (!cardDefault.includes('base64') && !cardDefault.includes('https')) {
          setCardDefault(estabProxImg);
        }
        if (!cardDefaultDetails.includes('base64') && !cardDefaultDetails.includes('https')) {
          setCardDefaultDetails(estabProxImg);
        }
        setQuery(listActions[2].query);
        setFormCard({
          ...formCard,
          action: listActions[2].value,
          thumbDesktop: isNewCard ? 'estabelecimentos-proximos.jpg' : infosForEdit.images.web,
          thumbMobile: isNewCard ? 'estabelecimentos-proximos.jpg' : infosForEdit.images.mobile,
          mobileDetails: isNewCard
            ? 'estabelecimentos-proximos-mobile.jpg'
            : infosForEdit.images.mobileDetails,
        });
        break;
      case '4':
        if (!cardDefault.includes('base64') && !cardDefault.includes('https')) {
          setCardDefault(estabSelectImg);
        }
        if (!cardDefaultDetails.includes('base64') && !cardDefaultDetails.includes('https')) {
          setCardDefaultDetails(estabSelectImg);
        }
        setQuery(listActions[3].query);
        setFormCard({
          ...formCard,
          action: listActions[3].value,
          thumbDesktop: isNewCard ? 'estabelecimentos-selecionados.jpg' : infosForEdit.images.web,
          thumbMobile: isNewCard ? 'estabelecimentos-selecionados.jpg' : infosForEdit.images.mobile,
          mobileDetails: isNewCard
            ? 'estabelecimentos-selecionados.jpg'
            : infosForEdit.images.mobileDetails,
        });
        break;
      case '5':
        if (!cardDefault.includes('base64') && !cardDefault.includes('https')) {
          setCardDefault(cashBackImg);
        }
        if (!cardDefaultDetails.includes('base64') && !cardDefaultDetails.includes('https')) {
          setCardDefaultDetails(cashBackImg);
        }
        setQuery(listActions[4].query);
        setFormCard({
          ...formCard,
          action: listActions[4].value,
          thumbDesktop: isNewCard ? 'promocoes-cashback.jpg' : infosForEdit.images.web,
          thumbMobile: isNewCard ? 'promocoes-cashback.jpg' : infosForEdit.images.mobile,
          mobileDetails: isNewCard
            ? 'promocoes-cashback-mobile.jpg'
            : infosForEdit.images.mobileDetails,
        });
        break;
    }

    switch (formCard.cardType) {
      case 'card_url':
        if (!cardDefault.includes('base64') && !cardDefault.includes('https')) {
          setCardDefault(urlImg);
        }
        if (!cardDefaultDetails.includes('base64') && !cardDefaultDetails.includes('https')) {
          setCardDefaultDetails(urlImg);
        }
        setFormCard({
          ...formCard,
          thumbDesktop: isNewCard ? 'cards/acessar-url.jpg' : infosForEdit.images.web,
          thumbMobile: isNewCard ? 'cards/acessar-url.jpg' : infosForEdit.images.mobile,
          mobileDetails: isNewCard
            ? 'cards/acessar-url-mobile.jpg'
            : infosForEdit.images.mobileDetails,
        });
        break;
      case 'card_item':
        if (!cardDefault.includes('base64') && !cardDefault.includes('https')) {
          setCardDefault(promoItemImg);
        }
        if (!cardDefaultDetails.includes('base64') && !cardDefaultDetails.includes('https')) {
          setCardDefaultDetails(promoItemImg);
        }
        setFormCard({
          ...formCard,
          thumbDesktop: isNewCard ? 'acessar-promocao.jpg' : infosForEdit.images.web,
          thumbMobile: isNewCard ? 'acessar-promocao.jpg' : infosForEdit.images.mobile,
          mobileDetails: isNewCard
            ? 'acessar-promocao-mobile.jpg'
            : infosForEdit.images.mobileDetails,
        });
        break;
    }
  }, [idActionList, query, formCard.cardType, isNewCard]);

  // Trás informações para o form quando escolhe a função editar card.
  useEffect(() => {
    async function getInfos() {
      if (!isNewCard) {
        setFormCard({
          ...formCard,
          keywords: data.keywords,
          orgs: data.orgs,
          action: infosForEdit.action,
          cardType: infosForEdit.type,
          description: infosForEdit.description,
          disableDate: convertToDate(infosForEdit.disableDate),
          thumbDesktop: infosForEdit?.images?.web,
          thumbMobile: infosForEdit.images.mobile,
          mobileDetails: infosForEdit.images.mobileDetails,
          title: infosForEdit.title,
        });

        const VerifyPathWeb = infosForEdit.images.web.includes('cards/')
          ? infosForEdit.images.web
          : `cards/${infosForEdit.images.web}`;

        setCardDefault(cfac22('STORAGE_URL') + encodeURIComponent(VerifyPathWeb) + '?alt=media');

        const VerifyPathMobile = infosForEdit.images.mobileDetails?.includes('cards/')
          ? infosForEdit.images.mobileDetails
          : `cards/${infosForEdit.images.mobileDetails}`;

        setCardDefaultDetails(
          cfac22('STORAGE_URL') + encodeURIComponent(VerifyPathMobile) + '?alt=media'
        );

        const listActionsData = listActions.find((item) => item.label === infosForEdit.label);

        if (listActionsData) {
          setIdActionList(listActionsData.id);
        }

        if (Object.keys(infosForEdit).length !== 0) {
          if (infosForEdit.appFilter.url) {
            //  setUrl(infosForEdit.appFilter.url);
            let url = infosForEdit.appFilter.url;
            // Verifica se a URL começa com "https://"
            if (!url.startsWith('https://')) {
              // Se não começar, adiciona "https://"
              url = 'https://' + url;
            }
            setUrl(url);
          } else if (infosForEdit.appFilter.itemId) {
            setItemId(infosForEdit.appFilter.itemId);
          } else if (infosForEdit.appFilter.query) {
            const queryValid = infosForEdit.appFilter.query.includes('LIKE');
            if (queryValid) {
              const oldTag = infosForEdit.appFilter.query.match(/:(.*?)(?=\))/); // pegando valor apartir do primeiro (":")
              const tagValue = oldTag[1].match(/:(.*)/); // pegando valor apartir do segundo (":")
              setTag(tagValue[1]);
            }
            setQuery(infosForEdit.appFilter.query);
          }
        }
      } else {
        setFormCard({
          action: '',
          cardType: 'card_list',
          description: '',
          keywords: data.keywords,
          orgs: data.orgs,
          platform: ['Web', 'Android', 'iOS'],
          thumbDesktop: '',
          thumbMobile: '',
          mobileDetails: '',
          title: '',
          disableDate: 1988161200000,
          enableDate: '',
          isPublished: infosForEdit.enableDate ? false : true,
        });

        setUrl('');
        setItemId('');
        setQuery('');

        setFilesForUpload({
          ...filesForUpload,
          thumbDesktop: '',
          mobileDetails: '',
        });
      }

      // Limpa imagens caso o usuário feche modal após fazer upload da imagem
      if (!openModal) {
        setFilesForUpload({
          ...filesForUpload,
          thumbDesktop: '',
          mobileDetails: '',
        });
        setIdActionList('');
      }
    }
    getInfos();
  }, [infosForEdit, isNewCard, openModal]);

  navigator.geolocation.getCurrentPosition(function (position) {
    setLatitude(position.coords.latitude);
    setLongitude(position.coords.longitude);
  });

  const handleDates = (type) => (event) => {
    switch (type) {
      case 'enableDate':
        setFormCard({ ...formCard, enableDate: event.target.value });
        break;
      case 'disableDate':
        setFormCard({ ...formCard, disableDate: event.target.value });
    }
  };

  const uploadImage = async (image, typeImg) => {
    const newImage = separateBase64String(image);
    let imageCardPath;

    await uploadFiles('cards', {
      mimeType: newImage.mimeType,
      fileName: 'clubeCards_' + typeImg + '_' + data.clubeId,
      buffer: newImage.buffer,
    })
      .then((res) => {
        const response = res.split('/');
        imageCardPath = 'cards/' + response[response.length - 1].split('?')[0];
      })
      .catch((error) => {
        console.log(error);
      });
    return imageCardPath;
  };

  //VERIFICA QUAL O MAIOR PRIORITY DOS CARDS ATUALMENTE ATIVOS
  useEffect(() => {
    const biggestValue = arr.reduce((maxPriority, item) => {
      return item.priority > maxPriority ? item.priority : maxPriority;
    }, 0);

    setMaxPriority(biggestValue);
  }, [arr]);

  useEffect(() => {
    if (cardDefault === '') {
      formCard.thumbDesktop = '';
    } else {
      formCard.thumbDesktop = isNewCard ? formCard.thumbDesktop : infosForEdit.images.web;
    }

    if (cardDefaultDetails === '') {
      formCard.mobileDetails = '';
    } else {
      formCard.mobileDetails = isNewCard
        ? formCard.mobileDetails
        : infosForEdit.images.mobileDetails;
    }
  }, [cardDefault, cardDefaultDetails, formCard]);

  async function generateNewCard(e) {
    e.preventDefault();
    setLoading(true);
    //Insere a tag dentro da query
    const queryString = query.replace('VALOR INSERIDO POR USUÁRIO', tag);
    const date = Date.now();
    const twentyFourHours = 86400000;
    const isUrl = verifyURL(url);

    let data = {
      ...formCard,
      appFilter: {
        itemId,
        query: idActionList === '2' || idActionList === '4' ? queryString : query,
        url,
      },
      enableDate:
        formCard.enableDate === '' ? date - twentyFourHours : convertToMillis(formCard.enableDate),
      disableDate:
        formCard.disableDate === 1988161200000
          ? formCard.disableDate
          : convertToMillis(formCard.disableDate),
      location: {
        longitude,
        latitude,
        radius: 8000,
      },
      orgs: formCard.orgs,
      keyWordsSet: isPrivateCard === true ? formCard.keywords : [],
      images: {
        mobile: cardDefault.includes('https')
          ? infosForEdit.images.mobile
          : cardDefault.includes('base64')
          ? await uploadImage(cardDefault, 'mobile')
          : `${'cards/' ? '' : 'cards/'}` + formCard.thumbMobile,
        web: cardDefault.includes('https')
          ? infosForEdit.images.web
          : cardDefault.includes('base64')
          ? await uploadImage(cardDefault, 'web')
          : `${'cards/' ? '' : 'cards/'}` + formCard.thumbDesktop,
        mobileDetails: cardDefaultDetails.includes('https')
          ? infosForEdit.images.mobileDetails
          : cardDefaultDetails.includes('base64')
          ? await uploadImage(cardDefaultDetails, 'mobileDetails')
          : `${'cards/' ? '' : 'cards/'}` + formCard.mobileDetails,
      },
      priority: isNewCard ? maxPriority + 1 : infosForEdit.priority,
      type: formCard.cardType,
    };

    setTimeout(() => {
      setLoading(false);
    }, 1000);

    switch (formCard.cardType) {
      case 'card_list':
        delete data.appFilter.url;
        delete data.appFilter.itemId;
        break;
      case 'card_url':
        delete data.appFilter.itemId;
        delete data.appFilter.query;
        break;
      case 'card_item':
        delete data.appFilter.query;
        delete data.appFilter.url;
        break;
    }

    delete data.keywords;
    delete data.thumbDesktop;
    delete data.thumbMobile;
    delete data.mobileDetails;
    delete data.cardType;
    delete data.label;

    try {
      if (
        formCard.title !== '' &&
        formCard.cardType !== '' &&
        formCard.action !== '' &&
        formCard.description !== '' &&
        !formCard.thumbDesktop !== '' &&
        formCard.thumbMobile !== '' &&
        !formCard.mobileDetails !== ''
      ) {
        if (formCard.cardType === 'card_url' && !isUrl) {
          setErrorUrl(true);
          return;
        } else {
          if (
            (formCard.cardType === 'card_list' && tag === '' && idActionList === '2') ||
            (formCard.cardType === 'card_list' && tag === '' && idActionList === '4')
          ) {
            setError(true);
          } else {
            if (formCard.cardType === 'card_url') {
              try {
                const validateUrl = new URL(url);
                const splitUrl = validateUrl.origin.split('.');

                if (splitUrl.length < 2 || data.appFilter.url === '') {
                  setErrorUrl(true);
                  return;
                }

                if (data.images.web === '' || data.images.mobile === '') {
                  setError(true);
                  return;
                }
              } catch (error) {
                setError(true);
                return;
              }
            }

            setError(false);
            if (Object.keys(infosForEdit).length === 0 || isNewCard) {
              const createSuccessfull = await createCard(data);
              if (createSuccessfull.data.success) {
                console.log(createSuccessfull.data);
                setCardsClub((prevState) => [
                  ...prevState,
                  {
                    id: createSuccessfull?.data?.result,
                    cardId: createSuccessfull?.data?.result,
                    ...data,
                  },
                ]);
              }
              // getInfosCards();
              if (!loading) close();
            } else {
              const updateSuccessfull = await updateCard(infosForEdit.cardId, data);
              if (updateSuccessfull.data.success) {
                setCardsClub((prevState) => {
                  const cardsClub = [...prevState];
                  const indexUpdatedCard = cardsClub
                    .map((item) => item.id)
                    .indexOf(infosForEdit.cardId);
                  cardsClub[indexUpdatedCard] = {
                    id: infosForEdit.cardId,
                    cardId: infosForEdit.cardId,
                    ...data,
                  };
                  return cardsClub;
                });
                // getInfosCards();
              }
              if (!loading) close();
            }
          }
        }
      } else {
        toast.error('Todos os dados devem ser preenchidos corretamente.');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(
        'Verifique se todos os campos foram preenchidos adequadamente, caso o erro persista, entre em contato com nosso suporte.'
      );
    }
  }

  function verifyURL(input) {
    // Expressão regular para validar uma URL
    let regex = new RegExp(
      '^(https?:\\/\\/)?(www\\.)?' + // Protocolo (opcional) e "www" (opcional)
        '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}(\\.br)?)|' + // Domínio com ou sem o .br
        '((\\d{1,3}\\.){3}\\d{1,3})' + // Ou endereço IP
        '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // Porta e caminho
        '(\\?[;&a-z\\d%_.~+=-]*)?' + // Parâmetros
        '(\\#[-a-z\\d_]*)?$',
      'i'
    );

    return regex.test(input);
  }

  const [isActiveOverflow, setIsActiveOverflow] = useState(true);

  const modalRef = useRef();
  const setScrollPosition = (element, scroll) => {
    element.current.scrollTop = scroll;

    if (scroll === 0) setIsActiveOverflow(false);
    else setIsActiveOverflow(true);
  };

  return (
    <Modal
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      open={openModal}
      onClose={close}
    >
      <ModalBoxClube ref={modalRef} isActiveOverflow={isActiveOverflow}>
        <ContentModalNewCard
          onSubmit={generateNewCard}
        >
          <Grid container>
            <Grid item md={12}>
              <TitleCards variant="h5" gutterBottom>
                Criar novo card
              </TitleCards>
            </Grid>
          </Grid>
          <FormLabel id="tipo-do-card">Tipo do card:</FormLabel>
          <RadioGroupRow
            row
            aria-labelledby="tipo-do-card"
            name="cardType"
            value={formCard.cardType}
            onChange={handleChangeTypeCard}
          >
            <FormControlLabel value="card_list" control={<Radio color="primary" />} label="Lista" />
            <FormControlLabel value="card_url" control={<Radio color="primary" />} label="Url" />
          </RadioGroupRow>

          <FormControl sx={{ m: 1, minWidth: 120, width: '100%' }}>
            {formCard.cardType === 'card_list' && (
              <>
                <S.NameGroup distance="1rem" spacing="0">
                  <InputLabel id="demo-simple-select-helper-label" required={isNewCard}>
                    {'Função'}
                  </InputLabel>
                  <SelectsModalNewCard
                    labelId="demo-simple-select-helper-label"
                    id="demo-simple-select-helper"
                    value={idActionList}
                    label="Função"
                    onChange={(event) => {
                      setIdActionList(event.target.value);
                    }}
                    required
                  >
                    {listActions.map((option, index) => (
                      <MenuItems key={index} value={option.id}>
                        {option.label}
                      </MenuItems>
                    ))}
                  </SelectsModalNewCard>
                </S.NameGroup>
                {idActionList === '2' ||
                formCard.label === 'Promoções selecionadas (por # ou TAG)' ||
                idActionList === '4' ||
                formCard.label === 'Estabelecimentos selecionados (por # ou TAG)' ? (
                  <>
                    <S.NameGroup distance="1rem" spacing="0">
                      <InputFormNewCard
                        required
                        id="outlined-basic"
                        label="Tag"
                        value={tag}
                        inputProps={{ maxLength: 30 }}
                        onChange={handleTag}
                        onBlur={(e) => e.preventDefault()}
                        onFocus={(e) => e.preventDefault()}
                        error={error}
                        helperText={error ? 'Uma tag deve ser inserida' : ''}
                      />
                    </S.NameGroup>
                    {isNewCard && <TagText>*TAG OBRIGATÓRIA EX: triibo</TagText>}
                  </>
                ) : null}
              </>
            )}

            <S.NameGroup distance="1rem" spacing="0">
              <InputFormNewCard
                required
                id="outlined-basic"
                label="Título"
                inputProps={{ maxLength: 100 }}
                value={formCard.title}
                onChange={(event) => setFormCard({ ...formCard, title: event.target.value })}
                onBlur={(e) => e.preventDefault()}
                onFocus={(e) => e.preventDefault()}
              />
            </S.NameGroup>
            {formCard.cardType === 'card_url' && (
              <>
                <S.NameGroup distance="1rem" spacing="0">
                  <InputFormNewCard
                    required
                    InputLabelProps={{ shrink: url !== '' && true }}
                    id="outlined-basic"
                    label={'Url'}
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    onBlur={handleMaskUrl}
                    onFocus={(e) => e.preventDefault()}
                    error={errorUrl}
                    helperText={errorUrl ? 'Uma url válida deve ser inserida' : ''}
                  />
                </S.NameGroup>
              </>
            )}
            <S.NameGroup distance="1rem" spacing="0">
              <InputFormNewCard
                required
                isexpansive="auto"
                id="outlined-multiline-flexible"
                label="Descrição"
                value={formCard.description}
                onChange={(e) => setFormCard({ ...formCard, description: e.target.value })}
                type="text"
                multiline
                onBlur={(e) => e.preventDefault()}
                onFocus={(e) => e.preventDefault()}
              />
            </S.NameGroup>

            {formCard.cardType === 'card_item' && (
              <>
                <S.NameGroup distance="1rem" spacing="0">
                  <S.FormLabel>Função:</S.FormLabel>
                  <SelectsModalNewCard
                    required
                    value={formCard.action}
                    label="Função"
                    onChange={handleAction}
                  >
                    <MenuItem disabled value="">
                      <em>Selecione uma função para o card</em>
                    </MenuItem>
                    {itemActions.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </SelectsModalNewCard>
                </S.NameGroup>
                <S.NameGroup distance="1rem" spacing="0">
                  <S.FormLabel>ID do item*:</S.FormLabel>
                  <S.TextArea
                    value={itemId}
                    onChange={(e) => setItemId(e.target.value)}
                    type="text"
                    id="id do item"
                    onBlur={(e) => e.preventDefault()}
                    onFocus={(e) => e.preventDefault()}
                  />
                </S.NameGroup>
              </>
            )}
          </FormControl>
          <FormLabel component="legend" style={{ margin: '2rem 0 1rem 0' }}>
            Imagem do card:
          </FormLabel>
          <strong>Selecione a imagem para seu card:</strong>
          <>
            <S.FormGroup>
              <strong>Imagem para desktop e mobile:</strong>
              <ImportImage
                imageDefault={true}
                autoWidth={true}
                image={cardDefault}
                setImage={setCardDefault}
                ratio={2.81 / 1}
                refScroll={modalRef}
                setScrollPosition={setScrollPosition}
                setIsActiveOverflow={setIsActiveOverflow}
              />
              <strong>Imagem para detalhes do card:</strong>
              <ImportImage
                imageDefault={true}
                autoWidth={true}
                image={cardDefaultDetails}
                setImage={setCardDefaultDetails}
                ratio={1.33 / 1}
                refScroll={modalRef}
                setScrollPosition={setScrollPosition}
                setIsActiveOverflow={setIsActiveOverflow}
              />
            </S.FormGroup>
          </>

          <FormControlLabel
            style={{ marginTop: '2rem' }}
            control={<SwitchPersonality checked={chooseDate} onChange={handleDateDefined} />}
            label={'Definir data de expiração do card?'}
          />

          {chooseDate && (
            <S.FormGroupRow distance="1rem">
              <TextField
                style={{ marginRight: '1rem', borderRadius: '0.5rem' }}
                id="datetime-local"
                label="Data de ativação"
                type="datetime-local"
                value={formCard.enableDate}
                onChange={handleDates('enableDate')}
                InputLabelProps={{
                  shrink: true,
                }}
                color="primary"
                required={formCard.isPublished}
              />
              <TextField
                style={{ borderRadius: '0.5rem' }}
                id="datetime-local"
                label="Data de desativação"
                type="datetime-local"
                value={formCard.disableDate}
                onChange={handleDates('disableDate')}
                InputLabelProps={{
                  shrink: true,
                }}
                color="primary"
                required={formCard.isPublished}
              />
            </S.FormGroupRow>
          )}

          <FormControlLabel
            control={<SwitchPersonality checked={isPrivateCard} onChange={handlePrivacityCard} />}
            label={'Visível apenas para membros?'}
          />

          <FormControlLabel
            control={
              <SwitchPersonality checked={formCard.isPublished} onChange={handlePublished} />
            }
            label={'Publicar card?'}
          />
          {error && (
            <span
              style={{
                color: 'red',
                fontSize: '0.8rem',
                margin: '1rem 0 0 0',
              }}
            >
              *Todos os campos devem ser preenchidos corretamente.
            </span>
          )}

          <S.ButtonSave
            variant="contained"
            type="submit"
            style={{ background: '#06bad0', color: '#FFF', minWidth: '8rem' }}
            startIcon={loading ? null : <Save />}
            disabled={loading}
          >
            {!loading ? (
              Object.keys(infosForEdit).length !== 0 && isNewCard === false ? (
                'Salvar card'
              ) : (
                'Criar Card'
              )
            ) : (
              <CircularProgress size="1.5rem" style={{ color: '#fff' }} />
            )}
          </S.ButtonSave>
        </ContentModalNewCard>
      </ModalBoxClube>
    </Modal>
  );
}

