import React, { useEffect, useState } from "react";
import * as S from "./style";
import { HeaderTitle } from "../RelationshipClub/HeaderTitle";
import { GoBackHeader } from "../RelationshipClub/GoBackHeader";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import Chip from "@mui/material/Chip";
import SaveIcon from "@mui/icons-material/Save";

import {
  getDeniedWordsByClub,
  addDeniedWords,
  inactiveDeniedWords,
} from "js/library/utils/API/apiDeniedWords";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ForbiddenWords = () => {
  const [loading, setLoading] = useState(false);
  const [activeWords, setActiveWords] = useState([]);
  const [newWord, setNewWord] = useState("");
  const [inactiveWords, setInactiveWords] = useState([]);

  useEffect(() => {
    const path = window.location.pathname;
    const clubId = path.split("/").pop();

    fetchDeniedWords(clubId);
  }, []);

  const fetchDeniedWords = async (clubId) => {
    try {
      const deniedWords = await getDeniedWordsByClub(clubId);
      setActiveWords(deniedWords);
    } catch (error) {
      console.error("Erro ao obter palavras proibidas:", error);
    }
  };

  const handleDeleteChip = (word) => {
    // Adicionar a palavra à lista de inativas
    setInactiveWords([...inactiveWords, word]);

    // Remover a palavra da lista visualmente
    const updatedList = activeWords.filter((item) => item !== word);
    setActiveWords(updatedList);
  };

  const handleAddWord = () => {
    if (newWord.trim() !== "") {
      // Verificar se a palavra já está na lista
      if (activeWords.includes(newWord.trim())) {
        toast.error("Essa palavra já está cadastrada.");
      } else {
        // Adicionar a palavra à lista apenas se não estiver presente
        setActiveWords([...activeWords, newWord.trim()]);
        setNewWord("");
      }
    } else {
      toast.error("Por favor, insira uma palavra válida.");
    }
  };

  const handleSave = async () => {
    const path = window.location.pathname;
    const clubId = path.split("/").pop();
    setLoading(true);

    try {
      // Adicionar palavras inativas à lista no banco de dados
      await inactiveDeniedWords(clubId, inactiveWords);

      // Adicionar palavras ativas à lista no banco de dados
      await addDeniedWords(clubId, activeWords);

      toast.success("Palavras proibidas foram salvas com sucesso!", {
        autoClose: 2000,
      });

      setLoading(false);
    } catch (error) {
      console.error("Erro ao salvar palavras proibidas:", error);
      toast.error("Erro ao salvar palavras proibidas", { autoClose: 2000 });
      setLoading(false);
    }
  };

  return (
    <Box sx={{ padding: "4rem 32px" }}>
      <ToastContainer />
      <GoBackHeader />
      <Container component="main" maxWidth="md">
        <HeaderTitle pageInfo="Termos proibidos" />

        <S.TextDescription>
          As palavras listadas abaixo são bloqueadas nesse clube. Nenhum
          estabelecimento ou promoção que contenha os termos irá ser exibido
          dentro do clube.
        </S.TextDescription>

        <S.AddWords>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <Typography variant="subtitle1" sx={{ marginLeft: "2px" }}>
              Adicione novas palavras à lista:
            </Typography>
            <TextField
              id="outlined-basic"
              variant="outlined"
              fullWidth
              value={newWord}
              onChange={(e) => setNewWord(e.target.value)}
              inputProps={{ style: { height: "10px" } }}
            />
          </Box>

          <Button
            variant="contained"
            sx={{ padding: "0px 40px", height: "43px" }}
            onClick={handleAddWord}
          >
            Adicionar
          </Button>
        </S.AddWords>

        <Typography variant="subtitle1" sx={{ marginLeft: "2px" }}>
          Lista de palavras proibidas:
        </Typography>
        <S.ListWords>
          {activeWords.length > 0 ? (
            activeWords.map((word, index) => (
              <Chip
                key={index}
                label={word}
                onDelete={() => handleDeleteChip(word)}
                style={{
                  color: "#fff",
                  backgroundColor: "#CDA8E0",
                  margin: "4px",
                }}
              />
            ))
          ) : (
            <Typography variant="subtitle1" style={{ color: "#b9b9b9" }}>
              Nenhuma palavra cadastrada...
            </Typography>
          )}
        </S.ListWords>

        <Button
          variant="contained"
          startIcon={!loading && <SaveIcon />}
          onClick={() => handleSave(activeWords)}
          disabled={loading}
          sx={{
            backgroundColor: loading && "#969090",
          }}
        >
          {loading ? (
            <CircularProgress size={25} sx={{ color: "#fff" }} />
          ) : (
            "Salvar"
          )}
        </Button>
      </Container>
    </Box>
  );
};

export default ForbiddenWords;
