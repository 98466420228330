import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

// STYLES
import * as S from '../../Establishment/ListEstablishment/styles';
import * as D from 'js/components/DesignSystem/styles';

// Componentes
import { AreaHeader, GoBack } from 'js/components/Configurations/CreateClub/styles';
import { InvisbleButton } from './segmentStyles';
import FieldList from './FieldList';

import PropTypes from 'prop-types';
import { toast } from 'react-toastify';

// Material UI
import {
  Typography,
  Box,
  MenuItem,
  FormControl,
  Select,
  Tabs,
  Tab,
  Paper,
  Tooltip,
  TextField,
  Button,
  Autocomplete,
} from '@mui/material';

import { useMediaQuery } from '@mui/material';

// Icones Material UI
import { MdArrowBackIosNew } from 'react-icons/md';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

// Drag and Drop
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

//APIS
import { segmentSearchFields } from 'js/library/utils/API/segmentSearchField';
import { updateResourceSegment } from 'js/library/utils/API/updateResourceSegment';
import { GetPartnerUserForms } from 'js/library/utils/API/getPartnerUserById';
import { updateUserSegment } from 'js/library/utils/API/updateUserSegment';

export default function Rules(props) {
  const rules = props.location.state.rules;

  const [value, setValue] = useState(0);
  const [listFields, setListFields] = useState([]);
  const [listFieldsUser, setListFieldsUser] = useState([]);
  const [groups, setGroups] = useState([]);
  const [conditions, setConditions] = useState({});
  const [showProgress, setShowProgress] = useState(false);
  const [segmentType, setSegmentType] = useState(null);
  const [invalidFields, setInvalidFields] = useState([]);

  const history = useHistory();

  const partnerIdClube = localStorage.getItem('partnerIdClube');

  // Media query do material ui
  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up('md'));
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down(550));

  useEffect(() => {
    const url = window.location.href;
    if (url.includes('segmento-recurso')) {
      setSegmentType(true);
    } else if (url.includes('segmento-pessoa')) {
      setSegmentType(false);
    }
  }, []);

  const actions = [
    'equals',
    'not equal',
    'empty',
    'not empty',
    'like',
    'not like',
    'including',
    'excluding',
    'regexp',
    'not regexp',
    'starts with',
    'ends with',
    'contains',
  ];

  const translation = {
    equals: 'igual',
    'not equal': 'não igual',
    empty: 'vazio',
    'not empty': 'não vazio',
    like: 'parecido com',
    'not like': 'não parecido com',
    including: 'incluindo',
    excluding: 'excluindo',
    regexp: 'expressão regular',
    'not regexp': 'não expressão regular',
    'starts with': 'começa com',
    'ends with': 'termina com',
    contains: 'contém',
  };

  const getTranslation = (action) => translation[action] || action;

  const conditionsList = ['E', 'OU'];

  const dataListFields = {
    from: 0,
    size: 1000,
  };

  // Lista de campos (Recursos)
  useEffect(() => {
    if (segmentType) {
      const searchFieldsData = async () => {
        try {
          const res = await segmentSearchFields(dataListFields);

          if (Array.isArray(res.result)) {
            let filteredFields = [];
            // Determina o tipo de tab selecionada
            switch (value) {
              case 0: // Estabelecimento
                filteredFields = res.result.filter(
                  (field) => field.tableOrigin === 'Estabelecimento-v2'
                );
                break;
              case 1: // Promoção
                filteredFields = res.result.filter((field) => field.tableOrigin === 'Store');
                break;
              case 2: // Card
                filteredFields = res.result.filter((field) => field.tableOrigin === 'Cards');
                break;
              case 3: // Post
                filteredFields = res.result.filter((field) => field.tableOrigin === 'feed-posts');
                break;
              default:
                filteredFields = res.result;
                break;
            }
            setListFields(filteredFields);
          }
        } catch (error) {
          console.error('erro:', error);
        }
      };

      searchFieldsData();
    }
  }, [value, segmentType]);

  // Lista de campos (Pessoas)
  useEffect(() => {
    if (segmentType === false) {
      const partnerUserForms = async () => {
        try {
          const res = await GetPartnerUserForms(partnerIdClube);

          setListFieldsUser(res.formFields);
        } catch (error) {
          console.error('erro', error);
        }
      };
      partnerUserForms();
    }
  }, [partnerIdClube, segmentType]);

  useEffect(() => {
    // Exibe o CircularProgress por 3 segundos
    if (listFields.length === 0) {
      setShowProgress(true);
      const timer = setTimeout(() => {
        setShowProgress(false);
      }, 3000);
      return () => clearTimeout(timer);
    }
  }, [listFields]);

  useEffect(() => {
    if (rules) {
      setGroups(rules);
    }
  }, [rules]);

  const handleSelectFields = (event, newValue) => {
    if (newValue !== null) {
      const selectedField = newValue.field || newValue.fieldName;
      const lastGroupKey = `grupo${Object.keys(groups).length || 1}`;
      const lastGroup = groups[lastGroupKey];
      const lastItemKey = lastGroup ? `c${Object.keys(lastGroup).length + 1}` : 'c1';

      // Adicionar um novo grupo à lista de grupos ou adicionar ao último grupo existente
      setGroups((prevGroups) => ({
        ...prevGroups,
        [lastGroupKey]: {
          ...lastGroup,
          [lastItemKey]: {
            action: actions[0],
            condition: 'and',
            field: selectedField,
            fieldId: selectedField.toLowerCase().replace(/\s+/g, ''),
            value: '',
          },
        },
      }));
    }
  };

  const handleChangeTab = (event, newValue) => {
    setValue(newValue);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    setConditions('E');

    const sourceGroupId = result.source.droppableId;
    const destinationGroupId = result.destination.droppableId;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    // Verifica se o movimento foi entre grupos diferentes
    if (sourceGroupId !== destinationGroupId) {
      const sourceGroup = groups[sourceGroupId];
      const destinationGroup = groups[destinationGroupId];

      const sourceItemId = Object.keys(sourceGroup)[sourceIndex];
      const itemToMove = { ...sourceGroup[sourceItemId] };

      // Remove o item do grupo de origem
      const updatedSourceGroup = { ...sourceGroup };
      delete updatedSourceGroup[sourceItemId];

      // Insere o item no grupo de destino
      const updatedDestinationGroup = { ...destinationGroup };
      const destinationKeys = Object.keys(destinationGroup);
      const newItemId = `c${destinationKeys.length + 1}`;
      updatedDestinationGroup[newItemId] = itemToMove;

      const updatedGroups = {
        ...groups,
        [sourceGroupId]: updatedSourceGroup,
        [destinationGroupId]: updatedDestinationGroup,
      };

      setGroups(updatedGroups);
    } else {
      // Movimento dentro do mesmo grupo
      const group = groups[sourceGroupId];
      const items = Object.keys(group).map((key) => group[key]);

      // Remove o item arrastado do array
      const [removed] = items.splice(sourceIndex, 1);

      // Insere o item na nova posição
      items.splice(destinationIndex, 0, removed);

      // Atualiza o estado com a nova ordem dos itens
      const updatedGroup = {};
      items.forEach((item, index) => {
        updatedGroup[`c${index + 1}`] = item;
      });

      const updatedGroups = {
        ...groups,
        [sourceGroupId]: updatedGroup,
      };

      setGroups(updatedGroups);
    }

    // Verifica se há grupos vazios e remove-os
    setGroups((prevState) => {
      const updatedGroups = { ...prevState };
      for (const key in updatedGroups) {
        if (Object.keys(updatedGroups[key]).length === 0) {
          delete updatedGroups[key];
        }
      }
      return updatedGroups;
    });
  };

  const handleDeleteDrag = (e, groupId, itemId) => {
    e.preventDefault();
    const updatedGroups = { ...groups };
    delete updatedGroups[groupId][itemId];

    // Verifica se o grupo ficou vazio após a exclusão, se ficou, apaga o grupo vazio
    if (Object.keys(updatedGroups[groupId]).length === 0) {
      delete updatedGroups[groupId];
    } else {
      // Renumerar os itens restantes do grupo
      let counter = 1;
      Object.keys(updatedGroups[groupId]).forEach((key) => {
        updatedGroups[groupId][`c${counter}`] = updatedGroups[groupId][key];
        if (key !== `c${counter}`) delete updatedGroups[groupId][key];
        counter++;
      });
    }

    setGroups(updatedGroups);
  };

  const handleChangeActions = (event, groupId, itemId, fieldValue) => {
    const newAction = event.target.value;
    setGroups((prevGroups) => ({
      ...prevGroups,
      [groupId]: {
        ...prevGroups[groupId],
        [itemId]: {
          ...prevGroups[groupId][itemId],
          action: newAction ?? actions[0],
          value: newAction === 'empty' || newAction === 'not empty' ? '' : fieldValue,
        },
      },
    }));

    // Limpa os campos inválidos quando o usuário começa a digitar
    if (newAction === 'empty' || newAction === 'not empty') {
      const newInvalidFields = invalidFields.filter((field) => field !== `${groupId}.${itemId}`);
      setInvalidFields(newInvalidFields);
    }
  };

  const handleChangeFieldValue = (event, groupId, itemId) => {
    const newValue = event.target.value;
    setGroups((prevGroups) => ({
      ...prevGroups,
      [groupId]: {
        ...prevGroups[groupId],
        [itemId]: {
          ...prevGroups[groupId][itemId],
          value: newValue ?? '',
        },
      },
    }));

    // Limpa os campos inválidos quando o usuário começa a digitar
    const newInvalidFields = invalidFields.filter((field) => field !== `${groupId}.${itemId}`);
    setInvalidFields(newInvalidFields);
  };

  const handleChangeCondition = (event, groupId, itemId) => {
    const newCondition = event.target.value;
    // Atualiza o estado conditions apenas para o select específico
    setConditions((prevState) => ({
      ...prevState,
      [itemId]: newCondition,
    }));
    // Verifica se a condição selecionada é "OU"
    if (newCondition === 'OU') {
      // Verifica se groups[groupId] contém o conjunto atual
      if (groups[groupId].hasOwnProperty(itemId)) {
        // Divide o grupo em dois grupos distintos
        const newGroup1 = {};
        const newGroup2 = {};
        let split = false;
        for (const [key, value] of Object.entries(groups[groupId])) {
          if (split || key === itemId) {
            // Se encontra o conjunto selecionado ou já tiver começado a dividir,
            // adiciona os conjuntos restantes ao novo grupo
            newGroup2[key] = value;
            split = true;
          } else {
            // Adiciona os conjuntos anteriores ao novo grupo
            newGroup1[key] = value;
          }
        }
        // Atualiza o estado groups com os novos grupos
        const newGroupId = `grupo${Object.keys(groups).length + 1}`;
        setGroups((prevState) => ({
          ...prevState,
          [groupId]: newGroup1,
          [newGroupId]: newGroup2,
        }));
      } else {
        console.error(`O conjunto ${itemId} não foi encontrado no grupo ${groupId}.`);
      }
    }
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        <Box sx={{ p: 3, display: value !== index ? 'none' : 'block' }}>
          <Typography component="span">{children}</Typography>
        </Box>
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const handleUpdateRules = async () => {
    const dataSegment = props.location.state;
    const idSegment = props.match.params.id;

    // Organizar os conjuntos dentro de cada grupo
    const organizedGroups = {};
    let index = 1;
    for (const groupId in groups) {
      const group = groups[groupId];
      const groupKey = `grupo${index++}`;
      organizedGroups[groupKey] = {};
      let counter = 1;
      for (const itemId in group) {
        organizedGroups[groupKey][`c${counter++}`] = group[itemId];
      }
    }

    const payloadResource = {
      active: dataSegment.active,
      creationDate: dataSegment.creationDate,
      id: dataSegment.id,
      name: dataSegment.name,
      partnerId: dataSegment.partnerId,
      rules: organizedGroups,
      updateDate: new Date(Date.now()).getTime(),
      userSegment: dataSegment.userSegment,
    };

    const payloadUser = {
      active: dataSegment.active,
      id: dataSegment.id,
      name: dataSegment.name,
      partnerId: dataSegment.partnerId,
      rules: organizedGroups,
    };

    const invalidFields = [];
    for (const groupId in organizedGroups) {
      for (const itemId in organizedGroups[groupId]) {
        const item = organizedGroups[groupId][itemId];
        if (item.value.trim() === '' && item.action !== 'empty' && item.action !== 'not empty') {
          invalidFields.push(`${groupId}.${itemId}`);
        }
      }
    }

    if (invalidFields.length > 0) {
      setInvalidFields(invalidFields);
      toast.error('Preencha os campos obrigatórios.', { autoClose: 2500 });
      return;
    }

    if (segmentType) {
      await updateResourceSegment(idSegment, payloadResource)
        .then(() => {
          toast.success('Segmento atualizado com sucesso.', { autoClose: 2500 });
          history.goBack();
        })
        .catch((e) => console.log('Ocorreu um erro:', e));
    } else {
      await updateUserSegment(idSegment, payloadUser)
        .then(() => {
          toast.success('Segmento atualizado com sucesso.', { autoClose: 2500 });
          history.goBack();
        })
        .catch((e) => console.log('Ocorreu um erro:', e));
    }
  };

  return (
    <S.Container style={{ paddingBottom: '200px' }}>
      <AreaHeader
        style={{
          color: '#08bad0',
          justifyContent: 'end',
          padding: '1rem',
          marginLeft: '3rem',
        }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <D.TriiboH1
        style={{
          textAlign: 'center',
        }}
      >
        {segmentType ? 'Gerenciar regras (Recursos)' : 'Gerenciar regras (Pessoas)'}
      </D.TriiboH1>

      <Box sx={{ width: '100%' }}>
        {segmentType ? (
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
              }}
            >
              <Tabs
                value={value}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
                orientation={isMobile ? 'vertical' : 'horizontal'}
              >
                <Tab label="Estabelecimento" {...a11yProps(0)} />
                <Tab label="Promoção" {...a11yProps(1)} />
                <Tab label="Card" {...a11yProps(2)} />
                <Tab label="Post" {...a11yProps(3)} />
              </Tabs>
            </Box>

            {/* estabelecimento */}
            {value === 0 && (
              <CustomTabPanel value={value} index={0}>
                <FieldList
                  isMobile={isMobile}
                  listFields={listFields}
                  showProgress={showProgress}
                  handleSelectFields={handleSelectFields}
                />
              </CustomTabPanel>
            )}

            {/* promoção */}
            {value === 1 && (
              <CustomTabPanel value={value} index={1}>
                <FieldList
                  isMobile={isMobile}
                  listFields={listFields}
                  showProgress={showProgress}
                  handleSelectFields={handleSelectFields}
                />
              </CustomTabPanel>
            )}

            {/* cards */}
            {value === 2 && (
              <CustomTabPanel value={value} index={2}>
                <FieldList
                  isMobile={isMobile}
                  listFields={listFields}
                  showProgress={showProgress}
                  handleSelectFields={handleSelectFields}
                />
              </CustomTabPanel>
            )}

            {/* posts */}
            {value === 3 && (
              <CustomTabPanel value={value} index={3}>
                <FieldList
                  isMobile={isMobile}
                  listFields={listFields}
                  showProgress={showProgress}
                  handleSelectFields={handleSelectFields}
                />
              </CustomTabPanel>
            )}
          </Box>
        ) : (
          <Autocomplete
            disablePortal
            id="combo-box-demo2"
            options={listFieldsUser}
            getOptionLabel={(option) => option.fieldName}
            onChange={handleSelectFields}
            filterOptions={(options, { inputValue }) => {
              if (!inputValue) return options;
              return options.filter((option) =>
                option.fieldName.toLowerCase().startsWith(inputValue.toLowerCase())
              );
            }}
            sx={{ width: 300 }}
            value={null}
            noOptionsText="Nenhum resultado encontrado..."
            clearOnBlur
            blurOnSelect
            renderInput={(params) => <TextField {...params} label="Selecione um campo..." />}
            renderOption={(props, option, index) => (
              <li key={`${props.key}-${props.id}-${index.index}`} {...props}>
                {option.fieldName}
              </li>
            )}
          />
        )}

        {/* Drag and drop */}
        <DragDropContext onDragEnd={handleDragEnd}>
          {Object.keys(groups).map((grupo, index) => (
            <Droppable key={grupo} droppableId={grupo}>
              {(provided) => (
                <div key={index} ref={provided.innerRef} {...provided.droppableProps}>
                  {Object.keys(groups[grupo]).map((item, index) => (
                    <Draggable key={grupo + item} draggableId={grupo + item} index={index}>
                      {(provided) => (
                        <div
                          key={grupo + item}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Paper
                            sx={{
                              padding: '2rem',
                              marginBottom: '10px',
                              marginLeft:
                                index > 0 && conditions[item.id] !== 'OU' ? '50px' : '25px',
                              marginTop: index === 0 && '40px',
                              display: 'flex',
                              flexDirection: isMobile ? 'column' : 'row',
                              gap: '10px',
                              alignItems: 'center',
                              justifyContent: isMobile ? 'center' : 'space-between',
                              position: 'relative',
                              width:
                                isMobile && index > 0
                                  ? '83%'
                                  : index > 0 && conditions[item.id] !== 'OU'
                                  ? '91%'
                                  : '97%',
                            }}
                            elevation={1}
                          >
                            {index > 0 && (
                              <Box
                                sx={{
                                  position: 'absolute',
                                  top: '-30px',
                                  backgroundColor: '#fff',
                                }}
                              >
                                <FormControl sx={{ width: '80px' }}>
                                  <Select
                                    value={conditions[item] || conditionsList[0]}
                                    onChange={(event) => handleChangeCondition(event, grupo, item)}
                                    size="small"
                                  >
                                    {conditionsList.map((condition, index) => (
                                      <MenuItem key={index} value={condition}>
                                        {condition}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              </Box>
                            )}

                            <Box
                              sx={{
                                width: isMobile
                                  ? 'max-content'
                                  : index > 0 && conditions[item.id] !== 'OU'
                                  ? '170px'
                                  : '200px',
                              }}
                            >
                              {groups[grupo][item].field}
                            </Box>

                            <FormControl sx={{ width: '200px' }}>
                              <Select
                                value={groups[grupo][item].action || actions[0]}
                                onChange={(event) =>
                                  handleChangeActions(event, grupo, item, groups[grupo][item].value)
                                }
                                size="small"
                                MenuProps={{
                                  MenuListProps: {
                                    autoFocus: true,
                                    style: { maxHeight: '200px' },
                                  },
                                }}
                              >
                                {actions.map((condition, index) => (
                                  <MenuItem key={index} value={condition}>
                                    {getTranslation(condition)}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>

                            <Box sx={{ position: 'relative' }}>
                              <TextField
                                sx={{
                                  width: isMdUp
                                    ? index > 0
                                      ? '370px'
                                      : '400px'
                                    : index > 0
                                    ? '170px'
                                    : '200px',
                                  ...(isMobile && { width: '200px' }),
                                  background:
                                    groups[grupo][item].action === 'empty' ||
                                    groups[grupo][item].action === 'not empty'
                                      ? '#ddd'
                                      : '#fff',
                                }}
                                disabled={
                                  groups[grupo][item].action === 'empty' ||
                                  (groups[grupo][item].action === 'not empty' && true)
                                }
                                inputProps={{
                                  style: {
                                    cursor:
                                      groups[grupo][item].action === 'empty' ||
                                      groups[grupo][item].action === 'not empty'
                                        ? 'not-allowed'
                                        : 'text',
                                  },
                                }}
                                error={invalidFields.includes(`${grupo}.${item}`)}
                                variant="outlined"
                                size="small"
                                value={groups[grupo][item].value || ''}
                                onChange={(event) => handleChangeFieldValue(event, grupo, item)}
                                InputLabelProps={{ shrink: false }} // Não mostra a label
                              />
                              {invalidFields.includes(`${grupo}.${item}`) && (
                                <Typography
                                  variant="caption"
                                  color="error"
                                  sx={{
                                    position: 'absolute',
                                    left: '4px',
                                    top: '100%',
                                  }}
                                >
                                  Preencha este campo *
                                </Typography>
                              )}
                            </Box>
                            {isMobile && invalidFields.includes(`${grupo}.${item}`) && (
                              <div style={{ padding: '5px' }}></div>
                            )}
                            <Tooltip title="Apagar" placement="top">
                              <InvisbleButton onClick={(e) => handleDeleteDrag(e, grupo, item)}>
                                <DeleteIcon color="error" size={25} />
                              </InvisbleButton>
                            </Tooltip>
                          </Paper>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </DragDropContext>
      </Box>

      {groups.length !== 0 && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            marginLeft: isMobile && '40px',
          }}
        >
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            disabled={groups === rules && true}
            onClick={handleUpdateRules}
          >
            <strong>Salvar</strong>
          </Button>
        </Box>
      )}
    </S.Container>
  );
}
