import React, { useRef, useState } from 'react';

import { createFeedContent } from 'js/library/utils/API/apiCreateFeedContent';
import { updateFeedContent } from 'js/library/utils/API/apiUpdateFeedContent';

import { convertDateToTimestamp } from 'js/library/utils/helpers';

import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import DashedButton from 'js/components/TriiboComponents/Buttons/DashedButton';
import PrimaryButton from 'js/components/TriiboComponents/Buttons/PrimaryButton';
import ListTemplate from '../ListTemplate';
import TagsDisplay from 'js/components/DesignSystem/TagsDisplay';
import TagsAdd from 'js/components/DesignSystem/TagsAdd';
import TagsAddUF from 'js/components/DesignSystem/TagsAddUF';
import EditPreview from '../EditPreview';
import { Switch, FormControlLabel, CircularProgress } from '@mui/material';

import calendarIcon from 'styles/assets/communications/calendarPurple.svg';
import { firebaseStorage } from 'js/library/utils/firebaseUtils';
import { toast, ToastContainer } from 'react-toastify';
import { cloneDeep } from 'lodash';
import { UF } from '../uf';
import { AreaHeader, GoBack } from 'js/components/Configurations/CreateClub/styles';
import { MdArrowBackIosNew } from 'react-icons/md';

export default function Highlights(props) {
  const { preForm } = props;
  const [formData, setFormData] = useState({
    disableDate: preForm ? convertDateToTimestamp(preForm.disableDate) : '',
    enableDate: preForm ? convertDateToTimestamp(preForm.enableDate) : '',
    image: preForm ? preForm.image : '',
    imageBlob: null,
    title: preForm ? preForm.title : '',
    subtitle: preForm ? preForm.subtitle : '',
    listType: preForm ? preForm.listType : '',
    query: preForm ? preForm.query : '',
    status: preForm ? preForm.status : false,
    regionFilter: preForm ? (preForm.regionFilter ? preForm.regionFilter : false) : false,
    filterType: preForm ? (preForm.filterType ? preForm.filterType : 'city') : 'city',
    filterValue: preForm ? (preForm.filterValue ? preForm.filterValue : []) : [],
    trackingId: preForm ? preForm.trackingId : '',
  });
  const [data, setData] = useState([]);
  const [indexItem, setIndexItem] = useState(null);
  const [isUpdating, setIsUpdating] = useState(false);

  const imageRef = useRef(null);

  const imageUploader = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const img = new Image();
      img.src = event.target.result;
      img.onload = () => {
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, img.width, img.height);
        canvas.toBlob(
          (blob) => {
            const fileConvert = new File([blob], `${file.name.split('.')[0]}.webp`, {
              type: 'image/webp',
            });
            setFormData({
              ...formData,
              imageBlob: blob,
              image: fileConvert,
            });
          },
          'image/webp',
          1
        );
      };
    };
  };

  const handleChangeTitle = (e) => {
    setFormData({ ...formData, title: e.target.value });
  };

  const handleChangeSubtitle = (e) => {
    setFormData({ ...formData, subtitle: e.target.value });
  };

  const handleChangeFilterType = (e) => {
    setFormData({ ...formData, filterType: e.target.value, filterValue: [] });
  };

  const handleChangeListType = (e) => {
    setFormData({ ...formData, listType: e.target.value });
  };

  const handleChangeQuery = (e) => {
    setFormData({ ...formData, query: e.target.value });
  };

  const handleChangeEnableDate = (e) => {
    const date = new Date(e.target.value).getTime();
    setFormData({ ...formData, enableDate: date });
  };

  const handleChangeDisableDate = (e) => {
    const date = new Date(e.target.value).getTime();
    setFormData({ ...formData, disableDate: date });
  };

  const handleAddHighlights = () => {
    if (
      !formData.disableDate ||
      !formData.enableDate ||
      !formData.image ||
      !formData.imageBlob ||
      !formData.title ||
      !formData.subtitle ||
      !formData.listType ||
      !formData.query
    ) {
      return toast.error('Preencha todos os dados obrigatórios.');
    }

    if (formData.enableDate > formData.disableDate) {
      return toast.error('Data de ativação é maior que a de desativação.');
    }

    setData([...data, formData]);
    setFormData({
      disableDate: '',
      enableDate: '',
      image: null,
      imageBlob: null,
      title: '',
      subtitle: '',
      listType: '',
      query: '',
      status: false,
      regionFilter: false,
      trackingId: '',
    });
  };

  const handleUpdateHighlights = async () => {
    setIsUpdating(true);
    let newObj = cloneDeep(formData);

    for (let key in newObj) {
      if (!newObj[key]) {
        delete newObj[key];
      }
    }

    const resolved = new Promise(async (resolved, rejected) => {
      const imagePath = await uploadingImages(newObj.imageBlob, 'highlightImage');
      if (imagePath) {
        const { imageBlob, image, ...rest } = newObj;

        return resolved({
          ...rest,
          image: imageBlob ? imagePath : image,
          id: preForm.id,
          type: preForm.type,
          status: preForm.status,
        });
      }
    });
    const res = await resolved;
    const filtered = (element) => {
      if (!element.regionFilter) {
        delete element.filterType;
        delete element.filterValue;
      }
      return element;
    };
    updateFeedContent([filtered(res)])
      .then(() => {
        toast.success('Feed atualizado com sucesso');
        setIsUpdating(true);
      })
      .catch(() => {
        toast.error('Ocorreu um erro');
        setIsUpdating(false);
      });
  };

  const handleEditHighlights = () => {
    setData((prevData) => {
      const copyData = [...prevData];
      copyData[indexItem] = formData;

      return copyData;
    });

    setFormData({
      disableDate: '',
      enableDate: '',
      image: null,
      imageBlob: null,
      title: '',
      subtitle: '',
      listType: '',
      query: '',
      status: false,
      trackingId: '',
    });

    setIndexItem(null);
  };

  const handleChangeTrackingId = (e) => {
    setFormData({ ...formData, trackingId: e.target.value.replace(/\s/g, '') });
  };

  const handleRemoveHighlight = (index) => {
    setData((prevData) => {
      const copyData = [...prevData];
      copyData.splice(index, 1);
      return copyData;
    });
  };

  const uploadingImages = async (image, type) => {
    let imagePath = '';

    const newStorageRef = firebaseStorage.ref('homeMapfre').child(type + Date.now());

    await newStorageRef.put(image).then((result) => {
      imagePath = result._delegate.ref._location.path_;
    });
    return imagePath;
  };

  const handleSubmitHighlights = async () => {
    setIsUpdating(true);
    const resolved = await Promise.all(
      data.map(async (item) => {
        const imagePath = await uploadingImages(item.imageBlob, 'highlightImage');
        if (imagePath) {
          const { imageBlob, ...rest } = item;

          return { ...rest, image: imagePath };
        }
      })
    );

    const filtered = resolved.map((element, index) => {
      if (!element.regionFilter) {
        delete element.filterType;
        delete element.filterValue;
      }
      if (element.url === '') {
        delete element.url;
      }
      return element;
    });

    createFeedContent('highlights', filtered)
      .then((res) => {
        toast.success('Feed atualizado com sucesso');
        setIsUpdating(false);
      })
      .catch(() => {
        toast.error('Ocorreu um erro');
        setIsUpdating(false);
      });
  };

  return (
    <S.Container>
      {preForm && (
        <AreaHeader
          style={{ color: '#08bad0', justifyContent: 'end', padding: '1rem', marginLeft: '3rem' }}
        >
          <GoBack
            onClick={() => {
              window.history.back();
            }}
          >
            <MdArrowBackIosNew /> Voltar
          </GoBack>
        </AreaHeader>
      )}
      <ToastContainer autoClose={6000} />
      <S.Content>
        <S.AddHighlights>
          <D.TriiboH3>
            <strong>Destaque</strong>
          </D.TriiboH3>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Nome identificador*</strong>
            </D.TriiboH4>

            <D.TriiboTextField
              rows={1}
              value={formData.trackingId}
              placeholder={'Nome da campanha'}
              onChange={(e) => handleChangeTrackingId(e)}
            />
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Imagem de destaque*</strong>
            </D.TriiboH4>

            {formData.image ? (
              <S.FileContainer>
                <PrimaryButton
                  width={'200px'}
                  onClick={() =>
                    setFormData({
                      ...formData,
                      image: null,
                      imageBlob: null,
                    })
                  }
                >
                  Limpar seleção
                </PrimaryButton>
                <D.TriiboH5>{formData.image.name}</D.TriiboH5>
              </S.FileContainer>
            ) : (
              <>
                <DashedButton width={'100%'} onClick={() => imageRef.current.click()}>
                  + Adicionar imagem
                </DashedButton>
                <input
                  type="file"
                  className="inputFile"
                  ref={imageRef}
                  onChange={(e) => imageUploader(e)}
                />
              </>
            )}
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Título*</strong>
            </D.TriiboH4>

            <D.TriiboTextField
              rows={1}
              value={formData.title}
              onChange={(e) => handleChangeTitle(e)}
            />
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Subtítulo*</strong>
            </D.TriiboH4>

            <D.TriiboTextField
              rows={1}
              value={formData.subtitle}
              onChange={(e) => handleChangeSubtitle(e)}
            />
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Tipo de Lista*</strong>
            </D.TriiboH4>

            <S.RadioSelectContainer>
              <S.Label>
                <S.InputRadio
                  value={'establishment'}
                  checked={formData.listType === 'establishment'}
                  onChange={(e) => handleChangeListType(e)}
                />
                Estabelecimentos
              </S.Label>

              <S.Label>
                <S.InputRadio
                  value={'promotion'}
                  checked={formData.listType === 'promotion'}
                  onChange={(e) => handleChangeListType(e)}
                />
                Promoções
              </S.Label>
            </S.RadioSelectContainer>
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Tag*</strong>
            </D.TriiboH4>

            <D.TriiboTextField
              rows={1}
              value={formData.query}
              placeholder={'Query para listagem das promoções/estabelecimentos'}
              onChange={(e) => handleChangeQuery(e)}
            />
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Data de Ativação*</strong>
            </D.TriiboH4>

            <S.SelectDateContainer>
              <img src={calendarIcon} alt="" />
              <S.InputDate
                type="datetime-local"
                value={
                  formData.enableDate &&
                  new Date(formData.enableDate - 1000 * 60 * 60 * 3).toISOString().substr(0, 16)
                }
                onChange={(e) => handleChangeEnableDate(e)}
              />
            </S.SelectDateContainer>
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Data de Desativação*</strong>
            </D.TriiboH4>

            <S.SelectDateContainer>
              <img src={calendarIcon} alt="" />
              <S.InputDate
                type="datetime-local"
                value={
                  formData.disableDate &&
                  new Date(formData.disableDate - 1000 * 60 * 60 * 3).toISOString().substr(0, 16)
                }
                onChange={(e) => handleChangeDisableDate(e)}
              />
            </S.SelectDateContainer>
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Status da publicação*</strong>
            </D.TriiboH4>

            <FormControlLabel
              control={
                <Switch
                  checked={formData.status}
                  onChange={() => {
                    setFormData({
                      ...formData,
                      status: !formData.status,
                    });
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Publicado"
            />
          </S.FormElement>

          <S.FormElement>
            <D.TriiboH4>
              <strong>Filtro regional</strong>
            </D.TriiboH4>

            <FormControlLabel
              control={
                <Switch
                  checked={formData.regionFilter}
                  onChange={() => {
                    setFormData({
                      ...formData,
                      regionFilter: !formData.regionFilter,
                    });
                  }}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
              }
              label="Filtrar por região"
            />
          </S.FormElement>
          {formData.regionFilter ? (
            <S.FormElement>
              <D.TriiboH4>
                <strong>Tipo de Listagem</strong>
              </D.TriiboH4>

              <S.RadioSelectContainer>
                <S.Label>
                  <S.InputRadio
                    value={'city'}
                    checked={formData.filterType === 'city'}
                    onChange={(e) => handleChangeFilterType(e)}
                  />
                  Cidade
                </S.Label>

                <S.Label>
                  <S.InputRadio
                    value={'state'}
                    checked={formData.filterType === 'state'}
                    onChange={(e) => handleChangeFilterType(e)}
                  />
                  Estado
                </S.Label>
              </S.RadioSelectContainer>
            </S.FormElement>
          ) : (
            <></>
          )}
          {formData.regionFilter ? (
            <S.FormElement>
              <D.TriiboH4>
                <strong>Adicionar Tags da filtragem</strong>
              </D.TriiboH4>

              {formData.filterType === 'city' ? (
                <TagsAdd form={formData} formKey={'filterValue'} setForm={setFormData} />
              ) : (
                <TagsAddUF
                  form={formData}
                  formKey={'filterValue'}
                  setForm={setFormData}
                  listArr={UF}
                  listKeys={['nome', 'sigla']}
                />
              )}
              <TagsDisplay form={formData} formKey={'filterValue'} setForm={setFormData} />
            </S.FormElement>
          ) : (
            <></>
          )}
          {preForm ? (
            <S.ButtonsContainer>
              <PrimaryButton
                width={'100%'}
                onClick={() => {
                  handleUpdateHighlights();
                }}
                disabled={isUpdating}
              >
                {isUpdating ? (
                  <CircularProgress size={30} color={'inherit'} />
                ) : (
                  'Atualizar Destaques'
                )}
              </PrimaryButton>
            </S.ButtonsContainer>
          ) : (
            <S.ButtonsContainer>
              {indexItem !== null ? (
                <>
                  <PrimaryButton
                    bgColor={'#f13a3a'}
                    width={'100%'}
                    onClick={() => {
                      setFormData({
                        disableDate: '',
                        enableDate: '',
                        image: null,
                        imageBlob: null,
                        title: '',
                        subtitle: '',
                        listType: '',
                        query: '',
                        trackingId: '',
                      });
                      setIndexItem(null);
                    }}
                  >
                    Cancelar
                  </PrimaryButton>
                  <PrimaryButton
                    width={'100%'}
                    onClick={() => {
                      if (indexItem !== null) {
                        handleEditHighlights();
                      } else {
                        handleAddHighlights();
                      }
                    }}
                  >
                    + Adicionar Destaque
                  </PrimaryButton>
                </>
              ) : (
                <PrimaryButton width={'100%'} onClick={handleAddHighlights}>
                  + Adicionar Destaque
                </PrimaryButton>
              )}
            </S.ButtonsContainer>
          )}
        </S.AddHighlights>
        {preForm ? (
          <S.ListBannersContainer>
            <EditPreview title={'Preview de imagens'} data={preForm} />
          </S.ListBannersContainer>
        ) : (
          <S.ListHighlights>
            <ListTemplate
              title={'Lista de Destaques'}
              data={data}
              type={'Destaque'}
              setFormData={setFormData}
              setIndexItem={setIndexItem}
              handleRemoveItem={handleRemoveHighlight}
            />
          </S.ListHighlights>
        )}
      </S.Content>

      {preForm ? (
        <></>
      ) : (
        <PrimaryButton onClick={handleSubmitHighlights} disabled={data.length < 1 || isUpdating}>
          {isUpdating ? <CircularProgress size={30} color={'inherit'} /> : 'Cadastrar Destaques'}
        </PrimaryButton>
      )}
    </S.Container>
  );
}

