import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const Container = styled.div`
  max-width: 1100px;
  padding: 1rem;
  margin: 5rem auto;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  width: 100%;

  h1 {
    text-align: center;
  }
`;

export const BoxSearch = styled.form`
  display: flex;
  max-width: 600px;
  width: 100%;
  max-height: 42px;
  margin: 0 auto;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
`;

export const InputBox = styled.div`
  flex: 1;
  width: 100%;
  height: 42px;
  display: flex;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;
  background: #fff;
  padding: 5px;
`;

export const Input = styled.input`
  flex: 1;
  border: none;
  outline: none;
  padding: 0 1rem;
  font-size: 16px;
  height: 100%;
`;

export const SearchIconButton = styled.button`
  background: none;
  height: 100%;
  border-left: 1px solid #ccc;
  border-bottom: none;
  border-top: none;
  border-right: none;
  border-radius: 0;
  padding-left: 10px;
  cursor: pointer;

  img {
    width: 24px;
  }
`;

export const EditButton = styled.a`
  background: #6e3296;
  border-radius: 10px;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 0.5rem 0;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    filter: opacity(0.9);
  }
`;
