import React, { useEffect, useState } from 'react';
import * as S from './styles';
import * as D from 'js/components/DesignSystem/styles';
import DropDown from 'js/components/DesignSystem/DropDown';
import searchIcon from 'styles/assets/SearchUser/search.svg';
import editIcon from 'styles/assets/communications/edit.svg';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {
  CircularProgress,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { GetAllCampaigns } from 'js/library/utils/API/Campaigns/apiGetAllCampaigns';
import { convertToDateDefault } from 'js/library/utils/helpers';
import usePagination from 'js/components/Establishment/RelationshipScreen/usePagination';
import { toast, ToastContainer } from 'react-toastify';
import { AreaHeader, GoBack } from '../../CreateClub/styles';
import { MdArrowBackIosNew } from 'react-icons/md';
import { useParams } from 'react-router';
import { getByOwner } from 'js/library/utils/API/Campaigns/apiGetByOwner';

export default function ListCampaigns() {
  const [campaigns, setCampaigns] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [filterSelected, setFilterSelected] = useState('');
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const params = useParams();
  const ownerId = params?.id;

  const getCampaingsByOwner = async () => {
    const response = await getByOwner(ownerId);
    if (response.success) {
      const formatCampaigns = response?.result?.map(
        ({ id, description, owner, periods, modules }) => {
          return {
            key: id,
            value: {
              description,
              owner,
              periods,
              modules,
            },
          };
        }
      );
      setCampaigns(formatCampaigns);
    } else toast.error(response);
  };

  useEffect(() => {
    if (params?.id) {
      const getCampaingsByOwner = async () => {
        const response = await getByOwner(ownerId);

        if (response.success) {
          const formatCampaigns = response?.result?.map(
            ({ id, description, owner, periods, modules }) => {
              return {
                key: id,
                value: {
                  modules,
                  description,
                  owner,
                  periods,
                },
              };
            }
          );
          setCampaigns(formatCampaigns);
        } else toast.error(response);
      };

      getCampaingsByOwner();
    } else {
      setLoading(true);
      GetAllCampaigns()
        .then((response) => {
          const arr = Object.entries(response.result).map((item) => {
            const [key, value] = item;
            return { key, value };
          });

          setCampaigns(arr);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          toast.error('Erro ao carregar lista de campanhas.');
          setLoading(false);
        });
    }
  }, [params]);

  const filteredCampaigns = (campaigns) => {
    if (inputSearch && !ownerId) {
      return campaigns.filter(({ key, value }) => {
        return filterSelected === 'CampaignId'
          ? key?.toLowerCase().includes(inputSearch.toLowerCase())
          : filterSelected === 'Owner' &&
              value?.owner?.toLowerCase().includes(inputSearch.toLowerCase());
      });
    } else if (inputSearch && ownerId) {
      return campaigns.filter(({ key }) => key?.toLowerCase().includes(inputSearch.toLowerCase()));
    } else {
      return campaigns;
    }
  };

  const PER_PAGE = 10;
  const count = Math.ceil(filteredCampaigns(campaigns) / PER_PAGE);
  const _DATA = usePagination(filteredCampaigns(campaigns), PER_PAGE);

  const handleChangePage = (e, p) => {
    setPage(p);
    _DATA.jump(p);
  };

  if (loading) {
    return (
      <div
        style={{
          width: '100%',
          height: '100vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size="50px" />
      </div>
    );
  }

  const concatId = params?.id ? `/${params?.id}` : '';

  return (
    <div style={{ padding: '4rem' }}>
      <AreaHeader style={{ color: '#08bad0', justifyContent: 'start', padding: '1rem' }}>
        <GoBack onClick={() => window.history.back()}>
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <ToastContainer autoClose={6000} />
      <S.Container>
        <D.TriiboH1 style={{ textAlign: 'center', marginBottom: '2rem' }}>Campanhas</D.TriiboH1>
        <S.SearchSection>
          {!ownerId && (
            <DropDown
              selectedOption={filterSelected}
              setSelectedOption={setFilterSelected}
              isSelectOpen={isFilterOpen}
              setIsSelectOpen={setIsFilterOpen}
              top={true}
              height={'42px'}
            />
          )}
          <S.SearchComponent>
            <S.InputBox filtered={filterSelected}>
              <S.Input
                placeholder="Pesquisar uma campanha..."
                disabled={ownerId ? false : !filterSelected}
                value={inputSearch}
                onChange={(e) => setInputSearch(e.target.value)}
              />
              <S.SearchIconButton type="submit" disabled={ownerId ? false : !filterSelected}>
                <img src={searchIcon} alt="ícone de pesquisa" />
              </S.SearchIconButton>
            </S.InputBox>
            {!filterSelected && (
              <S.ErrorMessage filtered={!filterSelected}>
                Por favor, selecione um filtro ao lado*
              </S.ErrorMessage>
            )}
          </S.SearchComponent>
          <S.AddCampaignButton
            to={{
              pathname: `/admin/configuracoes/campanhas/adicionarCampanha${concatId}`,
              state: '',
            }}
          >
            <span>+</span>
            <D.TriiboH4>Cadastrar Campanha</D.TriiboH4>
          </S.AddCampaignButton>
        </S.SearchSection>

        {_DATA.currentData().length > 0 ? (
          <>
            <TableContainer>
              <Table sx={{ minWidth: 650 }} size="medium" aria-label="">
                <TableHead>
                  <TableRow>
                    <TableCell align="left">
                      <strong>Id da campanha</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Owner</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Data Início</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Data Fim</strong>
                    </TableCell>
                    <TableCell align="left">
                      <strong>Número de Periodos</strong>
                    </TableCell>
                    <TableCell align="left"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {_DATA.currentData().map((campaign) => (
                    <TableRow
                      key={campaign.key}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell align="left">{campaign.key}</TableCell>
                      <TableCell align="left">{campaign.value.owner}</TableCell>
                      <TableCell align="left">
                        {Array.isArray(campaign.value.periods)
                          ? convertToDateDefault(
                              campaign.value.periods[0].startAt ||
                                campaign.value.periods[0].startsAt
                            )
                          : ''}
                      </TableCell>
                      <TableCell align="left">
                        {Array.isArray(campaign.value.periods)
                          ? convertToDateDefault(
                              campaign.value.periods[campaign.value.periods.length - 1].endsAt
                            )
                          : ''}
                      </TableCell>
                      <TableCell align="left">
                        {Array.isArray(campaign.value.periods) ? campaign.value.periods.length : ''}
                      </TableCell>
                      <TableCell align="left">
                        <S.StyledTooltip title="Editar" arrow>
                          <S.IconButton
                            to={`/admin/configuracoes/campanhas/atualizarCampanha/campaignId=${campaign.key}`}
                          >
                            <img src={editIcon} alt="" />
                          </S.IconButton>
                        </S.StyledTooltip>
                      </TableCell>
                      <TableCell align="left">
                        {campaign.value.modules?.includes('vouchersSeed') && (
                          <S.StyledTooltip title="Voucher" arrow>
                            <S.IconButton
                              to={`/admin/configuracoes/campanhas/subirVoucher/campaignId=${campaign.key}`}
                            >
                              <ConfirmationNumberIcon style={{ color: '#fff' }} fontSize="small" />
                            </S.IconButton>
                          </S.StyledTooltip>
                        )}
                      </TableCell>
                      <TableCell align="left">
                        {/* {campaign.value.modules?.includes('vouchersSeed') && ( */}
                        <S.StyledTooltip title="Voucher" arrow>
                          <S.IconButton
                            to={`/admin/configuracoes/campanhas/cadastroCodigo/campaignId=${campaign.key}/ownerId=${campaign.value.owner}`}
                          >
                            <AddCircleIcon style={{ color: '#fff' }} fontSize="small" />
                          </S.IconButton>
                        </S.StyledTooltip>

                        {/*  )} */}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Pagination
              sx={{ mt: 3 }}
              count={count}
              size="large"
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
            />
          </>
        ) : (
          <div style={{ margin: '0 auto' }}>Nenhuma campanha encontrada.</div>
        )}
      </S.Container>
    </div>
  );
}
