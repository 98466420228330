import React, { useState } from 'react';

import validateUserMapfre from 'js/library/utils/API/SearchUser/ValidateUserMapfre';
import { GetUserMapfre } from 'js/library/utils/API/SearchUser/apiGetUserMapfre';

import { cpfMask } from 'js/library/utils/helpers';

import InputSearch from 'js/components/TriiboComponents/InputSearch';
import { toast, ToastContainer } from 'react-toastify';
import { CircularProgress } from '@mui/material';

import UserInfoPreview from './UserInfoPreview';

const UnitPost = (props) => {
  const { usersArr, setUsersArr } = props;

  const [displayString, setDisplayString] = useState('');
  const [currentSearchResult, setCurrentSearchResult] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  function handleChange(e) {
    e.preventDefault();
    const inputChar = cpfMask(e.target.value.replace(/\D/g, ''));
    setDisplayString(inputChar);
  }

  function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    validateUserMapfre(displayString.replace(/\D/g, '')).then((res) => {
      if (res.user) {
        GetUserMapfre(res.uId).then((response) => {
          setCurrentSearchResult({ ...response, uId: res.uId });
          setIsLoading(false);
        });
      } else {
        toast.error('Usuário não encontrado ou inválido!');
        setIsLoading(false);
      }
    });
  }

  return (
    <>
      <ToastContainer autoClose={5000} />
      <form onSubmit={(e) => handleSubmit(e)}>
        <InputSearch
          value={displayString}
          onChange={(e) => handleChange(e)}
          disabled={false}
          placeholder="insira CPF MAPFRE"
        />
      </form>
      {isLoading ? <CircularProgress /> : <></>}
      <UserInfoPreview
        usersArr={usersArr}
        currentSearchResult={currentSearchResult}
        setUsersArr={setUsersArr}
      />
    </>
  );
};

export default UnitPost;

