import React, { useEffect, useState } from 'react';

// STYLES
import * as S from '../../Establishment/ListEstablishment/styles';
import * as D from 'js/components/DesignSystem/styles';
import { AreaHeader, GoBack } from 'js/components/Configurations/CreateClub/styles';
import {
  FormControl,
  Autocomplete,
  TextField,
  Typography,
  Box,
  Button,
  Switch,
} from '@mui/material';

// iCONES
import { MdArrowBackIosNew } from 'react-icons/md';
import { ArrowBack, Save } from '@mui/icons-material';

// COMPONENTES
import ImportImage from 'js/components/TriiboComponents/ImportImage';

// APIS
import { getByOwner } from 'js/library/utils/API/Campaigns/apiGetByOwner';
import { downloadImage } from 'js/library/services/StorageManager';
import { createCampaignLogin } from 'js/library/utils/API/CustomLogin/createCampaignLogin';
import { getUserInfo, separateBase64String } from 'js/library/utils/helpers';
import { ToastContainer, toast } from 'react-toastify';
import { uploadFiles } from 'js/library/utils/API/apiUploadFiles';
import { editCampaignLogin } from 'js/library/utils/API/CustomLogin/editCampaignLogin';
import { LoadingButton } from '@mui/lab';

export default function LoginManager(props) {
  const dataCampaigns = props.location.state;
  const uId = getUserInfo().uId;

  const [checked, setChecked] = useState(false);
  const [campaigns, setCampaigns] = useState([]);

  const [bgWeb, setBgWeb] = useState('');
  const [bgMobile, setBgMobile] = useState('');

  const [form, setForm] = useState({
    campaignId: props.match.params.id ?? '',
    backgroundDesktop: dataCampaigns?.backgroundDesktop ?? '',
    backgroundMobile: dataCampaigns?.backgroundMobile ?? '',
    modalTitle: dataCampaigns?.modalTitle ?? '',
    loginText: dataCampaigns?.loginText ?? '',
    inputSMS: dataCampaigns?.inputSMS ?? '',
    buttonLabel: dataCampaigns?.buttonLabel ?? '',
    conclusionText: dataCampaigns?.conclusionText ?? '',
    autoParticipate: dataCampaigns?.autoParticipate ?? false,
  });

  const [submitLoading, setSubmitLoading] = useState(false)

  const url = window.location.href;
  const isCreatingLogin = url.includes('adicionar');

  useEffect(() => {
    const listCampaigns = async () => {
      try {
        const data = await getByOwner('clubmapfre');

        setCampaigns(data.result);
      } catch (error) {
        console.error('Erro na requisição getByOwner:', error);
      }
    };
    listCampaigns();
  }, []);

  useEffect(() => {
    if (form.backgroundDesktop !== '') {
      downloadImage('customLogin', form.backgroundDesktop.split('/')[1])
        .then((downloadedImage) => {
          setBgWeb(downloadedImage);
        })
        .catch(() => { });
    }

    if (form.backgroundMobile !== '') {
      downloadImage('customLogin', form.backgroundMobile.split('/')[1])
        .then((downloadedImage) => {
          setBgMobile(downloadedImage);
        })
        .catch(() => { });
    }
  }, []);

  const handleChange = (e, newValue) => {
    const { name, value } = e.target || {};

    setForm((prevForm) => ({
      ...prevForm,
      campaignId: newValue ? newValue.id : prevForm.campaignId,
      ...(name && { [name]: value }),
      autoParticipate:
        name === 'autoParticipate' ? !prevForm.autoParticipate : prevForm.autoParticipate,
    }));
  };

  const uploadImage = async (image, filename) => {
    if (image) {
      const newImage = separateBase64String(image);
      let imageCardPath;
      try {
        const response = await (await uploadFiles('customLogin', {
          mimeType: newImage.mimeType,
          fileName: filename,
          buffer: newImage.buffer,
        })).split('/');

        imageCardPath = 'customLogin/' + response[response?.length - 1].split('?')[0];

      } catch (error) {
        console.log(error)
      }

      console.log(imageCardPath);
      return imageCardPath;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const currentDate = Date.now();

    const fileNameDesktop = 'bgWebCL-' + form.campaignId + currentDate;
    const fileNameMobile = 'bgMobileCL-' + form.campaignId + currentDate;

    if (!bgWeb || !bgMobile) {
      toast.warning("Selecione as imagens!")
      return;
    }

    setSubmitLoading(true);
    const [bgWebPath, bgMobilePath] = await Promise.all([
      bgWeb.includes('base64') && uploadImage(bgWeb, fileNameDesktop),
      bgMobile.includes('base64') && uploadImage(bgMobile, fileNameMobile),
    ]);

    const payload = {
      ...form,
      backgroundDesktop: bgWebPath || form.backgroundDesktop,
      backgroundMobile: bgMobilePath || form.backgroundMobile,
    };

    if (isCreatingLogin) {
      try {
        const response = await createCampaignLogin(uId, payload)
        if (response.success) {
          toast.success('Login criado com sucesso!');
          setTimeout(() => {
            window.history.back();
          }, 2000);
          setSubmitLoading(false);
        }
      } catch (error) {
        setSubmitLoading(false);
        if (error.status === 401) {
          toast.error('Essa campanha já possuí Login cadastrado!');
        } else {
          toast.error(
            'Houve um erro ao tentar criar o login de campanha, tente novamente mais tarde.'
          );
        }
      }
    } else {
      try {
        const response = await editCampaignLogin(uId, payload);
        if (response?.length) {
          setSubmitLoading(false);
          toast.success('Login editado com sucesso!');
          setTimeout(() => {
            window.history.back();
          }, 2000);
        }
      } catch (error) {
        setSubmitLoading(false);
        toast.error('Houve um erro ao tentar editar o login de campanha');
      }
    }
  };

  return (
    <S.Container>
      <ToastContainer autoClose={2000} />
      <AreaHeader
        style={{
          color: '#08bad0',
          justifyContent: 'end',
        }}
      >
        <GoBack
          onClick={() => {
            window.history.back();
          }}
        >
          <MdArrowBackIosNew /> Voltar
        </GoBack>
      </AreaHeader>
      <D.TriiboH1
        style={{
          textAlign: 'center',
        }}
      >
        {isCreatingLogin ? 'Cadastrar login' : 'Editar login'}
      </D.TriiboH1>

      <FormControl fullWidth>
        <form onSubmit={handleSubmit} style={{ width: '100%', display: 'flex', gap: '15px', flexDirection: 'column' }}>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={campaigns}
            value={campaigns.find((campaign) => campaign.id === form.campaignId) || null}
            name="campaignId"
            onChange={handleChange}
            getOptionLabel={(option) => option.id}
            filterOptions={(options, { inputValue }) => {
              if (!inputValue) return options;
              return options.filter((option) =>
                option.id.toLowerCase().startsWith(inputValue.toLowerCase())
              );
            }}
            sx={{ width: 400 }}
            noOptionsText="Nenhum resultado encontrado..."
            renderInput={(params) => <TextField {...params} required label="Selecione uma campanha" />}
            renderOption={(props, option, index) => (
              <li key={`${index.index}`} {...props}>
                {option.id}
              </li>
            )}
          />

          <TextField
            required
            id="outlined-required"
            label="Título do modal"
            name="modalTitle"
            value={form.modalTitle ?? ''}
            onChange={handleChange}
          />
          <TextField
            required
            id="outlined-required"
            label="Texto principal"
            multiline
            maxRows={6}
            name="loginText"
            value={form.loginText ?? ''}
            onChange={handleChange}
          />
          <TextField
            required
            id="outlined-required"
            label="Texto de confirmação SMS"
            name="inputSMS"
            value={form.inputSMS ?? ''}
            onChange={handleChange}
          />

          {form.autoParticipate && (
            <TextField
              id="outlined-required"
              label="Mensagem de confirmação da campanha"
              name="conclusionText"
              value={form.conclusionText ?? ''}
              onChange={handleChange}
            />
          )}

          {form.autoParticipate && (
            <TextField
              id="outlined-required"
              label="Texto do botão para enviar para a página da campanha"
              name="buttonLabel"
              value={form.buttonLabel ?? ''}
              onChange={handleChange}
            />
          )}

          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Switch
              checked={checked}
              value={form.autoParticipate ?? false}
              onChange={() => {
                setChecked(!checked);
                handleChange({ target: { name: 'autoParticipate' } });
              }}
              color="primary"
              name="autoParticipate"
              inputProps={{ 'aria-label': 'toggle switch' }}
              sx={{ left: '-10px' }}
            />
            <Typography variant="subtitle1" color="secondary" sx={{ paddingLeft: 0 }}>
              Participação automática na campanha
            </Typography>
          </Box>

          <Box sx={{ width: 400, height: 'auto', marginBottom: '30px' }}>
            <Typography variant="subtitle1" color="secondary" sx={{ paddingLeft: '7px' }}>
              Background Web
            </Typography>
            <ImportImage
              imageDefault={true}
              autoWidth={true}
              image={bgWeb}
              setImage={setBgWeb}
              ratio={16 / 9}
            />
          </Box>

          <Box sx={{ width: 400, height: 'auto', marginBottom: '30px' }}>
            <Typography variant="subtitle1" color="secondary" sx={{ paddingLeft: '7px' }}>
              Background Mobile
            </Typography>
            <ImportImage
              imageDefault={true}
              autoWidth={true}
              image={bgMobile}
              setImage={setBgMobile}
              ratio={9 / 19}
            />
          </Box>

          <Box sx={{ width: 'max-content', display: 'flex', gap: '20px' }}>
            <Button
              variant="contained"
              color="secondary"
              startIcon={<ArrowBack />}
              sx={{
                fontWeight: '550',
                color: 'white',
                textTransform: 'capitalize',
              }}
              onClick={() => {
                window.history.back();
              }}
            >
              Voltar
            </Button>

            <LoadingButton
              variant="contained"
              color="primary"
              startIcon={<Save />}
              type={"submit"}
              loading={submitLoading}
              sx={{
                fontWeight: '550',
                color: 'white',
                textTransform: 'capitalize',
              }}
            >
              Salvar
            </LoadingButton>
          </Box>
        </form>
      </FormControl>
    </S.Container >
  );
}
