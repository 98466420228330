import React, { useCallback, useEffect, useState } from 'react';
import ModelLPSignUp from '../../LandingPageSignUp/model';
import {
  Box,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Switch,
  TextField,
} from '@mui/material';
import { toast } from 'react-toastify';
import { Button } from '@mui/material';
import useForm from '../../Hooks/useForm';
import { ChromePicker } from 'react-color';
import ModelCarouselModal from './model';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { colorText, hexToRgba, rgbaToHex } from 'js/library/utils/helpers';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  boxShadow: 24,
  p: 4,
  overflowY: 'scroll',
};

function CarouselModal({ isActiveModals, setPayload, closeModal, selectedModalInfos }) {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [establishmentsOptions, setEstablishmentsOptions] = useState([]);
  const [inputSearch, setInputSearch] = useState('');
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedOptionsName, setSelectedOptionsName] = useState([]);
  const [loadingSearch, setLoadingSearch] = useState(false);
  const { form, validateForm, handleChange, setForm, clearForm } = useForm({
    carouselBackground: {
      value: '',
      required: false,
    },
    carouselData: {
      value: [],
      required: true,
    },
    title: {
      value: '',
      required: true,
    },
    carouselType: {
      value: '',
      required: true,
    },
    carouselAuxiliarImage: {
      value: false,
      required: true,
    },
  });

  const currentModal = ModelLPSignUp.modalsList.carousel;

  const renderTypeOptions = ModelCarouselModal.carouselTypes?.map(({ option, type }) => (
    <MenuItem key={type} value={type}>
      {option}
    </MenuItem>
  ));

  const handleSave = () => {
    if (validateForm()) {
      setPayload((prevState) => {
        const modules = prevState?.campaignLandpage?.modules;
        modules[selectedModalInfos.currentIndex] = {
          ...selectedModalInfos.modalInfos,
          carouselBackground: form?.carouselBackground?.value,
          carouselData: form?.carouselData?.value,
          title: form?.title?.value,
          carouselType: form?.carouselType?.value,
          carouselAuxiliarImage: form?.carouselAuxiliarImage?.value,
        };
        return {
          campaignLandpage: {
            ...prevState?.campaignLandpage,
            modules,
          },
        };
      });
      toast.success('Carrossel salvo com sucesso!');
      clearForm();
      setSelectedOptions([]);
      closeModal(currentModal);
    } else {
      toast.error('Preencha todos os campos!');
    }
  };

  const setListTypes = async (input, carouselType) => {
    setLoadingSearch(true);
    const response =
      carouselType === 'promotion'
        ? await ModelCarouselModal.getListPromotions(input, true, 0, 10)
        : await ModelCarouselModal.getListEstablishments(input, 0, 10);
    const options = response?.map(({ nome, id, title }) => ({ nome: nome || title, id }));
    setEstablishmentsOptions(options);
    setLoadingSearch(false);
  };

  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func.apply(this, args), delay);
    };
  };

  const debouncedSearch = useCallback(
    debounce((input) => {
      setListTypes(input, form.carouselType.value);
    }, 500),
    [form.carouselType.value]
  );

  const handleCloseModal = () => {
    setSelectedOptions([]);
    closeModal(currentModal);
    clearForm();
  };

  const getPromoOrEstabName = async (idsList) => {
    let response;
    if (form.carouselType.value === 'promotion')
      response = await ModelCarouselModal.getStoreNameById(idsList);
    else if (form.carouselType.value === 'establishment')
      response = (await ModelCarouselModal.getEstablishmentsNameById(idsList)).result;
    const selectedOptionsName = (response || [])?.map(({ title, nome }) => title || nome);
    setSelectedOptionsName(selectedOptionsName);
  };

  useEffect(() => {
    if (isActiveModals) {
      const { carouselBackground, carouselData, title, carouselType, carouselAuxiliarImage } =
        selectedModalInfos?.modalInfos;

      const hashrgx = /#/;

      setForm((prevState) => ({
        ...prevState,
        carouselBackground: {
          value: hashrgx.test(carouselBackground)
            ? hexToRgba(carouselBackground)
            : carouselBackground || 'rgba(255, 255, 255, 1)',
          required: false,
        },
        carouselData: {
          value: carouselData || '',
          required: true,
        },
        title: {
          value: title || '',
          required: true,
        },
        carouselType: {
          value: carouselType || '',
          required: true,
        },
        carouselAuxiliarImage: {
          value: carouselAuxiliarImage || false,
          required: true,
        },
      }));

      setSelectedOptions(carouselData);
      getPromoOrEstabName(carouselData);
    }
  }, [isActiveModals, selectedModalInfos]);

  useEffect(() => {
    if (form.carouselType.value && inputSearch) debouncedSearch(inputSearch);
  }, [form.carouselType.value, inputSearch]);

  useEffect(() => {
    if (selectedOptions?.length) getPromoOrEstabName(selectedOptions);
  }, [selectedOptions]);

  return (
    <Modal defaultValue={false} open={isActiveModals}>
      <Box
        justifyContent={'space-between'}
        display={'flex'}
        flexDirection={'column'}
        alignItems={'flex-start'}
        width={'100%'}
        maxHeight={'90vh'}
        maxWidth={'1000px'}
        bgcolor={'white'}
        borderRadius={'5px'}
        sx={style}
      >
        <FormGroup>
          <FormControlLabel
            control={
              <Switch id="carouselAuxiliarImage" onChange={(event) => handleChange(event)} />
            }
            label="Imagem auxiliar"
          />
        </FormGroup>

        <Box width={'100%'} display={'flex'} flexDirection={'column'}>
          <Box display={'flex'} alignItems={'center'} width={'100%'} columnGap={'12px'}>
            <TextField
              fullWidth
              label="Cor de fundo"
              id="carouselBackground"
              margin="normal"
              value={form?.carouselBackground?.value || ''}
              InputProps={{
                readOnly: true,
              }}
            />
            <Button
              sx={{
                backgroundColor: form.carouselBackground?.value,
                width: '304px',
                height: 'inherit',
                color: form.carouselBackground.value
                  ? colorText(rgbaToHex(form.carouselBackground?.value))
                  : '#000',
              }}
              variant="contained"
              onClick={() => setDisplayColorPicker((prevState) => !prevState)}
            >
              {displayColorPicker ? 'Salvar' : 'Selecione a cor de fundo'}
            </Button>
          </Box>
          {displayColorPicker && (
            <ChromePicker
              color={form?.carouselBackground?.value || 'rgba(255, 255, 255, 1)'}
              onChange={(event) =>
                setForm((prevState) => ({
                  ...prevState,
                  carouselBackground: {
                    ...prevState.carouselBackground,
                    value: `rgba(${event.rgb.r}, ${event.rgb.g}, ${event.rgb.b}, ${event.rgb.a})`,
                  },
                }))
              }
            />
          )}
        </Box>

        <TextField
          fullWidth
          label="Texto"
          id="title"
          margin="normal"
          value={form.title?.value || ''}
          onChange={(event) => handleChange(event)}
        />

        <FormControl fullWidth margin="normal">
          <InputLabel id="establishmentOrPromotion">
            Selecione estabelecimento ou promoção
          </InputLabel>
          <Select
            inputProps={{
              name: 'carouselType',
            }}
            onChange={(event) => {
              setSelectedOptionsName([]);
              setSelectedOptions([]);
              setForm((prevState) => ({
                ...prevState,
                carouselData: {
                  ...prevState,
                  value: [],
                },
              }));
              handleChange(event);
            }}
            label="Selecone estabelecimento ou promoção"
            value={form?.carouselType?.value || ''}
            margin="dense"
            size="20"
          >
            {renderTypeOptions}
          </Select>
        </FormControl>

        {form?.carouselType?.value && (
          <Autocomplete
            fullWidth
            multiple
            id="search-bar"
            options={establishmentsOptions}
            value={selectedOptions}
            getOptionLabel={(option) => `Nome: ${option.nome} | ID: ${option.id}`}
            isOptionEqualToValue={(option, selectedValue) => option.id === selectedValue}
            onChange={(_, newValue) => {
              const result = newValue.map((item) => item.id || item);
              setForm((form) => ({
                ...form,
                carouselData: {
                  ...form.carouselData,
                  value: result,
                },
              }));
              setSelectedOptions(result);
            }}
            renderTags={(_, getTagProps) => {
              return selectedOptionsName.map((option, index) => (
                <Chip
                  key={option.id || option}
                  label={option.id || option}
                  {...getTagProps({ index })}
                />
              ));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                onChange={(event) => setInputSearch(event.target.value)}
                value={inputSearch}
                variant="outlined"
                label={
                  loadingSearch ? (
                    <>
                      <CircularProgress size={20} color="inherit" />
                      &nbsp;Pesquisando...
                    </>
                  ) : form?.carouselType?.value === 'promotion' ? (
                    'Selecione as promoções'
                  ) : (
                    'Selecione os estabelecimentos'
                  )
                }
                placeholder="Pesquise"
                disabled={loadingSearch}
              />
            )}
          />
        )}

        <Box
          marginTop={'20px'}
          display={'flex'}
          alignItems={'center'}
          columnGap={'12px'}
          justifyContent={'center'}
          width={'100%'}
        >
          <Button variant="contained" color="primary" onClick={handleSave}>
            salvar
          </Button>

          <Button variant="contained" color="primary" onClick={handleCloseModal}>
            cancelar
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default CarouselModal;
