import React, { useState, useEffect } from 'react';
import { store } from 'js/core/configureStore';

// estilização com styled-components
import * as S from './styles';
import * as D from '../../DesignSystem/styles';
// componentes do material ui v5
import { Typography, Button, Tooltip, CircularProgress } from '@mui/material';
// icones
import { LockClock } from '@mui/icons-material';
import searchIcon from '../../../../styles/assets/SearchUser/search.svg';
import walletIcon from '../../../../styles/assets/SearchUser/wallet.svg';
import crownIcon from '../../../../styles/assets/SearchUser/crown.svg';
import keyIcon from '../../../../styles/assets/SearchUser/key.svg';
import orgIcon from '../../../../styles/assets/SearchUser/org.svg';
import trashIcon from '../../../../styles/assets/SearchUser/trash.svg';
import AuthorizedAndRegisteredIcon from '../../../../styles/assets/SearchUser/AuthorizedAndRegistered-icon.svg';
import AuthorizedIcon from '../../../../styles/assets/SearchUser/Authorized-icon.svg';
import NotAuthorizedIcon from '../../../../styles/assets/SearchUser/NotAuthorized-icon.svg';
import { IoIosArrowDown } from 'react-icons/io';
import { MdEdit } from 'react-icons/md';
import { BsFillTelephoneFill } from 'react-icons/bs';

import { findValueInArray, randomizeNumbersString } from '../../../library/utils/helpers';
import { GetUserMapfre } from 'js/library/utils/API/SearchUser/apiGetUserMapfre';
import { userReadCellphone_v3, userReadUid_v3 } from 'js/library/utils/API/apiUserRead';
import { toast, ToastContainer } from 'react-toastify';
import { PrintUser } from './PrintUser';
import DeleteUserModal from './Modals/DeleteUserModal';
import AddKeywords from './Modals/AddKeywords';
import AddOrgs from './Modals/AddOrgs';
import Wallet from './Modals/Wallet';
import EditInfosUser from './Modals/EditInfosUser';
import Signature from './Modals/Signature';
import BatchKeywords from './Modals/BatchKeywords';
import phoneMaskArray from 'js/components/DesignSystem/phoneMaskArray.json';
import validateUserMapfre from 'js/library/utils/API/SearchUser/ValidateUserMapfre';
import getUserSorteSaude from 'js/library/utils/API/SearchUser/apiGetUserSorteSaude';
import GeneratePassword from './Modals/GeneratePassword';
import PrimaryButton from 'js/components/TriiboComponents/Buttons/PrimaryButton';
import DropDown from 'js/components/DesignSystem/DropDown';
import { getSeuClubList } from 'js/library/utils/API/seuClube';
import { GetPartnerUserForms } from 'js/library/utils/API/getPartnerUserById';
import RenderInputDynamic from './RenderInputDynamic';
import { GetAllUserPartnersByCellphone } from 'js/library/utils/API/SearchUser/apiGetAllUserPartnersByCellphone';
import { ValidateUserPartner } from 'js/library/utils/API/apiValidateUserPartnerClub';
import { GetUserPartner } from 'js/library/utils/API/apiGetUserPartner';
import EditInfosMapfre from './Modals/EditInfosMapfre';
import EditInfosSorteSaude from './Modals/EditInfosSorteSaude';
import EditInfosClube from './Modals/EditInfosClube';
import ChangeCellphone from './Modals/ChangeCellphone';
import { purple } from '@mui/material/colors';
import { GoBackHeader } from '../CreateClub/RelationshipClub/GoBackHeader';
import { HeaderTitle } from '../CreateClub/RelationshipClub/HeaderTitle';

export default function SearchUser() {
  const [inputSearchCellPhone, setInputSearchCellPhone] = useState('');
  const [inputSearch, setInputSearch] = useState('');
  const [userv3, setUserv3] = useState(null);
  const [userPartnersLists, setUserPartnersLists] = useState([]);
  const [userUid, setUserUid] = useState('');
  const [searchSelected, setSearchSelected] = useState('');

  const [loading, setLoading] = useState(false);
  const [currentCountry, setCurrentCountry] = useState('');
  const [currentDDD, setCurrentDDD] = useState('+55');
  const [input, setInput] = useState('');
  const [inputMask, setInputMask] = useState('(99) 99999-9999');

  const [clubes, setClubes] = useState([]);
  const [selectedCommunity, setSelectedCommunity] = useState('');
  const [isCommunitiesOpen, setIsCommunitiesOpen] = useState(false);
  const [communities, setCommunities] = useState([]);
  const [formFields, setFormFields] = useState([]);
  const [accessField, setAccessField] = useState({});
  const [cardsToOpen, setCardsToOpen] = useState([]);

  const [isModalDeleteUserOpen, setIsModalDeleteUserOpen] = useState(false);
  const [isModalAddKeywordsOpen, setIsModalAddKeywordsOpen] = useState(false);
  const [isModalAddOrgsOpen, setIsModalAddOrgsOpen] = useState(false);
  const [isModalWalletOpen, setIsModalWalletOpen] = useState(false);
  const [isModalSignature, setIsModalSignature] = useState(false);
  const [isModalGenerate, setIsModalGenerate] = useState(false);
  const [isModalBatchKeywordOpen, setIsModalBatchKeywordOpen] = useState(false);
  const [isModalEditInfoUserOpen, setIsModalEditInfoUserOpen] = useState(false);
  const [isModalEditInfosMapfre, setIsModalEditInfosMapfre] = useState(false);
  const [isModalEditInfosSorteSaude, setIsModalEditInfosSorteSaude] = useState(false);
  const [isModalEditInfosClube, setIsModalEditInfosClube] = useState(false);
  const [isModalChangeCellphoneOpen, setIsModalChangeCellphoneOpen] = useState(false);
  const [partnerSellected, setPartnerSellected] = useState({});
  const [partnerNameSellected, setPartnerNameSellected] = useState('');

  const handleCountryChange = (e) => {
    const valuesArr = e.target.value.split(','); //transforma a string em um array a partir da vírgula
    setInputSearchCellPhone('');
    setCurrentDDD(valuesArr[0]);
    setInputMask(valuesArr[1]);
    setCurrentCountry(e.target.value);
  };

  useEffect(() => {
    setInput(currentDDD + inputSearchCellPhone.replace(/\D/g, ''));
  }, [inputSearchCellPhone]);

  const handleInputSearchCellPhone = (event) => {
    setInputSearchCellPhone(event.target.value);
  };

  const getUserV3ByCellphone = (cellPhone, findCardsToOpen) => {
    userReadCellphone_v3(cellPhone)
      .then((result) => {
        if (result.userInfo !== false) {
          result.userInfo =
            Object.keys(result.userInfo).length === 1 &&
            (result.userInfo.uId !== undefined || result.userInfo.uId !== null)
              ? null
              : result.userInfo;

          findCardsToOpen.push({
            cardId: 'Triibo',
            isOpen: false,
          });
          setUserUid(result.userInfo.uId);
          setUserv3(result.userInfo);
        } else {
          toast.error('Usuário não encontrado na V3');
        }
      })
      .catch(() => {
        toast.error('Erro ao buscar usuário');
      });
  };

  const getUserV3ByUid = (uId, findCardsToOpen) => {
    userReadUid_v3(uId, false)
      .then((result) => {
        const setResult = async (userResult) => {
          if (userResult.userInfo !== false) {
            findCardsToOpen.push({
              cardId: 'Triibo',
              isOpen: false,
            });

            setUserUid(result.userInfo.uId);
            setUserv3(userResult.userInfo);
          } else {
            toast.error('Usuário não encontrado na V3');
          }
        };

        if (Array.isArray(result.userInfo)) {
          result.userInfo.forEach(setResult);
        } else {
          setResult(result);
        }
      })
      .catch((error) => {
        toast.error('Erro ao buscar usuário');
      });
  };

  const handleGetUser = async (event) => {
    event.preventDefault();
    setUserv3(null);
    setLoading(true);
    setCardsToOpen([]);
    setUserPartnersLists([]);

    const uId = store.getState().codeInputModel.userInfo.uId;

    const cellPhone = input;
    let findCardsToOpen = [];

    if (searchSelected === 'cellPhone') {
      getUserV3ByCellphone(cellPhone, findCardsToOpen);
      try {
        const userPartners = await GetAllUserPartnersByCellphone(uId, cellPhone);

        userPartners.response.map((partner) => {
          findCardsToOpen.push({
            cardId: partner.partnerName,
            isOpen: false,
          });
        });
        setLoading(false);
        setUserPartnersLists(userPartners.response);
      } catch (error) {
        toast.error('Nenhum partner do usuário encontrado.');
        setLoading(false);
      }
      setCardsToOpen(findCardsToOpen);
    } else {
      if (selectedCommunity.value === 'clube_mapfre') {
        const validUserMapfre = await validateUserMapfre(inputSearch.replace(/\D/g, ''));
        let partnerListMapfre = {};

        if (validUserMapfre.user) {
          if (
            validUserMapfre.codStatus === '0' ||
            validUserMapfre.codStatus === '1' ||
            validUserMapfre.codStatus === '5'
          ) {
            partnerListMapfre.status = 'AuthorizedAndRegistered';
          }

          if (
            validUserMapfre.codStatus === '2' ||
            validUserMapfre.codStatus === '3' ||
            validUserMapfre.codStatus === '4'
          ) {
            partnerListMapfre.status = 'NotAuthorized';
          }

          if (validUserMapfre.codStatus === '6') {
            partnerListMapfre.status = 'ExceptionList';
          }
        }

        if (!validUserMapfre.user) {
          if (
            validUserMapfre.codStatus === '0' ||
            validUserMapfre.codStatus === '1' ||
            validUserMapfre.codStatus === '5'
          ) {
            partnerListMapfre.status = 'Authorized';
          }

          if (
            validUserMapfre.codStatus === '2' ||
            validUserMapfre.codStatus === '3' ||
            validUserMapfre.codStatus === '4'
          ) {
            partnerListMapfre.status = 'NotAuthorized';
          }

          if (validUserMapfre.codStatus === '6') {
            partnerListMapfre.status = 'ExceptionList';
          }
        }

        if (validUserMapfre.uId) {
          getUserV3ByUid(validUserMapfre.uId, findCardsToOpen);
          setUserUid(validUserMapfre.uId);

          const valueUserMapfre = await GetUserMapfre(validUserMapfre.uId);

          if (valueUserMapfre.partnerInfo) {
            partnerListMapfre = {
              ...partnerListMapfre,
              ...valueUserMapfre.partnerInfo,
            };
          }
        } else {
          partnerListMapfre.user = 'Não encontrado.';
        }

        partnerListMapfre.partnerName = 'Mapfre';

        findCardsToOpen.push({
          cardId: 'Mapfre',
          isOpen: false,
        });

        setCardsToOpen(findCardsToOpen);
        setLoading(false);
        setUserPartnersLists([partnerListMapfre]);
        return;
      }

      if (selectedCommunity.value === 'sorte_saude') {
        const value = await getUserSorteSaude(inputSearch.replace(/\D/g, ''));

        if (!value.success) {
          const { error } = value.response.data;

          setLoading(false);
          setCardsToOpen(findCardsToOpen);
          return toast.error(error);
        } else {
          getUserV3ByUid(value.response.uId, findCardsToOpen);

          const partnerList = value.response.partnerList.find(
            (partner) => partner.partnerName === 'BrasilCap'
          );

          findCardsToOpen.push({
            cardId: partnerList.partnerName,
            isOpen: false,
          });
          partnerList.status = 'AuthorizedAndRegistered';
          setLoading(false);
          setUserUid(value.response.uId);
          setCardsToOpen(findCardsToOpen);

          return setUserPartnersLists([partnerList]);
        }
      }

      const selecttedClub = clubes.find((club) => club.partnerId === selectedCommunity.value);

      const validateUserInfos = await ValidateUserPartner(
        accessField.inputMask === 'cpf' ? inputSearch.replace(/\D/g, '') : inputSearch,
        selectedCommunity.value
      );

      if (validateUserInfos.cellPhone) {
        getUserV3ByCellphone(validateUserInfos.cellPhone, findCardsToOpen);
      }

      const partner = await GetUserPartner(selectedCommunity.value, validateUserInfos.uId);
      let clubPartner;

      if (!partner.response) {
        findCardsToOpen.push({
          cardId: selecttedClub.clubeId,
          isOpen: false,
        });
        clubPartner = {
          partnerName: selecttedClub.clubeId,
          user: 'Não encontrado.',
        };
        toast.error('Usuário não encontrado nesse clube.');
      } else {
        findCardsToOpen.push({
          cardId: partner.response.partnerName,
          isOpen: false,
        });

        clubPartner = partner.response;
      }

      clubPartner.status = !validateUserInfos.isEnabled
        ? 'NotAuthorized'
        : validateUserInfos.user
        ? 'AuthorizedAndRegistered'
        : 'Authorized';
      setUserPartnersLists([clubPartner]);

      setCardsToOpen(findCardsToOpen);
      setLoading(false);
    }
  };

  const handleCloseModal = (action) => {
    switch (action) {
      case 'closeModalDeleteUser':
        setIsModalDeleteUserOpen(false);
        break;
      case 'closeModalAddKeywords':
        setIsModalAddKeywordsOpen(false);
        break;
      case 'closeModalAddOrgs':
        setIsModalAddOrgsOpen(false);
        break;
      case 'closeModalWallet':
        setIsModalWalletOpen(false);
        break;
      case 'closeModalSignature':
        setIsModalSignature(false);
        break;
      case 'closeModalBatchKeyword':
        setIsModalBatchKeywordOpen(false);
        break;
      case 'closeModalGenerate':
        setIsModalGenerate(false);
        break;
      case 'closeModalEditInfoUser':
        setIsModalEditInfoUserOpen(false);
        break;
      case 'closeModalEditInfosMapfre':
        setIsModalEditInfosMapfre(false);
        break;
      case 'closeModalEditInfosSorteSaude':
        setIsModalEditInfosSorteSaude(false);
        break;
      case 'closeModalEditInfosClube':
        setIsModalEditInfosClube(false);
        break;
      case 'closeModalChangeCellphone':
        setIsModalChangeCellphoneOpen(false);
        break;
      default:
        break;
    }
  };

  const handleOpenEditModals = (partnerName) => {
    if (partnerName === 'Triibo') {
      return setIsModalEditInfoUserOpen(true);
    }

    if (partnerName === 'Mapfre') {
      return setIsModalEditInfosMapfre(true);
    }

    if (partnerName === 'BrasilCap') {
      return setIsModalEditInfosSorteSaude(true);
    }

    setIsModalEditInfosClube(true);
  };

  const handleOpenCard = (cardName) => {
    const updatedCards = cardsToOpen.map((card) => {
      if (card.cardId === cardName) {
        return { ...card, isOpen: !card.isOpen };
      }
      return card;
    });

    setCardsToOpen(updatedCards);
  };

  const handleChangeSearchSelect = (option) => {
    setUserv3(null);
    setCardsToOpen([]);
    setUserPartnersLists([]);
    setSearchSelected(option);
    setInputSearchCellPhone('');
    setCurrentDDD('+55');
    setInput('');
  };

  const handleClickGenerete = (e) => {
    e.preventDefault();
    if (userv3) {
      setIsModalGenerate(true);
    } else {
      toast.error('Não é possível gerar um código para um usuário sem telefone !');
    }
  };

  useEffect(() => {
    const userInfo = store.getState().codeInputModel.userInfo;

    async function getListClubs() {
      const list = await getSeuClubList(userInfo.uId);
      setClubes(list);

      const formattedList = list.map((clube) => {
        return {
          label: clube.clubeId,
          value: clube.partnerId,
        };
      });
      setCommunities([
        {
          label: 'Club MAPFRE',
          value: 'clube_mapfre',
        },
        {
          label: 'Sorte Saúde',
          value: 'sorte_saude',
        },
        ...formattedList,
      ]);
    }
    getListClubs();
  }, []);

  useEffect(() => {
    // chamar api de forms do partner
    if (selectedCommunity) {
      setUserv3(null);
      setCardsToOpen([]);
      setUserPartnersLists([]);

      if (selectedCommunity.value !== 'clube_mapfre' && selectedCommunity.value !== 'sorte_saude') {
        GetPartnerUserForms(selectedCommunity.value)
          .then((res) => {
            const getAccessTokenField = res.formFields.filter((field) => field.indexKey)[0];

            setAccessField(getAccessTokenField);
            setFormFields(res.formFields);
          })
          .catch((err) => {
            toast.error('Clube não possui chave de acesso ainda.');
          });
      } else {
        setAccessField({
          inputMask: '999.999.999-99',
          inputType: 'text',
          fieldName: 'cpf',
          label: 'CPF',
        });
      }
    }
  }, [selectedCommunity]);

  return (
    <S.SearchUserBox height={userv3 !== null ? '100%' : '100vh'} style={{ padding: '4rem 32px' }}>
      <GoBackHeader />
      <HeaderTitle pageInfo="Pesquisar usuário" />
      <ToastContainer autoClose={2000} />
      <S.Header>
        <Button
          title="Alterar keywords em lote"
          variant="contained"
          color="primary"
          size="medium"
          onClick={() => setIsModalBatchKeywordOpen(true)}
        >
          Alterar keywords em lote
        </Button>
      </S.Header>

      <S.Content>
        <S.OptionsSearch>
          <PrimaryButton
            width={'100%'}
            color={
              searchSelected ? (searchSelected === 'accessToken' ? '#fff' : '#B0B0B0') : '#fff'
            }
            bgColor={
              searchSelected
                ? searchSelected === 'accessToken'
                  ? '#6e3296'
                  : '#F2F2F2'
                : '#6e3296'
            }
            onClick={() => handleChangeSearchSelect('accessToken')}
          >
            Chave de acesso
          </PrimaryButton>
          <PrimaryButton
            width={'100%'}
            color={searchSelected ? (searchSelected === 'cellPhone' ? '#fff' : '#B0B0B0') : '#fff'}
            bgColor={
              searchSelected ? (searchSelected === 'cellPhone' ? '#6e3296' : '#F2F2F2') : '#6e3296'
            }
            onClick={() => handleChangeSearchSelect('cellPhone')}
          >
            Número do celular
          </PrimaryButton>
        </S.OptionsSearch>

        {searchSelected ? (
          searchSelected === 'cellPhone' ? (
            <S.BoxSearchInput component={'form'} onSubmit={() => handleGetUser}>
              <S.Adornment>
                <select
                  style={{
                    fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                    flex: '1',
                    height: '100%',
                    border: 'none',
                    outline: 'none',
                    fontSize: '17px',
                    color: '#383838d6',
                    borderRadius: '10px',
                    cursor: 'pointer',
                  }}
                  value={currentCountry}
                  onChange={handleCountryChange}
                >
                  {phoneMaskArray.map((country, index) => (
                    <option
                      style={{
                        fontFamily: "NotoColorEmojiLimited, 'Source Sans Pro', sans-serif",
                      }}
                      key={country.id}
                      value={[country.ddd, country.mask, country.fullMask]}
                    >
                      {country.emoji} {country.initials} {country.ddd}
                    </option>
                  ))}
                </select>
              </S.Adornment>
              <S.SerachInput
                mask={inputMask}
                value={inputSearchCellPhone}
                placeholder={randomizeNumbersString(inputMask)}
                onChange={handleInputSearchCellPhone}
                className="inputSearch"
              />
              <div className="searchIcon">
                <Button type="submit" onClick={handleGetUser}>
                  <img src={searchIcon} alt="icone-pesquisa" />
                </Button>
              </div>
            </S.BoxSearchInput>
          ) : (
            <S.AccessTokenFilters>
              <DropDown
                selectedOption={selectedCommunity}
                setSelectedOption={setSelectedCommunity}
                isSelectOpen={isCommunitiesOpen}
                setIsSelectOpen={setIsCommunitiesOpen}
                data={communities}
                placeholder={'Selecione uma comunidade'}
                top={true}
                height={'42px'}
                disabled={communities.length < 1}
              />
              {selectedCommunity ? (
                <RenderInputDynamic
                  fieldInfos={accessField}
                  inputValue={inputSearch}
                  setInputValue={setInputSearch}
                  handleSubmit={handleGetUser}
                />
              ) : (
                <div></div>
              )}
            </S.AccessTokenFilters>
          )
        ) : null}

        <S.UserInfoBox>
          {loading ? (
            <CircularProgress size="10rem" style={{ margin: '0 auto' }} />
          ) : userv3 || userPartnersLists.length > 0 ? (
            <>
              <S.ActionsBox>
                <div className="icons">
                  <ul>
                    <li>
                      <Tooltip title="Keywords">
                        <Button
                          className="buttonAction"
                          onClick={() => setIsModalAddKeywordsOpen(true)}
                        >
                          <img src={keyIcon} alt="icone-keywords" />
                        </Button>
                      </Tooltip>
                    </li>
                    <li>
                      <Tooltip title="Organizações">
                        <Button
                          className="buttonAction"
                          onClick={() => setIsModalAddOrgsOpen(true)}
                        >
                          <img src={orgIcon} alt="icone-organizaçoes" />
                        </Button>
                      </Tooltip>
                    </li>
                    <li>
                      <Tooltip title="Carteira">
                        <Button className="buttonAction" onClick={() => setIsModalWalletOpen(true)}>
                          <img src={walletIcon} alt="icone-carteira" />
                        </Button>
                      </Tooltip>
                    </li>
                    <li>
                      <Tooltip title="Assinaturas">
                        <Button className="buttonAction" onClick={() => setIsModalSignature(true)}>
                          <img src={crownIcon} alt="icone-assinatura" />
                        </Button>
                      </Tooltip>
                    </li>
                    <li>
                      <Tooltip title="Código Temporário">
                        <Button className="buttonAction" onClick={handleClickGenerete}>
                          <LockClock sx={{ color: purple[800] }} fontSize="large" />
                        </Button>
                      </Tooltip>
                    </li>
                    <li>
                      <Tooltip title="Trocar Telefone">
                        <Button
                          className="buttonAction"
                          onClick={() => setIsModalChangeCellphoneOpen(true)}
                        >
                          <S.IconPhone>
                            <BsFillTelephoneFill color="#fff" />
                          </S.IconPhone>
                        </Button>
                      </Tooltip>
                    </li>
                  </ul>
                </div>
                <div className="deleteUser">
                  <Button
                    onClick={() => {
                      setPartnerNameSellected('Triibo');
                      setPartnerSellected(null);
                      setIsModalDeleteUserOpen(true);
                    }}
                  >
                    <img src={trashIcon} alt="icone-carteira" />
                    <Typography variant="h6" component="span">
                      Excluir usuário
                    </Typography>
                  </Button>
                </div>
              </S.ActionsBox>
              <S.CardsContainer>
                {searchSelected === 'cellPhone' && userv3 && (
                  <S.CardContainer>
                    <S.CardHeader onClick={() => handleOpenCard('Triibo')}>
                      <S.CardHeaderItem>
                        <span>Comunidade</span>
                        <D.TriiboH4>Triibo</D.TriiboH4>
                      </S.CardHeaderItem>

                      <S.EditInfosContainer
                        style={{ marginLeft: 'auto', marginRight: '0.3rem' }}
                        onClick={() => handleOpenEditModals('Triibo')}
                        visible={
                          cardsToOpen.length > 0
                            ? cardsToOpen.filter((card) => card.cardId === 'Triibo')[0].isOpen
                            : false
                        }
                      >
                        <S.IconEdit>
                          <MdEdit color="#fff" />
                        </S.IconEdit>
                        Editar
                      </S.EditInfosContainer>
                      <IoIosArrowDown size={30} />
                    </S.CardHeader>
                    <S.CardContent
                      open={
                        cardsToOpen.length > 0
                          ? cardsToOpen.filter((card) => card.cardId === 'Triibo')[0].isOpen
                          : false
                      }
                    >
                      <PrintUser
                        user={userv3}
                        count={null}
                        title={'Triibo'}
                        setPartnerSellected={setPartnerSellected}
                        setPartnerNameSellected={setPartnerNameSellected}
                        setIsModalDeleteUserOpen={setIsModalDeleteUserOpen}
                      />
                    </S.CardContent>
                  </S.CardContainer>
                )}

                {userPartnersLists.length > 0 &&
                  userPartnersLists.map((partner, i) => (
                    <S.CardContainer key={i}>
                      <S.CardHeader onClick={() => handleOpenCard(partner.partnerName)}>
                        <S.CardHeaderItem>
                          <span>Comunidade</span>
                          <D.TriiboH4>{partner.partnerName}</D.TriiboH4>
                        </S.CardHeaderItem>
                        {searchSelected !== 'cellPhone' && (
                          <>
                            <S.CardHeaderItem>
                              <span>Status</span>
                              {partner.status === 'NotAuthorized' ? (
                                <S.Status>
                                  <img src={NotAuthorizedIcon} alt="não autorizado" />
                                  <D.TriiboH4>Não Autorizado</D.TriiboH4>
                                </S.Status>
                              ) : partner.status === 'Authorized' ? (
                                <S.Status>
                                  <img src={AuthorizedIcon} alt="icone autorizado" />
                                  <D.TriiboH4>Autorizado</D.TriiboH4>
                                </S.Status>
                              ) : partner.status === 'ExceptionList' ? (
                                <S.Status>
                                  <img src={AuthorizedIcon} alt="icone autorizado" />
                                  <D.TriiboH4>Autorizado por ExceptionList</D.TriiboH4>
                                </S.Status>
                              ) : (
                                <S.Status>
                                  <img
                                    src={AuthorizedAndRegisteredIcon}
                                    alt="icone autorizado e registrado"
                                  />
                                  <D.TriiboH4>Autorizado e Cadastrado</D.TriiboH4>
                                </S.Status>
                              )}
                            </S.CardHeaderItem>

                            <S.EditInfosContainer
                              onClick={() => {
                                handleOpenEditModals(partner.partnerName);
                                setPartnerSellected(partner);
                              }}
                              visible={
                                partner.status !== 'AuthorizedAndRegistered'
                                  ? false
                                  : cardsToOpen.length > 0
                                  ? cardsToOpen.filter(
                                      (card) => card.cardId === partner.partnerName
                                    )[0].isOpen
                                  : false
                              }
                            >
                              <S.IconEdit>
                                <MdEdit color="#fff" />
                              </S.IconEdit>
                              Editar
                            </S.EditInfosContainer>
                          </>
                        )}

                        <IoIosArrowDown size={30} />
                      </S.CardHeader>
                      <S.CardContent
                        open={
                          cardsToOpen.length > 0
                            ? cardsToOpen.filter((card) => card.cardId === partner.partnerName)[0]
                                .isOpen
                            : false
                        }
                      >
                        <PrintUser
                          user={partner}
                          count={null}
                          title={partner.partnerName}
                          setPartnerSellected={setPartnerSellected}
                          setPartnerNameSellected={setPartnerNameSellected}
                          setIsModalDeleteUserOpen={setIsModalDeleteUserOpen}
                        />
                      </S.CardContent>
                    </S.CardContainer>
                  ))}

                {searchSelected !== 'cellPhone' && userv3 && (
                  <S.CardContainer>
                    <S.CardHeader onClick={() => handleOpenCard('Triibo')}>
                      <S.CardHeaderItem>
                        <span>Comunidade</span>
                        <D.TriiboH4>Triibo</D.TriiboH4>
                      </S.CardHeaderItem>
                      <div></div>
                      <S.EditInfosContainer
                        style={{ marginLeft: 'auto', marginRight: '0.3rem' }}
                        onClick={() => handleOpenEditModals('Triibo')}
                        visible={
                          cardsToOpen.length > 0
                            ? cardsToOpen.filter((card) => card.cardId === 'Triibo')[0].isOpen
                            : false
                        }
                      >
                        <S.IconEdit>
                          <MdEdit color="#fff" />
                        </S.IconEdit>
                        Editar
                      </S.EditInfosContainer>
                      <IoIosArrowDown size={30} />
                    </S.CardHeader>
                    <S.CardContent
                      open={
                        cardsToOpen.length > 0
                          ? cardsToOpen.filter((card) => card.cardId === 'Triibo')[0].isOpen
                          : false
                      }
                    >
                      <PrintUser
                        user={userv3}
                        count={null}
                        title={'Triibo'}
                        setPartnerSellected={setPartnerSellected}
                        setPartnerNameSellected={setPartnerNameSellected}
                        setIsModalDeleteUserOpen={setIsModalDeleteUserOpen}
                      />
                    </S.CardContent>
                  </S.CardContainer>
                )}
              </S.CardsContainer>
              <br />
              <br />
            </>
          ) : null}
        </S.UserInfoBox>
      </S.Content>

      {/* Modals */}

      {isModalDeleteUserOpen && (
        <DeleteUserModal
          userUid={userUid}
          isModalDeleteUserOpen={isModalDeleteUserOpen}
          handleCloseModal={handleCloseModal}
          setUserv3={setUserv3}
          partnerName={partnerNameSellected}
          partnerList={partnerSellected}
        />
      )}

      {isModalAddKeywordsOpen && (
        <AddKeywords
          isModalAddKeywordsOpen={isModalAddKeywordsOpen}
          handleCloseModal={handleCloseModal}
          userv3={userv3}
          setUserv3={setUserv3}
        />
      )}

      {isModalAddOrgsOpen && (
        <AddOrgs
          isModalAddOrgsOpen={isModalAddOrgsOpen}
          handleCloseModal={handleCloseModal}
          userv3={userv3}
        />
      )}

      {isModalWalletOpen && (
        <Wallet
          handleCloseModal={handleCloseModal}
          isModalWalletOpen={isModalWalletOpen}
          userv3={userv3}
        />
      )}

      {isModalEditInfoUserOpen && (
        <EditInfosUser
          isModalEditInfoUserOpen={isModalEditInfoUserOpen}
          handleCloseModal={handleCloseModal}
          userv3={userv3}
          setUserv3={setUserv3}
        />
      )}

      {isModalSignature && (
        <Signature
          handleCloseModal={handleCloseModal}
          isModalSignature={isModalSignature}
          userv3={userv3}
        />
      )}

      {isModalGenerate && (
        <GeneratePassword
          handleCloseModal={handleCloseModal}
          isModalGenerate={isModalGenerate}
          cellPhone={
            searchSelected === 'accessToken'
              ? findValueInArray(userv3.contactList, 'type', 'cellPhone').value
              : input
          }
        />
      )}

      {isModalBatchKeywordOpen && (
        <BatchKeywords
          handleCloseModal={handleCloseModal}
          isModalBatchKeywordOpen={isModalBatchKeywordOpen}
        />
      )}

      {isModalEditInfosMapfre && (
        <EditInfosMapfre
          userUid={userUid}
          handleCloseModal={handleCloseModal}
          isModalEditInfosMapfre={isModalEditInfosMapfre}
          partnerList={partnerSellected}
        />
      )}

      {isModalEditInfosSorteSaude && (
        <EditInfosSorteSaude
          userUid={userUid}
          handleCloseModal={handleCloseModal}
          isModalEditInfosSorteSaude={isModalEditInfosSorteSaude}
          partnerList={partnerSellected}
        />
      )}

      {isModalEditInfosClube && (
        <EditInfosClube
          userUid={userUid}
          formFields={formFields}
          handleCloseModal={handleCloseModal}
          isModalEditInfosClube={isModalEditInfosClube}
          partnerList={partnerSellected}
        />
      )}

      {isModalChangeCellphoneOpen && (
        <ChangeCellphone
          userUid={userUid}
          userInfo={userv3}
          isModalChangeCellphoneOpen={isModalChangeCellphoneOpen}
          handleCloseModal={handleCloseModal}
        />
      )}
    </S.SearchUserBox>
  );
}

